import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { constants as STORAGEKEY } from '../constants/constant';
import { constants as ROUTE_CONST } from '../constants/routes';

const AppRoute = ({ component: Component, layout: Layout, isAuthProtected, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (isAuthProtected && !localStorage.getItem(STORAGEKEY.ACCESSTOKEN)) {
        return (
          <Redirect
            to={{ pathname: ROUTE_CONST.LANDING_PAGE, state: { from: props.location } }}
          />
        );
      }

      return (
        <Layout>
          <Component {...props} />
        </Layout>
      );
    }}
  />
);

export default AppRoute;
