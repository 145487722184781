import React, { useEffect, useState } from 'react';
import { Col, Collapse, Container, FormGroup, Label } from 'reactstrap';
import { Formik, Form } from 'formik';
import get from 'lodash/get';
import * as Yup from 'yup';
import moment from 'moment';
import AuthInput from '../../../Custom/AuthInput';
import { GetApplication, UpdateExchangeApplication } from '../../../services/Application';
import { StepsCalculator } from './StepsCalculator';
import { notificationMessageFunction } from '../../../constants/notificationConst';
import toast from 'react-hot-toast';
import Loader from '../../../constants/Loader';

const getMemberSchema = (length) => {
  let schema = {};
  for (let index = 0; index < length; index++) {
    schema = {
      ...schema,
      [`first_name_${index}`]: Yup.string(),
      [`last_name_${index}`]: Yup.string(),
      [`email_${index}`]: Yup.string().email('Invalid Email'),
      [`linkedin_${index}`]: Yup.string(),
      [`position_${index}`]: Yup.string(),
      [`start_date_${index}`]: Yup.date(),
      [`end_date_${index}`]: Yup.date()
    };
  }
  return Yup.object().shape(schema);
};
function Step3({
  isLoading,
  setIsLoading,
  setExchangeCompleteStep,
  applicationId,
  nextPage,
  setApplicationData,
  prevPage,
  applicationData,
  isUpdateExchange = false,
  isSectionCollapsed,
  toggleSection,
  GetApplicationDetailsEdit,
  primaryTextState
}) {
  const [teamMembers, setTeamMembers] = useState([]);
  const [completeStep, setCompleteStep] = useState(0);

  useEffect(() => {
    if (applicationId !== '' && !isUpdateExchange) {
      GetApplication({ application_id: applicationId }).then((res) => {
        const application = get(res, 'data.data.data[0]', '');
        if (application.exchange_application.team_members.length > 0) {
          let teamMembersValues = [];
          for (const member of application.exchange_application.team_members) {
            teamMembersValues.push({
              first_name: member.first_name,
              last_name: member.last_name,
              email: member.email,
              linkedin: member.linkedin,
              position: member.position,
              start_date:
                member && member.previous_experience
                  ? moment(member.previous_experience[0].start_date).format('YYYY-MM-DD')
                  : '',
              end_date:
                member && member.previous_experience
                  ? moment(member.previous_experience[0].end_date).format('YYYY-MM-DD')
                  : ''
            });
          }
          setTeamMembers(application.exchange_application.team_members);
        }
        setCompleteStep(application.exchange_application.percentage_complete);
      });
    } else {
      if (
        applicationData &&
        applicationData.exchange_application &&
        applicationData.exchange_application.team_members.length > 0
      ) {
        let teamMembersValues = [];
        for (const member of applicationData.exchange_application.team_members) {
          teamMembersValues.push({
            first_name: member.first_name,
            last_name: member.last_name,
            email: member.email,
            linkedin: member.linkedin,
            position: member.position,
            start_date:
              member &&
              member.previous_experience &&
              member.previous_experience.length > 0 &&
              member.previous_experience[0].start_date
                ? moment(member.previous_experience[0].start_date).format('YYYY-MM-DD')
                : '',
            end_date:
              member &&
              member.previous_experience &&
              member.previous_experience.length > 0 &&
              member.previous_experience[0].end_date
                ? moment(member.previous_experience[0].end_date).format('YYYY-MM-DD')
                : ''
          });
        }
        setTeamMembers(applicationData.exchange_application.team_members);
      }
    }
  }, [applicationData, applicationId, isUpdateExchange]);

  const separateValuesIntoMembers = (values) => {
    const members = [];
    Object.keys(values).forEach((key) => {
      const match = key.match(/_(\d+)$/);
      if (!match) return;
      const index = parseInt(match[1], 10);
      const newKey = key.replace(/_\d+$/, '');
      if (!members[index]) members[index] = {};
      members[index][newKey] = values[key];
    });

    return members;
  };

  const addNewTeamMember = (values) => {
    const newMembers = separateValuesIntoMembers(values);
    const updatedMembers = [...newMembers, {}];
    setTeamMembers(updatedMembers);
  };

  const handleDeleteMember = (index) => {
    const newTeamMembers = [...teamMembers];
    newTeamMembers.splice(index, 1);
    setTeamMembers(newTeamMembers);
  };

  const generateInitialValues = () => {
    const initialValues = {};
    teamMembers.forEach((member, index) => {
      initialValues[`first_name_${index}`] = member.first_name || '';
      initialValues[`last_name_${index}`] = member.last_name || '';
      initialValues[`email_${index}`] = member.email || '';
      initialValues[`linkedin_${index}`] = member.linkedin || '';
      initialValues[`position_${index}`] = member.position || '';
      initialValues[`start_date_${index}`] = moment(member.start_date).format(
        'YYYY-MM-DD'
      );
      initialValues[`end_date_${index}`] = moment(member.end_date).format('YYYY-MM-DD');
    });
    return initialValues;
  };

  const handleSubmit = (values) => {
    const team_members = teamMembers.map((_, i) => ({
      first_name: values[`first_name_${i}`],
      last_name: values[`last_name_${i}`],
      email: values[`email_${i}`],
      linkedin: values[`linkedin_${i}`],
      position: values[`position_${i}`],
      previous_experience: [
        { start_date: values[`start_date_${i}`], end_date: values[`end_date_${i}`] }
      ]
    }));
    const stepsRequired = Math.floor(StepsCalculator(2, completeStep, 36));
    const data = {
      team_members: JSON.stringify(team_members),
      percentage_complete: stepsRequired
    };
    if (applicationId !== '') {
      UpdateExchangeApplication({ application_id: applicationId }, data)
        .then((res) => {
          const application = get(res, 'data.data.data', '');
          setApplicationData(application);
          if (isUpdateExchange) {
            toast.success('Team Members updated successfully', {
              style: { fontSize: '12px' }
            });
            GetApplicationDetailsEdit(applicationId);
          }
          nextPage();
          setIsLoading(false);
        })
        .catch((err) => {
          const errorMessage = get(err, 'data.error.message');
          toast.error(notificationMessageFunction(errorMessage), {
            style: { fontSize: '12px' }
          });
        });
    }

    setExchangeCompleteStep(Array.from({ length: 3 }, (_, index) => index));
  };

  return (
    <React.Fragment>
      <div onClick={isSectionCollapsed ? toggleSection : undefined}>
        {isUpdateExchange ? (
          <>
            <div
              className='cursor-pointer col-12 font-size-14 ml-2 d-flex justify-content-between align-items-center'
              onClick={toggleSection}>
              <span
                className='text-black font-weight-400'
                style={{ color: primaryTextState }}>
                Team Members
              </span>
              <i
                className={`font-size-22 mdi mdi-chevron-${
                  isSectionCollapsed === true ? 'up' : 'down'
                }`}
                style={{ color: primaryTextState }}
              />
            </div>
          </>
        ) : (
          <div className='col-12 font-size-16 pb-2 ml-2'>
            <span
              className='text-black font-weight-400'
              style={{ color: primaryTextState }}>
              Team Members
            </span>
          </div>
        )}
        <Collapse isOpen={!isSectionCollapsed}>
          {isLoading ? (
            <div className='mt-5'>
              <Loader />
            </div>
          ) : (
            <Container className='col-12 col-sm-auto'>
              <div>
                <Formik
                  enableReinitialize
                  initialValues={generateInitialValues()}
                  onSubmit={handleSubmit}
                  validationSchema={getMemberSchema(teamMembers.length)}>
                  {({ touched, errors, values, handleChange, setFieldTouched }) => (
                    <Form>
                      {teamMembers.map((_, index) => (
                        <div key={index}>
                          {teamMembers && teamMembers.length > 0 && (
                            <>
                              {[
                                'first_name',
                                'last_name',
                                'email',
                                'linkedin',
                                'position',
                                'start_date',
                                'end_date'
                              ].map((field) => (
                                <Col className='mt-2' key={`${field}_${index}`}>
                                  <CustomInput
                                    label={field.replace('_', ' ')}
                                    htmlFor={`${field}_${index}`}
                                    setFieldTouched={setFieldTouched}
                                    name={`${field}_${index}`}
                                    placeholder={field.replace('_', ' ')}
                                    values={values}
                                    handleChange={handleChange}
                                    errors={errors}
                                    touched={touched}
                                    type={field.includes('date') ? 'date' : 'text'}
                                    primaryTextState={primaryTextState}
                                  />
                                </Col>
                              ))}
                            </>
                          )}

                          <div className='col-12 d-flex align-items-center justify-content-between mr-2 mb-4 mt-2'>
                            {teamMembers.length === index + 1 ? (
                              <button
                                className='btn text-white application-button mt-2'
                                type='button'
                                onClick={() => addNewTeamMember(values)}>
                                {' '}
                                Add Person
                              </button>
                            ) : (
                              <span></span>
                            )}
                            {teamMembers.length !== 0 ? (
                              <i
                                className='font-size-20 fas fa-trash-alt cursor-pointer mt-2 mr-2'
                                onClick={() => handleDeleteMember(index)}
                              />
                            ) : (
                              <span></span>
                            )}
                          </div>
                          <hr />
                        </div>
                      ))}

                      {teamMembers && teamMembers.length === 0 && (
                        <>
                          <div className='col-12 d-flex align-items-center justify-content-between mr-2 mb-4 mt-2'>
                            {teamMembers.length === 0 ? (
                              <button
                                className='btn text-white application-button mt-2'
                                type='button'
                                onClick={() => addNewTeamMember(values)}>
                                {' '}
                                Add Person
                              </button>
                            ) : (
                              <span></span>
                            )}
                          </div>
                          <hr />
                        </>
                      )}

                      <div className='d-flex justify-content-between align-items-center mr-2 ml-2 mt-4'>
                        <i
                          className='mdi mdi-chevron-double-left font-size-32 ml-1 cursor-pointer'
                          onClick={prevPage}
                          style={{ color: primaryTextState }}
                        />
                        <button
                          className='btn application-button text-white'
                          type='submit'
                          onSubmit={(values) => handleSubmit(values)}>
                          Next
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </Container>
          )}
        </Collapse>
      </div>
    </React.Fragment>
  );
}

export default Step3;

function toReadableLabel(str) {
  return str
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

const CustomInput = ({
  label,
  htmlFor,
  setFieldTouched,
  name,
  placeholder,
  values,
  handleChange,
  errors,
  touched,
  type = 'text',
  extraLabel,
  primaryTextState
}) => (
  <FormGroup className='mb-2'>
    <div className='font-size-12'>
      <Label
        htmlFor={htmlFor}
        className='text-black font-weight-normal'
        style={{ color: primaryTextState }}>
        {toReadableLabel(label)} {extraLabel}
      </Label>
    </div>
    <div>
      <AuthInput
        setFieldTouched={setFieldTouched}
        withTouchError={true}
        type={type}
        name={name}
        className={'font-size-12 font-weight-400 input-height'}
        placeholder={'Enter ' + toReadableLabel(placeholder)}
        values={values}
        handleChange={handleChange}
        errors={errors}
        touched={touched}
      />
    </div>
  </FormGroup>
);
