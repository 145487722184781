import React, { useEffect, useRef, useState } from 'react';
import { Col, Collapse, Container, FormGroup, Label } from 'reactstrap';
import get from 'lodash/get';
import * as Yup from 'yup';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import toast from 'react-hot-toast';
import { Formik, Form, ErrorMessage } from 'formik';
import { GetApplication, UpdateExchangeApplication } from '../../../services/Application';
import { StepsCalculator } from './StepsCalculator';
import { notificationMessageFunction } from '../../../constants/notificationConst';
import documentUploadIcon from '../../../assets/images/documentupload.png';
import documentUploadedIcon from '../../../assets/images/documentuploaded.png';
import AuthInput from '../../../Custom/AuthInput';
import { constants as CONST } from '../../../constants/application';
import { handleSubmitLiteApplication } from './LiteComplete';
import Loader from '../../../constants/Loader';

function Step18({
  isLoading,
  setIsLoading,
  setExchangeCompleteStep,
  applicationId,
  nextPage,
  setApplicationData,
  prevPage,
  applicationData,
  isUpdateExchange = false,
  isSectionCollapsed,
  toggleSection,
  GetApplicationDetailsEdit,
  primaryTextState,
  history,
  paramsValue
}) {
  const applicationStatus = applicationData.application_status;
  applicationData = applicationData.exchange_application;

  const [initialValue, setInitialValue] = useState({
    token_use_case: '',
    tokenomics_doc: null,
    tokenomics_doc_status: '',
    token_distribution_doc: null,
    token_distribution_doc_status: ''
  });
  const [completeStep, setCompleteStep] = useState(0);

  const TokenUseCaseSchema = Yup.object().shape({
    token_use_case: Yup.string().required('Token use case is required'),
    tokenomics_doc: Yup.mixed(),
    token_distribution_doc: Yup.mixed()
  });

  useEffect(() => {
    if (applicationId !== '' && !isUpdateExchange) {
      GetApplication({ application_id: applicationId }).then((res) => {
        const application = get(res, 'data.data.data[0]', '');
        if (application.exchange_application.token_use_case) {
          setInitialValue({
            token_use_case: application.exchange_application.token_use_case,
            tokenomics_doc: application.exchange_application.tokenomics_doc,
            token_distribution_doc:
              application.exchange_application.token_distribution_doc,
            tokenomics_doc_status: application.exchange_application.tokenomics_doc_status,
            token_distribution_doc_status:
              application.exchange_application.token_distribution_doc_status
          });
        }

        setCompleteStep(application.exchange_application.percentage_complete);
      });
    } else {
      if (applicationData && applicationData.token_use_case) {
        setInitialValue({
          token_use_case: applicationData.token_use_case,
          tokenomics_doc: applicationData.tokenomics_doc,
          token_distribution_doc: applicationData.token_distribution_doc,
          tokenomics_doc_status: applicationData.tokenomics_doc_status,
          token_distribution_doc_status: applicationData.token_distribution_doc_status
        });
      }
    }
  }, [applicationData, applicationId, isUpdateExchange]);

  const handleSubmit = (values, { setFieldError }) => {
    const payloadData = new FormData();
    if (
      values.token_use_case === '' &&
      initialValue.tokenomics_doc_status !== '' &&
      initialValue.tokenomics_doc_status !== undefined &&
      initialValue.token_distribution_doc_status !== undefined &&
      initialValue.token_distribution_doc_status !== '' &&
      typeof values.tokenomics_doc !== 'object' &&
      typeof values.token_distribution_doc !== 'object'
    ) {
      nextPage();
      setIsLoading(false);
    } else {
      if (!values.tokenomics_doc) {
        setFieldError('tokenomics_doc', 'Tokenomics document is required');
        return;
      }
      if (!values.token_distribution_doc) {
        setFieldError(
          'token_distribution_doc',
          'Token distribution document is required'
        );
        return;
      }

      if (
        values.tokenomics_doc &&
        typeof values.tokenomics_doc !== 'string' &&
        values.tokenomics_doc.size / 1024 > 50000 &&
        values.token_distribution_doc &&
        typeof values.token_distribution_doc !== 'string' &&
        values.token_distribution_doc.size / 1024 > 50000
      ) {
        setFieldError('tokenomics_doc', 'File size should be less than 50 MB');
        setFieldError('token_distribution_doc', 'File size should be less than 50 MB');
        return;
      }

      if (
        values.tokenomics_doc &&
        typeof values.tokenomics_doc !== 'string' &&
        values.tokenomics_doc.size / 1024 > 50000
      ) {
        setFieldError('tokenomics_doc', 'File size should be less than 50 MB');
        return;
      }

      if (
        values.token_distribution_doc &&
        typeof values.token_distribution_doc !== 'string' &&
        values.token_distribution_doc.size / 1024 > 50000
      ) {
        setFieldError('token_distribution_doc', 'File size should be less than 50 MB');
        return;
      }

      payloadData.append('token_use_case', values.token_use_case);
      payloadData.append('tokenomics_doc', values.tokenomics_doc);
      if (values.token_use_case) {
        payloadData.append('tokenomics_doc_status', 'uploading');
      }
      payloadData.append('token_distribution_doc', values.token_distribution_doc);
      if (values.token_distribution_doc) {
        payloadData.append('token_distribution_doc_status', 'uploading');
      }
      let stepsRequired = Math.floor(StepsCalculator(3, completeStep, 86));
      payloadData.append('percentage_complete', stepsRequired);
      setIsLoading(true);

      UpdateExchangeApplication({ application_id: applicationId }, payloadData)
        .then((res) => {
          const application = get(res, 'data.data.data', '');
          setApplicationData(application);
          if (isUpdateExchange) {
            toast.success('Tokenomics updated successfully', {
              style: { fontSize: '12px' }
            });
            GetApplicationDetailsEdit(applicationId);
          }
          nextPage();
          setIsLoading(false);
        })
        .catch((err) => {
          const errorMessage = get(err, 'data.error.message');
          toast.error(notificationMessageFunction(errorMessage), {
            style: { fontSize: '12px' }
          });
        });
    }
    setExchangeCompleteStep(Array.from({ length: 18 }, (_, index) => index));
  };

  return (
    <React.Fragment>
      <div onClick={isSectionCollapsed ? toggleSection : undefined}>
        {isUpdateExchange ? (
          <>
            <div
              className='cursor-pointer col-12 font-size-14 ml-2 d-flex justify-content-between align-items-center'
              onClick={toggleSection}>
              <span
                className='text-black font-weight-400'
                style={{ color: primaryTextState }}>
                Tokenomics
              </span>
              <i
                className={`font-size-22 mdi mdi-chevron-${
                  isSectionCollapsed === true ? 'up' : 'down'
                }`}
                style={{ color: primaryTextState }}
              />
            </div>
          </>
        ) : (
          <div className='col-12 font-size-16 pb-2 ml-2'>
            <span
              className='text-black font-weight-400'
              style={{ color: primaryTextState }}>
              Tokenomics
            </span>
          </div>
        )}
        <Collapse isOpen={!isSectionCollapsed}>
          {isLoading ? (
            <div className='mt-5'>
              <Loader />
            </div>
          ) : (
            <Container className='col-12 col-sm-auto pt-2'>
              <div>
                <Formik
                  initialValues={initialValue}
                  enableReinitialize={true}
                  validationSchema={TokenUseCaseSchema}
                  onSubmit={handleSubmit}>
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form>
                      <div>
                        <Col>
                          <FormGroup className='mb-1'>
                            <CustomFileInput
                              labelName='Tokenomics'
                              fieldName='tokenomics_doc'
                              errors={errors}
                              headerName={'Upload your Tokenomics'}
                              values={values}
                              handleChange={handleChange}
                              setFieldValue={setFieldValue}
                              primaryTextState={primaryTextState}
                            />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup className='mb-1'>
                            <CustomFileInput
                              labelName='Token Distribution'
                              fieldName='token_distribution_doc'
                              errors={errors}
                              headerName={'Upload your Token Distribution'}
                              values={values}
                              handleChange={handleChange}
                              setFieldValue={setFieldValue}
                              primaryTextState={primaryTextState}
                            />
                          </FormGroup>
                        </Col>

                        <Col>
                          <FormGroup className='mb-4 mt-1'>
                            <div className='font-size-12'>
                              <Label
                                htmlFor='token_use_case'
                                className='text-black font-weight-normal'
                                style={{ color: primaryTextState }}>
                                {CONST.USE_CASES_OF_TOKENS}
                              </Label>
                            </div>
                            <div>
                              <AuthInput
                                type='textarea'
                                name='token_use_case'
                                placeholder={CONST.ENTER_DESCRIPTION}
                                values={values}
                                className='font-size-12'
                                handleChange={handleChange}
                                errors={errors}
                                touched={touched}></AuthInput>
                            </div>
                          </FormGroup>
                        </Col>
                      </div>
                      <div className='d-flex justify-content-between align-items-center mt-4 mr-2 ml-2'>
                        <i
                          className='mdi mdi-chevron-double-left font-size-32 ml-1 cursor-pointer'
                          onClick={prevPage}
                          style={{ color: primaryTextState }}
                        />
                        <div>
                          {(applicationStatus === 'INCOMPLETE' || !applicationStatus) && (
                            <button
                              className='btn btn-status-change text-white mr-2'
                              type='submit'
                              style={{ width: '142px', height: '50px' }}
                              onClick={() =>
                                handleSubmitLiteApplication({
                                  applicationId,
                                  setApplicationData,
                                  history,
                                  paramsValue,
                                  setIsLoading
                                })
                              }>
                              Submit
                            </button>
                          )}
                          <button
                            className='btn application-button text-white'
                            type='submit'
                            onSubmit={(values) => handleSubmit(values)}>
                            Next
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </Container>
          )}
        </Collapse>
      </div>
    </React.Fragment>
  );
}

export default Step18;

const CustomFileInput = ({
  labelName,
  fieldName,
  headerName,
  values,
  setFieldValue,
  primaryTextState
}) => {
  const fileInputRef = useRef(null);

  const handleFileButtonClick = () => {
    fileInputRef.current.click();
  };
  return (
    <FormGroup className='mb-3'>
      <div className='font-size-12'>
        <Label
          htmlFor='description'
          className={`text-black cursor-pointer font-weight-normal ${
            !labelName ? 'mt-3' : 'mt-1'
          }`}
          data-replace={`${labelName}`}
          style={{ color: primaryTextState }}>
          <span>
            {labelName}:{' '}
            <span className='font-weight-medium'>
              {values[fieldName + '_status'] === 'uploaded'
                ? 'Uploaded'
                : values[fieldName + '_status'] === 'uploading'
                ? 'Uploading'
                : values[fieldName + '_status'] === 'upload_error'
                ? 'Upload Error'
                : ''}
            </span>
          </span>
        </Label>
      </div>
      <div className='file-upload d-flex direction-row justify-content-between align-items-center pl-1'>
        <Label
          htmlFor={values[fieldName]}
          className='mt-2'
          onClick={handleFileButtonClick}
          style={{ color: primaryTextState }}>
          <div className='cursor-pointer'>
            <span className='rounded-circle' style={{ color: '#134a87', fontSize: 12 }}>
              {values[fieldName] ? (
                <img src={documentUploadedIcon} alt={values[fieldName]} />
              ) : (
                <img src={documentUploadIcon} alt={values[fieldName]} />
              )}
            </span>
            <span className='font-size-12 ml-2'>{headerName}</span>
          </div>
        </Label>
        <div>
          {values[fieldName] !== null && typeof values[fieldName] === 'object' ? (
            <a
              href={URL.createObjectURL(values[fieldName])}
              target='_blank'
              rel='noopener noreferrer'
              className='pr-2 font-size-12 font-weight-bold text-wrap text-primary'>
              View File
            </a>
          ) : values[fieldName] !== null &&
            typeof values[fieldName] === 'string' &&
            values[fieldName].length > 0 ? (
            <a
              href={values[fieldName]}
              target='_blank'
              rel='noopener noreferrer'
              className='pr-2 font-size-12 font-weight-bold text-wrap text-primary'>
              View File
            </a>
          ) : (
            ''
          )}
        </div>
        <input
          type='file'
          name={fieldName}
          style={{ display: 'none' }}
          ref={fileInputRef}
          onChange={(event) => {
            setFieldValue(fieldName, event.currentTarget.files[0]);
          }}
        />

        <ReactTooltip
          anchorId={fieldName}
          place='bottom'
          content={values[fieldName] && values[fieldName].name}
        />
      </div>
      <ErrorMessage name={fieldName}>
        {(msg) => (
          <div style={{ fontSize: 12 }} className='text-left mt-1 text-danger'>
            {msg}
          </div>
        )}
      </ErrorMessage>
    </FormGroup>
  );
};
