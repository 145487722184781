import React, { useEffect, useState } from 'react';
import { Col, Collapse, Container, FormGroup, Input, Label } from 'reactstrap';
import get from 'lodash/get';
import * as Yup from 'yup';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import toast from 'react-hot-toast';
import { Formik, Form, ErrorMessage } from 'formik';
import { GetApplication, UpdateExchangeApplication } from '../../../services/Application';
import { StepsCalculator } from './StepsCalculator';
import { notificationMessageFunction } from '../../../constants/notificationConst';
import AuthInput from '../../../Custom/AuthInput';
import { constants as CONST } from '../../../constants/application';
import { investorsListAPI } from '../../../services/investors';
import { handleSubmitLiteApplication } from './LiteComplete';
import Loader from '../../../constants/Loader';

function Step19({
  isLoading,
  setIsLoading,
  setExchangeCompleteStep,
  applicationId,
  nextPage,
  setApplicationData,
  prevPage,
  applicationData,
  isUpdateExchange = false,
  isSectionCollapsed,
  toggleSection,
  GetApplicationDetailsEdit,
  primaryTextState,
  primaryColorState,
  updateRound,
  setUpdateRound,
  history,
  paramsValue
}) {
  const applicationStatus = applicationData.application_status;
  const allOptions = [
    { id: 0, value: 'n/a', label: 'N/A' },
    { id: 1, value: 'seed', label: 'Seed' },
    { id: 2, value: 'series_a', label: 'Series A' },
    { id: 3, value: 'series_b', label: 'Series B' },
    { id: 4, value: 'series_c', label: 'Series C' },
    { id: 5, value: 'series_d', label: 'Series D' },
    { id: 6, value: 'series_e', label: 'Series E' },
    { id: 7, value: 'late_stage_round', label: 'Late Stage Round' },
    { id: 8, value: 'ipo', label: 'IPO' }
  ];
  const [options, setOptions] = useState([
    { id: 0, value: 'n/a', label: 'N/A' },
    { id: 1, value: 'seed', label: 'Seed' },
    { id: 2, value: 'series_a', label: 'Series A' },
    { id: 3, value: 'series_b', label: 'Series B' },
    { id: 4, value: 'series_c', label: 'Series C' },
    { id: 5, value: 'series_d', label: 'Series D' },
    { id: 6, value: 'series_e', label: 'Series E' },
    { id: 7, value: 'late_stage_round', label: 'Late Stage Round' },
    { id: 8, value: 'ipo', label: 'IPO' }
  ]);
  const [investorsList, setInvestorsList] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [completeStep, setCompleteStep] = useState(0);
  const [displayinvestorData, setDisplayinvestorData] = useState(false);
  const [displayinvestorListData, setDisplayinvestorListData] = useState([]);
  const [selectedRound, setselectedRound] = useState('');
  const [showRound, setShowRound] = useState(false);
  const [initialValue, setInitialValue] = useState({
    selectRound: '',
    totalAmountStage: '',
    tokenPriceStage: '',
    dynamicFields: [{ name: '', amount: '', lead: false, tier: '' }]
  });

  useEffect(() => {
    if (displayinvestorListData.length > 0) {
      let displayInvestorsList = displayinvestorListData.map((i) => i.round);
      let updatedRound = options.filter(
        (i) => !displayInvestorsList.includes(i.value) && i.id !== 0
      );
      setOptions([...updatedRound]);
    }
  }, [displayinvestorListData]);

  useEffect(() => {
    investorsListAPI()
      .then((res) => {
        const list = res.data.data.data.map((e) => ({
          label: e.name,
          value: e.name,
          tier: e.tier
        }));
        setInvestorsList(list);
      })
      .catch((err) => {});

    if (applicationId !== '' && !isUpdateExchange) {
      GetApplication({ application_id: applicationId }).then((res) => {
        const application = get(res, 'data.data.data[0]', '');
        if (application.exchange_application.investment_stages.length > 0) {
          setShowRound(true);
          setDisplayinvestorListData(application.exchange_application.investment_stages);
        }
        setCompleteStep(application.exchange_application.percentage_complete);
      });
    } else {
      if (
        applicationData &&
        applicationData.exchange_application &&
        applicationData.exchange_application.investment_stages.length > 0
      ) {
        setShowRound(true);
        setDisplayinvestorListData(
          applicationData.exchange_application.investment_stages
        );
      }
    }
  }, [applicationData, applicationId, isUpdateExchange]);

  const handleChangeOpt = (selectedOption, setFieldValue) => {
    setFieldValue('selectRound', selectedOption);
    setselectedRound(selectedOption.value);
    if (selectedOption.id === 0) {
      setDisplayinvestorData(false);
    } else {
      setDisplayinvestorData(true);
    }
  };

  const handleTextAmountChange = (index, value, values, setFieldValue) => {
    let arr = { ...values };
    arr.dynamicFields[index].amount =
      '$' + Number(value.replace(/[^0-9]/g, '')).toLocaleString();
    setFieldValue(arr);
  };

  const handleLeadChange = (index, value, values, setFieldValue) => {
    let arr = { ...values };
    arr.dynamicFields[index].lead = value;
    setFieldValue(arr);
  };

  const handleSubmitRound = (values, setFieldValue) => {
    let val = values.dynamicFields[values.dynamicFields.length - 1];
    if (val.name !== '' && val.amount !== '') {
      let RoundData = {
        round: selectedRound,
        token_price: values.tokenPriceStage,
        token_size: values.totalAmountStage,
        all_investors: values.dynamicFields
      };
      setShowRound(true);
      setDisplayinvestorListData([...displayinvestorListData, RoundData]);
      setselectedRound('');
      let arr = { ...values };
      arr.dynamicFields = [{ name: '', amount: '', lead: false, tier: '' }];
      arr.tokenPriceStage = '';
      arr.totalAmountStage = '';
      setFieldValue(arr);
      setDisplayinvestorData(false);
    }
  };

  const handleChangeInvestor = (selected, index, values, setFieldValue) => {
    let arr = { ...values };
    arr.dynamicFields[index].name = selected.label;
    arr.dynamicFields[index].tier = selected.tier;
    setFieldValue(arr);
  };

  const handleSubmit = (values) => {
    if (values && !values.selectRound && !values.dynamicFields) {
      const data = { investment_stages: [] };
      JSON.stringify(data);
      setIsLoading(true);

      UpdateExchangeApplication({ application_id: applicationId }, data)
        .then((res) => {
          const application = get(res, 'data.data.data', '');
          setApplicationData(application);
          if (isUpdateExchange) {
            toast.success('Investment History updated successfully', {
              style: { fontSize: '12px' }
            });
            GetApplicationDetailsEdit(applicationId);
          }
          nextPage();
          setIsLoading(false);
        })
        .catch((err) => {
          const errorMessage = get(err, 'data.error.message');
          toast.error(notificationMessageFunction(errorMessage), {
            style: { fontSize: '12px' }
          });
        });
    } else if (values.selectRound.id === 0 && values.selectRound) {
      nextPage();
      setIsLoading(false);
    } else {
      if (displayinvestorListData[0]['round'] === '') {
        return;
      }
      let val = values.dynamicFields[values.dynamicFields.length - 1];
      if (
        (displayinvestorData && val.name !== '' && val.amount !== '') ||
        !displayinvestorData
      ) {
        let stepsRequired = Math.floor(StepsCalculator(1, completeStep, 89));
        const data = {
          investment_stages: JSON.stringify(displayinvestorListData),
          percentage_complete: stepsRequired
        };
        JSON.stringify(data);
        setIsLoading(true);

        UpdateExchangeApplication({ application_id: applicationId }, data)
          .then((res) => {
            const application = get(res, 'data.data.data', '');
            setApplicationData(application);
            if (isUpdateExchange) {
              toast.success('Investment History updated successfully', {
                style: { fontSize: '12px' }
              });
              GetApplicationDetailsEdit(applicationId);
            }
            nextPage();
            setIsLoading(false);
          })
          .catch((err) => {
            const errorMessage = get(err, 'data.error.message');
            toast.error(notificationMessageFunction(errorMessage), {
              style: { fontSize: '12px' }
            });
          });
      }
    }
    setExchangeCompleteStep(Array.from({ length: 20 }, (_, index) => index));
  };

  const updateInvestorData = (value) => {
    const newInvestorList = displayinvestorListData.map((item) =>
      item.round === value.selectRound
        ? {
            round: value.selectRound,
            token_price: value.tokenPriceStage,
            token_size: value.totalAmountStage,
            all_investors: value.dynamicFields
          }
        : item
    );
    setDisplayinvestorListData(newInvestorList);
    setUpdateRound(false);
  };

  const validationSchema = Yup.object().shape({
    selectRound: Yup.object().nullable().required('Round is required'),
    tokenPriceStage: Yup.string().required('Stage Token Price is required'),
    totalAmountStage: Yup.string().required('Stage Total Amount is required'),
    dynamicFields: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required('Name is required'),
        amount: Yup.string().required('Amount is required'),
        lead: Yup.bool(),
        tier: Yup.string()
      })
    )
  });

  const addField = (setFieldValue, values) => {
    if (
      values.dynamicFields[values.dynamicFields.length - 1].amount !== '' &&
      values.dynamicFields[values.dynamicFields.length - 1].name !== ''
    ) {
      let arr = { ...values };
      arr.dynamicFields.push({ name: '', amount: '', lead: false, tier: '' });
      setFieldValue(arr);
    }
  };

  const removeField = (index, setFieldValue, values) => {
    let arr = { ...values };
    arr.dynamicFields.splice(index, 1);
    setFieldValue(arr);
  };

  const handleRemoveField = (index) => {
    const updatedQuestionsData = [...displayinvestorListData];
    const removeField = updatedQuestionsData[index];
    const findValue = allOptions.find((opt) => opt.value === removeField.round);
    updatedQuestionsData.splice(index, 1);
    setDisplayinvestorListData(updatedQuestionsData);
    let NAOption = [];
    if (displayinvestorListData && displayinvestorListData.length === 1) {
      NAOption = [{ id: 0, value: 'n/a', label: 'N/A' }];
    }
    const sortedData = [findValue, ...options, ...NAOption].sort((a, b) => {
      const indexA = allOptions.findIndex((item) => item.id === a.id);
      const indexB = allOptions.findIndex((item) => item.id === b.id);

      if (indexA < indexB) {
        return -1;
      }
      if (indexA > indexB) {
        return 1;
      }

      return 0;
    });

    setOptions(sortedData);
  };

  function convertToNumber(str) {
    const sanitizedString = str.replace(/[$,]/g, '');
    return parseInt(sanitizedString, 10);
  }

  return (
    <React.Fragment>
      {!updateRound ? (
        <div onClick={isSectionCollapsed ? toggleSection : undefined}>
          {isUpdateExchange ? (
            <>
              <div
                className='cursor-pointer col-12 font-size-14 ml-2 d-flex justify-content-between align-items-center'
                onClick={toggleSection}>
                <span
                  className='text-black font-weight-400'
                  style={{ color: primaryTextState }}>
                  Investment History
                </span>
                <i
                  className={`font-size-22 mdi mdi-chevron-${
                    isSectionCollapsed === true ? 'up' : 'down'
                  }`}
                  style={{ color: primaryTextState }}
                />
              </div>
            </>
          ) : (
            <div className='col-12 font-size-16 pb-2 ml-2'>
              <span
                className='text-black font-weight-400'
                style={{ color: primaryTextState }}>
                Investment History
              </span>
            </div>
          )}
          <Collapse isOpen={!isSectionCollapsed}>
            {isLoading ? (
              <div className='mt-5'>
                <Loader />
              </div>
            ) : (
              <Container
                className='col-12 col-sm-auto pt-3'
                style={{ minHeight: '500px' }}>
                <Formik
                  enableReinitialize={true}
                  initialValues={initialValue}
                  validate={(values) => {
                    let errors = {};
                    const sumAmount = values.dynamicFields.reduce(
                      (sum, field) =>
                        sum +
                        (isNaN(convertToNumber(field.amount))
                          ? 0
                          : convertToNumber(field.amount)),
                      0
                    );
                    if (convertToNumber(values.totalAmountStage) <= sumAmount) {
                      errors.totalAmountStage =
                        'Total amount stage should be greater than the sum of all investor amounts';
                    }

                    values.dynamicFields.forEach((field, index) => {
                      if (!field.amount) {
                        errors[`dynamicFields.${index}.amount`] = 'Amount is Required';
                      }
                      if (!field.name) {
                        errors[`dynamicFields.${index}.name`] = 'Name is Required';
                      }
                    });
                    return errors;
                  }}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}>
                  {({ errors, touched, values, setErrors, setFieldValue }) => (
                    <Form style={{ height: '100%' }}>
                      <Col>
                        <FormGroupSelectRound
                          errors={errors}
                          name='selectRound'
                          handleChangeOpt={(selectedOption) =>
                            handleChangeOpt(selectedOption, setFieldValue)
                          }
                          options={options}
                          displayinvestorListData={displayinvestorListData}
                          primaryTextState={primaryTextState}
                          primaryColorState={primaryColorState}
                        />
                      </Col>

                      <Col>
                        {displayinvestorData && (
                          <InvestorData
                            updateRound={updateRound}
                            setUpdateRound={setUpdateRound}
                            values={values}
                            setErrors={setErrors}
                            errors={errors}
                            touched={touched}
                            investorsList={investorsList}
                            setFieldValue={setFieldValue}
                            handleSubmitRound={handleSubmitRound}
                            displayinvestorListData={displayinvestorListData}
                            handleChangeInvestor={handleChangeInvestor}
                            handleLeadChange={handleLeadChange}
                            handleTextAmountChange={handleTextAmountChange}
                            addField={addField}
                            setDisplayinvestorData={setDisplayinvestorData}
                            removeField={removeField}
                            updateInvestorData={updateInvestorData}
                            primaryTextState={primaryTextState}
                            primaryColorState={primaryColorState}
                          />
                        )}
                      </Col>

                      <Col>
                        {showRound && (
                          <div className='pt-3 pb-5 mt-4'>
                            {displayinvestorListData.map((round, index) => (
                              <div className='round-info' key={index}>
                                <span
                                  key={index}
                                  className='ml-2 d-flex justify-content-between font-size-12'>
                                  <u>
                                    <span
                                      className='font-size-14 font-weight-medium'
                                      style={{ color: primaryTextState }}>
                                      {allOptions.find((opt) => opt.value === round.round)
                                        ?.label || 'Unknown'}{' '}
                                      Round
                                    </span>
                                  </u>
                                </span>

                                <span
                                  className='ml-2 mr-2 mt-2 total-price mt-0 font-size-12 d-flex justify-content-between'
                                  style={{ color: primaryTextState }}>
                                  <span>Total Amount</span>{' '}
                                  <span>{round.token_size}</span>
                                </span>
                                <span
                                  className='mt-2 ml-2 mr-2 total-price mt-0 font-size-12 d-flex justify-content-between'
                                  style={{ color: primaryTextState }}>
                                  <span>Total Price</span>{' '}
                                  <span>{round.token_price}</span>
                                </span>
                                <div className='investor-list mt-3 ml-2'>
                                  <span
                                    className='font-size-14 font-weight-medium'
                                    style={{ color: primaryTextState }}>
                                    <u>Investors</u>
                                  </span>
                                  <div
                                    className='table-responsive font-size-12 mt-2'
                                    style={{ color: primaryTextState }}>
                                    {round.all_investors.map((input, idx) => (
                                      <>
                                        <div className='d-flex justify-content-between'>
                                          <span>Investor Name</span>
                                          <span>{input.name}</span>
                                        </div>
                                        <div className='mt-1 d-flex justify-content-between'>
                                          <span>Investment Amount</span>
                                          <span>{input.amount}</span>
                                        </div>
                                        <div className='mt-1 d-flex justify-content-between'>
                                          <span>Lead Investor?</span>
                                          <span>{input.lead ? 'True' : 'False'}</span>
                                        </div>
                                      </>
                                    ))}
                                  </div>
                                  <hr style={{ background: primaryTextState }} />
                                </div>
                                <div className='d-flex justify-content-end'>
                                  <i
                                    className='fa-solid fas fa-pencil-alt font-size-22 mr-2 cursor-pointer'
                                    onClick={() => {
                                      setSelectedData({
                                        selectRound: round.round,
                                        totalAmountStage: round.token_size,
                                        tokenPriceStage: round.token_price,
                                        dynamicFields: round.all_investors
                                      });
                                      setInitialValue({
                                        selectRound: round.round,
                                        totalAmountStage: round.token_size,
                                        tokenPriceStage: round.token_price,
                                        dynamicFields: round.all_investors
                                      });

                                      setUpdateRound(!updateRound);
                                    }}
                                    style={{ color: primaryTextState }}
                                  />
                                  <i
                                    className='fas fa-trash-alt font-size-22 cursor-pointer'
                                    onClick={() => handleRemoveField(index)}
                                    style={{ color: primaryTextState }}
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </Col>

                      <div className='d-flex justify-content-between align-items-center mt-3 mr-2 ml-2'>
                        <i
                          className='mdi mdi-chevron-double-left font-size-32 ml-1 cursor-pointer'
                          onClick={prevPage}
                          style={{ color: primaryTextState }}
                        />
                        <div>
                          {(applicationStatus === 'INCOMPLETE' || !applicationStatus) && (
                            <button
                              className='btn btn-status-change text-white mr-2'
                              type='submit'
                              style={{ width: '142px', height: '50px' }}
                              onClick={() =>
                                handleSubmitLiteApplication({
                                  applicationId,
                                  setApplicationData,
                                  history,
                                  paramsValue,
                                  setIsLoading
                                })
                              }>
                              Submit
                            </button>
                          )}
                          {values &&
                          values.dynamicFields &&
                          values.dynamicFields.length === 1 &&
                          values.selectRound.id === 0 ? (
                            <button
                              type='submit'
                              className='btn application-button text-white'
                              onClick={(values) => handleSubmit(values)}>
                              Next
                            </button>
                          ) : (
                            <button
                              type='submit'
                              className='btn application-button text-white'
                              onSubmit={(values) => handleSubmit(values)}>
                              Next
                            </button>
                          )}
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </Container>
            )}
          </Collapse>
        </div>
      ) : (
        <>
          <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validate={(values) => {
              let errors = {};
              const sumAmount = values.dynamicFields.reduce(
                (sum, field) =>
                  sum +
                  (isNaN(convertToNumber(field.amount))
                    ? 0
                    : convertToNumber(field.amount)),
                0
              );
              if (convertToNumber(values.totalAmountStage) <= sumAmount) {
                errors.totalAmountStage =
                  'Total amount stage should be greater than the sum of all investor amounts';
              }

              values.dynamicFields.forEach((field, index) => {
                if (!field.amount) {
                  errors[`dynamicFields.${index}.amount`] = 'Amount is Required';
                }
                if (!field.name) {
                  errors[`dynamicFields.${index}.name`] = 'Name is Required';
                }
              });
              return errors;
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}>
            {({ errors, touched, values, setErrors, setFieldValue }) => (
              <Form style={{ height: '100%' }}>
                <UpdateRoundModal
                  updateRound={updateRound}
                  setUpdateRound={setUpdateRound}
                  values={selectedData}
                  setErrors={setErrors}
                  errors={errors}
                  touched={touched}
                  investorsList={investorsList}
                  setFieldValue={setFieldValue}
                  handleSubmitRound={handleSubmitRound}
                  displayinvestorListData={displayinvestorListData}
                  handleChangeInvestor={handleChangeInvestor}
                  handleLeadChange={handleLeadChange}
                  handleTextAmountChange={handleTextAmountChange}
                  addField={addField}
                  setDisplayinvestorData={setDisplayinvestorData}
                  removeField={removeField}
                  setInitialValue={setInitialValue}
                  updateInvestorData={updateInvestorData}
                  primaryTextState={primaryTextState}
                  primaryColorState={primaryColorState}
                />
              </Form>
            )}
          </Formik>
        </>
      )}
    </React.Fragment>
  );
}

export default Step19;

const FormGroupSelectRound = ({
  name,
  handleChangeOpt,
  options,
  displayinvestorListData,
  primaryTextState,
  primaryColorState
}) => (
  <FormGroup>
    <Label
      htmlFor={name}
      className='text-black font-weight-normal font-size-12'
      style={{ color: primaryTextState }}>
      {CONST.ROUND}
    </Label>
    {displayinvestorListData && displayinvestorListData.length === 0 ? (
      <Select
        options={options}
        name={name}
        defaultValue={
          displayinvestorListData.length === 0
            ? options.find((item) => item.id === 0)
            : ''
        }
        styles={{
          control: (styles) => ({
            ...styles,
            backgroundColor: primaryColorState,
            borderRadius: '8px'
          }),
          singleValue: (styles) => ({
            ...styles,
            color: primaryTextState
          })
        }}
        onChange={handleChangeOpt}
        className='font-size-12 border-grey'
      />
    ) : (
      <Select
        options={options}
        styles={{
          control: (styles) => ({
            ...styles,
            backgroundColor: primaryColorState,
            borderRadius: '8px'
          }),
          singleValue: (styles) => ({
            ...styles,
            color: primaryTextState
          })
        }}
        name={name}
        onChange={handleChangeOpt}
        className='font-size-12 border-grey'
      />
    )}
    <ErrorMessage
      name={name}
      component='div'
      className='text-left mt-1 text-danger font-size-12'
    />
  </FormGroup>
);

const InvestorData = ({
  values,
  errors,
  touched,
  investorsList,
  setFieldValue,
  handleSubmitRound,
  displayinvestorListData,
  handleChangeInvestor,
  handleLeadChange,
  handleTextAmountChange,
  addField,
  setDisplayinvestorData,
  removeField,
  isEdit = false,
  updateInvestorData,
  primaryTextState,
  primaryColorState
}) => {
  return (
    <div className={`pt-3 pb-5 ${!isEdit && 'investor_box'}`}>
      <Col>
        <FormGroup className='ml-2 mr-2'>
          <div className='font-size-12'>
            <Label
              htmlFor='totalAmountStage'
              className='text-black font-weight-normal'
              style={{ color: primaryTextState }}>
              {CONST.TOKEN_SIZE}
            </Label>
          </div>
          <div>
            <AuthInput
              type='text'
              name='totalAmountStage'
              className='font-size-12'
              values={values}
              placeholder={'Enter total amount of invesment stage'}
              handleChange={(e) => {
                if (e.target.value.endsWith('.')) {
                  setFieldValue('totalAmountStage', e.target.value);
                  return;
                }

                const rawValue = e.target.value.replace(/[^0-9.]/g, '');

                const decimalValue =
                  rawValue.split('.').length > 2
                    ? rawValue.slice(0, rawValue.lastIndexOf('.'))
                    : rawValue;

                const parts = decimalValue.split('.');
                const formattedValue =
                  parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
                  (parts[1] ? '.' + parts[1] : '');

                if (formattedValue) {
                  setFieldValue('totalAmountStage', '$' + formattedValue);
                } else {
                  setFieldValue('totalAmountStage', '$0');
                }
              }}
              errors={errors}
              touched={touched}></AuthInput>
          </div>
        </FormGroup>
      </Col>
      <Col>
        <FormGroup className='ml-2 mr-2'>
          <div className='font-size-12'>
            <Label
              htmlFor='tokenPriceStage'
              className='text-black font-weight-normal'
              style={{ color: primaryTextState }}>
              {CONST.TOKEN_PRICE}
            </Label>
          </div>
          <div>
            <AuthInput
              type='text'
              name='tokenPriceStage'
              className='font-size-12'
              values={values}
              placeholder={'Enter token price'}
              handleChange={(e) => {
                if (e.target.value.endsWith('.')) {
                  setFieldValue('tokenPriceStage', e.target.value);
                  return;
                }

                const rawValue = e.target.value.replace(/[^0-9.]/g, '');

                const decimalValue =
                  rawValue.split('.').length > 2
                    ? rawValue.slice(0, rawValue.lastIndexOf('.'))
                    : rawValue;

                const parts = decimalValue.split('.');
                const formattedValue =
                  parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
                  (parts[1] ? '.' + parts[1] : '');

                if (formattedValue) {
                  setFieldValue('tokenPriceStage', '$' + formattedValue);
                } else {
                  setFieldValue('tokenPriceStage', '$0');
                }
              }}
              errors={errors}
              touched={touched}></AuthInput>
          </div>
        </FormGroup>
      </Col>

      <Col lg={12}>
        <div className='font-size-14 ml-2 mr-2'>
          <Label
            className='text-black font-weight-normal'
            style={{ color: primaryTextState }}>
            <u>{CONST.ADD_INVESTORS}</u>
          </Label>
        </div>
      </Col>

      {values.dynamicFields.map((input, index) => (
        <React.Fragment key={index}>
          <Col>
            <FormGroup className='font-size-12 ml-2 mr-2'>
              <Label
                htmlFor='investors_name'
                className='text-black font-weight-normal'
                style={{ color: primaryTextState }}>
                Name
              </Label>
              <CreatableSelect
                options={investorsList}
                className='font-size-12'
                value={investorsList.find((e, i) => e.value === input.name)}
                onChange={(val) =>
                  handleChangeInvestor(val, index, values, setFieldValue)
                }
                styles={{
                  control: (styles) => ({
                    ...styles,
                    backgroundColor: primaryColorState,
                    borderRadius: '8px'
                  }),
                  singleValue: (styles) => ({
                    ...styles,
                    color: primaryTextState
                  })
                }}
                isClearable
                placeholder='Type or select an investor'
              />
              <ErrorMessage
                name={`dynamicFields.${index}.name`}
                component='div'
                className='error font-size-12'
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup className='font-size-12 ml-2 mr-2'>
              <Label
                htmlFor='investors_amount'
                className='text-black font-weight-normal'
                style={{ color: primaryTextState }}>
                Amount
              </Label>
              <Input
                type='text'
                placeholder='Enter investment amount'
                name={`dynamicFields.${index}.amount`}
                value={input.amount}
                style={{ color: primaryTextState }}
                className='font-size-12'
                onChange={(e) =>
                  handleTextAmountChange(index, e.target.value, values, setFieldValue)
                }
                min={0}
              />
              <ErrorMessage
                name={`dynamicFields.${index}.amount`}
                component='div'
                className='error font-size-12'
              />
            </FormGroup>
          </Col>
          <div className='d-flex ml-2 mr-2'>
            <Col>
              <FormGroup className='font-size-12 ml-4'>
                <Input
                  type='checkbox'
                  value={input.lead}
                  name={`${index}.lead`}
                  className='font-size-12 mr-3'
                  style={{ color: primaryTextState }}
                  onChange={(e) =>
                    handleLeadChange(index, e.target.checked, values, setFieldValue)
                  }
                />
                <Label
                  htmlFor='investors_amount'
                  className='text-black font-weight-normal'
                  style={{ color: primaryTextState }}>
                  Lead
                </Label>
                <ErrorMessage
                  name={`${index}.lead`}
                  component='div'
                  className='error font-size-12'
                />
              </FormGroup>
            </Col>

            <div className='font-size-20 mt-1 d-flex'>
              {index !== 0 && (
                <Col>
                  <i
                    className='fas fa-trash-alt cursor-pointer mr-1 font-size-18'
                    onClick={() => removeField(index, setFieldValue, values)}></i>
                </Col>
              )}
              {index === 0 &&
                values.dynamicFields &&
                values.dynamicFields.length <= 0 && (
                  <Col className='font-size-22 ml-1'>
                    <i
                      className='mdi mdi-plus-circle-outline cursor-pointer'
                      onClick={(event) => {
                        addField(setFieldValue, values);
                      }}></i>
                  </Col>
                )}
              {index === values.dynamicFields.length - 1 && (
                <Col className='font-size-22 ml-1'>
                  <i
                    className='mdi mdi-plus-circle-outline cursor-pointer'
                    onClick={(event) => {
                      addField(setFieldValue, values);
                    }}></i>
                </Col>
              )}
            </div>
          </div>
          <hr
            style={{
              borderColor: 'grey',
              borderWidth: '1px',
              width: '90%'
            }}
          />
        </React.Fragment>
      ))}
      <div className='d-flex align-items-center justify-content-center'>
        {isEdit ? (
          <button
            type='submit'
            onClick={() => {
              updateInvestorData(values);
            }}
            className='btn btn-auth'
            style={{ width: 150, height: 40 }}>
            Edit Round
          </button>
        ) : (
          <>
            <button
              type='submit'
              onClick={() => {
                if (!errors || Object.keys(errors).length <= 0) {
                  handleSubmitRound(values, setFieldValue);
                }
              }}
              className='btn btn-auth'
              style={{ width: 150, height: 40 }}>
              Add Round
            </button>
            {displayinvestorListData.length > 0 && (
              <button
                type='submit'
                onClick={() => {
                  setDisplayinvestorData(false);
                }}
                className='btn btn-auth ml-2'
                style={{ width: 150, height: 40 }}>
                Clear
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const UpdateRoundModal = ({
  updateRound,
  setUpdateRound,
  values,
  setErrors,
  errors,
  touched,
  investorsList,
  setFieldValue,
  handleSubmitRound,
  displayinvestorListData,
  handleChangeInvestor,
  handleLeadChange,
  handleTextAmountChange,
  addField,
  setDisplayinvestorData,
  removeField,
  updateInvestorData,
  setInitialValue,
  primaryTextState,
  primaryColorState
}) => {
  return (
    <div>
      <div className='col-12 font-size-16 pb-2 ml-2 d-flex justify-content-between mb-4'>
        <span className='text-black font-weight-400' style={{ color: primaryTextState }}>
          Edit Investor
        </span>
        <i
          className='mdi mdi-close font-size-16 mr-2 cursor-pointer'
          style={{ color: primaryTextState }}
          onClick={() => {
            setUpdateRound(!updateRound);
            setInitialValue({
              selectRound: '',
              totalAmountStage: '',
              tokenPriceStage: '',
              dynamicFields: [{ name: '', amount: '', lead: false, tier: '' }]
            });
          }}
        />
      </div>
      <InvestorData
        values={values}
        setErrors={setErrors}
        errors={errors}
        touched={touched}
        investorsList={investorsList}
        setFieldValue={setFieldValue}
        handleSubmitRound={handleSubmitRound}
        displayinvestorListData={displayinvestorListData}
        handleChangeInvestor={handleChangeInvestor}
        handleLeadChange={handleLeadChange}
        handleTextAmountChange={handleTextAmountChange}
        addField={addField}
        setDisplayinvestorData={setDisplayinvestorData}
        removeField={removeField}
        isEdit={true}
        updateInvestorData={updateInvestorData}
        primaryTextState={primaryTextState}
        primaryColorState={primaryColorState}
      />
    </div>
  );
};
