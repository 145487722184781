import React, { useEffect, useRef, useState } from 'react';
import { Col, Collapse, Container, FormGroup, Input, Label } from 'reactstrap';
import get from 'lodash/get';
import * as Yup from 'yup';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import toast from 'react-hot-toast';
import { Formik, Form, ErrorMessage } from 'formik';
import { GetApplication, UpdateExchangeApplication } from '../../../services/Application';
import { StepsCalculator } from './StepsCalculator';
import { notificationMessageFunction } from '../../../constants/notificationConst';
import documentUploadIcon from '../../../assets/images/documentupload.png';
import documentUploadedIcon from '../../../assets/images/documentuploaded.png';
import { handleSubmitLiteApplication } from './LiteComplete';
import Loader from '../../../constants/Loader';

function Step17({
  isLoading,
  setIsLoading,
  setExchangeCompleteStep,
  applicationId,
  nextPage,
  setApplicationData,
  prevPage,
  applicationData,
  isUpdateExchange = false,
  isSectionCollapsed,
  toggleSection,
  GetApplicationDetailsEdit,
  primaryTextState,
  history,
  paramsValue
}) {
  const applicationStatus = applicationData.application_status;
  const [initialValue, setInitialValue] = useState({
    interested_in_audit: false,
    audit_doc: null,
    audit_doc_status: ''
  });
  const [completeStep, setCompleteStep] = useState(0);
  const AuditingReportSchema = Yup.object().shape({
    interested_in_audit: Yup.string()
  });

  const checkboxHandler = () => {
    setInitialValue({
      ...initialValue,
      interested_in_audit: !initialValue.interested_in_audit
    });
  };

  useEffect(() => {
    if (applicationId !== '' && !isUpdateExchange) {
      GetApplication({ application_id: applicationId }).then((res) => {
        const application = get(res, 'data.data.data[0]', '');
        let initialData = { interested_in_audit: false, audit_doc: '' };
        if (
          application.exchange_application &&
          application.exchange_application.interested_in_audit
        ) {
          initialData = {
            ...initialData,
            interested_in_audit: application.exchange_application.interested_in_audit
          };
          setInitialValue({
            interested_in_audit: application.exchange_application.interested_in_audit,
            audit_doc: application.exchange_application.audit_doc,
            audit_doc_status: application.exchange_application.audit_doc_status
          });
        }
        if (
          application.exchange_application &&
          application.exchange_application.audit_doc
        ) {
          initialData = {
            ...initialData,
            audit_doc: application.exchange_application.audit_doc,
            audit_doc_status: application.exchange_application.audit_doc_status
          };
        }
        setInitialValue(initialData);
        setCompleteStep(application.exchange_application.percentage_complete);
      });
    } else {
      let initialData = { interested_in_audit: false, audit_doc: null };

      if (
        applicationData &&
        applicationData.exchange_application &&
        applicationData.exchange_application.interested_in_audit
      ) {
        initialData = {
          ...initialData,
          interested_in_audit: applicationData.exchange_application.interested_in_audit,
          audit_doc_status: applicationData.exchange_application.audit_doc_status
        };
        setInitialValue({
          interested_in_audit: applicationData.exchange_application.interested_in_audit,
          audit_doc: applicationData.exchange_application.audit_doc,
          audit_doc_status: applicationData.exchange_application.audit_doc_status
        });
      }
      if (
        applicationData &&
        applicationData.exchange_application &&
        applicationData.exchange_application.audit_doc
      ) {
        initialData = {
          ...initialData,
          audit_doc: applicationData.exchange_application.audit_doc,
          audit_doc_status: applicationData.exchange_application.audit_doc_status
        };
      }
      setInitialValue(initialData);
    }
  }, [applicationData, applicationId, isUpdateExchange]);

  const handleSubmit = (values, { setFieldError }) => {
    if (
      initialValue.audit_doc_status !== '' &&
      initialValue.audit_doc_status !== undefined &&
      typeof values.audit_doc !== 'object'
    ) {
      nextPage();
      setIsLoading(false);
    } else {
      const data = new FormData();
      data.append('interested_in_audit', values.interested_in_audit);
      if (
        values.audit_doc &&
        typeof values.audit_doc !== 'string' &&
        values.audit_doc.size / 1024 > 50000
      ) {
        setFieldError('audit_doc', 'File size should be less than 50 MB');
        return;
      }

      data.append('audit_doc', values.audit_doc);
      if (values.audit_doc) {
        data.append('audit_doc_status', 'uploading');
      }

      let stepsRequired = Math.floor(StepsCalculator(1, completeStep, 71));
      data.append('percentage_complete', stepsRequired);
      setIsLoading(true);

      UpdateExchangeApplication({ application_id: applicationId }, data)
        .then((res) => {
          const application = get(res, 'data.data.data', '');
          setApplicationData(application);
          if (isUpdateExchange) {
            toast.success('Auditing report updated successfully', {
              style: { fontSize: '12px' }
            });
            GetApplicationDetailsEdit(applicationId);
          }
          nextPage();
          setIsLoading(false);
        })
        .catch((err) => {
          const errorMessage = get(err, 'data.error.message');
          toast.error(notificationMessageFunction(errorMessage), {
            style: { fontSize: '12px' }
          });
        });
    }

    setExchangeCompleteStep(Array.from({ length: 18 }, (_, index) => index));
  };

  return (
    <React.Fragment>
      <div onClick={isSectionCollapsed ? toggleSection : undefined}>
        {isUpdateExchange ? (
          <>
            <div
              className='cursor-pointer col-12 font-size-14 ml-2 d-flex justify-content-between align-items-center'
              onClick={toggleSection}>
              <span
                className='text-black font-weight-400'
                style={{ color: primaryTextState }}>
                Auditing report
              </span>
              <i
                className={`font-size-22 mdi mdi-chevron-${
                  isSectionCollapsed === true ? 'up' : 'down'
                }`}
                style={{ color: primaryTextState }}
              />
            </div>
          </>
        ) : (
          <div className='col-12 font-size-16 pb-2 ml-2'>
            <span
              className='text-black font-weight-400'
              style={{ color: primaryTextState }}>
              Auditing report
            </span>
          </div>
        )}
        <Collapse isOpen={!isSectionCollapsed}>
          {isLoading ? (
            <div className='mt-5'>
              <Loader />
            </div>
          ) : (
            <Container className='col-12 col-sm-auto pt-2'>
              <div>
                <Formik
                  initialValues={initialValue}
                  enableReinitialize={true}
                  validationSchema={AuditingReportSchema}
                  onSubmit={handleSubmit}>
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form>
                      <div>
                        <Col className='mt-3'>
                          <FormGroup check className='d-flex align-items-center'>
                            <Label check style={{ color: primaryTextState }}>
                              <Input
                                type='checkbox'
                                name='interested_in_audit'
                                values={values}
                                onChange={() => checkboxHandler()}
                                checked={values.interested_in_audit}
                                handleChange={handleChange}
                                errors={errors}
                                touched={touched}
                              />
                              <span className='font-size-12 font-weight-normal'>
                                Are you interested in receiving a Smart Contract audit.{' '}
                              </span>
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col className='mt-3'>
                          <CustomFileInput
                            labelName='Upload your Audit'
                            fieldName='audit_doc'
                            errors={errors}
                            headerName={'Upload your Audit'}
                            values={values}
                            handleChange={handleChange}
                            setFieldValue={setFieldValue}
                            primaryTextState={primaryTextState}
                          />
                        </Col>
                      </div>
                      <div className='d-flex justify-content-between align-items-center mt-4 mr-2 ml-2'>
                        <i
                          className='mdi mdi-chevron-double-left font-size-32 ml-1 cursor-pointer'
                          onClick={prevPage}
                          style={{ color: primaryTextState }}
                        />
                        <div>
                          {(applicationStatus === 'INCOMPLETE' || !applicationStatus) && (
                            <button
                              className='btn btn-status-change text-white mr-2'
                              type='submit'
                              style={{ width: '142px', height: '50px' }}
                              onClick={() =>
                                handleSubmitLiteApplication({
                                  applicationId,
                                  setApplicationData,
                                  history,
                                  paramsValue,
                                  setIsLoading
                                })
                              }>
                              Submit
                            </button>
                          )}
                          <button
                            className='btn application-button text-white'
                            type='submit'
                            onSubmit={(values) => handleSubmit(values)}>
                            Next
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </Container>
          )}
        </Collapse>
      </div>
    </React.Fragment>
  );
}

export default Step17;

const CustomFileInput = ({
  labelName,
  fieldName,
  headerName,
  values,
  setFieldValue,
  primaryTextState
}) => {
  const fileInputRef = useRef(null);

  const handleFileButtonClick = () => {
    fileInputRef.current.click();
  };
  return (
    <FormGroup className='mb-3'>
      <div className='font-size-12'>
        <Label
          htmlFor='description'
          className={`text-black cursor-pointer font-weight-normal ${
            !labelName ? 'mt-3' : 'mt-1'
          }`}
          data-replace={`${labelName}`}
          style={{ color: primaryTextState }}>
          <span>
            {labelName}:{' '}
            <span className='font-weight-medium'>
              {values[fieldName + '_status'] === 'uploaded'
                ? 'Uploaded'
                : values[fieldName + '_status'] === 'uploading'
                ? 'Uploading'
                : values[fieldName + '_status'] === 'upload_error'
                ? 'Upload Error'
                : ''}
            </span>
          </span>
        </Label>
      </div>
      <div className='file-upload d-flex direction-row justify-content-between align-items-center pl-1'>
        <Label
          htmlFor={values[fieldName]}
          className='mt-2'
          onClick={handleFileButtonClick}
          style={{ color: primaryTextState }}>
          <div className='cursor-pointer'>
            <span className='rounded-circle' style={{ color: '#134a87', fontSize: 12 }}>
              {values[fieldName] ? (
                <img src={documentUploadedIcon} alt={values[fieldName]} />
              ) : (
                <img src={documentUploadIcon} alt={values[fieldName]} />
              )}
            </span>
            <span className='font-size-12 ml-2'>{headerName}</span>
          </div>
        </Label>
        <div>
          {values[fieldName] !== null && typeof values[fieldName] === 'object' ? (
            <a
              href={URL.createObjectURL(values[fieldName])}
              target='_blank'
              rel='noopener noreferrer'
              className='pr-2 font-size-12 font-weight-bold text-wrap text-primary'>
              View File
            </a>
          ) : values[fieldName] !== null &&
            typeof values[fieldName] === 'string' &&
            values[fieldName].length > 0 ? (
            <a
              href={values[fieldName]}
              target='_blank'
              rel='noopener noreferrer'
              className='pr-2 font-size-12 font-weight-bold text-wrap text-primary'>
              View File
            </a>
          ) : (
            ''
          )}
        </div>
        <input
          type='file'
          name={fieldName}
          style={{ display: 'none' }}
          ref={fileInputRef}
          onChange={(event) => {
            setFieldValue(fieldName, event.currentTarget.files[0]);
          }}
        />

        <ReactTooltip
          anchorId={fieldName}
          place='bottom'
          content={values[fieldName] && values[fieldName].name}
        />
      </div>
      <ErrorMessage name={fieldName}>
        {(msg) => (
          <div style={{ fontSize: 12 }} className='text-left mt-1 text-danger'>
            {msg}
          </div>
        )}
      </ErrorMessage>
    </FormGroup>
  );
};
