import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';
import { constants as ROUTE_CONST } from '../../../constants/routes';

function Congratulations({ primaryTextState, paramsValue }) {
  return (
    <React.Fragment>
      <div className='d-flex justify-content-center align-items-center'>
        <Container
          className='pt-4 m-0 d-flex align-items-center justify-content-center'
          style={{ flexDirection: 'column', width: '490px', textAlign: 'center' }}>
          <span className='text-black font-size-22' style={{ color: primaryTextState }}>
            Congratulations🎊
          </span>
          <span
            className='text-black font-size-16 m-4'
            style={{ color: primaryTextState }}>
            You've completed your Application Click here to view the status.
          </span>
          <Link
            to={
              ROUTE_CONST.VIEW_APPLICATIONS +
              '?' +
              (paramsValue && paramsValue.widgetTheme
                ? `&widgetTheme=${paramsValue.widgetTheme}`
                : '') +
              (paramsValue && paramsValue.containerColor
                ? `&containerColor=${paramsValue.containerColor}`
                : '') +
              (paramsValue && paramsValue.primaryColor
                ? `&primaryColor=${paramsValue.primaryColor}`
                : '') +
              (paramsValue && paramsValue.primaryText
                ? `&primaryText=${paramsValue.primaryText}`
                : '') +
              (paramsValue && paramsValue.secondaryText
                ? `&secondaryText=${paramsValue.secondaryText}`
                : '')
            }>
            <div className='d-flex flex-column justify-content-center align-items-center text-white btn application-button'>
              GO
            </div>
          </Link>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default Congratulations;
