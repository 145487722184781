import React, { useEffect, useMemo, useState } from 'react';
import { Col, Collapse, Container, FormGroup, Label } from 'reactstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import get from 'lodash/get';
import { constants as ERROR_CONST } from '../../../constants/errorMessage';
import { GetApplication, UpdateExchangeApplication } from '../../../services/Application';
import { StepsCalculator } from './StepsCalculator';
import { notificationMessageFunction } from '../../../constants/notificationConst';
import toast from 'react-hot-toast';
import { constants as CONST } from '../../../constants/application';
import ReactTags from 'react-tag-autocomplete';
import { allCountries } from 'country-region-data';
import { regions } from '../../../constants/regionConst';
import { handleSubmitLiteApplication } from './LiteComplete';
import Loader from '../../../constants/Loader';

function Step22({
  isLoading,
  setIsLoading,
  setExchangeCompleteStep,
  applicationId,
  nextPage,
  setApplicationData,
  prevPage,
  applicationData,
  isUpdateExchange = false,
  isSectionCollapsed,
  toggleSection,
  GetApplicationDetailsEdit,
  primaryTextState,
  history,
  paramsValue
}) {
  const applicationStatus = applicationData.application_status;
  applicationData = applicationData.exchange_application;

  const ListingPriceSchema = Yup.object().shape({
    target_geo: Yup.array()
      .min(1, ERROR_CONST.TARGETGEO_REQUIRED)
      .required(ERROR_CONST.TARGETGEO_REQUIRED)
  });
  const [completeStep, setCompleteStep] = useState(0);
  const [tags, setTags] = useState([]);
  const [initialValue, setInitialValue] = useState({
    target_geo: []
  });

  const allRegionOptions = useMemo(() => {
    return allCountries.reduce((acc, [countryName, countryCode, regions]) => {
      const countryRegions = regions.map(([region]) => ({
        country: countryName,
        countryCode,
        region,
        value: region.toLowerCase(),
        label: region
      }));
      return [...acc, ...countryRegions];
    }, []);
  }, []);
  const suggestionsRegion = [...allRegionOptions, ...regions].map(({ value, label }) => ({
    id: value,
    name: label
  }));

  const onDelete = (index) => {
    setTags(tags.filter((t, i) => i !== index));
  };

  const onAddition = (tag) => {
    setTags([...tags, tag]);
  };

  useEffect(() => {
    if (applicationId !== '' && !isUpdateExchange) {
      GetApplication({ application_id: applicationId }).then((res) => {
        const application = get(res, 'data.data.data[0]', '');
        if (application.exchange_application.target_geo) {
          setInitialValue({
            target_geo: application.exchange_application.target_geo
          });
          const initTags = suggestionsRegion.filter(({ name }) =>
            application.exchange_application.target_geo.includes(name)
          );
          setTags(initTags);
        }
        setCompleteStep(application.exchange_application.percentage_complete);
      });
    } else {
      if (applicationData && applicationData.target_geo) {
        setInitialValue({
          target_geo: applicationData.target_geo
        });
        const initTags = suggestionsRegion.filter(({ name }) =>
          applicationData.target_geo.includes(name)
        );
        setTags(initTags);
      }
    }
  }, [applicationData, applicationId, isUpdateExchange]);

  const handleSubmit = (values) => {
    let stepsRequired = Math.floor(StepsCalculator(1, completeStep, 100));
    if (values.target_geo !== applicationData.target_geo) {
      const data = {
        target_geo: JSON.stringify(values.target_geo),
        percentage_complete: stepsRequired
      };
      setIsLoading(true);
      UpdateExchangeApplication({ application_id: applicationId }, data)
        .then((res) => {
          const application = get(res, 'data.data.data', '');
          setApplicationData(application);
          if (isUpdateExchange) {
            toast.success('Regions updated successfully', {
              style: { fontSize: '12px' }
            });
            GetApplicationDetailsEdit(applicationId);
          }
          nextPage();
          setIsLoading(false);
        })
        .catch((err) => {
          const errorMessage = get(err, 'data.error.message');
          toast.error(notificationMessageFunction(errorMessage), {
            style: { fontSize: '12px' }
          });
        });
    } else {
      nextPage();
    }
    setExchangeCompleteStep(Array.from({ length: 23 }, (_, index) => index));
  };

  return (
    <React.Fragment>
      <div onClick={isSectionCollapsed ? toggleSection : undefined}>
        {isUpdateExchange ? (
          <>
            <div
              className='cursor-pointer col-12 font-size-14 ml-2 d-flex justify-content-between align-items-center'
              onClick={toggleSection}>
              <span
                className='text-black font-weight-400'
                style={{ color: primaryTextState }}>
                Primary focal areas or principal user base regions
              </span>
              <i
                className={`font-size-22 mdi mdi-chevron-${
                  isSectionCollapsed === true ? 'up' : 'down'
                }`}
                style={{ color: primaryTextState }}
              />
            </div>
          </>
        ) : (
          <div className='col-12 font-size-16 pb-2 ml-2'>
            <span
              className='text-black font-weight-400'
              style={{ color: primaryTextState }}>
              Primary focal areas or principal user base regions
            </span>
          </div>
        )}
        <Collapse isOpen={!isSectionCollapsed}>
          {isLoading ? (
            <div className='mt-5'>
              <Loader />
            </div>
          ) : (
            <Container className='col-12 col-sm-auto pt-2 mt-3'>
              <div>
                <Formik
                  initialValues={initialValue}
                  enableReinitialize={true}
                  validationSchema={ListingPriceSchema}
                  onSubmit={handleSubmit}>
                  {({ errors, touched, values, setFieldValue }) => (
                    <Form>
                      <div>
                        <Col>
                          <FormGroup className='mb-5'>
                            <div className='font-size-12'>
                              <Label
                                htmlFor='target_geo'
                                className='text-black font-weight-normal'
                                style={{ color: primaryTextState }}>
                                {CONST.TARGETGEO}
                              </Label>
                            </div>
                            <div>
                              <ReactTags
                                tags={tags}
                                classNames={{
                                  root: 'font-size-12 border-dark custom-react-tags border-grey'
                                }}
                                style={{ color: primaryTextState }}
                                name='target_geo'
                                placeholderText='Select Region'
                                suggestions={suggestionsRegion}
                                onDelete={(index) => {
                                  onDelete(index);
                                  const newTags = tags.filter((t, i) => i !== index);
                                  setFieldValue(
                                    'target_geo',
                                    newTags.map((tag) => tag.name)
                                  );
                                }}
                                onAddition={(tag) => {
                                  onAddition(tag);
                                  const newTags = [...tags, tag];
                                  setFieldValue(
                                    'target_geo',
                                    newTags.map((tag) => tag.name)
                                  );
                                }}
                              />
                            </div>
                            {errors.target_geo && touched.target_geo ? (
                              <div className='text-danger font-size-12'>
                                {errors.target_geo}
                              </div>
                            ) : null}
                          </FormGroup>
                        </Col>
                      </div>
                      <div className='d-flex justify-content-between align-items-center mt-3 mr-2 ml-2'>
                        <i
                          className='mdi mdi-chevron-double-left font-size-32 ml-1 cursor-pointer'
                          onClick={prevPage}
                          style={{ color: primaryTextState }}
                        />
                        <div>
                          {(applicationStatus === 'INCOMPLETE' || !applicationStatus) && (
                            <button
                              className='btn btn-status-change text-white mr-2'
                              type='submit'
                              style={{ width: '142px', height: '50px' }}
                              onClick={() =>
                                handleSubmitLiteApplication({
                                  applicationId,
                                  setApplicationData,
                                  history,
                                  paramsValue,
                                  setIsLoading
                                })
                              }>
                              Submit
                            </button>
                          )}
                          <button
                            className='btn application-button text-white'
                            type='submit'
                            onSubmit={(values) => handleSubmit(values)}>
                            Next
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </Container>
          )}
        </Collapse>
      </div>
    </React.Fragment>
  );
}

export default Step22;
