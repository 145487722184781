import React, { Component } from 'react';

import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { withQueryParams, StringParam } from 'use-query-params';

// Import menuDropdown
// eslint-disable-next-line no-unused-vars
import LanguageDropdown from '../CommonForBoth/TopbarDropdown/LanguageDropdown';

//Import i18n
import { withNamespaces } from 'react-i18next';

import { toggleRightSidebar } from '../../store/actions';

import Divider from './Divider';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearch: false,
      isSocialPf: false
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleRightbar = this.toggleRightbar.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
  }

  /**
   * Toggle sidebar
   */
  toggleMenu() {
    this.props.toggleMenuCallback();
  }

  /**
   * Toggles the sidebar
   */
  toggleRightbar() {
    this.props.toggleRightSidebar();
  }

  toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  render() {
    const {
      history,
      query: { search }
    } = this.props;
    const routeSegments = this.props.location.pathname
      .split('/')
      .filter((segment) => segment !== '');
    const wordToFind = 'application';
    let applicationIndex = routeSegments.findIndex((segment) =>
      segment.toLowerCase().includes(wordToFind)
    );
    return (
      <React.Fragment>
        <header id='page-topbar'>
          <div className='navbar-header d-flex justify-content-between align-item-center px-4'>
            <div className='d-flex'>
              <Button
                size='sm'
                color='none'
                type='button'
                onClick={this.toggleMenu}
                className='pl-0 pr-4 font-size-24 header-item waves-effect'
                id='vertical-menu-btn'>
                <i className='ri-menu-2-line align-middle'></i>
              </Button>
              <div className='pr-2 d-flex'>
                <Link to='/' className='logo logo-dark d-flex justify-content-center'>
                  <span className='logo-lg d-flex flex-column justify-content-center'>
                    <span className='font-size-22 font-weight-bold'>Listus</span>
                    <span className='font-size-12' style={{ color: 'grey' }}>
                      Powered by{' '}
                      <span className='font-weight-normal text-dark'>Rampnalysis</span>
                    </span>
                  </span>
                </Link>
              </div>
              <Divider className='d-none d-lg-block mx-5' />
            </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { layoutType } = state.Layout;
  return { layoutType };
};

export default connect(mapStatetoProps, { toggleRightSidebar })(
  withNamespaces()(
    withRouter(
      withQueryParams(
        {
          search: StringParam
        },
        Header
      )
    )
  )
);
