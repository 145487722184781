import React, { useEffect, useState } from 'react';
import { useQueryParam, StringParam } from 'use-query-params';
import { Paginator } from 'primereact/paginator';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import GetResponse from '../ExchangeApplication/GetResponse';

function ResponsePage({
  page,
  first,
  order,
  loading,
  setFirst,
  setPage,
  allResponses,
  currentPageData,
  setCurrentPageData,
  setApplicationId,
  statusSuggestions,
  GetApplicationResponseFunc,
  primaryTextState,
  containerColorState,
  primaryColorState,
  paramsValue
}) {
  const [applicationId] = useQueryParam('application_id', StringParam);
  const [expandedRows, setExpandedRows] = useState([]);
  const [totalCount] = useState(0);

  useEffect(() => {
    setApplicationId(applicationId);
  }, []);

  const onPageChange = (event) => {
    setFirst(event.first);
    setPage(event.page + 1);
    const newPageData = allResponses.slice(event.first, event.first + event.rows);
    setCurrentPageData(newPageData);
  };

  useEffect(() => {
    GetApplicationResponseFunc(applicationId);
  }, [applicationId, order, statusSuggestions]);

  const toggleRowExpansion = (index) => {
    if (expandedRows.includes(index)) {
      setExpandedRows(expandedRows.filter((rowIndex) => rowIndex !== index));
    } else {
      setExpandedRows([...expandedRows, index]);
    }
  };

  return (
    <React.Fragment>
      <div className='ml-2 mr-2 mt-3'>
        {loading ? (
          <div className='h-100 d-flex align-items-center justify-content-center py-4'>
            <i
              className='fas fa-circle-notch fa-spin text-muted'
              style={{ fontSize: 48 }}></i>
          </div>
        ) : (
          <>
            {currentPageData && currentPageData.length > 0 ? (
              currentPageData.map((application, key) => (
                <React.Fragment key={key}>
                  <div onClick={() => toggleRowExpansion(key)}>
                    <GetResponse
                      application={application}
                      index={key}
                      page={page}
                      GetApplicationResponseFunc={GetApplicationResponseFunc}
                      applicationId={applicationId}
                      expandedRows={expandedRows}
                      primaryColorState={primaryColorState}
                      primaryTextState={primaryTextState}
                      paramsValue={paramsValue}
                    />
                  </div>
                  {expandedRows.includes(key) && (
                    <>
                      <hr className='ml-2 mr-2' />
                      <div className='application-card-border ml-2 mr-2'>
                        <div className='expanded-row'>
                          {application &&
                          application.applicationResponse.field &&
                          Object.keys(application.applicationResponse.field).length >
                            0 ? (
                            <ul
                              className='notes-list px-3 pt-3'
                              style={{ color: primaryTextState }}>
                              {Object.keys(application.applicationResponse.field).map(
                                (fItem, index) => (
                                  <li key={index} className='font-size-12'>
                                    <span className='font-weight-medium'>
                                      <u>{fItem.toUpperCase()}</u>:
                                    </span>
                                    {'  '}
                                    {application.applicationResponse.field[fItem]}
                                  </li>
                                )
                              )}
                            </ul>
                          ) : application.applicationResponse &&
                            application.applicationResponse.notes ? (
                            <span
                              className='notes-list font-size-12 px-3 pt-3'
                              style={{ color: primaryTextState }}>
                              {application.applicationResponse.notes}
                            </span>
                          ) : (
                            <div className='no-notes' style={{ color: primaryTextState }}>
                              No notes available
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </React.Fragment>
              ))
            ) : (
              <div className='application-card-border ml-1 mr-1 mt-3 py-2 d-flex justify-content-center'>
                <span style={{ color: primaryTextState }}>No response found</span>
              </div>
            )}
          </>
        )}
      </div>

      <Paginator
        rows={5}
        totalRecords={totalCount}
        first={first}
        onPageChange={(e) => onPageChange(e)}
        style={{ backgroundColor: containerColorState, color: primaryTextState }}
      />
    </React.Fragment>
  );
}

export default ResponsePage;
