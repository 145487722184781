import React from 'react';
import TimeAgo from 'react-timeago';
import { stringify } from 'query-string';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Collapse } from 'reactstrap';
import moment from 'moment';
import { ReactComponent as CheckCircleIcon } from '../../assets/images/icons/check.svg';

const ProfileIcon = ({ iconUrl, width = 40, height = 40, primaryTextState }) => (
  <div style={{ padding: '5px' }}>
    {iconUrl && iconUrl.length > 0 ? (
      <img
        src={iconUrl}
        style={{ borderRadius: '50%' }}
        width={width}
        height={height}
        alt='user'
      />
    ) : (
      <i
        className='fa fa-user'
        style={{
          borderRadius: '50%',
          border: '1px solid #f0f0f0',
          width: `${width}px`,
          height: `${height}px`,
          background: '#f0f0f0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: primaryTextState
        }}
      />
    )}
  </div>
);

const getSeverity = (status) => {
  const statusToSeverity = {
    REJECT: 'danger',
    ACCEPT: 'success',
    ADD_QUESTIONS: 'info',
    negotiation: 'warning',
    COMPLETE: 'success',
    QUOTE: 'secondary',
    renewal: 'secondary'
  };

  return statusToSeverity[status] || 'secondary';
};

const statusSuggestions = [
  { id: 'REQUEST_ACTION', name: 'Action' },
  { id: 'PROCESSING', name: 'Processing' },
  { id: 'ACCEPT', name: 'Accept' },
  { id: 'REJECT', name: 'Reject' },
  { id: 'KYB', name: 'KYB' },
  { id: 'QUOTE', name: 'Quote' },
  { id: 'PAID', name: 'Paid' },
  { id: 'ADD_QUESTIONS', name: 'Add Question' }
];

function GetApplication({
  application,
  index,
  activeId,
  onClick,
  history,
  setFirst,
  params,
  primaryColorState,
  primaryTextState,
  secondaryTextState
}) {
  const handleContinue = () => handleContinueClick(application, history);

  const ProgressBar = ({ status, percentage, primaryTextState, isLite = false }) => (
    <div className='name-label font-weight-medium font-size-12 mb-2'>
      <span className='mr-2'>
        <CircularProgressbar
          value={status === 'COMPLETE' ? 100 : percentage}
          text={`${status === 'COMPLETE' ? '100' : percentage}%`}
        />
      </span>
      <span style={{ color: primaryTextState }}>
        {status === 'COMPLETE' ? '100' : percentage}%{' '}
        {isLite ? 'Lite Complete' : 'Complete'}
      </span>
    </div>
  );

  const handleContinueClick = (application, history) => {
    if (application.application_type === 'ONRAMP') {
      history.push(
        `/view-onramp-application${params === '' ? '?' : params}&${stringify({
          application_id: application._id
        })}`
      );
    } else {
      history.push(
        `/view-exchange-application${params === '' ? '?' : params}&${stringify({
          application_id: application._id
        })}`
      );
    }
  };

  return (
    <React.Fragment key={index}>
      <div
        className='application-card-border card-shadow-application'
        style={{ background: primaryColorState }}
        onClick={() => onClick(index)}>
        <div className='d-flex'>
          <ProfileIcon
            iconUrl={
              application && application.ticker_logo
                ? application.ticker_logo
                : application.exchange_application &&
                  application.exchange_application.ticker_logo
                ? application.exchange_application.ticker_logo
                : ''
            }
            primaryTextState={primaryTextState}
          />
          <label
            className='d-flex justify-content-center align-items-center name-label font-weight-medium font-size-14'
            style={{ color: primaryTextState }}>
            {application.ticker
              ? application.ticker
              : application.exchange_application.token_name}
          </label>
        </div>
        <span
          className='font-size-12 font-weight-medium ml-1'
          style={{ color: primaryTextState }}>
          {application.application_type}
        </span>
        <span className='ticker-label font-size-12' style={{ color: secondaryTextState }}>
          {' '}
          - Last updated{' '}
          <TimeAgo
            date={application.updated_at}
            formatter={(value, unit, suffix) => {
              if (value < 1 && unit === 'second') {
                return 'just now';
              } else {
                return value + ' ' + unit + (value > 1 ? 's' : '') + ' ' + suffix;
              }
            }}
          />
        </span>
        <div className='ml-1'>
          <ProgressBar
            primaryTextState={primaryTextState}
            status={application.application_status}
            percentage={
              application.application_status === 'COMPLETE'
                ? '100'
                : application?.application_type === 'ONRAMP'
                ? application?.app_onramp_per
                : application?.application_type === 'EXCHANGE'
                ? application?.app_exchange_per
                : ''
            }
          />
          {application?.application_type === 'EXCHANGE' && (
            <ProgressBar
              primaryTextState={primaryTextState}
              status={application.application_status}
              percentage={
                application.application_status === 'LITE_COMPLETE'
                  ? '100'
                  : application?.application_type === 'EXCHANGE'
                  ? application?.app_exchange_lite_per
                  : ''
              }
              isLite={true}
            />
          )}
        </div>

        <div className='mt-3 ml-1 d-flex justify-content-between'>
          <button
            style={{ height: '27px', width: '81px' }}
            className='btn font-size-10 application-button text-white text-center d-flex justify-content-center align-items-center'
            type='submit'
            onClick={handleContinue}
            // onClick={() =>
            //   window.open(
            //     process.env.REACT_APP_PROD_FRONTEND +
            //       (application.application_type === 'EXCHANGE'
            //         ? `/application/viewExchange?application_id=${
            //             application._id
            //           }&accessToken=${localStorage.getItem(CONST.ACCESSTOKEN)}`
            //         : `/application/view?application_id=${
            //             application._id
            //           }&accessToken=${localStorage.getItem(CONST.ACCESSTOKEN)}`)
            //   )
            // }
          >
            Continue
          </button>
          {application.sub_application &&
            application.sub_application.length > 0 &&
            application.sub_application.length >= 1 &&
            application.sub_application.filter(
              (app) =>
                app && app.onRampAppData && Object.keys(app.onRampAppData).length > 0
            ).length > 0 && (
              <div className='d-flex justify-content-center align-items-center'>
                <i
                  className={`font-size-22 mdi mdi-chevron-${
                    activeId === index ? 'up' : 'down'
                  }`}
                />{' '}
              </div>
            )}
        </div>
      </div>
      {application.sub_application &&
        application.sub_application.length > 0 &&
        application.sub_application &&
        application.sub_application.filter(
          (app) => app && app.onRampAppData && Object.keys(app.onRampAppData).length > 0
        ).length > 0 && (
          <div key={index}>
            <hr className='ml-3 mr-3' />
            <SubApplications
              applications={application.sub_application}
              activeId={activeId}
              index={index}
            />
          </div>
        )}
    </React.Fragment>
  );
}

export default GetApplication;

export const SubApplications = ({ applications, activeId, index }) => {
  return (
    <Collapse className='mt-2' isOpen={activeId === index} key={index}>
      <div className='application-card-border subcard-shadow-application ml-1 mr-1'>
        {applications && applications.length > 0 ? (
          <>
            {applications
              .filter(
                (app) =>
                  app && app.onRampAppData && Object.keys(app.onRampAppData).length > 0
              )
              .map((application, i) => (
                <>
                  <div className='d-flex justify-content-between align-items-center'>
                    <span className='font-size-12 font-weight-500 text-dark-grey'>
                      Requested By
                    </span>
                    <span className='d-flex flex-row'>
                      <ProfileIcon
                        width={20}
                        height={20}
                        iconUrl={
                          application &&
                          application.onRampAppData &&
                          application.onRampAppData.logo_url
                            ? application.onRampAppData.logo_url
                            : ''
                        }
                      />
                      <span className='font-size-12 font-weight-500 text-dark-grey d-flex justify-content-between align-items-center'>
                        {application.onRampAppData &&
                          application.onRampAppData.on_ramp_name}
                      </span>
                    </span>
                  </div>
                  <div className='d-flex justify-content-between align-items-center mt-2'>
                    <span className='font-size-12 font-weight-500 text-dark-grey'>
                      Status
                    </span>
                    <span className='d-flex flex-row'>
                      <span
                        className={`px-3 py-1 d-flex justify-content-center align-items-center font-size-8 font-weight-normal badge badge-pill badge-${getSeverity(
                          application.application_status
                        )}`}>
                        {application &&
                          application.application_status &&
                          statusSuggestions.find(
                            (status) => status.id === application.application_status
                          ).name}{' '}
                      </span>
                    </span>
                  </div>
                  <div className='d-flex justify-content-between align-items-center mt-3'>
                    <span className='font-size-12 font-weight-500 text-dark-grey'>
                      Date Submitted
                    </span>
                    <span className='d-flex flex-row'>
                      <span className='font-size-12 font-weight-500 text-dark-grey d-flex justify-content-between align-items-center'>
                        {moment(application.created_at).format('DD/MM/YYYY hh:mm A')}
                      </span>
                    </span>
                  </div>
                  <div className='d-flex justify-content-between align-items-center mt-3'>
                    <span className='font-size-12 font-weight-500 text-dark-grey'>
                      Needed Review
                    </span>
                    <span className='d-flex flex-row'>
                      <span className='font-size-12 font-weight-500 text-dark-grey d-flex justify-content-between align-items-center'>
                        {actionBodyTemplate(application)}
                      </span>
                    </span>
                  </div>
                  <hr />
                  <div className='d-flex justify-content-between align-items-center mt-3'>
                    <span className='font-size-12 font-weight-500 text-dark-grey'>
                      Field Changes Requested
                    </span>
                    <span className='font-size-12 font-weight-500 text-dark-grey'>
                      Notes
                    </span>
                  </div>
                  <div className='d-flex justify-content-between align-items-center mt-3'>
                    <span className='font-size-12 font-weight-500 text-dark-grey'>
                      {application &&
                      application.onRampAppData &&
                      application.onRampAppData.field &&
                      Object.keys(application.onRampAppData.field).length > 0 ? (
                        <>
                          {Object.keys(application.onRampAppData.field)
                            .splice(0, 5)
                            .map((value, index) => (
                              <div
                                className={
                                  application.onRampAppData.completed_field &&
                                  application.onRampAppData.completed_field.length > 0 &&
                                  application.onRampAppData.completed_field.includes(
                                    value
                                  )
                                    ? 'text-success'
                                    : 'text-warning'
                                }
                                key={index}>
                                {index + 1}. {value}
                              </div>
                            ))}
                          {Object.keys(application.onRampAppData.field).length > 5 &&
                            '...more'}
                        </>
                      ) : application &&
                        application.application_status === 'ADD_QUESTIONS' &&
                        application.add_questions &&
                        application.add_questions.length > 0 ? (
                        application.add_questions.map((que, index) => (
                          <div key={index}>
                            {index + 1}. {que.type}
                          </div>
                        ))
                      ) : (
                        <div>No fields available</div>
                      )}{' '}
                    </span>
                    <span className='font-size-12 font-weight-500 text-dark-grey'>
                      {application &&
                      application.onRampAppData &&
                      application.onRampAppData.field &&
                      Object.keys(application.onRampAppData.field).length > 0 ? (
                        Object.entries(application.onRampAppData.field).map(
                          ([key, value]) => {
                            if (value.length > 0) {
                              const truncatedValue =
                                String(value).length > 20
                                  ? `${String(value).substring(0, 20)}...`
                                  : String(value);
                              return (
                                <div key={key} data-tip={value}>
                                  &#8226; {truncatedValue}
                                </div>
                              );
                            } else {
                              return <div key={key} data-tip={value}></div>;
                            }
                          }
                        )
                      ) : application.onRampAppData && application.onRampAppData.notes ? (
                        String(application.onRampAppData.notes).length > 20 ? (
                          `${String(application.onRampAppData.notes).substring(0, 20)}...`
                        ) : (
                          String(application.onRampAppData.notes)
                        )
                      ) : application &&
                        application.application_status === 'ADD_QUESTIONS' &&
                        application.add_questions &&
                        application.add_questions.length > 0 ? (
                        application.add_questions.map((que, index) => (
                          <div key={index}>
                            {index + 1}.
                            {String(que.question).length > 20
                              ? `${String(que.question).substring(0, 20)}...`
                              : String(que.question)}
                          </div>
                        ))
                      ) : (
                        <div>No notes available</div>
                      )}{' '}
                    </span>
                  </div>
                </>
              ))}
          </>
        ) : (
          <tr>
            <td colSpan={7}>
              <span className='d-flex justify-content-center'>
                No sub applications found
              </span>
            </td>
          </tr>
        )}
      </div>
    </Collapse>
  );
};

const actionBodyTemplate = (application) => {
  if (application && application.onRampAppData) {
    const { onRampAppData } = application;
    if (onRampAppData.field) {
      const fieldKeys = Object.keys(onRampAppData.field);
      const completedFieldKeys = onRampAppData.completed_field;
      if (fieldKeys.length === completedFieldKeys.length) {
        return (
          <div className='badge font-size-12 p-2' style={{ borderRadius: 8 }}>
            Completed
          </div>
        );
      }
      let foundFirstPending = false;
      return fieldKeys
        .map((value, index) => {
          const isFieldCompleted = completedFieldKeys.includes(value);
          if (isFieldCompleted) {
            return (
              <div key={index} className='mb-1'>
                <CheckCircleIcon height={26} width={28} />
                {value}
              </div>
            );
          } else if (!foundFirstPending) {
            foundFirstPending = true;
            return (
              <div
                key={index}
                className='badge font-size-12 p-2'
                style={{ borderRadius: 8 }}>
                Pending
              </div>
            );
          }
          return null;
        })
        .filter(Boolean);
    } else if (onRampAppData.status === 'ACCEPT' || onRampAppData.status === 'REJECT') {
      return (
        <div className='badge font-size-12 p-2' style={{ borderRadius: 8 }}>
          Completed
        </div>
      );
    } else if (onRampAppData && onRampAppData.response_complete) {
      return (
        <div className='badge font-size-12 p-2' style={{ borderRadius: 8 }}>
          Completed
        </div>
      );
    } else if (onRampAppData && !onRampAppData.response_complete) {
      return (
        <div className='badge font-size-12 p-2' style={{ borderRadius: 8 }}>
          Pending
        </div>
      );
    } else {
      return (
        <div>
          {onRampAppData.status === 'QUOTE' &&
          onRampAppData.quote_price &&
          onRampAppData.quote_price.crypto_currency ? (
            <div
              onClick={() => {
                const externalURL = onRampAppData.payments_txs[0]?.invoiceLinks?.pay;
                const newTab = window.open(externalURL, '_blank');
                newTab.focus();
              }}
              className='p-2 badge badge-secondary font-size-12 cursor-pointer'
              style={{ borderRadius: 8 }}>
              View Invoice
            </div>
          ) : onRampAppData.status === 'QUOTE' &&
            onRampAppData.quote_price &&
            onRampAppData.quote_price.currency ? (
            'Payment Awaited'
          ) : (
            '-'
          )}
        </div>
      );
    }
  } else {
    return (
      <div className='badge font-size-12 p-2' style={{ borderRadius: 8 }}>
        N/A
      </div>
    );
  }
};
