import React, { useEffect, useState } from 'react';
import { Col, Collapse, Container, FormGroup, Input, Label } from 'reactstrap';
import { Formik, Form } from 'formik';
import get from 'lodash/get';
import Select from 'react-select';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { UpdateExchangeApplication } from '../../../services/Application';
import { StepsCalculator } from './StepsCalculator';
import { notificationMessageFunction } from '../../../constants/notificationConst';
import { constants as CONST } from '../../../constants/application';
import { ExchangeListAPI } from '../../../services/Auth';
import { handleSubmitLiteApplication } from './LiteComplete';
import Loader from '../../../constants/Loader';

function Step8({
  isLoading,
  setIsLoading,
  setExchangeCompleteStep,
  applicationId,
  nextPage,
  setApplicationData,
  prevPage,
  applicationData,
  isUpdateExchange = false,
  isSectionCollapsed,
  toggleSection,
  GetApplicationDetailsEdit,
  primaryTextState,
  primaryColorState,
  history,
  paramsValue
}) {
  const applicationStatus = applicationData.application_status;
  applicationData = applicationData.exchange_application;
  const [completeStep, setCompleteStep] = useState(0);
  const [exchangeList, setExchangeList] = useState([]);
  const [allExchangeList, setAllExchangeList] = useState([]);
  const [filterExchangeList, setFilterExchangeList] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const CapMarketSchema = Yup.object().shape({
    existing_exchnage_listed: Yup.string()
  });
  const changeHandlerExchange = (selectValues) => {
    const filteredData = allExchangeList.filter((item) =>
      selectValues.some((dataItem) => dataItem.label === item.exchange_name)
    );
    const updatedArrayList2 = filteredData.map((items) => {
      const matchedItem = applicationData?.existing_exchanges_listed.find(
        (item) => item.exchange_name.toLowerCase() === items.exchange_name.toLowerCase()
      );

      if (matchedItem) {
        return {
          ...items,
          hour24_trading_volume: matchedItem.hour24_trading_volume
        };
      }
      return items;
    });
    setSelectedOptions(selectValues);
    setFilterExchangeList([...updatedArrayList2]);
  };

  const handleSubmit = (values) => {
    if (filterExchangeList.length > 0) {
      const formData = filterExchangeList.map((item) => {
        const inputElement = document.getElementsByName(item.exchange_name)[0];
        const volume = inputElement.value;

        return {
          exchange_name: item.exchange_name,
          hour24_trading_volume: volume
        };
      });
      let stepsRequired = Math.floor(StepsCalculator(1, completeStep, 40));
      let data = {
        existing_exchanges_listed: JSON.stringify(formData),
        percentage_complete: stepsRequired
      };
      setIsLoading(true);
      UpdateExchangeApplication({ application_id: applicationId }, data)
        .then((res) => {
          const application = get(res, 'data.data.data', '');
          setApplicationData(application);
          if (isUpdateExchange) {
            toast.success('24h trading volume updated successfully', {
              style: { fontSize: '12px' }
            });
            GetApplicationDetailsEdit(applicationId);
          }
          nextPage();
          setIsLoading(false);
        })
        .catch((err) => {
          const errorMessage = get(err, 'data.error.message');
          toast.error(notificationMessageFunction(errorMessage), {
            style: { fontSize: '12px' }
          });
        });
      setExchangeCompleteStep(Array.from({ length: 9 }, (_, index) => index));
    }

    if (filterExchangeList.every((i) => i.hour24_trading_volume)) {
      if (filterExchangeList.length > 0) {
        const formData = filterExchangeList.map((item) => {
          const inputElement = document.getElementsByName(item.exchange_name)[0];
          const volume = inputElement.value;

          return {
            exchange_name: item.exchange_name,
            hour24_trading_volume: volume
          };
        });
        let stepsRequired = Math.floor(StepsCalculator(1, completeStep, 40));
        let data = {
          existing_exchanges_listed: JSON.stringify(formData),
          percentage_complete: stepsRequired
        };
        JSON.stringify(data);
        UpdateExchangeApplication({ application_id: applicationId }, data)
          .then((res) => {
            const application = get(res, 'data.data.data', '');
            setApplicationData(application);
            nextPage();
          })
          .catch((err) => {
            const errorMessage = get(err, 'data.error.message');
            toast.error(notificationMessageFunction(errorMessage), {
              style: { fontSize: '12px' }
            });
          });
      } else {
        nextPage();
      }
    }
  };

  const ValidateTable = ({ index, item }) => {
    const [localVolume, setLocalVolume] = useState(item.hour24_trading_volume || '');

    useEffect(() => {
      setLocalVolume(item.hour24_trading_volume);
    }, [item.hour24_trading_volume]);

    const handleBlur = () => {
      const updatedFilterExchangeList = filterExchangeList.map((exchange) => {
        if (exchange._id === item._id) {
          return { ...exchange, hour24_trading_volume: localVolume };
        }
        return exchange;
      });
      setFilterExchangeList(updatedFilterExchangeList);
    };

    return (
      <>
        <td>{index + 1}</td>
        <td className=''>{item.exchange_name}</td>
        <td>
          <div className='d-flex'>
            <label style={{ float: 'left' }} className='pt-3 pr-2'>
              $
            </label>

            <Input
              type='text'
              name={item.exchange_name}
              style={{ borderRadius: '8px', width: '100%' }}
              value={localVolume}
              placeholder='Enter 24 hours trading volume'
              className='font-size-12'
              onChange={(e) => {
                setLocalVolume(
                  Number(e.target.value.replace(/[^0-9]/g, '')).toLocaleString()
                );
              }}
              onBlur={handleBlur}
            />
          </div>
        </td>
      </>
    );
  };

  useEffect(() => {
    if (applicationData && applicationData.percentage_complete) {
      setCompleteStep(applicationData.percentage_complete);
    }
  }, []);

  const exchangeListApiFunction = () => {
    ExchangeListAPI()
      .then((res) => {
        const GetExchangeData = get(res, 'data.data.data', []);

        setAllExchangeList(GetExchangeData);
        const newData = res.data.data.data.map((item) => {
          return { label: item.exchange_name, value: item.exchange_name };
        });
        setExchangeList(newData);
        if (applicationData && applicationData.existing_exchanges_listed) {
          const selectedList = applicationData?.existing_exchanges_listed.map(
            (exchange) => ({
              label: exchange.exchange_name,
              value: exchange.exchange_name
            })
          );
          setSelectedOptions(selectedList);
          const filteredData = GetExchangeData.filter((item) =>
            selectedList.some((dataItem) => dataItem.label === item.exchange_name)
          );
          const updatedArrayList2 = filteredData.map((items) => {
            const matchedItem = applicationData.existing_exchanges_listed.find(
              (item) =>
                item.exchange_name.toLowerCase() === items.exchange_name.toLowerCase()
            );

            if (matchedItem) {
              return {
                ...items,
                hour24_trading_volume: matchedItem.hour24_trading_volume
              };
            }
            return items;
          });
          setFilterExchangeList(updatedArrayList2);
        }
      })
      .catch((err) => {
        const errorMessage = err && err.data && err.data.error && err.data.error.message;
        toast.error(notificationMessageFunction(errorMessage), {
          style: { fontSize: '12px' }
        });
      });
  };

  useEffect(() => {
    exchangeListApiFunction();
  }, [applicationData]);

  return (
    <React.Fragment>
      <div onClick={isSectionCollapsed ? toggleSection : undefined}>
        {isUpdateExchange ? (
          <>
            <div
              className='cursor-pointer col-12 font-size-14 ml-2 d-flex justify-content-between align-items-center'
              onClick={toggleSection}>
              <span
                className='text-black font-weight-400'
                style={{ color: primaryTextState }}>
                Currently where is it listed and 24-h trading volume
              </span>
              <i
                className={`font-size-22 mdi mdi-chevron-${
                  isSectionCollapsed === true ? 'up' : 'down'
                }`}
                style={{ color: primaryTextState }}
              />
            </div>
          </>
        ) : (
          <div className='col-12 font-size-16 pb-2 ml-2'>
            <span
              className='text-black font-weight-400'
              style={{ color: primaryTextState }}>
              Currently where is it listed and 24-h trading volume
            </span>
          </div>
        )}
        <Collapse isOpen={!isSectionCollapsed}>
          {isLoading ? (
            <div className='mt-5'>
              <Loader />
            </div>
          ) : (
            <Container className='col-12 col-sm-auto pt-3' style={{ minHeight: '500px' }}>
              <div>
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    existing_exchnage_listed: selectedOptions || []
                  }}
                  validationSchema={CapMarketSchema}
                  onSubmit={handleSubmit}>
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form>
                      <div>
                        <Col>
                          <FormGroup className='mb-4'>
                            <div className='font-size-12'>
                              <Label
                                htmlFor='existing_exchnage_listed'
                                className='text-black font-weight-normal'
                                style={{ color: primaryTextState }}>
                                {CONST.EXISTING_EXCHANGES_LISTED} ({CONST.OPTIONAL})
                              </Label>
                            </div>
                            <div>
                              <Select
                                className='select-input border-dark font-size-12'
                                isMulti
                                style={{
                                  paddingRight: '30px',
                                  borderRadius: '8px'
                                }}
                                styles={{
                                  control: (styles) => ({
                                    ...styles,
                                    backgroundColor: primaryColorState,
                                    borderRadius: '8px'
                                  }),
                                  singleValue: (styles) => ({
                                    ...styles,
                                    color: primaryTextState
                                  })
                                }}
                                options={exchangeList}
                                value={selectedOptions}
                                onChange={(val) => {
                                  changeHandlerExchange(val);
                                  setFieldValue('existing_exchnage_listed', val.value);
                                }}
                                name='existing_exchnage_listed'
                              />
                            </div>
                          </FormGroup>
                        </Col>

                        <Col lg={12} md={12} sm={12}>
                          {filterExchangeList.length > 0 ? (
                            <div className='table-responsive'>
                              <table className='table table-centered table-hover'>
                                <thead
                                  className='font-size-12 center-text'
                                  style={{ color: primaryTextState }}>
                                  <tr style={{ color: primaryTextState }}>
                                    <th>#</th>
                                    <th>Exchange Name</th>
                                    <th>24 hour trading volume</th>
                                  </tr>
                                </thead>
                                <tbody style={{ color: primaryTextState }}>
                                  {filterExchangeList.map((item, index) => (
                                    <tr
                                      key={index}
                                      style={{ color: primaryTextState }}
                                      className='center-text font-size-12 font-weight-medium cursor-pointer'>
                                      <ValidateTable item={item} index={index} />
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          ) : (
                            ''
                          )}
                        </Col>
                      </div>

                      <div className='d-flex justify-content-between align-items-center mt-4 mr-2 ml-2'>
                        <i
                          className='mdi mdi-chevron-double-left font-size-32 ml-1 cursor-pointer'
                          onClick={prevPage}
                          style={{ color: primaryTextState }}
                        />
                        <div>
                          {(applicationStatus === 'INCOMPLETE' || !applicationStatus) && (
                            <button
                              className='btn btn-status-change text-white mr-2'
                              type='submit'
                              style={{ width: '142px', height: '50px' }}
                              onClick={() =>
                                handleSubmitLiteApplication({
                                  applicationId,
                                  setApplicationData,
                                  history,
                                  paramsValue,
                                  setIsLoading
                                })
                              }>
                              Submit
                            </button>
                          )}

                          <button
                            className='btn application-button text-white'
                            type='submit'
                            onClick={(values) => handleSubmit(values)}>
                            Next
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </Container>
          )}
        </Collapse>
      </div>
    </React.Fragment>
  );
}

export default Step8;
