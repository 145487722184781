import React, { useState } from 'react';
import Drawer from 'react-modern-drawer';
import { Link } from 'react-router-dom';
import insurance from '../assets/images/insurance.png';
import terms from '../assets/images/terms-and-conditions.png';
import { constants as ROUTE_CONST } from '../constants/routes';
import listuslogo from '../assets/images/listus-logo-b.png';
import listuslogoW from '../assets/images/listus-logo-w.png';
import { useThemeContext } from './ThemeProvider';

function DrawerPage() {
  const { paramsValue, widgetTheme, containerColorState, primaryTextState } =
    useThemeContext();

  const [toggle, setToggle] = useState(false);

  const toggleMenu = () => {
    setToggle(!toggle);
  };

  return (
    <>
      <Link
        to={
          ROUTE_CONST.LANDING_PAGE +
          '?' +
          (paramsValue && paramsValue.widgetTheme
            ? `&widgetTheme=${paramsValue.widgetTheme}`
            : '') +
          (paramsValue && paramsValue.containerColor
            ? `&containerColor=${paramsValue.containerColor}`
            : '') +
          (paramsValue && paramsValue.primaryColor
            ? `&primaryColor=${paramsValue.primaryColor}`
            : '') +
          (paramsValue && paramsValue.primaryText
            ? `&primaryText=${paramsValue.primaryText}`
            : '') +
          (paramsValue && paramsValue.secondaryText
            ? `&secondaryText=${paramsValue.secondaryText}`
            : '')
        }>
        <i
          className='mdi mdi-home align-middle position-absolute font-size-22 cursor-pointer'
          style={{ top: '14px', left: '12px', color: primaryTextState }}
        />
      </Link>
      <Drawer
        open={toggle}
        onClose={toggleMenu}
        direction='right'
        zIndex={1002}
        size={'40vh'}
        style={{
          position: 'absolute',
          height: '100%',
          background: containerColorState
        }}>
        <div className='p-3 d-flex justify-content-center aling-items-center'>
          {widgetTheme === 'dark' ? (
            <img src={listuslogoW} alt='listus' height={80} />
          ) : (
            <img src={listuslogo} alt='listus' height={80} />
          )}
        </div>
        <hr className='mr-3 ml-3' />
        <div className='mr-3 ml-3 mt-2'>
          <p
            className='d-flex align-items-center cursor-pointer'
            onClick={() => window.open('https://www.rampnalysis.com/privacy-policy')}>
            <img src={insurance} alt='privacy-policy' height={22} />
            <span className='font-size-14 ml-2' style={{ color: primaryTextState }}>
              Privacy Policy
            </span>
            <i
              className='mdi mdi-chevron-right font-size-20'
              style={{ position: 'absolute', right: 5, color: primaryTextState }}
            />
          </p>
          <p
            className='d-flex align-items-center cursor-pointer'
            onClick={() => window.open('https://www.rampnalysis.com/terms-of-service')}>
            <img src={terms} alt='privacy-policy' height={22} />
            <span className='font-size-14 ml-2' style={{ color: primaryTextState }}>
              Terms of Service
            </span>
            <i
              className='mdi mdi-chevron-right font-size-20'
              style={{ position: 'absolute', right: 5, color: primaryTextState }}
            />
          </p>
        </div>
      </Drawer>
      <i
        className='ri-menu-2-line align-middle font-size-22 position-absolute cursor-pointer'
        style={{ top: '12px', right: '12px', color: primaryTextState }}
        onClick={toggleMenu}></i>
    </>
  );
}

export default DrawerPage;
