import React, { useCallback, useEffect, useState } from 'react';
import { StreamChat } from 'stream-chat';
import {
  Chat,
  Channel,
  ChannelHeader,
  ChannelList,
  MessageList,
  MessageInput,
  Window
} from 'stream-chat-react';
import 'stream-chat-react/dist/css/index.css';
import './streamChat.css';
import { StringParam, useQueryParam } from 'use-query-params';

import { Row } from 'reactstrap';
import {
  GetChannelsDetailsAPI,
  GetStreamTokenAPI,
  GetStreamUserDataAPI
} from '../../../services/Auth';

// const client = new StreamChat(process.env.REACT_APP_STREAM_CHAT_API);

const StreamChatElem = ({ applicationAll, page }) => {
  const client = new StreamChat(process.env.REACT_APP_STREAM_CHAT_API);

  const [applicationId] = useQueryParam('application_id', StringParam);
  const [userChannels, setUserChannels] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [userToken, setUserToken] = useState('');
  const [showNotificationBanner, setShowNotificationBanner] = useState(false);

  const fetchUserData = useCallback(async () => {
    try {
      const userDataResponse = await GetStreamUserDataAPI(applicationId);
      if (userDataResponse?.data?.data?.data?.[0]) {
        const channels = await GetChannelsDetailsAPI(
          `${applicationId}-${applicationAll.business_user_id}`
        );
        setUserChannels(channels.data.data.data);
        const tokenResponse = await GetStreamTokenAPI(applicationId);
        const userData = userDataResponse.data.data.data[0];
        const token = tokenResponse.data.data.data;
        setUserToken(token);
        setCurrentUser({ ...userData });
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  }, [applicationAll.business_user_id, applicationId]);

  useEffect(() => {
    fetchUserData();
  }, [applicationId, applicationAll, page, fetchUserData]);

  const handleNewMessage = (event) => {
    if (Notification.permission === 'granted') {
      new Notification(event.user.name, {
        body: event.message.text
      });
    }
  };

  useEffect(() => {
    if (window.Notification && Notification.permission === 'default') {
      setShowNotificationBanner(true);
    }

    client.on('connection.changed', ({ online = false }) => {
      if (!online) console.log('connection lost');
    });

    client
      .connectUser(
        {
          id: `${applicationId}-${applicationAll.business_user_id}`,
          name: currentUser?.name
        },
        userToken
      )
      .then(() => {
        console.log('Connected to Stream Chat');
        client.on('message.new', handleNewMessage);
      })
      .catch((error) => {
        console.error('Error connecting to Stream Chat:', error);
      });
  }, [
    applicationAll,
    applicationAll.business_user_id,
    applicationId,
    userToken,
    currentUser,
    client
  ]);

  const grantPermission = () => {
    Notification.requestPermission().then((result) => {
      if (result === 'granted') {
        new Notification('Notifications are now enabled!');
      }
    });

    setShowNotificationBanner(false);
  };

  return (
    <div>
      {userChannels &&
      currentUser &&
      Object.keys(currentUser).length > 0 &&
      applicationAll &&
      applicationAll.business_user_id &&
      applicationId ? (
        <Chat client={client} theme='messaging light' initialNavOpen={false}>
          {showNotificationBanner && (
            <div className='notification-banner'>
              <p>
                We needs your permission to enable desktop notifications
                <button onClick={grantPermission} className='btn'>
                  Click here
                </button>
              </p>
            </div>
          )}
          <ChannelList
            showChannelSearch={true}
            filters={{
              type: 'messaging',
              members: {
                $in: [`${applicationId}-${applicationAll.business_user_id}`]
              }
            }}
          />
          <Channel>
            <Window>
              <ChannelHeader />
              <MessageList />
              <MessageInput />
            </Window>
          </Channel>
        </Chat>
      ) : (
        <Row className='d-flex align-items-center justify-content-center'>
          <span className='py-4 font-size-22 text-center'>No chat initiated yet</span>
        </Row>
      )}
    </div>
  );
};

export default StreamChatElem;
