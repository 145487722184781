import React, { Component } from 'react';
import { Switch, BrowserRouter as Router } from 'react-router-dom';
import { connect } from 'react-redux';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';
import { parse, stringify } from 'query-string';
import { Helmet } from 'react-helmet';

// Import Routes
import { authProtectedRoutes, publicRoutes } from './routes/';
import AppRoute from './routes/route';

import lottie from 'lottie-web';
import { defineElement } from 'lord-icon-element';

// layouts
import HorizontalLayout from './components/VerticalLayout/';

// Import scss
import './theme.scss';
import { ThemeProvider } from './pages/ThemeProvider';

function createParamsData(search) {
  const searchParams = new URLSearchParams(search);
  const paramsData = {};
  for (const [key, value] of searchParams) {
    paramsData[key] = value;
  }
  return paramsData;
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      widgetTheme: '',
      paramsValueData: {}
    };
    this.getLayout = this.getLayout.bind(this);
    defineElement(lottie.loadAnimation);
  }

  /**
   * Returns the layout
   */
  getLayout = () => {
    return HorizontalLayout;
  };

  componentDidMount() {
    const params = window.location.search;
    const paramsValue = createParamsData(params);
    this.setState({
      widgetTheme:
        paramsValue['widgetTheme'] === 'dark'
          ? 'dark'
          : paramsValue['widgetTheme'] === 'light'
          ? 'light'
          : paramsValue['widgetTheme'] === 'bluey'
          ? 'bluey'
          : '',
      paramsValueData: paramsValue
    });

    this.updateStyles(paramsValue['widgetTheme']);
  }

  updateStyles(widgetTheme) {
    document.body.style.setProperty(
      'background-color',
      widgetTheme === 'dark'
        ? '#272727'
        : widgetTheme === 'light'
        ? '#f6f7f9'
        : widgetTheme === 'bluey'
        ? '#f6f7f9'
        : '#f6f7f9',
      'important'
    );
  }
  getSubdomain(hostname) {
    const parts = hostname.split('.');
    if (parts.length > 2) {
      return parts[0];
    }
    return '';
  }

  render() {
    const Layout = this.getLayout();
    const subdomain = this.getSubdomain(window.location.hostname);
    const titlePrefix =
      subdomain === 'app'
        ? ''
        : subdomain && subdomain.length > 0
        ? subdomain + ' X '
        : '';

    return (
      <React.Fragment>
        <Helmet>
          <title>{`${titlePrefix}Listus Powered by Rampnalysis`}</title>
          <meta
            property='og:title'
            content={`${titlePrefix}Listus Powered by Rampnalysis`}
          />
        </Helmet>
        <ThemeProvider>
          <Router>
            <QueryParamProvider
              adapter={ReactRouter5Adapter}
              options={{
                searchStringToObject: parse,
                objectToSearchString: stringify
              }}>
              <Switch>
                {publicRoutes.map((route, idx) => (
                  <AppRoute
                    path={route.path}
                    layout={Layout}
                    component={route.component}
                    key={idx}
                    isAuthProtected={false}
                  />
                ))}
                {authProtectedRoutes.map((route, idx) => (
                  <AppRoute
                    path={route.path}
                    layout={Layout}
                    component={route.component}
                    key={idx}
                    isAuthProtected={true}
                  />
                ))}
              </Switch>
            </QueryParamProvider>
          </Router>
        </ThemeProvider>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    layout: state.Layout
  };
};

export default connect(mapStateToProps, null)(App);
