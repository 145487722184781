import React, { useEffect, useState } from 'react';
import { useQueryParam, StringParam } from 'use-query-params';
import TimeAgo from 'react-timeago';
import {
  DeleteNotifications,
  GetApplicationNotifications
} from '../../../services/Application';
import toast from 'react-hot-toast';
import { notificationMessageFunction } from '../../../constants/notificationConst';
import imageLogo from '../../../assets/images/users/avatar-5.jpg';
import Loader from '../../../constants/Loader';

function Notification({ primaryTextState, primaryColorState }) {
  const [notifications, setNotifications] = useState({});
  const [loading, setLoading] = useState(false);
  const [applicationId] = useQueryParam('application_id', StringParam);

  useEffect(() => {
    setLoading(true);
    getAllNotificationList();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getAllNotificationList = () => {
    GetApplicationNotifications(applicationId)
      .then((res) => {
        setLoading(false);
        const data = res && res.data && res.data.data && res.data.data.data;
        setNotifications(data.response);
        return data;
      })
      .catch((err) => {
        setLoading(false);
        const errorMessage = err && err.data && err.data.error && err.data.error.message;
        toast.error(notificationMessageFunction(errorMessage), {
          style: { fontSize: '12px' }
        });
      });
  };
  const handleClose = (id) => {
    setLoading(true);
    DeleteNotifications(id)
      .then((res) => {
        setLoading(false);
        getAllNotificationList();
      })
      .catch((err) => {
        const errorMessage = err && err.data && err.data.error && err.data.error.message;
        toast.error(notificationMessageFunction(errorMessage), {
          style: { fontSize: '12px' }
        });
      });
  };

  return (
    <React.Fragment>
      <section className='section-20'>
        <div className='container mt-2'>
          <h3
            className='m-b-20 heading-line font-size-14'
            style={{ color: primaryTextState }}>
            Notifications{' '}
            <i className='fa fa-bell text-muted' style={{ color: primaryTextState }}></i>
          </h3>
          {loading ? (
            <Loader />
          ) : (
            <div className='notification-ui_dd-content'>
              {notifications &&
                notifications.length > 0 &&
                notifications.map((notification, key) => (
                  <div
                    key={key}
                    className='notification-list notification-list--unread'
                    style={{ background: primaryColorState }}>
                    <div className='notification-list_content'>
                      <div className='notification-list_img'>
                        {notification.logo_url ? (
                          <img src={notification.logo_url} alt='user' />
                        ) : (
                          <img src={imageLogo} alt='user' />
                        )}
                      </div>
                      <div className='notification-list_detail font-size-12'>
                        <p>
                          <b style={{ color: primaryTextState }}>{notification.title}</b>
                        </p>
                        {notification.field && notification.field.length > 0 && (
                          <p
                            className='text-muted font-size-10'
                            style={{ color: primaryTextState }}>
                            Edit required in{' '}
                            {Object.keys(notification.field).map((value, index) => (
                              <div key={index} className='text-black'>
                                {value}
                              </div>
                            ))}
                          </p>
                        )}
                        {notification?.notes && notification.notes.length > 0 && (
                          <p className='font-size-10' style={{ color: primaryTextState }}>
                            Notes from Compliance: {notification.notes}
                          </p>
                        )}
                        <p style={{ color: primaryTextState }}>
                          <small>
                            <TimeAgo
                              date={notification.created_at}
                              formatter={(value, unit, suffix) => {
                                if (value < 1 && unit === 'second') {
                                  return 'just now';
                                } else {
                                  return (
                                    value +
                                    ' ' +
                                    unit +
                                    (value > 1 ? 's' : '') +
                                    ' ' +
                                    suffix
                                  );
                                }
                              }}
                            />
                          </small>
                        </p>
                      </div>
                    </div>
                    <div className='notification-list_feature-img'>
                      <button
                        className='close'
                        style={{ color: primaryTextState }}
                        aria-label='Close'
                        onClick={() => handleClose(notification._id)}>
                        <span aria-hidden='true'>&times;</span>
                      </button>
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
      </section>
    </React.Fragment>
  );
}

export default Notification;
