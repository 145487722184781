import { notificationMessageFunction } from '../../../constants/notificationConst';
import { constants as ROUTE_CONST } from '../../../constants/routes';
import { UpdateExchangeApplication } from '../../../services/Application';
import get from 'lodash/get';
import toast from 'react-hot-toast';

export const handleSubmitLiteApplication = ({
  applicationId,
  setApplicationData,
  history,
  paramsValue,
  setIsLoading
}) => {
  let data = { application_status: 'LITE_COMPLETE' };

  UpdateExchangeApplication({ application_id: applicationId }, data)
    .then((res) => {
      const application = get(res, 'data.data.data', '');
      setApplicationData(application);
      history.push(
        ROUTE_CONST.VIEW_APPLICATIONS +
          '?' +
          (paramsValue && paramsValue.widgetTheme
            ? `&widgetTheme=${paramsValue.widgetTheme}`
            : '') +
          (paramsValue && paramsValue.containerColor
            ? `&containerColor=${paramsValue.containerColor}`
            : '') +
          (paramsValue && paramsValue.primaryColor
            ? `&primaryColor=${paramsValue.primaryColor}`
            : '') +
          (paramsValue && paramsValue.primaryText
            ? `&primaryText=${paramsValue.primaryText}`
            : '') +
          (paramsValue && paramsValue.secondaryText
            ? `&secondaryText=${paramsValue.secondaryText}`
            : '')
      );
      setIsLoading(false);
    })
    .catch((err) => {
      const errorMessage = get(err, 'data.error.message');
      toast.error(notificationMessageFunction(errorMessage), {
        style: { fontSize: '12px' }
      });
    });
};
