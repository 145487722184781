import api from '../api/userApi';
import * as url from '../api/userURL';

export const BusinessWidgetLoginAPI = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .post(url.LOGIN_URL, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const BusinessWidgetVerificationAPI = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .post(url.VERIFY_EMAIL, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetMyProfileAPI = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.MY_PROFILE)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const ExchangeListAPI = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .get(url.EXCHANGE_LIST)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const UpdateMyProfileAPI = (paydata) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putWithToken(url.UPDATE_MY_PROFILE, paydata)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const ReferralCodeDetailsAPI = (domain) => {
  return new Promise(async (resolve, reject) => {
    return api
      .get(url.REFERRAL_CODE + `?domain=${domain}`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetStreamUserDataAPI = (applicationId) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.GET_BUSINESS_STREAM_USER + `?application_id=${applicationId}`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetStreamTokenAPI = (applicationId) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.GET_BUSINESS_STREAM_TOKEN + `?application_id=${applicationId}`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetChannelsDetailsAPI = (userId) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.QUERY_CHANNEL + `?userId=${userId}`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
