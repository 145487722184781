export const DevURL = `${process.env.REACT_APP_BACKEND_RNALYS_USER_HOST}/api/`;
// export const DevURL = 'http://localhost:3004/api/';

export const ADMIN_URL = 'admin/';
export const BUSINESS_URL = 'business/';
export const USER_URL = 'user/';

// ? Authentication URL
export const LOGIN_URL = USER_URL + 'listus_login';
export const VERIFY_EMAIL = USER_URL + 'verify';
export const MY_PROFILE = USER_URL + 'me';
export const UPDATE_MY_PROFILE = USER_URL + 'widget_profile_update';
export const REFERRAL_CODE = USER_URL + 'referral_code';

export const QUERY_CHANNEL = USER_URL + 'query_channel';
export const GET_BUSINESS_STREAM_TOKEN = BUSINESS_URL + 'stream/get_token';
export const GET_BUSINESS_STREAM_USER = BUSINESS_URL + 'stream/get_user_data';

// ? Exchange
export const EXCHANGE_LIST = ADMIN_URL + 'users/exchange_info';
export const INVESTORS_NAME = ADMIN_URL + 'users/investor_tier';

export const URL = DevURL;
