import React, { useEffect, useRef, useState } from 'react';
import { Col, Collapse, Container, FormGroup, Label } from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import get from 'lodash/get';
import { constants as CONST } from '../../../constants/application';
import documentUploadedIcon from '../../../assets/images/documentuploaded.png';
import documentUploadIcon from '../../../assets/images/documentupload.png';
import {
  GetApplication,
  UpdateApplication,
  UploadApplicationDocs
} from '../../../services/Application';
import { notificationMessageFunction } from '../../../constants/notificationConst';
import AuthInput from '../../../Custom/AuthInput';

function Step3({
  setOnrampCompleteStep,
  nextPage,
  applicationId,
  prevPage,
  isUpdateOnramp = false,
  isSectionCollapsed,
  toggleSection,
  setApplicationData,
  applicationData,
  primaryTextState
}) {
  const [initialValue, setInitialValue] = useState({
    product_custodial: '',
    user_access_to_wallet: '',
    pre_populate_wallet_address: '',
    crypto_to_other_party: '',
    describe_ramp_services: '',
    whitepapers_doc: '',
    integration_flows_doc: '',
    flow_of_funds_diagram_doc: ''
  });

  useEffect(() => {
    if (applicationId !== '' && !isUpdateOnramp) {
      GetApplication({ application_id: applicationId }).then((res) => {
        const application = get(res, 'data.data.data[0]', '');
        setApplicationData(application);
        const {
          product_custodial,
          pre_populate_wallet_address,
          crypto_to_other_party,
          user_access_to_wallet,
          describe_ramp_services,
          whitepapers_doc,
          integration_flows_doc,
          flow_of_funds_diagram_doc
        } = application;

        setInitialValue({
          product_custodial: product_custodial ? product_custodial?.toString() : 'false',
          user_access_to_wallet:
            user_access_to_wallet !== null && user_access_to_wallet
              ? user_access_to_wallet.toString()
              : 'null',
          pre_populate_wallet_address:
            pre_populate_wallet_address !== null && pre_populate_wallet_address
              ? pre_populate_wallet_address.toString()
              : 'null',
          crypto_to_other_party:
            crypto_to_other_party !== null && crypto_to_other_party
              ? crypto_to_other_party.toString()
              : 'null',
          describe_ramp_services: describe_ramp_services,
          whitepapers_doc: whitepapers_doc,
          integration_flows_doc: integration_flows_doc,
          flow_of_funds_diagram_doc: flow_of_funds_diagram_doc
        });
      });
    } else {
      if (applicationData) {
        const {
          product_custodial,
          pre_populate_wallet_address,
          crypto_to_other_party,
          user_access_to_wallet,
          describe_ramp_services,
          whitepapers_doc,
          integration_flows_doc,
          flow_of_funds_diagram_doc
        } = applicationData;

        setInitialValue({
          product_custodial: product_custodial ? product_custodial?.toString() : 'false',
          user_access_to_wallet:
            user_access_to_wallet !== null && user_access_to_wallet
              ? user_access_to_wallet.toString()
              : 'null',
          pre_populate_wallet_address:
            pre_populate_wallet_address !== null && pre_populate_wallet_address
              ? pre_populate_wallet_address.toString()
              : 'null',
          crypto_to_other_party:
            crypto_to_other_party !== null && crypto_to_other_party
              ? crypto_to_other_party.toString()
              : 'null',
          describe_ramp_services: describe_ramp_services,
          whitepapers_doc: whitepapers_doc,
          integration_flows_doc: integration_flows_doc,
          flow_of_funds_diagram_doc: flow_of_funds_diagram_doc
        });
      }
    }
  }, [applicationData, applicationId, isUpdateOnramp]);

  const handleSubmit = (values, { setFieldError }) => {
    const data = {
      product_custodial: values.product_custodial,
      user_access_to_wallet:
        values.user_access_to_wallet !== 'null' ? values.user_access_to_wallet : null,
      pre_populate_wallet_address:
        values.pre_populate_wallet_address !== 'null'
          ? values.pre_populate_wallet_address
          : null,
      crypto_to_other_party:
        values.crypto_to_other_party !== 'null' ? values.crypto_to_other_party : null,
      describe_ramp_services:
        values.describe_ramp_services !== 'null' ? values.describe_ramp_services : null
    };

    const payloadData = new FormData();
    if (
      values.whitepapers_doc &&
      typeof values.whitepapers_doc !== 'string' &&
      values.whitepapers_doc.size / 1024 > 50000
    ) {
      setFieldError('whitepapers_doc', 'File size should be less than 50 MB');
      return;
    }
    if (
      values.integration_flows_doc &&
      typeof values.integration_flows_doc !== 'string' &&
      values.integration_flows_doc.size / 1024 > 50000
    ) {
      setFieldError('integration_flows_doc', 'File size should be less than 50 MB');
      return;
    }
    if (
      values.flow_of_funds_diagram_doc &&
      typeof values.flow_of_funds_diagram_doc !== 'string' &&
      values.flow_of_funds_diagram_doc.size / 1024 > 50000
    ) {
      setFieldError('flow_of_funds_diagram_doc', 'File size should be less than 50 MB');
      return;
    }
    payloadData.append('whitepapers_doc', values.whitepapers_doc);
    payloadData.append('integration_flows_doc', values.integration_flows_doc);
    payloadData.append('flow_of_funds_diagram_doc', values.flow_of_funds_diagram_doc);
    UploadApplicationDocs({ application_id: applicationId }, payloadData)
      .then((res) => {})
      .catch((err) => {
        const errorMessage = get(err, 'data.error.message');
        toast.error(notificationMessageFunction(errorMessage), {
          style: { fontSize: '12px' }
        });
      });
    UpdateApplication(applicationId, data)
      .then((res) => {})
      .catch((err) => {
        const errorMessage = get(err, 'data.error.message');
        toast.error(notificationMessageFunction(errorMessage), {
          style: { fontSize: '12px' }
        });
      });
    setOnrampCompleteStep(Array.from({ length: 3 }, (_, index) => index));
    nextPage();
  };

  const integrationDetailSchema = Yup.object().shape({
    product_custodial: Yup.string().required('Select one option'),
    user_access_to_wallet: Yup.string().required('Select one option'),
    pre_populate_wallet_address: Yup.string().required('Select one option'),
    crypto_to_other_party: Yup.string().required('Select one option'),
    describe_ramp_services: Yup.string().required('Field is required')
  });

  return (
    <React.Fragment>
      <div onClick={isSectionCollapsed ? toggleSection : undefined}>
        {isUpdateOnramp ? (
          <>
            <div
              className='cursor-pointer col-12 font-size-14 ml-2 d-flex justify-content-between align-items-center'
              onClick={toggleSection}>
              <span
                className='text-black font-weight-400'
                style={{ color: primaryTextState }}>
                Wallet and Fund Flow Details
              </span>
              <i
                className={`font-size-22 mdi mdi-chevron-${
                  isSectionCollapsed === true ? 'up' : 'down'
                }`}
                style={{ color: primaryTextState }}
              />
            </div>
          </>
        ) : (
          <div className='col-12 font-size-16 pb-2 ml-2'>
            <span
              className='text-black font-weight-400'
              style={{ color: primaryTextState }}>
              Wallet and Fund Flow Details
            </span>
          </div>
        )}
        <Collapse isOpen={!isSectionCollapsed}>
          <Container className='pt-4 mt-2 pt-md-1'>
            <div>
              <Formik
                initialValues={initialValue}
                enableReinitialize={true}
                validationSchema={integrationDetailSchema}
                onSubmit={handleSubmit}>
                {({
                  errors,
                  touched,
                  values,
                  handleChange,
                  setFieldTouched,
                  setFieldValue
                }) => (
                  <Form>
                    <Col>
                      <FormGroup className='mb-3'>
                        <div className='font-size-12'>
                          <Label
                            htmlFor='product_custodial'
                            className='text-black font-weight-normal'
                            style={{ color: primaryTextState }}>
                            {CONST.PRODUCT_CUSTODIAL_OR_NOT}
                          </Label>
                        </div>
                        <div
                          className='d-flex direction-row'
                          role='group'
                          aria-labelledby='my-radio-group'>
                          <div className='d-flex align-items-center mr-4'>
                            <Label
                              className='mt-2 font-size-12 d-flex align-items-center'
                              style={{ color: primaryTextState }}>
                              <Field
                                name='product_custodial'
                                type='radio'
                                value='true'
                                style={{
                                  height: '1.725rem',
                                  width: '1rem',
                                  marginRight: '5px'
                                }}
                              />
                              {CONST.CUSTODIAL}
                            </Label>
                          </div>
                          <div className='d-flex align-items-center ml-4'>
                            <Label
                              className='mt-2 font-size-12 ml-2 d-flex align-items-center'
                              style={{ color: primaryTextState }}>
                              <Field
                                name='product_custodial'
                                type='radio'
                                value='false'
                                style={{
                                  height: '1.725rem',
                                  width: '1rem',
                                  marginRight: '5px'
                                }}
                              />
                              {CONST.NON_CUSTODIAL}
                            </Label>
                          </div>
                        </div>
                        {errors['product_custodial'] && touched['product_custodial'] && (
                          <span
                            style={{ fontSize: 12 }}
                            className='text-left text-danger'>
                            {errors['product_custodial']}
                          </span>
                        )}
                      </FormGroup>
                    </Col>

                    <Col>
                      <FormGroup className='mb-3'>
                        <div className='font-size-12'>
                          <Label
                            htmlFor='user_access_to_wallet'
                            className='text-black font-weight-normal'
                            style={{ color: primaryTextState }}>
                            {CONST.ACCESS_CONTROL_FUNDS}
                          </Label>
                        </div>
                        <div
                          className='d-flex direction-row'
                          role='group'
                          aria-labelledby='my-radio-group'>
                          <div className='d-flex align-items-center mr-4'>
                            <Label
                              className='mt-2 font-size-12 d-flex align-items-center'
                              style={{ color: primaryTextState }}>
                              <Field
                                name='user_access_to_wallet'
                                type='radio'
                                value='true'
                                style={{
                                  height: '1.725rem',
                                  width: '1rem',
                                  marginRight: '5px'
                                }}
                              />
                              {CONST.YES}
                            </Label>
                          </div>
                          <div className='d-flex align-items-center ml-4 mr-4'>
                            <Label
                              className='ml-2  font-size-12   mt-2 d-flex align-items-center'
                              style={{ color: primaryTextState }}>
                              <Field
                                name='user_access_to_wallet'
                                type='radio'
                                value='false'
                                style={{
                                  height: '1.725rem',
                                  width: '1rem',
                                  marginRight: '5px'
                                }}
                              />
                              {CONST.NO}
                            </Label>
                          </div>
                          <div className='d-flex align-items-center  ml-4 mr-4'>
                            <Label
                              className='ml-2  font-size-12   mt-2 d-flex align-items-center'
                              style={{ color: primaryTextState }}>
                              <Field
                                name='user_access_to_wallet'
                                type='radio'
                                value='null'
                                style={{
                                  height: '1.725rem',
                                  width: '1rem',
                                  marginRight: '5px'
                                }}
                              />
                              {CONST.NA}
                            </Label>
                          </div>
                        </div>
                        {errors['user_access_to_wallet'] &&
                          touched['user_access_to_wallet'] && (
                            <span
                              style={{ fontSize: 12 }}
                              className='text-left text-danger'>
                              {errors['user_access_to_wallet']}
                            </span>
                          )}
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup className='mb-3'>
                        <div className='font-size-12'>
                          <Label
                            htmlFor='pre_populate_wallet_address'
                            className='text-black font-weight-normal'
                            style={{ color: primaryTextState }}>
                            Are you planning to auto-fill wallet addresses during
                            integration?
                          </Label>
                        </div>
                        <div className='d-flex direction-row'>
                          <div className='d-flex align-items-center  mr-4'>
                            <Label
                              className='mt-2 font-size-12 d-flex align-items-center'
                              style={{ color: primaryTextState }}>
                              <Field
                                name='pre_populate_wallet_address'
                                type='radio'
                                value='true'
                                style={{
                                  height: '1.725rem',
                                  width: '1rem',
                                  marginRight: '5px'
                                }}
                              />
                              {CONST.YES}
                            </Label>
                          </div>
                          <div className='d-flex align-items-center ml-4 mr-4'>
                            <Label
                              className='ml-2  font-size-12 mt-2 d-flex align-items-center'
                              style={{ color: primaryTextState }}>
                              <Field
                                name='pre_populate_wallet_address'
                                type='radio'
                                value='false'
                                style={{
                                  height: '1.725rem',
                                  width: '1rem',
                                  marginRight: '5px'
                                }}
                              />
                              {CONST.NO}
                            </Label>
                          </div>
                          <div className='d-flex align-items-center  ml-4 mr-4'>
                            <Label
                              className='ml-2 font-size-12 mt-2 d-flex align-items-center'
                              style={{ color: primaryTextState }}>
                              <Field
                                name='pre_populate_wallet_address'
                                type='radio'
                                value='null'
                                style={{
                                  height: '1.725rem',
                                  width: '1rem',
                                  marginRight: '5px'
                                }}
                              />
                              {CONST.NA}
                            </Label>
                          </div>
                        </div>
                        {errors['pre_populate_wallet_address'] &&
                          touched['pre_populate_wallet_address'] && (
                            <span
                              style={{ fontSize: 12 }}
                              className='text-left text-danger'>
                              {errors['pre_populate_wallet_address']}
                            </span>
                          )}
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup className='mb-3'>
                        <div className='font-size-12'>
                          <Label
                            htmlFor='crypto_to_other_party'
                            className='text-black font-weight-normal'
                            style={{ color: primaryTextState }}>
                            Will the cryptocurrency be directed somewhere other than the
                            user's designated wallet?
                          </Label>
                        </div>
                        <div className='d-flex direction-row'>
                          <div className='d-flex align-items-center  mr-4'>
                            <Label
                              className='mt-2 font-size-12 d-flex align-items-center'
                              style={{ color: primaryTextState }}>
                              <Field
                                name='crypto_to_other_party'
                                type='radio'
                                value='true'
                                style={{
                                  height: '1.725rem',
                                  width: '1rem',
                                  marginRight: '5px'
                                }}
                              />
                              {CONST.YES}
                            </Label>
                          </div>
                          <div className='d-flex align-items-center ml-4 mr-4'>
                            <Label
                              className='ml mt-2 font-size-12 d-flex align-items-center'
                              style={{ color: primaryTextState }}>
                              <Field
                                name='crypto_to_other_party'
                                type='radio'
                                value='false'
                                style={{
                                  height: '1.725rem',
                                  width: '1rem',
                                  marginRight: '5px'
                                }}
                              />
                              {CONST.NO}
                            </Label>
                          </div>
                          <div className='d-flex align-items-center  ml-4 mr-4'>
                            <Label
                              className='ml-2  font-size-12 mt-2  d-flex align-items-center'
                              style={{ color: primaryTextState }}>
                              <Field
                                name='crypto_to_other_party'
                                type='radio'
                                value='null'
                                style={{
                                  height: '1.725rem',
                                  width: '1rem',
                                  marginRight: '5px'
                                }}
                              />
                              {CONST.NA}
                            </Label>
                          </div>
                        </div>

                        {errors['crypto_to_other_party'] &&
                          touched['crypto_to_other_party'] && (
                            <span
                              style={{ fontSize: 12 }}
                              className='text-left text-danger'>
                              {errors['crypto_to_other_party']}
                            </span>
                          )}
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup className='mb-3'>
                        <div className='font-size-12'>
                          <Label
                            htmlFor='describe_ramp_services'
                            className='text-black font-weight-normal'
                            style={{ color: primaryTextState }}>
                            Describe how you will be integrating an onramp
                          </Label>
                        </div>
                        <div>
                          <AuthInput
                            setFieldTouched={setFieldTouched}
                            withTouchError={true}
                            type='textarea'
                            name='describe_ramp_services'
                            rows='6'
                            className={'border-grey font-size-12'}
                            placeholder='Write a description here'
                            values={values}
                            handleChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup className='mb-3'>
                        <div className='font-size-12'>
                          <Label
                            htmlFor='description'
                            className='text-black font-weight-normal'
                            style={{ color: primaryTextState }}>
                            Optional
                          </Label>
                        </div>
                        <CustomFileInput
                          labelName=''
                          fieldName='whitepapers_doc'
                          errors={errors}
                          headerName={'Whitepapers'}
                          values={values}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          primaryTextState={primaryTextState}
                        />
                        <CustomFileInput
                          labelName=''
                          fieldName='integration_flows_doc'
                          errors={errors}
                          headerName={'Integration Flows'}
                          values={values}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          primaryTextState={primaryTextState}
                        />
                        <CustomFileInput
                          labelName=''
                          fieldName='flow_of_funds_diagram_doc'
                          errors={errors}
                          headerName={'Flow of Funds Diagram'}
                          values={values}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          primaryTextState={primaryTextState}
                        />
                      </FormGroup>
                    </Col>

                    <div className='d-flex justify-content-between align-items-center mt-5 mr-2 ml-2'>
                      <i
                        className='mdi mdi-chevron-double-left font-size-32 ml-1 cursor-pointer'
                        onClick={prevPage}
                        style={{ color: primaryTextState }}
                      />
                      <button
                        className='btn application-button text-white'
                        type='submit'
                        onSubmit={(values) => handleSubmit(values)}>
                        Next
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Container>
        </Collapse>
      </div>
    </React.Fragment>
  );
}

export default Step3;

const CustomFileInput = ({
  fieldName,
  headerName,
  values,
  setFieldValue,
  primaryTextState
}) => {
  const fileInputRef = useRef(null);

  const handleFileButtonClick = () => {
    fileInputRef.current.click();
  };
  return (
    <FormGroup className='mb-3'>
      <div className='file-upload d-flex direction-row justify-content-between align-items-center pl-1'>
        <Label
          htmlFor={values[fieldName]}
          className='mt-2'
          onClick={handleFileButtonClick}>
          <div className='cursor-pointer'>
            <span className='rounded-circle' style={{ color: '#134a87', fontSize: 12 }}>
              {values[fieldName] ? (
                <img src={documentUploadedIcon} alt={values[fieldName]} />
              ) : (
                <img src={documentUploadIcon} alt={values[fieldName]} />
              )}
            </span>
            <span className='font-size-12 ml-2' style={{ color: primaryTextState }}>
              {headerName}
            </span>
          </div>
        </Label>
        <div>
          {values[fieldName] !== null && typeof values[fieldName] === 'object' ? (
            <a
              href={URL.createObjectURL(values[fieldName])}
              target='_blank'
              rel='noopener noreferrer'
              className='pr-2 font-size-12 font-weight-bold text-wrap text-primary'>
              View File
            </a>
          ) : values[fieldName] !== null &&
            typeof values[fieldName] === 'string' &&
            values[fieldName].length > 0 ? (
            <a
              href={values[fieldName]}
              target='_blank'
              rel='noopener noreferrer'
              className='pr-2 font-size-12 font-weight-bold text-wrap text-primary'>
              View File
            </a>
          ) : (
            ''
          )}
        </div>
        <input
          type='file'
          name={fieldName}
          style={{ display: 'none' }}
          ref={fileInputRef}
          onChange={(event) => {
            setFieldValue(fieldName, event.currentTarget.files[0]);
          }}
        />

        <ReactTooltip
          anchorId={fieldName}
          place='bottom'
          content={values[fieldName] && values[fieldName].name}
        />
      </div>
    </FormGroup>
  );
};
