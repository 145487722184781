import React, { useEffect, useRef, useState } from 'react';
import { ErrorMessage, Form, Formik } from 'formik';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import * as Yup from 'yup';
import get from 'lodash/get';
import {
  EditUser,
  GetApplication,
  UpdateApplication
} from '../../../services/Application';
import { Col, Collapse, Container, FormGroup, Label } from 'reactstrap';
import GetUserList from './GetUserList';
import Loader from '../../../constants/Loader';
import { constants as CONST } from '../../../constants/application';
import documentUploadIcon from '../../../assets/images/documentupload.png';
import documentUploadedIcon from '../../../assets/images/documentuploaded.png';
import moment from 'moment';
import toast from 'react-hot-toast';
import { notificationMessageFunction } from '../../../constants/notificationConst';

function Step2({
  setOnrampCompleteStep,
  nextPage,
  applicationId,
  prevPage,
  setUserModal,
  userModal,
  CustomInput,
  isUpdateOnramp = false,
  isSectionCollapsed,
  toggleSection,
  applicationData,
  setApplicationData,
  primaryTextState
}) {
  const [userData, setUserData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [user, setUser] = useState();
  const [initialValue, setInitialValue] = useState({
    full_name: '',
    date_of_birth: '',
    country: '',
    ownership_percent: '',
    role: '',
    user_id_doc: null
  });

  const beneficiaryUserSchema = Yup.object().shape({
    full_name: Yup.string()
      .min(3, 'Full Name must be at least 3 characters')
      .max(50, 'Full Name must be at most 50 characters')
      .matches(/^[^\s]+(\s+[^\s]+)*$/, 'Leading or trailing spaces are not allowed')
      .required('Full Name is required'),
    date_of_birth: Yup.date()
      .required('Date of Birth is required')
      .typeError('Invalid Date')
      .test('DOB', 'Age must be more than 18', (value) => {
        return moment().diff(moment(value), 'years') >= 18;
      }),
    country: Yup.string().required('Country is required'),
    ownership_percent: Yup.number()
      .max(100, 'Ownership percentage must be 100% or less')
      .required('Ownership percentage is required'),
    role: Yup.string().required('Role is required'),
    user_id_doc: Yup.mixed().required('Identification Docs is required')
  });

  const addUser = () => {
    setUserModal(!userModal);
  };

  const getApplicationFunction = (applicationId) => {
    if (applicationId !== '' && !isUpdateOnramp) {
      GetApplication({ application_id: applicationId }).then((res) => {
        const application = get(res, 'data.data.data[0]', '');
        setUserData(application.beneficial_ownership);
        setApplicationData(application);
        setIsLoaded(true);
      });
    } else {
      if (applicationData) {
        setUserData(applicationData.beneficial_ownership);
        setIsLoaded(true);
      }
    }
  };

  const callApplication = (applicationId) => {
    if (applicationId !== '') {
      GetApplication({ application_id: applicationId }).then((res) => {
        const application = get(res, 'data.data.data[0]', '');
        setUserData(application.beneficial_ownership);
        setApplicationData(application);
        setIsLoaded(true);
      });
    }
  };

  useEffect(() => {
    setOnrampCompleteStep(Array.from({ length: 2 }, (_, index) => index));
    if (applicationId !== '') {
      getApplicationFunction(applicationId);
    }
  }, [userModal, applicationId, setOnrampCompleteStep]);

  const handleSubmit = (values) => {
    const payloadData = new FormData();
    if (user && Object.keys(user).length > 0) {
      payloadData.append(
        'full_name',
        values.full_name ? values.full_name : user.full_name
      );
      payloadData.append('user_id_doc', values.user_id_doc);
      payloadData.append(
        'date_of_birth',
        values.date_of_birth
          ? moment(values.date_of_birth).format('YYYY-MM-DD')
          : moment(user.date_of_birth).format('YYYY-MM-DD')
      );
      payloadData.append('country', values.country ? values.country : user.country);
      payloadData.append('role', values.role ? values.role : user.role);
      payloadData.append(
        'ownership_percent',
        values.ownership_percent ? values.ownership_percent : user.ownership_percent
      );
      const params = {
        sub_application_id: applicationId,
        user_id: user?._id
      };

      EditUser(params, payloadData)
        .then((res) => {
          toast.success('User added successfully', { style: { fontSize: '12px' } });
          setInitialValue({
            full_name: '',
            date_of_birth: '',
            country: '',
            ownership_percent: '',
            role: '',
            profile: null
          });
          setUserModal(!userModal);
          callApplication(applicationId);
          getApplicationFunction(applicationId);
        })
        .catch((err) => {
          const errorMessage = get(err, 'data.error.message');
          toast.error(notificationMessageFunction(errorMessage), {
            style: { fontSize: '12px' }
          });
        });
    } else {
      payloadData.append('user_id_doc', values.user_id_doc);
      payloadData.append('full_name', values.full_name);
      payloadData.append('date_of_birth', values.date_of_birth);
      payloadData.append('country', values.country);
      payloadData.append('role', values.role);
      payloadData.append('ownership_percent', values.ownership_percent);
      toast.success('User added successfully', { style: { fontSize: '12px' } });
      setInitialValue({
        full_name: '',
        date_of_birth: '',
        country: '',
        ownership_percent: '',
        role: '',
        user_id_doc: null
      });
      UpdateApplication(applicationId, payloadData)
        .then((res) => {
          callApplication(applicationId);
          setUserModal(!userModal);
          getApplicationFunction(applicationId);
          setUser(null);
        })
        .catch((err) => {
          const errorMessage = get(err, 'data.error.message');
          toast.error(notificationMessageFunction(errorMessage), {
            style: { fontSize: '12px' }
          });
        });
    }
  };

  const fileInputRef = useRef(null);

  const handleFileButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <React.Fragment>
      {!userModal ? (
        <>
          <div onClick={isSectionCollapsed ? toggleSection : undefined}>
            {isUpdateOnramp ? (
              <>
                <div
                  className='cursor-pointer col-12 font-size-14 ml-2 d-flex justify-content-between align-items-center'
                  onClick={toggleSection}>
                  <span
                    className='text-black font-weight-400'
                    style={{ color: primaryTextState }}>
                    Beneficial Ownership
                  </span>
                  <i
                    className={`font-size-22 mdi mdi-chevron-${
                      isSectionCollapsed === true ? 'up' : 'down'
                    }`}
                    style={{ color: primaryTextState }}
                  />
                </div>
              </>
            ) : (
              <div className='col-12 font-size-16 pb-2 ml-2'>
                <span
                  className='text-black font-weight-400'
                  style={{ color: primaryTextState }}>
                  Beneficial Ownership
                </span>
              </div>
            )}
            <Collapse isOpen={!isSectionCollapsed}>
              <div className='p-4 p-sm-1'>
                <Container className='pt-4 pt-md-1'>
                  <div>
                    {!isLoaded ? (
                      <Loader />
                    ) : (
                      <>
                        {userData && (
                          <div className='col-12 col-sm-auto font-size-12 font-weight-medium'>
                            <div className='table-responsive'>
                              <table className='table table-centered table-hover'>
                                <thead>
                                  <tr className='font-size-12 text-muted text-center'>
                                    <th
                                      className='pt-3 pb-4 border-0'
                                      style={{ color: primaryTextState }}>
                                      #
                                    </th>
                                    <th
                                      className='pt-3 pb-4 border-0'
                                      style={{ color: primaryTextState }}>
                                      Full Name
                                    </th>
                                    <th
                                      className='pt-3 pb-4 border-0'
                                      style={{ color: primaryTextState }}>
                                      Date Of Birth
                                    </th>
                                    <th
                                      className='pt-3 pb-4 border-0'
                                      style={{ color: primaryTextState }}>
                                      Country
                                    </th>
                                    <th
                                      className='pt-3 pb-4 border-0'
                                      style={{ color: primaryTextState }}>
                                      % Ownership
                                    </th>
                                    <th
                                      className='pt-3 pb-4 border-0'
                                      style={{ color: primaryTextState }}>
                                      Role
                                    </th>
                                    <th
                                      className='pt-3 pb-4 border-0'
                                      style={{ color: primaryTextState }}>
                                      Identity Documents
                                    </th>
                                    <th
                                      className='pt-3 pb-4 border-0'
                                      style={{ color: primaryTextState }}>
                                      Action
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {userData && userData.length > 0 ? (
                                    userData.map((user, key) => (
                                      <tr
                                        key={key}
                                        className='font-size-12 font-weight-medium center-text'
                                        style={{ color: primaryTextState }}>
                                        <GetUserList
                                          user={user}
                                          handleOpenModal={addUser}
                                          setUser={setUser}
                                          applicationId={applicationId}
                                          index={key}
                                          getApplicationFunction={getApplicationFunction}
                                          setInitialValue={setInitialValue}
                                        />
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td
                                        colSpan={8}
                                        className='center-text text-center'
                                        style={{ color: primaryTextState }}>
                                        No user found
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        )}
                        <div className='d-flex justify-content-end mr-2 mt-4'>
                          <button
                            style={{ width: '86px', height: '30px' }}
                            className='btn application-button text-white font-size-12 d-flex align-items-center'
                            onClick={addUser}>
                            Add User
                          </button>
                        </div>
                        <div className='d-flex justify-content-between align-items-center mt-5 mr-2 ml-2'>
                          <i
                            className='mdi mdi-chevron-double-left font-size-32 ml-1 cursor-pointer'
                            onClick={prevPage}
                            style={{ color: primaryTextState }}
                          />

                          <button
                            className='btn application-button text-white'
                            type='submit'
                            onClick={() => nextPage()}>
                            Next
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </Container>
              </div>
            </Collapse>
          </div>
        </>
      ) : (
        <div>
          <div className='col-12 font-size-16 pb-2 ml-2 d-flex justify-content-between mb-4'>
            <span
              className='text-black font-weight-400'
              style={{ color: primaryTextState }}>
              {user && Object.keys(user).length > 0 ? 'Edit' : 'Add'} Beneficial User
            </span>
            <i
              className='mdi mdi-close font-size-16 text-dark mr-2 cursor-pointer'
              style={{ color: primaryTextState }}
              onClick={() => setUserModal(false)}
            />
          </div>

          <div className='ml-2 mr-2'>
            <Formik
              enableReinitialize
              initialValues={initialValue}
              validationSchema={beneficiaryUserSchema}
              onSubmit={handleSubmit}>
              {({
                errors,
                touched,
                values,
                handleChange,
                setFieldValue,
                setFieldTouched
              }) => (
                <Form>
                  <div>
                    <Col>
                      <CustomInput
                        label={CONST.FULL_NAME}
                        htmlFor='full_name'
                        setFieldTouched={setFieldTouched}
                        name='full_name'
                        placeholder={CONST.ENTER_FULL_NAME}
                        values={values}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                        primaryTextState={primaryTextState}
                      />
                    </Col>
                    <Col>
                      <CustomInput
                        label={'Date Of Birth'}
                        htmlFor='date_of_birth'
                        setFieldTouched={setFieldTouched}
                        name='date_of_birth'
                        placeholder={CONST.ENTER_DOB}
                        values={values}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                        type='date'
                        primaryTextState={primaryTextState}
                      />
                    </Col>

                    <Col>
                      <CustomInput
                        label={'Country'}
                        htmlFor='country'
                        setFieldTouched={setFieldTouched}
                        name='country'
                        placeholder={CONST.ENTER_COUNTRY}
                        values={values}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                        primaryTextState={primaryTextState}
                      />
                    </Col>

                    <Col>
                      <CustomInput
                        label={'% Ownership'}
                        htmlFor='ownership_percent'
                        setFieldTouched={setFieldTouched}
                        name='ownership_percent'
                        placeholder={CONST.ENTER_OWNERSHIP}
                        values={values}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                        type='number'
                        primaryTextState={primaryTextState}
                      />
                    </Col>

                    <Col>
                      <CustomInput
                        label={'Role'}
                        htmlFor='role'
                        setFieldTouched={setFieldTouched}
                        name='role'
                        placeholder={CONST.ENTER_ROLE}
                        values={values}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                        primaryTextState={primaryTextState}
                      />
                    </Col>

                    <Col>
                      <FileUploadFunction
                        handleFileButtonClick={handleFileButtonClick}
                        values={values}
                        fileInputRef={fileInputRef}
                        setFieldValue={setFieldValue}
                        primaryTextState={primaryTextState}
                      />
                    </Col>
                  </div>
                  <div className='d-flex justify-content-end mr-2 mt-4'>
                    <button
                      className='btn application-button text-white'
                      type='submit'
                      onSubmit={(values) => handleSubmit(values)}>
                      {user && Object.keys(user).length > 0 ? 'Edit' : 'Add'}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default Step2;

export const FileUploadFunction = ({
  handleFileButtonClick,
  values,
  fileInputRef,
  setFieldValue,
  primaryTextState
}) => {
  return (
    <FormGroup className='mb-3'>
      <div className='font-size-12'>
        <Label
          htmlFor='user_id_doc'
          className='text-black font-weight-normal'
          style={{ color: primaryTextState }}>
          Identification Docs
        </Label>
      </div>
      <div className='file-upload d-flex direction-row justify-content-between align-items-center pl-1'>
        <Label
          htmlFor='user_id_doc'
          className='mt-2'
          onClick={handleFileButtonClick}
          style={{ color: primaryTextState }}>
          <div className='cursor-pointer'>
            <span className='rounded-circle' style={{ color: '#134a87', fontSize: 12 }}>
              {values.user_id_doc ? (
                <img src={documentUploadedIcon} alt='user_id_doc' />
              ) : (
                <img src={documentUploadIcon} alt='user_id_doc' />
              )}
            </span>
            <span className='font-size-12 ml-2'>Identification Docs</span>
          </div>
        </Label>
        <div>
          {values.user_id_doc !== null && typeof values.user_id_doc === 'object' ? (
            <a
              href={URL.createObjectURL(values.user_id_doc)}
              target='_blank'
              rel='noopener noreferrer'
              className='pr-2 font-size-12 font-weight-bold text-wrap text-primary'>
              View File
            </a>
          ) : values.user_id_doc !== null &&
            typeof values.user_id_doc === 'string' &&
            values.user_id_doc.length > 0 ? (
            <a
              href={values.user_id_doc}
              target='_blank'
              rel='noopener noreferrer'
              className='pr-2 font-size-12 font-weight-bold text-wrap text-primary'>
              View File
            </a>
          ) : (
            ''
          )}
        </div>
        <input
          type='file'
          name='user_id_doc'
          style={{ display: 'none' }}
          ref={fileInputRef}
          onChange={(event) => {
            setFieldValue('user_id_doc', event.currentTarget.files[0]);
          }}
        />

        <ReactTooltip
          anchorId='user_id_doc'
          place='bottom'
          content={values.user_id_doc && values.user_id_doc.name}
        />
      </div>
      <ErrorMessage name='user_id_doc'>
        {(msg) => (
          <div style={{ fontSize: 12 }} className='text-left mt-1 text-danger'>
            {msg}
          </div>
        )}
      </ErrorMessage>
    </FormGroup>
  );
};
