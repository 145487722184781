import React from 'react';
import { Toaster } from 'react-hot-toast';
import DrawerPage from '../DrawerPage';
import { useState } from 'react';
import HorizontalStepper from './Steps/HorizontalStepper';
import { FormGroup, Label } from 'reactstrap';
import AuthInput from '../../Custom/AuthInput';
import Step1 from './Steps/Step1';
import Step2 from './Steps/Step2';
import Step3 from './Steps/Step3';
import Step4 from './Steps/Step4';
import Step5 from './Steps/Step5';
import Congratulations from './Steps/Congratulations';
import Loader from '../../constants/Loader';
import { useThemeContext } from '../ThemeProvider';

function OnrampApplication() {
  const { paramsValue, containerColorState, primaryTextState, secondaryTextState } =
    useThemeContext();

  const [step, setStep] = useState(0);
  const [applicationId, setApplicationId] = useState('');
  const [onrampCompleteStep, setOnrampCompleteStep] = useState([0]);
  const [isLoading, setIsLoading] = useState(false);
  const [userModal, setUserModal] = useState(false);
  const [applicationData, setApplicationData] = useState({});

  const prevPage = () => {
    if (step) setStep(step - 1);
  };
  const nextPage = () => {
    if (step < 5) setStep(step + 1);
  };

  const currentComponent = () => {
    const commonProps = {
      applicationId,
      setOnrampCompleteStep,
      setApplicationId,
      prevPage,
      nextPage,
      CustomInput,
      setIsLoading,
      setUserModal,
      userModal,
      primaryTextState,
      paramsValue,
      applicationData,
      setApplicationData
    };

    switch (step) {
      case 0:
        return <Step1 {...commonProps} />;
      case 1:
        return <Step2 {...commonProps} />;
      case 2:
        return <Step3 {...commonProps} />;
      case 3:
        return <Step4 {...commonProps} />;
      case 4:
        return <Step5 {...commonProps} />;
      case 5:
        return <Congratulations {...commonProps} />;
      default:
    }
  };

  return (
    <React.Fragment>
      <div className='widget-container'>
        <div
          className='d-flex card py-2'
          style={{ backgroundColor: containerColorState }}>
          {!userModal && (
            <>
              <span
                className='font-size-16 font-weight-medium d-flex justify-content-center align-items-center mt-1'
                style={{ color: primaryTextState }}>
                Onramp Application
              </span>
              <div style={{ marginTop: '32px' }}>
                <DrawerPage />
                <HorizontalStepper
                  activeStep={step}
                  setActiveStep={setStep}
                  onrampCompleteStep={onrampCompleteStep}
                  primaryTextState={primaryTextState}
                  secondaryTextState={secondaryTextState}
                />
              </div>
              <hr className='ml-2 mr-2' />
            </>
          )}
          <div
            className='py-2'
            style={{ maxHeight: '750px', overflowY: 'auto', overflowX: 'hidden' }}>
            {isLoading ? (
              <>
                <Loader />
              </>
            ) : (
              <>{currentComponent()}</>
            )}
          </div>
        </div>
      </div>
      <Toaster position='bottom-center' reverseOrder={false} />
    </React.Fragment>
  );
}

export default OnrampApplication;

const CustomInput = ({
  label,
  htmlFor,
  setFieldTouched,
  name,
  placeholder,
  values,
  handleChange,
  errors,
  touched,
  type = 'text',
  extraLabel,
  primaryTextState
}) => (
  <FormGroup className='mb-3'>
    <div className='font-size-12'>
      <Label
        htmlFor={htmlFor}
        className='text-black font-weight-normal'
        style={{ color: primaryTextState }}>
        {label} {extraLabel}
      </Label>
    </div>
    <div>
      <AuthInput
        setFieldTouched={setFieldTouched}
        withTouchError={true}
        type={type}
        name={name}
        className={'font-size-12 font-weight-400 input-height'}
        placeholder={placeholder}
        values={values}
        handleChange={handleChange}
        errors={errors}
        touched={touched}
      />
    </div>
  </FormGroup>
);
