import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import DrawerPage from '../DrawerPage';
import { Container } from 'reactstrap';
import { GetAllApplications } from '../../services/Application';
import { notificationMessageFunction } from '../../constants/notificationConst';
import toast from 'react-hot-toast';
import GetApplication from './GetApplication';
import Loader from '../../constants/Loader';
import { useThemeContext } from '../ThemeProvider';

function ViewApplications({ history }) {
  const {
    params,
    containerColorState,
    primaryColorState,
    primaryTextState,
    secondaryTextState
  } = useThemeContext();
  const [isLoaded, setIsLoaded] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [allApplications, setAllApplications] = useState([]);
  const [activeId, setActiveId] = useState(null);
  const [order, setOrder] = useState(null);
  const [first, setFirst] = useState(0);
  const [applicationDelete, setApplicationDelete] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [search, setSearch] = useState(null);
  const [searchValue, setSearchValue] = useState(null);
  const [suggestions, setSuggestions] = useState([
    { id: 'EXCHANGE', name: 'Exchange', checked: false },
    { id: 'ONRAMP', name: 'Onramp', checked: false }
  ]);
  const defaultApp = [
    { id: 'EXCHANGE', name: 'Exchange', checked: false },
    { id: 'ONRAMP', name: 'Onramp', checked: false }
  ];
  const orderOptions = [
    { id: 'status_asc', name: 'ASC' },
    { id: 'status_desc', name: 'DESC' }
  ];

  useEffect(() => {
    const type = suggestions
      .filter((suggestion) => suggestion.checked)
      .map((suggestion) => suggestion.id);

    GetAllApplications({ page: 1, order, search, type })
      .then((res) => {
        const newArray = get(res, 'data.data.data', []);
        newArray.forEach((responseData) => {
          if (responseData.app_fields_per === 0) {
            const percentage =
              (responseData.exchange_application.percentage_complete / 42) * 100;
            responseData.completepercentage = Math.round(percentage);
          } else {
            responseData.completepercentage = responseData.app_fields_per;
          }
        });
        setApplicationDelete(false);
        setAllApplications(newArray);
        setIsLoaded(true);
      })
      .catch((err) => {
        const errorMessage = get(err, 'data.error.message');
        toast.error(notificationMessageFunction(errorMessage), {
          style: { fontSize: '12px' }
        });
      });
  }, [search, order, suggestions, applicationDelete]);

  const onClick = (id) => {
    if (activeId === id) {
      return setActiveId(null);
    }

    setActiveId(id);
  };

  const searchFunction = (search) => {
    setSearch(search);
  };

  const handleCheckboxChange = (id) => {
    const updatedSuggestions = suggestions.map((suggestion) => {
      if (suggestion.id === id) {
        return { ...suggestion, checked: !suggestion.checked };
      }
      return suggestion;
    });

    setSuggestions(updatedSuggestions);
  };

  const handleRadioChange = (orderId) => {
    setOrder(orderId);
  };

  return (
    <React.Fragment>
      <div className='widget-container'>
        <div
          className='d-flex card py-2'
          style={{ backgroundColor: containerColorState }}>
          <span
            className='font-size-16 font-weight-medium d-flex justify-content-center align-items-center mt-1'
            style={{ color: primaryTextState }}>
            Applications
          </span>

          <DrawerPage />
          <hr className='mr-2 ml-2 mt-2 mb-2' />
          {showFilter ? (
            <>
              <div className='d-flex align-items-center'>
                <i
                  className='mdi mdi-keyboard-backspace font-size-22 ml-2'
                  style={{ color: primaryTextState }}
                  onClick={() => {
                    setShowFilter(false);
                    setSuggestions(defaultApp);
                    setOrder(null);
                  }}
                />
                <div className='cursor-pointer ml-3'>
                  <i
                    className='ri-equalizer-fill font-size-14'
                    style={{ color: primaryTextState }}
                  />
                  <span className='ml-2 font-size-14' style={{ color: primaryTextState }}>
                    Filter
                  </span>
                </div>
              </div>
              <hr className='mr-2 ml-2 mt-2 mb-2' />
              <span
                className='font-size-14 font-weight-500 ml-3 mb-2'
                style={{ color: primaryTextState }}>
                Select Application
              </span>
              <div className={`filter-section`}>
                {suggestions.map((suggestion) => (
                  <div
                    key={suggestion.id}
                    className='d-flex align-items-center ml-3 mb-2'
                    style={{ wordBreak: 'break-all' }}>
                    <input
                      type='checkbox'
                      checked={suggestion.checked}
                      style={{ height: 16, width: 16 }}
                      onChange={() => {
                        handleCheckboxChange(suggestion.id);
                      }}
                    />
                    <span
                      className='font-size-12 mr-2 ml-1'
                      style={{ color: primaryTextState }}>
                      {suggestion.name}
                    </span>
                  </div>
                ))}
              </div>
              <hr className='mr-2 ml-2 mt-2 mb-2' />
              <span
                className='font-size-14 font-weight-500 ml-3 mb-2'
                style={{ color: primaryTextState }}>
                Completion Order
              </span>
              <div className={`mb-3 filter-section`}>
                {orderOptions.map((option) => (
                  <div
                    key={option.id}
                    className='d-flex align-items-center ml-3 mb-2'
                    style={{ wordBreak: 'break-all' }}>
                    <input
                      type='radio'
                      name='order'
                      value={option.id}
                      checked={order === option.id}
                      style={{ height: 16, width: 16, color: primaryTextState }}
                      onChange={() => handleRadioChange(option.id)}
                    />
                    <span
                      className='font-size-12 mr-2 ml-1'
                      style={{ color: primaryTextState }}>
                      {option.name}
                    </span>
                  </div>
                ))}
              </div>
              <div className='d-flex align-items-center justify-content-center mt-4'>
                <button
                  className='btn application-button text-white'
                  onClick={() => setShowFilter(false)}>
                  Apply
                </button>
              </div>
            </>
          ) : (
            <>
              <div className='ml-3 mr-3 d-flex justify-content-between align-items-center'>
                {!isSearching ? (
                  <>
                    <div
                      className='cursor-pointer d-flex justify-content-center'
                      onClick={() => setShowFilter(true)}>
                      <i
                        className='ri-equalizer-fill font-size-14'
                        style={{ color: primaryTextState }}
                      />
                      <span
                        className='ml-2 font-size-14'
                        style={{ color: primaryTextState }}>
                        Filter
                      </span>
                    </div>
                    <div
                      className='font-size-14 d-flex justify-content-center align-items-center cursor-pointer'
                      onClick={() => setIsSearching(true)}>
                      <i
                        className='font-size-20 mdi mdi-magnify ml-2'
                        style={{ color: primaryTextState }}
                      />
                      <span style={{ color: primaryTextState }}>Search</span>
                    </div>
                  </>
                ) : (
                  <>
                    <input
                      type='text'
                      className='form-control form-control-search font-size-12'
                      placeholder='Search by Project Name, Ticker Name, Token Address'
                      onChange={(event) => setSearchValue(event.target.value)}
                      onKeyPress={(event) => {
                        if (event.key === 'Enter') {
                          searchFunction(searchValue);
                        }
                      }}
                      style={{ color: primaryTextState }}
                      autoFocus
                    />
                    <span
                      className='ml-3 cursor-pointer'
                      onClick={() => searchFunction(searchValue)}
                      style={{ color: primaryTextState }}>
                      GO
                    </span>
                    <i
                      className='mdi mdi-close font-size-22 ml-3 cursor-pointer'
                      style={{ color: primaryTextState }}
                      onClick={() => {
                        setIsSearching(false);
                        setSearchValue('');
                        searchFunction('');
                      }}
                    />
                  </>
                )}
              </div>
              <hr className='mr-2 ml-2 mt-2 mb-2' />
              <div
                className='pb-2'
                style={{ maxHeight: '750px', overflowY: 'auto', overflowX: 'hidden' }}>
                <Container className='col-12 col-sm-auto pt-3'>
                  <div>
                    {!isLoaded ? (
                      <Loader />
                    ) : allApplications && allApplications?.length > 0 ? (
                      <>
                        {allApplications &&
                          allApplications?.length > 0 &&
                          allApplications.map((application, index) => (
                            <div key={index}>
                              <GetApplication
                                params={params}
                                primaryColorState={primaryColorState}
                                primaryTextState={primaryTextState}
                                secondaryTextState={secondaryTextState}
                                application={application}
                                index={index}
                                activeId={activeId}
                                onClick={() => onClick(index)}
                                history={history}
                                setFirst={setFirst}
                                setApplicationDelete={setApplicationDelete}
                              />
                            </div>
                          ))}
                      </>
                    ) : (
                      <span className='d-flex justify-content-center mt-4'>
                        No data found.
                      </span>
                    )}
                  </div>
                </Container>
              </div>
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default ViewApplications;
