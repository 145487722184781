import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import { FormGroup, Label } from 'reactstrap';
import AuthInput from '../../../Custom/AuthInput';
import { GetApplication } from '../../../services/Application';
import Loader from '../../../constants/Loader';
import Step1 from '../../OnrampApplication/Steps/Step1';
import Step2 from '../../OnrampApplication/Steps/Step2';
import Step3 from '../../OnrampApplication/Steps/Step3';
import Step4 from '../../OnrampApplication/Steps/Step4';
import Step5 from '../../OnrampApplication/Steps/Step5';

function ApplicationDetails({
  applicationId,
  setApplicationId,
  primaryColorState,
  primaryTextState
}) {
  const [step, setStep] = useState(0);
  const [applicationData, setApplicationData] = useState({});
  const [onrampCompleteStep, setOnrampCompleteStep] = useState([0]);
  const [isLoading, setIsLoading] = useState(false);
  const [openedSection, setOpenedSection] = useState(null);
  const [userModal, setUserModal] = useState(false);

  const toggleSection = (index) => {
    if (openedSection === index) {
      setOpenedSection(null);
    } else {
      setOpenedSection(index);
    }
  };

  const prevPage = () => {
    if (step) {
      toggleSection(openedSection - 1);
      setOpenedSection(openedSection - 1);
      setStep(step - 1);
    }
  };
  const nextPage = () => {
    if (step < 5) {
      toggleSection(openedSection + 1);
      setOpenedSection(openedSection + 1);
      setStep(step + 1);
    }
  };

  const commonProps = {
    isLoading,
    applicationData,
    setApplicationData,
    setOnrampCompleteStep,
    userModal,
    setUserModal,
    setApplicationId,
    setIsLoading,
    applicationId,
    prevPage,
    nextPage,
    CustomInput,
    isUpdateOnramp: true
  };

  useEffect(() => {
    if (applicationId !== '') {
      GetApplication({ application_id: applicationId }).then((res) => {
        const application = get(res, 'data.data.data[0]', '');
        setApplicationData(application);
      });
    }
  }, [applicationId]);

  const renderStep = (stepNumber, StepComponent) => {
    return (
      <div
        className='py-2 exchange-card-border ml-2 mr-2 mt-2'
        style={{ background: primaryColorState }}>
        <StepComponent
          {...commonProps}
          toggleSection={() => toggleSection(stepNumber)}
          isSectionCollapsed={openedSection !== stepNumber}
          primaryTextState={primaryTextState}
        />
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className='mt-4'>
        {renderStep(0, Step1)}
        {renderStep(1, Step2)}
        {renderStep(2, Step3)}
        {renderStep(3, Step4)}
        {renderStep(4, Step5)}
      </div>
    </React.Fragment>
  );
}

export default ApplicationDetails;

const CustomInput = ({
  label,
  htmlFor,
  setFieldTouched,
  name,
  placeholder,
  values,
  handleChange,
  errors,
  touched,
  type = 'text',
  extraLabel,
  primaryTextState
}) => (
  <FormGroup className='mb-2'>
    <div className='font-size-12'>
      <Label
        htmlFor={htmlFor}
        className='text-black font-weight-normal'
        style={{ color: primaryTextState }}>
        {label} {extraLabel}
      </Label>
    </div>
    <div>
      <AuthInput
        setFieldTouched={setFieldTouched}
        withTouchError={true}
        type={type}
        name={name}
        className={'font-size-12 font-weight-400 input-height'}
        placeholder={placeholder}
        values={values}
        handleChange={handleChange}
        errors={errors}
        touched={touched}
      />
    </div>
  </FormGroup>
);
