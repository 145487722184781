import moment from 'moment';
import React from 'react';
import deleteIcon from '../../../assets/images/trash.png';
import { notifySuccess } from '../../../Custom/notification';
import { DeleteUser } from '../../../services/Application';

function GetUserList({
  user,
  applicationId,
  handleOpenModal,
  setUser,
  index,
  getApplicationFunction,
  setInitialValue
}) {
  const handleDeleteUser = () => {
    DeleteUser(applicationId, user._id).then((res) => {
      notifySuccess('Beneficiary User Removed successfully');
      getApplicationFunction(applicationId);
    });
  };

  const handleEditUser = () => {
    setUser(user);
    setInitialValue({
      full_name: (user && user.full_name) || '',
      date_of_birth: (user && moment(user.date_of_birth).format('YYYY-MM-DD')) || '',
      country: (user && user.country) || '',
      ownership_percent: (user && user.ownership_percent) || '',
      role: (user && user.role) || '',
      user_id_doc: (user && user.id_doc) || null
    });
    handleOpenModal();
  };

  return (
    <React.Fragment>
      <td className='pl-3'>{index + 1}</td>
      <td className='pl-3'>{user.full_name}</td>
      <td className='pl-3'>{moment(user.date_of_birth).format('DD/MM/YYYY')}</td>
      <td className='pl-3'>{user.country}</td>
      <td className='pl-3'>{user.ownership_percent}%</td>
      <td className='pl-3'>{user.role}</td>
      <td className='pl-3'>
        <a
          href={user.id_doc}
          target='_blank'
          rel='noopener noreferrer'
          className='text-primary'>
          View Doc
        </a>
      </td>
      <td className='d-flex align-items-center'>
        <i
          className='cursor-pointer mdi mdi-pencil font-size-22'
          onClick={handleEditUser}></i>
        <div className='cursor-pointer' onClick={handleDeleteUser}>
          <i className='mdi mdi-delete font-size-22' />
        </div>
      </td>
    </React.Fragment>
  );
}

export default GetUserList;
