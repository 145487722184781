import React, { createContext, useContext, useState, useEffect } from 'react';
import { createParamsData } from '../constants/params';

const ThemeContext = createContext();

export function useThemeContext() {
  return useContext(ThemeContext);
}

export function ThemeProvider({ children }) {
  const params = window.location.search;
  const paramsValue = createParamsData(params);

  const widgetTheme = paramsValue['widgetTheme'] || 'light';
  const containerColor = paramsValue['containerColor'] || '';
  const primaryColor = paramsValue['primaryColor'] || '';
  const primaryText = paramsValue['primaryText'] || '';
  const secondaryText = paramsValue['secondaryText'] || '';

  const [containerColorState, setContainerColorState] = useState('');
  const [primaryColorState, setPrimaryColorState] = useState('');
  const [primaryTextState, setPrimaryTextState] = useState('');
  const [secondaryTextState, setSecondaryTextState] = useState('');

  const widgetThemeFunction = () => {
    setPrimaryColorState(
      widgetTheme === 'dark'
        ? '#222229'
        : widgetTheme === 'light'
        ? '#ffffff'
        : widgetTheme === 'bluey'
        ? '#ffffff'
        : '#ffffff'
    );

    setPrimaryTextState(
      widgetTheme === 'dark'
        ? '#ffffff'
        : widgetTheme === 'light'
        ? '#141519'
        : widgetTheme === 'bluey'
        ? '#141519'
        : '#141519'
    );

    setSecondaryTextState(
      widgetTheme === 'dark'
        ? '#bbbbbb'
        : widgetTheme === 'light'
        ? '#bbbbbb'
        : widgetTheme === 'bluey'
        ? '#bbbbbb'
        : '#bbbbbb'
    );

    setContainerColorState(
      widgetTheme === 'dark'
        ? '#141416'
        : widgetTheme === 'light'
        ? '#ffffff'
        : widgetTheme === 'bluey'
        ? '#ffffff'
        : '#ffffff'
    );

    if (primaryColor && primaryColor.length > 0) {
      setPrimaryColorState('#' + primaryColor);
    }

    if (primaryText && primaryText.length > 0) {
      setPrimaryTextState('#' + primaryText);
    }

    if (containerColor && containerColor.length > 0) {
      setContainerColorState('#' + containerColor);
    }

    if (secondaryText && secondaryText.length > 0) {
      setSecondaryTextState('#' + secondaryText);
    }
  };

  useEffect(() => {
    widgetThemeFunction();
  }, [containerColor, primaryColor, primaryText, secondaryText]);

  return (
    <ThemeContext.Provider
      value={{
        params,
        paramsValue,
        widgetTheme,
        containerColorState,
        primaryColorState,
        primaryTextState,
        secondaryTextState
      }}>
      {children}
    </ThemeContext.Provider>
  );
}
