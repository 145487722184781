import React from 'react';

function Loader() {
  return (
    <div className='h-100 d-flex align-items-center justify-content-center py-4'>
      <i className='fas fa-circle-notch fa-spin text-muted' style={{ fontSize: 48 }}></i>
    </div>
  );
}

export default Loader;
