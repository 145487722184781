import React from 'react';
import SimpleBar from 'simplebar-react';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import step1 from '../../../assets/images/step_01.png';
import step1_2 from '../../../assets/images/step_01_2.png';
import step2 from '../../../assets/images/step_02.png';
import step2_2 from '../../../assets/images/step_02_2.png';
import step3 from '../../../assets/images/step_03.png';
import step3_2 from '../../../assets/images/step_03_2.png';
import step4 from '../../../assets/images/step_04.png';
import step4_2 from '../../../assets/images/step_04_2.png';
import step5 from '../../../assets/images/step_05.png';
import step5_2 from '../../../assets/images/step_05_2.png';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: 'linear-gradient(180deg, #0055B9 0%, #5590D6 100%)'
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: 'linear-gradient(180deg, #0055B9 0%, #5590D6 100%)'
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1
  }
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: ownerState.active ? '#FFF' : '#FFF',
  border: '2px solid #0055B9',
  zIndex: 1,
  color: ownerState.active ? '#FFF' : '#0055B9',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage: 'linear-gradient(180deg, #0055B9 0%, #5590D6 100%)'
  }),
  ...(ownerState.completed && {
    backgroundImage: '#FFF',
    border: '2px solid #0055B9'
  })
}));

function ColorlibStepIcon(props) {
  const { active, completed, className, icon } = props;

  const icons = {
    1: <img src={icon === 1 && active ? step1 : step1_2} alt='step1' height={18} />,
    2: <img src={icon === 2 && active ? step2 : step2_2} alt='step2' height={18} />,
    3: <img src={icon === 3 && active ? step3 : step3_2} alt='step3' height={18} />,
    4: <img src={icon === 4 && active ? step4 : step4_2} alt='step4' height={18} />,
    5: <img src={icon === 5 && active ? step5 : step5_2} alt='step5' height={18} />
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

function HorizontalStepper({
  activeStep,
  setActiveStep,
  onrampCompleteStep,
  primaryTextState,
  secondaryTextState
}) {
  const steps = [
    'General Information',
    'Beneficial Ownership',
    'Wallet and Fund Flow Details',
    'Partner Entity Compliance and Documentation',
    'Certifications'
  ];

  return (
    <Stack sx={{ width: '100%' }} spacing={4}>
      <SimpleBar style={{ maxHeight: '110px', overflowY: 'hidden' }}>
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<ColorlibConnector />}>
          {steps.map((step, index) => (
            <Step key={step}>
              <StepLabel
                classes={{
                  label: `font-size-10 ${
                    activeStep === index
                      ? 'font-weight-bold text-black'
                      : index < activeStep
                      ? 'text-grey'
                      : 'text-grey'
                  }`
                }}
                StepIconComponent={ColorlibStepIcon}
                onClick={() => onrampCompleteStep.includes(index) && setActiveStep(index)}
                completed={index < activeStep}>
                <span
                  style={{
                    color: activeStep === index ? primaryTextState : secondaryTextState
                  }}>
                  {step}
                </span>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </SimpleBar>
    </Stack>
  );
}

export default HorizontalStepper;
