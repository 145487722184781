import React, { useEffect, useState } from 'react';
import { Collapse, Container, FormGroup, Label } from 'reactstrap';
import { Formik, Form } from 'formik';
import { TagsInput } from 'react-tag-input-component';
import get from 'lodash/get';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { GetApplication, UpdateExchangeApplication } from '../../../services/Application';
import { StepsCalculator } from './StepsCalculator';
import { notificationMessageFunction } from '../../../constants/notificationConst';
import { constants as CONST } from '../../../constants/application';
import { handleSubmitLiteApplication } from './LiteComplete';
import Loader from '../../../constants/Loader';

function Step9({
  isLoading,
  setIsLoading,
  setExchangeCompleteStep,
  applicationId,
  nextPage,
  setApplicationData,
  prevPage,
  applicationData,
  isUpdateExchange = false,
  isSectionCollapsed,
  toggleSection,
  GetApplicationDetailsEdit,
  primaryTextState,
  history,
  paramsValue
}) {
  const applicationStatus = applicationData.application_status;
  applicationData = applicationData.exchange_application;

  const CapMarketSchema = Yup.object().shape({
    market_makers: Yup.array()
      .of(Yup.string().required('Market makers is required'))
      .min(1, 'At least one market maker is required')
  });
  const [initialValue, setInitialValue] = useState({
    market_makers: []
  });
  const [completeStep, setCompleteStep] = useState(0);

  useEffect(() => {
    if (applicationId !== '' && !isUpdateExchange) {
      GetApplication({ application_id: applicationId }).then((res) => {
        const application = get(res, 'data.data.data[0]', '');
        if (application.exchange_application.market_makers) {
          setInitialValue({
            ...initialValue,
            market_makers: application.exchange_application.market_makers
          });
        }
        setCompleteStep(application.exchange_application.percentage_complete);
      });
    } else {
      if (applicationData && applicationData.market_makers) {
        setInitialValue({
          ...initialValue,
          market_makers: applicationData.market_makers
        });
      }
    }
  }, [applicationData, applicationId, isUpdateExchange]);

  const handleSubmit = (values) => {
    let stepsRequired = Math.floor(StepsCalculator(1, completeStep, 46));
    if (
      applicationData &&
      applicationData.market_makers &&
      values &&
      values.market_makers &&
      applicationData.market_makers.length !== values.market_makers.length
    ) {
      const data = {
        market_makers: JSON.stringify(values.market_makers),
        percentage_complete: stepsRequired
      };
      JSON.stringify(data);
      setIsLoading(true);

      UpdateExchangeApplication({ application_id: applicationId }, data)
        .then((res) => {
          const application = get(res, 'data.data.data', '');
          setApplicationData(application);
          if (isUpdateExchange) {
            toast.success('Market Makers updated successfully', {
              style: { fontSize: '12px' }
            });
            GetApplicationDetailsEdit(applicationId);
          }
          nextPage();
          setIsLoading(false);
        })
        .catch((err) => {
          const errorMessage = get(err, 'data.error.message');
          toast.error(notificationMessageFunction(errorMessage), {
            style: { fontSize: '12px' }
          });
        });
      setExchangeCompleteStep(Array.from({ length: 10 }, (_, index) => index));
    } else {
      nextPage();
    }
  };

  const handleTagsChange = (newTags) => {
    setInitialValue({ ...initialValue, market_makers: newTags });
  };

  return (
    <React.Fragment>
      <div onClick={isSectionCollapsed ? toggleSection : undefined}>
        {isUpdateExchange ? (
          <>
            <div
              className='cursor-pointer col-12 font-size-14 ml-2 d-flex justify-content-between align-items-center'
              onClick={toggleSection}>
              <span
                className='text-black font-weight-400'
                style={{ color: primaryTextState }}>
                Name of your market maker
              </span>
              <i
                className={`font-size-22 mdi mdi-chevron-${
                  isSectionCollapsed === true ? 'up' : 'down'
                }`}
                style={{ color: primaryTextState }}
              />
            </div>
          </>
        ) : (
          <div className='col-12 font-size-16 pb-2 ml-2'>
            <span
              className='text-black font-weight-400'
              style={{ color: primaryTextState }}>
              Name of your market maker
            </span>
          </div>
        )}
        <Collapse isOpen={!isSectionCollapsed}>
          {isLoading ? (
            <div className='mt-5'>
              <Loader />
            </div>
          ) : (
            <Container className='col-12 col-sm-auto pt-3 ml-2 mr-2'>
              <div>
                <Formik
                  initialValues={initialValue}
                  enableReinitialize={true}
                  validationSchema={CapMarketSchema}
                  onSubmit={handleSubmit}>
                  {({ errors, touched, values, handleChange }) => (
                    <Form className='mr-2'>
                      <div>
                        <FormGroup className='mb-4'>
                          <div className='font-size-12'>
                            <Label
                              htmlFor='market_makers'
                              className='text-black font-weight-normal'
                              style={{ color: primaryTextState }}>
                              {CONST.MARKETMAKERS}
                            </Label>
                          </div>
                          <div>
                            <TagsInput
                              value={values.market_makers}
                              onChange={handleTagsChange}
                              name='market_makers'
                              classNames={{ input: 'font-size-12', tag: 'font-size-12' }}
                              placeHolder='Enter your market maker'
                              handleChange={handleChange}
                              errors={errors}
                              touched={touched}
                              required
                            />
                            <em
                              className='font-size-12'
                              style={{ color: primaryTextState }}>
                              press enter to add new tag
                            </em>
                          </div>
                          {errors && (
                            <div className='error font-size-12'>
                              {errors.market_makers}
                            </div>
                          )}
                        </FormGroup>
                      </div>

                      <div className='d-flex justify-content-between align-items-center mt-4 mr-2 ml-2'>
                        <i
                          className='mdi mdi-chevron-double-left font-size-32 ml-1 cursor-pointer'
                          onClick={prevPage}
                          style={{ color: primaryTextState }}
                        />
                        <div>
                          {(applicationStatus === 'INCOMPLETE' || !applicationStatus) && (
                            <button
                              className='btn btn-status-change text-white mr-2'
                              type='submit'
                              style={{ width: '142px', height: '50px' }}
                              onClick={() =>
                                handleSubmitLiteApplication({
                                  applicationId,
                                  setApplicationData,
                                  history,
                                  paramsValue,
                                  setIsLoading
                                })
                              }>
                              Submit
                            </button>
                          )}
                          <button
                            className='btn application-button text-white'
                            type='submit'
                            onSubmit={(values) => handleSubmit(values)}>
                            Next
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </Container>
          )}
        </Collapse>
      </div>
    </React.Fragment>
  );
}

export default Step9;
