import React, { useEffect, useState } from 'react';
import { Col, Collapse, Container, FormGroup, Input, Label, Row } from 'reactstrap';
import get from 'lodash/get';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { GetApplication, UpdateExchangeApplication } from '../../../services/Application';
import { StepsCalculator } from './StepsCalculator';
import { notificationMessageFunction } from '../../../constants/notificationConst';
import { constants as ERROR_CONST } from '../../../constants/errorMessage';
import { handleSubmitLiteApplication } from './LiteComplete';
import Loader from '../../../constants/Loader';

function Step14({
  isLoading,
  setIsLoading,
  setExchangeCompleteStep,
  applicationId,
  nextPage,
  setApplicationData,
  prevPage,
  applicationData,
  isUpdateExchange = false,
  isSectionCollapsed,
  toggleSection,
  GetApplicationDetailsEdit,
  primaryTextState,
  history,
  paramsValue
}) {
  const applicationStatus = applicationData.application_status;
  const [initialValue, setInitialValue] = useState({
    listing_program_at_bybit: [
      { id: 1, label: 'Direct Listing', value: 'direct_listing', isChecked: false },
      { id: 2, label: 'Launchpad / IEO', value: 'launchpad', isChecked: false },
      { id: 3, label: 'Launchpool', value: 'launchpool', isChecked: false },
      { id: 4, label: 'All Listing Programs', value: 'all_programs', isChecked: false }
    ]
  });
  const [completeStep, setCompleteStep] = useState(0);
  const ListingProgramsSchema = Yup.object().shape({
    listing_program_at_bybit: Yup.array()
      .required(ERROR_CONST.SELECT_CHECKBOX)
      .min(1, ERROR_CONST.SELECT_CHECKBOX)
  });

  useEffect(() => {
    if (applicationId !== '' && !isUpdateExchange) {
      GetApplication({ application_id: applicationId }).then((res) => {
        const application = get(res, 'data.data.data[0]', '');
        if (application.exchange_application.listing_program.length) {
          const updatedList = initialValue.listing_program_at_bybit.map((program) => {
            if (
              application.exchange_application.listing_program.includes(program.value)
            ) {
              return { ...program, isChecked: true };
            } else {
              return program;
            }
          });

          setInitialValue({
            listing_program_at_bybit: updatedList
          });
        }
        setCompleteStep(application.exchange_application.percentage_complete);
      });
    } else {
      if (
        applicationData &&
        applicationData.exchange_application &&
        applicationData.exchange_application.listing_program.length
      ) {
        const updatedList = initialValue.listing_program_at_bybit.map((program) => {
          if (
            applicationData.exchange_application.listing_program.includes(program.value)
          ) {
            return { ...program, isChecked: true };
          } else {
            return program;
          }
        });

        setInitialValue({ listing_program_at_bybit: updatedList });
      }
    }
  }, [applicationData, applicationId, isUpdateExchange]);

  const handleSubmit = (values, { setFieldError }) => {
    let stepsRequired = Math.floor(StepsCalculator(1, completeStep, 68));
    const listingValue = values.listing_program_at_bybit
      .filter((list) => list.isChecked === true && list.value !== 'all_programs')
      .map((list) => list.value);
    const data = {
      listing_program: JSON.stringify(listingValue),
      percentage_complete: stepsRequired
    };
    if (data.listing_program.length <= 0) {
      setFieldError('listing_program_at_bybit', 'select at least one program');
      return;
    }
    JSON.stringify(data);
    setIsLoading(true);

    UpdateExchangeApplication({ application_id: applicationId }, data)
      .then((res) => {
        const application = get(res, 'data.data.data', '');
        setApplicationData(application);
        if (isUpdateExchange) {
          toast.success('Programs of Interest updated successfully', {
            style: { fontSize: '12px' }
          });
          GetApplicationDetailsEdit(applicationId);
        }
        nextPage();
        setIsLoading(false);
      })
      .catch((err) => {
        const errorMessage = get(err, 'data.error.message');
        toast.error(notificationMessageFunction(errorMessage), {
          style: { fontSize: '12px' }
        });
      });
    setExchangeCompleteStep(Array.from({ length: 15 }, (_, index) => index));
  };

  return (
    <React.Fragment>
      <div onClick={isSectionCollapsed ? toggleSection : undefined}>
        {isUpdateExchange ? (
          <>
            <div
              className='cursor-pointer col-12 font-size-14 ml-2 d-flex justify-content-between align-items-center'
              onClick={toggleSection}>
              <span
                className='text-black font-weight-400'
                style={{ color: primaryTextState }}>
                Programs of Interest
              </span>
              <i
                className={`font-size-22 mdi mdi-chevron-${
                  isSectionCollapsed === true ? 'up' : 'down'
                }`}
                style={{ color: primaryTextState }}
              />
            </div>
          </>
        ) : (
          <div className='col-12 font-size-16 pb-2 ml-2'>
            <span
              className='text-black font-weight-400'
              style={{ color: primaryTextState }}>
              Programs of Interest
            </span>
          </div>
        )}
        <Collapse isOpen={!isSectionCollapsed}>
          {isLoading ? (
            <div className='mt-5'>
              <Loader />
            </div>
          ) : (
            <Container className='col-12 col-sm-auto pt-3 ml-2'>
              <div>
                <Formik
                  initialValues={initialValue}
                  enableReinitialize={true}
                  validationSchema={ListingProgramsSchema}
                  onSubmit={handleSubmit}>
                  {({ values, handleChange, setFieldValue }) => (
                    <Form>
                      <div>
                        <Row>
                          <Col>
                            <FormGroup>
                              <div>
                                {values.listing_program_at_bybit?.map(
                                  (program, index) => (
                                    <FormGroup check key={index}>
                                      <Label check style={{ color: primaryTextState }}>
                                        <Field
                                          type='checkbox'
                                          name={`listing_program_at_bybit[${index}].isChecked`}
                                          as={Input}
                                          onChange={(event) => {
                                            const { name, checked } = event.target;
                                            if (
                                              name ===
                                              'listing_program_at_bybit[3].isChecked'
                                            ) {
                                              const updatedPrograms =
                                                initialValue.listing_program_at_bybit.map(
                                                  (program, index) => {
                                                    return {
                                                      ...program,
                                                      isChecked: checked
                                                    };
                                                  }
                                                );

                                              setFieldValue(
                                                'listing_program_at_bybit',
                                                updatedPrograms
                                              );
                                            } else {
                                              handleChange(event);
                                            }
                                          }}
                                        />{' '}
                                        <span className='font-size-12'>
                                          {program.label}
                                        </span>
                                      </Label>
                                    </FormGroup>
                                  )
                                )}
                                <ErrorMessage name='listing_program_at_bybit'>
                                  {(errorMsg) => (
                                    <div style={{ color: 'red' }}>{errorMsg}</div>
                                  )}
                                </ErrorMessage>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>

                      <div className='d-flex justify-content-between align-items-center mt-4 mr-2'>
                        <i
                          className='mdi mdi-chevron-double-left font-size-32 ml-1 cursor-pointer'
                          onClick={prevPage}
                          style={{ color: primaryTextState }}
                        />
                        <div>
                          {(applicationStatus === 'INCOMPLETE' || !applicationStatus) && (
                            <button
                              className='btn btn-status-change text-white mr-2'
                              type='submit'
                              style={{ width: '142px', height: '50px' }}
                              onClick={() =>
                                handleSubmitLiteApplication({
                                  applicationId,
                                  setApplicationData,
                                  history,
                                  paramsValue,
                                  setIsLoading
                                })
                              }>
                              Submit
                            </button>
                          )}
                          <button
                            type='submit'
                            className='btn application-button text-white'
                            onSubmit={(values) => handleSubmit(values)}>
                            Next
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </Container>
          )}
        </Collapse>
      </div>
    </React.Fragment>
  );
}

export default Step14;
