import React, { useEffect, useState } from 'react';
import { Col, Collapse, Container, FormGroup, Label } from 'reactstrap';
import { Formik, Form } from 'formik';
import get from 'lodash/get';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { GetApplication, UpdateExchangeApplication } from '../../../services/Application';
import { StepsCalculator } from './StepsCalculator';
import { notificationMessageFunction } from '../../../constants/notificationConst';
import { constants as CONST } from '../../../constants/application';
import AuthInput from '../../../Custom/AuthInput';
import { handleSubmitLiteApplication } from './LiteComplete';
import Loader from '../../../constants/Loader';

function Step12({
  isLoading,
  setIsLoading,
  setExchangeCompleteStep,
  applicationId,
  nextPage,
  setApplicationData,
  prevPage,
  applicationData,
  isUpdateExchange = false,
  isSectionCollapsed,
  toggleSection,
  GetApplicationDetailsEdit,
  primaryTextState,
  history,
  paramsValue
}) {
  const applicationStatus = applicationData.application_status;
  applicationData = applicationData.exchange_application;

  const [initialValue, setInitialValue] = useState({
    marketing_budget_allocated_for_listing: ''
  });
  const [completeStep, setCompleteStep] = useState(0);

  const CapMarketSchema = Yup.object().shape({
    marketing_budget_allocated_for_listing: Yup.string().required(
      'Marketing budget allocated for listing is required'
    )
  });

  useEffect(() => {
    if (applicationId !== '' && !isUpdateExchange) {
      GetApplication({ application_id: applicationId }).then((res) => {
        const application = get(res, 'data.data.data[0]', '');

        if (application.exchange_application.marketing_budget_allocated_for_listing) {
          setInitialValue({
            marketing_budget_allocated_for_listing:
              application.exchange_application.marketing_budget_allocated_for_listing
          });
        }
        setCompleteStep(application.exchange_application.percentage_complete);
      });
    } else {
      if (applicationData && applicationData.marketing_budget_allocated_for_listing) {
        setInitialValue({
          marketing_budget_allocated_for_listing:
            applicationData.marketing_budget_allocated_for_listing
        });
      }
    }
  }, [applicationData, applicationId, isUpdateExchange]);

  const handleSubmit = (values) => {
    let stepsRequired = Math.floor(StepsCalculator(1, completeStep, 57));
    if (
      values.marketing_budget_allocated_for_listing !==
      applicationData.marketing_budget_allocated_for_listing
    ) {
      const data = {
        marketing_budget_allocated_for_listing:
          values.marketing_budget_allocated_for_listing,
        percentage_complete: stepsRequired
      };
      setIsLoading(true);

      UpdateExchangeApplication({ application_id: applicationId }, data)
        .then((res) => {
          const application = get(res, 'data.data.data', '');
          setApplicationData(application);
          if (isUpdateExchange) {
            toast.success('Marketing Budget updated successfully', {
              style: { fontSize: '12px' }
            });
            GetApplicationDetailsEdit(applicationId);
          }
          nextPage();
          setIsLoading(false);
        })
        .catch((err) => {
          const errorMessage = get(err, 'data.error.message');
          toast.error(notificationMessageFunction(errorMessage), {
            style: { fontSize: '12px' }
          });
        });
      setExchangeCompleteStep(Array.from({ length: 13 }, (_, index) => index));
    } else {
      nextPage();
    }
  };
  return (
    <React.Fragment>
      <div onClick={isSectionCollapsed ? toggleSection : undefined}>
        {isUpdateExchange ? (
          <>
            <div
              className='cursor-pointer col-12 font-size-14 ml-2 d-flex justify-content-between align-items-center'
              onClick={toggleSection}>
              <span
                className='text-black font-weight-400'
                style={{ color: primaryTextState }}>
                Optimizing Listing Success: Allocating an Effective Exchange Marketing
                Budget
              </span>
              <i
                className={`font-size-22 mdi mdi-chevron-${
                  isSectionCollapsed === true ? 'up' : 'down'
                }`}
                style={{ color: primaryTextState }}
              />
            </div>
          </>
        ) : (
          <div className='col-12 font-size-16 pb-2 ml-2'>
            <span
              className='text-black font-weight-400'
              style={{ color: primaryTextState }}>
              Optimizing Listing Success: Allocating an Effective Exchange Marketing
              Budget
            </span>
          </div>
        )}
        <Collapse isOpen={!isSectionCollapsed}>
          {isLoading ? (
            <div className='mt-5'>
              <Loader />
            </div>
          ) : (
            <Container className='col-12 col-sm-auto pt-4'>
              <div>
                <Formik
                  initialValues={initialValue}
                  enableReinitialize={true}
                  validationSchema={CapMarketSchema}
                  onSubmit={handleSubmit}>
                  {({ errors, touched, values, setFieldValue, handleChange }) => (
                    <Form>
                      <div>
                        <Col>
                          <FormGroup className='mb-4'>
                            <div className='font-size-12'>
                              <Label
                                htmlFor='marketing_budget_allocated_for_listing'
                                className='text-black font-weight-normal'
                                style={{ color: primaryTextState }}>
                                {CONST.MARKETINGBUDGETALLOCATEDFORLISTING}
                              </Label>
                            </div>
                            <div>
                              <AuthInput
                                type='text'
                                className='font-size-12'
                                name='marketing_budget_allocated_for_listing'
                                placeholder={'Enter marketing budget'}
                                values={values}
                                handleChange={(e) => {
                                  if (e.target.value.endsWith('.')) {
                                    setFieldValue(
                                      'marketing_budget_allocated_for_listing',
                                      e.target.value
                                    );
                                    return;
                                  }

                                  const rawValue = e.target.value.replace(/[^0-9.]/g, '');

                                  const decimalValue =
                                    rawValue.split('.').length > 2
                                      ? rawValue.slice(0, rawValue.lastIndexOf('.'))
                                      : rawValue;

                                  const parts = decimalValue.split('.');
                                  const formattedValue =
                                    parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
                                    (parts[1] ? '.' + parts[1] : '');

                                  if (formattedValue) {
                                    setFieldValue(
                                      'marketing_budget_allocated_for_listing',
                                      '$' + formattedValue
                                    );
                                  } else {
                                    setFieldValue(
                                      'marketing_budget_allocated_for_listing',
                                      '$0'
                                    );
                                  }
                                }}
                                errors={errors}
                                touched={touched}></AuthInput>
                            </div>
                          </FormGroup>
                        </Col>
                      </div>

                      <div className='d-flex justify-content-between align-items-center mt-4 mr-2 ml-2'>
                        <i
                          className='mdi mdi-chevron-double-left font-size-32 ml-1 cursor-pointer'
                          onClick={prevPage}
                          style={{ color: primaryTextState }}
                        />
                        <div>
                          {(applicationStatus === 'INCOMPLETE' || !applicationStatus) && (
                            <button
                              className='btn btn-status-change text-white mr-2'
                              type='submit'
                              style={{ width: '142px', height: '50px' }}
                              onClick={() =>
                                handleSubmitLiteApplication({
                                  applicationId,
                                  setApplicationData,
                                  history,
                                  paramsValue,
                                  setIsLoading
                                })
                              }>
                              Submit
                            </button>
                          )}
                          <button
                            className='btn application-button text-white'
                            type='submit'
                            onSubmit={(values) => handleSubmit(values)}>
                            Next
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </Container>
          )}
        </Collapse>
      </div>
    </React.Fragment>
  );
}

export default Step12;
