export const constants = {
  ACCESSTOKEN: 'ACCESSTOKEN',
  REFERRED_USER_ID: 'REFERRED_USER_ID',
  EMAIL: 'EMAIL',
  ACCOUNT_TYPE: 'ACCOUNT_TYPE',
  OTP: 'OTP',
  WELCOME_RAMPNALYSIS: 'Welcome to Rampnalysis',
  ENTER_YOUR_EMAIL_PASSWORD:
    'Enter your email address and password to access your account.',
  FORGOT_PASSWORD: 'If you want to reset your password,',
  CREATE_AN_ACCOUNT: "Don't have an account?",
  ALREADY_HAS_AN_ACCOUNT: 'Already has an account?',
  DID_NOT_RECEIVE: "Didn't receive,",
  CLICK_HERE: 'Click here',
  RESEND_OTP: 'Resend OTP',
  EMAIL_ADDRESS: 'Email address',
  SELECT_ACCOUNT_TYPE: 'Select account type',
  NONE: 'None',
  ON_RAMP_USER: 'On Ramp User',
  BUSINESS_USER: 'Business User',
  EXCHANGE_USER: 'Exchange User',
  STANDARD_USER: 'Standard User',
  ON_RAMP_NAME: 'On ramp name',
  COMPANY_NAME: 'Company name',
  EXCHANGE_NAME: 'Exchange name',
  BRAND_NAME: 'Brand name',
  WEBSITE: 'Website',
  PRODUCT_ENABLED: 'Product Enabled',
  BUY_FEE_PERCENTAGE: 'Buy Fee Percentage',
  SELL_FEE_PERCENTAGE: 'Sell Fee Percentage',
  LOGO_URL: 'Logo URL (For co-branded Email)',
  BUY: 'Buy',
  SELL: 'Sell',
  DISABLE_EMAIL: 'Disable Email',
  PASSWORD: 'Password',
  CONFIRM_PASSWORD: 'Confirm password',
  VERIFICATION_REQUIRED: 'Verification Required',
  ENTER_EMAIL_ADDRESS: 'Enter Email Address',
  ENTER_OTP: 'Enter OTP',
  VERIFICATION_DESCRIPTION:
    "To Continue, complete this verification step. We've sent a One Time Password (OTP) to the email EMAIL_ADDRESS Please enter it below.",
  FORGET_PASSWORD_DESCRIPTION:
    'We will send you a reset password link to your email address.',
  CREATE_NEW_PASSWORD: 'Create New Password',
  CREATE_NEW_PASSWORD_DESCRIPTION: "We'll ask for this password whenever you sign in.",
  CRYPTO: 'Crypto',
  NETWORK: 'Network',
  PROVIDER: 'Provider',
  CRYPTO_NAME: 'Crypto Name',
  CRYPTO_SYMBOL: 'Symbol',
  BEST_CRYPTO_ASSETS: 'Best crypto assets',
  SUPPORTED_NETWORKS: 'Supported Networks',
  SUPPORTED_CRYPTO_ASSETS: 'Supported Crypto Assets',
  SUPPORTED_PAYMENT_OPTIONS: 'Supported Payment Options',
  SETTINGS: 'Settings',
  AVAILABLE_ON_RAMPS: 'Available Onramps',
  SETTINGS_PROFILE_DESCRIPTION:
    'Disable sending emails (our system will not send any email to your customer except the email verification)',
  ACCEPT_INVITATION: 'Accept Invitation',
  ROLE: 'ROLE'
};
