import React, { useEffect, useRef, useState } from 'react';
import { Col, Collapse, Container, FormGroup, Label } from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import get from 'lodash/get';
import { constants as CONST } from '../../../constants/application';
import documentUploadedIcon from '../../../assets/images/documentuploaded.png';
import documentUploadIcon from '../../../assets/images/documentupload.png';
import {
  GetApplication,
  UpdateApplication,
  UploadApplicationDocs
} from '../../../services/Application';
import { notificationMessageFunction } from '../../../constants/notificationConst';

function Step4({
  setOnrampCompleteStep,
  nextPage,
  applicationId,
  prevPage,
  isUpdateOnramp = false,
  isSectionCollapsed,
  toggleSection,
  applicationData,
  setApplicationData,
  primaryTextState
}) {
  const [initialValue, setInitialValue] = useState({
    regulatory_auth: '',
    AML_CTF_compliance: '',
    KYC_checks_on_users: '',
    users_screening: '',
    services_to_minors: '',
    corporate_registration_doc: '',
    terms_of_service_doc: '',
    privacy_policy_doc: '',
    AML_CTF_policy_doc: '',
    licence_doc: '',
    additional_docs: '',
    legal_opinion_doc: '',
    policy_procedures_doc: ''
  });

  useEffect(() => {
    if (applicationId !== '' && !isUpdateOnramp) {
      GetApplication({ application_id: applicationId }).then((res) => {
        const application = get(res, 'data.data.data[0]', '');
        setApplicationData(application);
        const {
          regulatory_authorisation_requirement,
          AML_CTF_compliance,
          KYC_checks_on_users,
          sanctions_screening_of_users,
          services_to_minors
        } = application;

        if (
          regulatory_authorisation_requirement !== undefined &&
          AML_CTF_compliance !== undefined &&
          KYC_checks_on_users !== undefined &&
          sanctions_screening_of_users !== undefined &&
          services_to_minors !== undefined
        ) {
          setInitialValue({
            regulatory_auth: regulatory_authorisation_requirement.toString(),
            users_screening:
              sanctions_screening_of_users !== null
                ? sanctions_screening_of_users.toString()
                : 'null',
            AML_CTF_compliance:
              AML_CTF_compliance !== null ? AML_CTF_compliance.toString() : 'null',
            KYC_checks_on_users:
              KYC_checks_on_users !== null ? KYC_checks_on_users.toString() : 'null',
            services_to_minors:
              services_to_minors !== null ? services_to_minors.toString() : 'null',
            corporate_registration_doc: application.corporate_registration_doc,
            terms_of_service_doc: application.terms_of_service_doc,
            privacy_policy_doc: application.privacy_policy_doc,
            AML_CTF_policy_doc: application.AML_CTF_policy_doc,
            licence_doc: application.licence_doc,

            additional_docs: application.additional_docs,
            legal_opinion_doc: application.legal_opinion_doc,
            policy_procedures_doc: application.policy_procedures_doc
          });
        }
      });
    } else {
      if (applicationData) {
        const {
          regulatory_authorisation_requirement,
          AML_CTF_compliance,
          KYC_checks_on_users,
          sanctions_screening_of_users,
          services_to_minors
        } = applicationData;

        if (
          regulatory_authorisation_requirement !== undefined &&
          AML_CTF_compliance !== undefined &&
          KYC_checks_on_users !== undefined &&
          sanctions_screening_of_users !== undefined &&
          services_to_minors !== undefined
        ) {
          setInitialValue({
            regulatory_auth: regulatory_authorisation_requirement.toString(),
            users_screening:
              sanctions_screening_of_users !== null
                ? sanctions_screening_of_users.toString()
                : 'null',
            AML_CTF_compliance:
              AML_CTF_compliance !== null ? AML_CTF_compliance.toString() : 'null',
            KYC_checks_on_users:
              KYC_checks_on_users !== null ? KYC_checks_on_users.toString() : 'null',
            services_to_minors:
              services_to_minors !== null ? services_to_minors.toString() : 'null',
            corporate_registration_doc: applicationData.corporate_registration_doc,
            terms_of_service_doc: applicationData.terms_of_service_doc,
            privacy_policy_doc: applicationData.privacy_policy_doc,
            AML_CTF_policy_doc: applicationData.AML_CTF_policy_doc,
            licence_doc: applicationData.licence_doc,
            additional_docs: applicationData.additional_docs,
            legal_opinion_doc: applicationData.legal_opinion_doc,
            policy_procedures_doc: applicationData.policy_procedures_doc
          });
        }
      }
    }
  }, [applicationData, applicationId, isUpdateOnramp]);

  const handleSubmit = (values) => {
    const data = {
      regulatory_authorisation_requirement: values.regulatory_auth,
      AML_CTF_compliance:
        values.AML_CTF_compliance !== 'null' ? values.AML_CTF_compliance : null,
      KYC_checks_on_users:
        values.KYC_checks_on_users !== 'null' ? values.KYC_checks_on_users : null,
      sanctions_screening_of_users:
        values.users_screening !== 'null' ? values.users_screening : null,
      services_to_minors:
        values.services_to_minors !== 'null' ? values.services_to_minors : null
      // corporate_registration_doc: values.corporate_registration_doc,
      // terms_of_service_doc: values.terms_of_service_doc,
      // privacy_policy_doc: values.privacy_policy_doc
      // AML_CTF_policy_doc: values.AML_CTF_compliance==="true" ? values.AML_CTF_policy_doc : '',
      // licence_doc: values.regulatory_auth ==="true"? values.licence_doc : ''
    };
    const payloadData = new FormData();
    Object.keys(values).forEach((key) => {
      if (key === 'additional_docs' && values.additional_docs[0]) {
        let docs = Array.from(values.additional_docs);
        for (let i = 0; i < docs.length; i++) {
          payloadData.append('additional_docs', docs[i][0]);
        }
      } else {
        payloadData.append(key, values[key]);
      }
    });
    UploadApplicationDocs({ application_id: applicationId }, payloadData)
      .then((res) => {
        // const application = get(res, 'data.data.data', '');
      })
      .catch((err) => {
        const errorMessage = get(err, 'data.error.message');
        toast.error(notificationMessageFunction(errorMessage), {
          style: { fontSize: '12px' }
        });
      });
    UpdateApplication(applicationId, data)
      .then((res) => {})
      .catch((err) => {
        const errorMessage = get(err, 'data.error.message');
        toast.error(notificationMessageFunction(errorMessage), {
          style: { fontSize: '12px' }
        });
      });
    setOnrampCompleteStep(Array.from({ length: 4 }, (_, index) => index));

    nextPage();
  };

  const integrationDetailSchema = Yup.object().shape({
    regulatory_auth: Yup.string().required('Select one option'),
    AML_CTF_compliance: Yup.string().required('Select one option'),
    KYC_checks_on_users: Yup.string().required('Select one option'),
    users_screening: Yup.string().required('Select one option'),
    services_to_minors: Yup.string().required('Select one option'),
    licence_doc: Yup.mixed().when('regulatory_auth', {
      is: (value) => value == 'true',
      then: Yup.mixed().required('License document is required')
    }),
    AML_CTF_policy_doc: Yup.mixed().when('AML_CTF_compliance', {
      is: (value) => value == 'true',
      then: Yup.mixed().required('AML / CTF Compliance is required')
    })
  });

  return (
    <React.Fragment>
      <div onClick={isSectionCollapsed ? toggleSection : undefined}>
        {isUpdateOnramp ? (
          <>
            <div
              className='cursor-pointer col-12 font-size-14 ml-2 d-flex justify-content-between align-items-center'
              onClick={toggleSection}>
              <span
                className='text-black font-weight-400'
                style={{ color: primaryTextState }}>
                Partner Entity Compliance and Documentation
              </span>
              <i
                className={`font-size-22 mdi mdi-chevron-${
                  isSectionCollapsed === true ? 'up' : 'down'
                }`}
                style={{ color: primaryTextState }}
              />
            </div>
          </>
        ) : (
          <div className='col-12 font-size-16 pb-2 ml-2'>
            <span
              className='text-black font-weight-400'
              style={{ color: primaryTextState }}>
              Partner Entity Compliance and Documentation
            </span>
          </div>
        )}
        <Collapse isOpen={!isSectionCollapsed}>
          <Container className='col-12 col-sm-auto pt-4'>
            <div>
              <Formik
                initialValues={initialValue}
                enableReinitialize={true}
                validationSchema={integrationDetailSchema}
                onSubmit={handleSubmit}>
                {({ errors, touched, values, handleChange, setFieldValue }) => (
                  <Form>
                    <Col>
                      <FormGroup className='mb-3'>
                        <div className='font-size-12'>
                          <Label
                            htmlFor='regulatory_auth'
                            className='text-black font-weight-normal'
                            style={{ color: primaryTextState }}>
                            Is the partner entity subject to any regulatory authorization
                            or licensing requirements?
                          </Label>
                        </div>
                        <div className='d-flex direction-row'>
                          <div className='d-flex align-items-center mr-4'>
                            <Label
                              className='mt-2 d-flex align-items-center font-size-12'
                              style={{ color: primaryTextState }}>
                              <Field
                                name='regulatory_auth'
                                type='radio'
                                value='true'
                                onClick={() => {
                                  setFieldValue('licence_doc', '');
                                }}
                                style={{
                                  height: '1.725rem',
                                  width: '1rem',
                                  marginRight: '5px'
                                }}
                              />
                              {CONST.YES}
                            </Label>
                          </div>
                          <div className='d-flex align-items-center  ml-4'>
                            <Label
                              className='ml-2 mt-2  d-flex align-items-center font-size-12'
                              style={{ color: primaryTextState }}>
                              <Field
                                name='regulatory_auth'
                                type='radio'
                                value='false'
                                onClick={() => {
                                  setFieldValue('licence_doc', '');
                                }}
                                style={{
                                  height: '1.725rem',
                                  width: '1rem',
                                  marginRight: '5px'
                                }}
                              />
                              {CONST.NO}
                            </Label>
                          </div>
                        </div>

                        {errors['regulatory_auth'] && touched['regulatory_auth'] && (
                          <span
                            style={{ fontSize: 12 }}
                            className='text-left text-danger'>
                            {errors['regulatory_auth']}
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup className='mb-3'>
                        <div className='font-size-12'>
                          <Label
                            htmlFor='AML_CTF_compliance'
                            className='text-black font-weight-normal'
                            style={{ color: primaryTextState }}>
                            Does the partner entity maintain an AML/CTF compliance
                            program?
                          </Label>
                        </div>
                        <div className='d-flex direction-row'>
                          <div className='d-flex align-items-center mr-4'>
                            <Label
                              className='mt-2  d-flex align-items-center font-size-12'
                              style={{ color: primaryTextState }}>
                              <Field
                                name='AML_CTF_compliance'
                                type='radio'
                                value='true'
                                onClick={() => {
                                  setFieldValue('AML_CTF_policy_doc', '');
                                }}
                                style={{
                                  height: '1.725rem',
                                  width: '1rem',
                                  marginRight: '5px'
                                }}
                              />
                              {CONST.YES}
                            </Label>
                          </div>
                          <div className='d-flex align-items-center ml-4 mr-4'>
                            <Label
                              className='ml-2 mt-2  d-flex align-items-center font-size-12'
                              style={{ color: primaryTextState }}>
                              <Field
                                name='AML_CTF_compliance'
                                type='radio'
                                value='false'
                                onClick={() => {
                                  setFieldValue('AML_CTF_policy_doc', '');
                                }}
                                style={{
                                  height: '1.725rem',
                                  width: '1rem',
                                  marginRight: '5px'
                                }}
                              />
                              {CONST.NO}
                            </Label>
                          </div>
                          <div className='d-flex align-items-center ml-4 mr-4'>
                            <Label
                              className='ml-2 mt-2  d-flex align-items-center font-size-12'
                              style={{ color: primaryTextState }}>
                              <Field
                                name='AML_CTF_compliance'
                                type='radio'
                                value='null'
                                style={{
                                  height: '1.725rem',
                                  width: '1rem',
                                  marginRight: '5px'
                                }}
                              />
                              {CONST.NA}
                            </Label>
                          </div>
                        </div>
                        {errors['AML_CTF_compliance'] &&
                          touched['AML_CTF_compliance'] && (
                            <span
                              style={{ fontSize: 12 }}
                              className='text-left text-danger'>
                              {errors['AML_CTF_compliance']}
                            </span>
                          )}
                      </FormGroup>
                    </Col>

                    <Col>
                      <FormGroup className='mb-3'>
                        <div className='font-size-12'>
                          <Label
                            htmlFor='KYC_checks_on_users'
                            className='text-black font-weight-normal'
                            style={{ color: primaryTextState }}>
                            Does the partner entity perform KYC checks on its users?
                          </Label>
                        </div>
                        <div className='d-flex direction-row'>
                          <div className='d-flex align-items-center mr-4'>
                            <Label
                              className='mt-2  d-flex align-items-center font-size-12'
                              style={{ color: primaryTextState }}>
                              <Field
                                name='KYC_checks_on_users'
                                type='radio'
                                value='true'
                                style={{
                                  height: '1.725rem',
                                  width: '1rem',
                                  marginRight: '5px'
                                }}
                              />
                              {CONST.YES}
                            </Label>
                          </div>
                          <div className='d-flex align-items-center ml-4 mr-4'>
                            <Label
                              className='ml-2 mt-2  d-flex align-items-center font-size-12'
                              style={{ color: primaryTextState }}>
                              <Field
                                name='KYC_checks_on_users'
                                type='radio'
                                value='false'
                                style={{
                                  height: '1.725rem',
                                  width: '1rem',
                                  marginRight: '5px'
                                }}
                              />
                              {CONST.NO}
                            </Label>
                          </div>
                          <div className='d-flex align-items-center ml-4 mr-4'>
                            <Label
                              className='ml-2 mt-2  d-flex align-items-center font-size-12'
                              style={{ color: primaryTextState }}>
                              <Field
                                name='KYC_checks_on_users'
                                type='radio'
                                value='null'
                                style={{
                                  height: '1.725rem',
                                  width: '1rem',
                                  marginRight: '5px'
                                }}
                              />
                              {CONST.NA}
                            </Label>
                          </div>
                        </div>
                        {errors['KYC_checks_on_users'] &&
                          touched['KYC_checks_on_users'] && (
                            <span
                              style={{ fontSize: 12 }}
                              className='text-left text-danger'>
                              {errors['KYC_checks_on_users']}
                            </span>
                          )}
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup className='mb-3'>
                        <div className='font-size-12'>
                          <Label
                            htmlFor='users_screening'
                            className='text-black font-weight-normal'
                            style={{ color: primaryTextState }}>
                            Does the partner entity perform sanctions screening of its
                            users?
                          </Label>
                        </div>
                        <div className='d-flex direction-row'>
                          <div className='d-flex align-items-center mr-4'>
                            <Label
                              className='mt-2  d-flex align-items-center font-size-12'
                              style={{ color: primaryTextState }}>
                              <Field
                                name='users_screening'
                                type='radio'
                                value='true'
                                style={{
                                  height: '1.725rem',
                                  width: '1rem',
                                  marginRight: '5px'
                                }}
                              />
                              {CONST.YES}
                            </Label>
                          </div>
                          <div className='d-flex align-items-center ml-4 mr-4'>
                            <Label
                              className='ml-2 mt-2  d-flex align-items-center font-size-12'
                              style={{ color: primaryTextState }}>
                              <Field
                                name='users_screening'
                                type='radio'
                                value='false'
                                style={{
                                  height: '1.725rem',
                                  width: '1rem',
                                  marginRight: '5px'
                                }}
                              />
                              {CONST.NO}
                            </Label>
                          </div>
                          <div className='d-flex align-items-center ml-4 mr-4'>
                            <Label
                              className='ml-2 mt-2  d-flex align-items-center font-size-12'
                              style={{ color: primaryTextState }}>
                              <Field
                                name='users_screening'
                                type='radio'
                                value='null'
                                style={{
                                  height: '1.725rem',
                                  width: '1rem',
                                  marginRight: '5px'
                                }}
                              />
                              {CONST.NA}
                            </Label>
                          </div>
                        </div>
                        {errors['users_screening'] && touched['users_screening'] && (
                          <span
                            style={{ fontSize: 12 }}
                            className='text-left text-danger'>
                            {errors['users_screening']}
                          </span>
                        )}
                      </FormGroup>
                    </Col>

                    <Col>
                      <FormGroup className='mb-3'>
                        <div className='font-size-12'>
                          <Label
                            htmlFor='services_to_minors'
                            className='text-black font-weight-normal'
                            style={{ color: primaryTextState }}>
                            Does the partner entity provide services to minors (under 18
                            years of age)?
                          </Label>
                        </div>
                        <div className='d-flex direction-row'>
                          <div className='d-flex align-items-center mr-4'>
                            <Label
                              className='mt-2 d-flex align-items-center font-size-12'
                              style={{ color: primaryTextState }}>
                              <Field
                                name='services_to_minors'
                                type='radio'
                                value='true'
                                style={{
                                  height: '1.725rem',
                                  width: '1rem',
                                  marginRight: '5px'
                                }}
                              />
                              {CONST.YES}
                            </Label>
                          </div>
                          <div className='d-flex align-items-center ml-4 mr-4'>
                            <Label
                              className='ml-2 mt-2  d-flex align-items-center font-size-12'
                              style={{ color: primaryTextState }}>
                              <Field
                                name='services_to_minors'
                                type='radio'
                                value='false'
                                style={{
                                  height: '1.725rem',
                                  width: '1rem',
                                  marginRight: '5px'
                                }}
                              />
                              {CONST.NO}
                            </Label>
                          </div>
                          <div className='d-flex align-items-center ml-4 mr-4'>
                            <Label
                              className='ml-2 mt-2  d-flex align-items-center font-size-12'
                              style={{ color: primaryTextState }}>
                              <Field
                                name='services_to_minors'
                                type='radio'
                                value='null'
                                style={{
                                  height: '1.725rem',
                                  width: '1rem',
                                  marginRight: '5px'
                                }}
                              />
                              {CONST.NA}
                            </Label>
                          </div>
                        </div>
                        {errors['services_to_minors'] &&
                          touched['services_to_minors'] && (
                            <span
                              style={{ fontSize: 12 }}
                              className='text-left text-danger'>
                              {errors['services_to_minors']}
                            </span>
                          )}
                      </FormGroup>
                    </Col>

                    <Col>
                      <FormGroup className='mb-2'>
                        <div className='font-size-12'>
                          <Label
                            htmlFor='description'
                            className='text-black font-weight-normal'
                            style={{ color: primaryTextState }}>
                            Required
                          </Label>
                        </div>
                        <CustomFileInput
                          labelName=''
                          fieldName='corporate_registration_doc'
                          errors={errors}
                          headerName={'Corporate Registration Documents'}
                          values={values}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          primaryTextState={primaryTextState}
                        />
                        <CustomFileInput
                          labelName=''
                          fieldName='terms_of_service_doc'
                          errors={errors}
                          headerName={'Terms of Service'}
                          values={values}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          primaryTextState={primaryTextState}
                        />
                        <CustomFileInput
                          labelName=''
                          fieldName='privacy_policy_doc'
                          errors={errors}
                          headerName={'Privacy Policy'}
                          values={values}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          primaryTextState={primaryTextState}
                        />
                        <div
                          className='d-flex justify-content-between'
                          style={{ flexDirection: 'column' }}>
                          {values.AML_CTF_compliance === 'true' && (
                            <CustomFileInput
                              labelName=''
                              fieldName='AML_CTF_policy_doc'
                              errors={errors}
                              headerName={'AML / CTF Compliance'}
                              values={values}
                              handleChange={handleChange}
                              setFieldValue={setFieldValue}
                              primaryTextState={primaryTextState}
                            />
                          )}
                          {values.regulatory_auth === 'true' && (
                            <CustomFileInput
                              labelName=''
                              fieldName='licence_doc'
                              errors={errors}
                              headerName={'Copy of Licenses'}
                              values={values}
                              handleChange={handleChange}
                              setFieldValue={setFieldValue}
                              primaryTextState={primaryTextState}
                            />
                          )}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup className='mb-3'>
                        <div className='font-size-12'>
                          <Label
                            htmlFor='description'
                            className='text-black font-weight-normal'
                            style={{ color: primaryTextState }}>
                            Optional Documents
                          </Label>
                        </div>
                        <CustomFileInput
                          labelName=''
                          fieldName='legal_opinion_doc'
                          errors={errors}
                          headerName={'Legal Opinion'}
                          values={values}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          primaryTextState={primaryTextState}
                        />
                        <CustomFileInput
                          labelName=''
                          fieldName='policy_procedures_doc'
                          errors={errors}
                          headerName={'Policies / Procedures'}
                          values={values}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          primaryTextState={primaryTextState}
                        />
                        {/* <CustomFileInput
                          labelName=''
                          fieldName='additional_docs'
                          errors={errors}
                          headerName={'Additional Documents'}
                          values={values}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                        /> */}
                      </FormGroup>
                    </Col>
                    <div className='d-flex justify-content-between align-items-center mt-5 mr-2 ml-2'>
                      <i
                        className='mdi mdi-chevron-double-left font-size-32 ml-1 cursor-pointer'
                        onClick={prevPage}
                        style={{ color: primaryTextState }}
                      />
                      <button
                        className='btn application-button text-white'
                        type='submit'
                        onSubmit={(values) => handleSubmit(values)}>
                        Next
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Container>
        </Collapse>
      </div>
    </React.Fragment>
  );
}

export default Step4;

const CustomFileInput = ({
  fieldName,
  headerName,
  values,
  setFieldValue,
  primaryTextState
}) => {
  const fileInputRef = useRef(null);

  const handleFileButtonClick = () => {
    fileInputRef.current.click();
  };
  return (
    <FormGroup className='mb-3'>
      <div className='file-upload d-flex direction-row justify-content-between align-items-center pl-1'>
        <Label
          htmlFor={values[fieldName]}
          className='mt-2'
          onClick={handleFileButtonClick}>
          <div className='cursor-pointer'>
            <span className='rounded-circle' style={{ color: '#134a87', fontSize: 12 }}>
              {values[fieldName] ? (
                <img src={documentUploadedIcon} alt={values[fieldName]} />
              ) : (
                <img src={documentUploadIcon} alt={values[fieldName]} />
              )}
            </span>
            <span className='font-size-12 ml-2' style={{ color: primaryTextState }}>
              {headerName}
            </span>
          </div>
        </Label>
        <div>
          {values[fieldName] !== null && typeof values[fieldName] === 'object' ? (
            <a
              href={URL.createObjectURL(values[fieldName])}
              target='_blank'
              rel='noopener noreferrer'
              className='pr-2 font-size-12 font-weight-bold text-wrap text-primary'
              style={{ color: primaryTextState }}>
              View File
            </a>
          ) : values[fieldName] !== null &&
            typeof values[fieldName] === 'string' &&
            values[fieldName].length > 0 ? (
            <a
              href={values[fieldName]}
              target='_blank'
              rel='noopener noreferrer'
              className='pr-2 font-size-12 font-weight-bold text-wrap text-primary'
              style={{ color: primaryTextState }}>
              View File
            </a>
          ) : (
            ''
          )}
        </div>
        <input
          type='file'
          name={fieldName}
          style={{ display: 'none' }}
          ref={fileInputRef}
          onChange={(event) => {
            setFieldValue(fieldName, event.currentTarget.files[0]);
          }}
        />

        <ReactTooltip
          anchorId={fieldName}
          place='bottom'
          content={values[fieldName] && values[fieldName].name}
        />
      </div>
    </FormGroup>
  );
};
