export const widgetParams = {
  API_KEY: 'api-key',
  ACCESS_TOKEN: 'accessToken',
  WIDGET_HEIGHT: 'widgetHeight',
  WIDGET_WIDTH: 'widgetWidth',
  WIDGET_THEME: 'widgetTheme',
  PRIMARY_COLOR: 'primaryColor',
  SECONDARY_COLOR: 'secondaryColor',
  PRIMARY_TEXT: 'primaryText',
  SECONDARY_TEXT: 'secondaryText',
  CONTAINER_COLOR: 'containerColor'
};

export const createParamsData = (paramsURL) => {
  let paramsValues = {};
  for (const [key, value] of Object.entries(widgetParams)) {
    if (new URLSearchParams(paramsURL).get([value])) {
      paramsValues = {
        ...paramsValues,
        [value]: new URLSearchParams(paramsURL).get([value])
      };
    }
  }

  return paramsValues;
};
