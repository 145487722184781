import React, { useState } from 'react';
import { get } from 'lodash';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { StringParam, useQueryParam } from 'use-query-params';
import DrawerPage from '../../DrawerPage';
import { GetApplicationResponse } from '../../../services/Application';
import { notificationMessageFunction } from '../../../constants/notificationConst';
import toast, { Toaster } from 'react-hot-toast';
import ApplicationDetails from './ApplicationDetails';
import ResponsePage from './ResponsePage';
import Notification from './Notification';
import StreamChatElem from './StreamChat';
import { useThemeContext } from '../../ThemeProvider';

function ViewExchnagesApplication(props) {
  const { paramsValue, containerColorState, primaryColorState, primaryTextState } =
    useThemeContext();

  const [page, setPage] = useState('response');
  const [first, setFirst] = useState(0);
  const [applicationIdData] = useQueryParam('application_id', StringParam);
  const [responsePage, setResponsePage] = useState(1);
  const [order, setOrder] = useState(null);
  const [allResponses, setAllResponses] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [applicationId, setApplicationId] = useState(applicationIdData || null);
  const [loading, setLoading] = useState(true);
  const [applicationAll, setApplicationAll] = useState({});
  const [currentPageData, setCurrentPageData] = useState([]);
  const [statusSuggestions, setStatusSuggestions] = useState([
    {
      id: 'REQUEST_ACTION',
      name: 'Action',
      icon: 'fas fa-unlock',
      color: 'black',
      checked: false
    },
    {
      id: 'ACCEPT',
      name: 'Accept',
      icon: 'fas fa-check-circle',
      color: 'green',
      checked: false
    },
    { id: 'KYB', name: 'KYB', icon: 'fas fa-lock', color: 'black', checked: false },
    {
      id: 'QUOTE',
      name: 'Quote',
      icon: 'fas fa-quote-left',
      color: 'black',
      checked: false
    },
    {
      id: 'PAID',
      name: 'Paid',
      icon: 'fas fa-thumbs-up',
      color: 'green',
      checked: false
    },
    {
      id: 'ADD_QUESTIONS',
      name: 'Add Question',
      icon: 'fas fa-question',
      color: 'black',
      checked: false
    }
  ]);

  const GetApplicationResponseFunc = (applicationId) => {
    const status = statusSuggestions
      .filter((suggestion) => suggestion.checked)
      .map((suggestion) => suggestion.id);
    setLoading(true);
    GetApplicationResponse({ applicationId, order, status })
      .then((res) => {
        setAllResponses(get(res, 'data.data.data', []));
        const slicedData = get(res, 'data.data.data', []).slice(first, first + 5);
        const count = get(res, 'data.data.count', 0);
        setLoading(false);
        setCurrentPageData(slicedData);
        setTotalCount(count);
      })
      .catch((err) => {
        const errorMessage = get(err, 'data.error.message');
        toast.error(notificationMessageFunction(errorMessage), {
          style: { fontSize: '12px' }
        });
      });
  };

  return (
    <React.Fragment>
      <div className='widget-container'>
        <div
          className='d-flex card py-2'
          style={{ backgroundColor: containerColorState }}>
          <span
            className='font-size-16 font-weight-medium d-flex justify-content-center align-items-center mt-1'
            style={{ color: primaryTextState }}>
            Exchange Application
          </span>
          <DrawerPage />
          <hr className='ml-2 mr-2 mt-2 mb-2' />
          <div style={{ maxHeight: '750px', overflowY: 'auto', overflowX: 'hidden' }}>
            <Nav tabs className='nav-tabs-custom nav-justified'>
              <NavItem>
                <NavLink
                  className='cursor-pointer'
                  onClick={() => setPage('application')}>
                  <span
                    className={`font-size-10 font-weight-medium`}
                    style={{ color: '#2770C6' }}>
                    <span
                      className={`${page === 'application' ? 'underline-heading' : ''}`}>
                      Application Page
                    </span>
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className='cursor-pointer' onClick={() => setPage('response')}>
                  <span
                    className='font-size-10 font-weight-medium'
                    style={{ color: '#2770C6' }}>
                    <span className={`${page === 'response' ? 'underline-heading' : ''}`}>
                      Response
                    </span>
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className='cursor-pointer'
                  onClick={() => setPage('notification')}>
                  <span
                    className='font-size-10 font-weight-medium'
                    style={{ color: '#2770C6' }}>
                    <span
                      className={`${page === 'notification' ? 'underline-heading' : ''}`}>
                      Notifications
                    </span>
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className='cursor-pointer' onClick={() => setPage('chat')}>
                  <span
                    className='font-size-10 font-weight-medium'
                    style={{ color: '#2770C6' }}>
                    <span className={`${page === 'chat' ? 'underline-heading' : ''}`}>
                      Chat
                    </span>{' '}
                  </span>
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={page}>
              <TabPane tabId='application'>
                <ApplicationDetails
                  props={props}
                  history={props.history}
                  setApplicationId={setApplicationId}
                  applicationId={applicationId}
                  setApplicationAll={setApplicationAll}
                  primaryTextState={primaryTextState}
                  primaryColorState={primaryColorState}
                />
              </TabPane>
              <TabPane tabId='response'>
                <ResponsePage
                  page={responsePage}
                  allResponses={allResponses}
                  setOrder={setOrder}
                  totalCount={totalCount}
                  first={first}
                  order={order}
                  loading={loading}
                  setPage={setResponsePage}
                  setResponsePage={setResponsePage}
                  setFirst={setFirst}
                  currentPageData={currentPageData}
                  setApplicationId={setApplicationId}
                  setStatusSuggestions={setStatusSuggestions}
                  GetApplicationResponseFunc={GetApplicationResponseFunc}
                  setCurrentPageData={setCurrentPageData}
                  statusSuggestions={statusSuggestions}
                  primaryTextState={primaryTextState}
                  containerColorState={containerColorState}
                  primaryColorState={primaryColorState}
                  paramsValue={paramsValue}
                />
              </TabPane>
              <TabPane tabId='notification'>
                <Notification
                  props={props}
                  page={page}
                  primaryTextState={primaryTextState}
                  primaryColorState={primaryColorState}
                />
              </TabPane>
              <TabPane tabId='chat'>
                <StreamChatElem
                  props={props}
                  page={page}
                  applicationAll={applicationAll}
                />
              </TabPane>
            </TabContent>
            <Toaster position='bottom-center' reverseOrder={false} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ViewExchnagesApplication;
