import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import { FormGroup, Label } from 'reactstrap';
import AuthInput from '../../../Custom/AuthInput';
import { GetApplication, UpdateExchangeApplication } from '../../../services/Application';
import Step1 from '../../ExchangeApplication/Steps/Step1';
import Step2 from '../../ExchangeApplication/Steps/Step2';
import Step3 from '../../ExchangeApplication/Steps/Step3';
import Step4 from '../../ExchangeApplication/Steps/Step4';
import Step5 from '../../ExchangeApplication/Steps/Step5';
import Step6 from '../../ExchangeApplication/Steps/Step6';
import Step7 from '../../ExchangeApplication/Steps/Step7';
import Step8 from '../../ExchangeApplication/Steps/Step8';
import Step9 from '../../ExchangeApplication/Steps/Step9';
import Step10 from '../../ExchangeApplication/Steps/Step10';
import Step11 from '../../ExchangeApplication/Steps/Step11';
import Step12 from '../../ExchangeApplication/Steps/Step12';
import Step13 from '../../ExchangeApplication/Steps/Step13';
import Step14 from '../../ExchangeApplication/Steps/Step14';
import Step15 from '../../ExchangeApplication/Steps/Step15';
import Step16 from '../../ExchangeApplication/Steps/Step16';
import Step17 from '../../ExchangeApplication/Steps/Step17';
import Step18 from '../../ExchangeApplication/Steps/Step18';
import Step19 from '../../ExchangeApplication/Steps/Step19';
import Step20 from '../../ExchangeApplication/Steps/Step20';
import Step21 from '../../ExchangeApplication/Steps/Step21';
import Step22 from '../../ExchangeApplication/Steps/Step22';
import Step23 from '../../ExchangeApplication/Steps/Step23';
import Step24 from '../../ExchangeApplication/Steps/Step24';
import Loader from '../../../constants/Loader';
import toast from 'react-hot-toast';
import { notificationMessageFunction } from '../../../constants/notificationConst';

function ApplicationDetails({
  applicationId,
  setApplicationId,
  primaryColorState,
  primaryTextState,
  setApplicationAll,
  history
}) {
  const [step, setStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [initialExchangeValue, setInitialExchangeValue] = useState({
    applicant_first_name: '',
    applicant_last_name: '',
    applicant_email: '',
    applicant_telegram: '',
    project_name: '',
    token_name: '',
    ticker_logo: '',
    project_website: '',
    project_description: '',
    team_members: [],
    twitter: '',
    discord: '',
    blockchain_network: '',
    token_address: '',
    number_active_users: 0,
    market_makers: [],
    marketing_partners: '',
    exchange_launch_marketing_campaign: '',
    marketing_budget_allocated_for_listing: '',
    whitepapers_doc: '',
    whitepapers_doc_url: '',
    pitch_decks_doc: '',
    pitch_decks_doc_url: '',
    listing_program_at_bybit: [],
    listing_date: '',
    listing_price: '',
    token_use_case: '',
    tokenomics_doc: null,
    token_distribution_doc: null,
    road_map: '',
    unique_selling_point: '',
    target_geo: []
  });
  const [exchangeCompleteStep, setExchangeCompleteStep] = useState([0]);
  const [applicationData, setApplicationData] = useState({});
  const [openedSection, setOpenedSection] = useState(null);

  const toggleSection = (index) => {
    if (openedSection === index) {
      setOpenedSection(null);
    } else {
      setOpenedSection(index);
    }
  };

  const prevPage = () => {
    if (step) {
      toggleSection(openedSection - 1);
      setOpenedSection(openedSection - 1);
      setStep(step - 1);
    }
  };
  const nextPage = () => {
    if (step < 27) {
      toggleSection(openedSection + 1);
      setOpenedSection(openedSection + 1);
      setStep(step + 1);
    }
  };

  const commonProps = {
    isLoading,
    setApplicationId,
    setIsLoading,
    setExchangeCompleteStep,
    applicationId,
    setApplicationData,
    prevPage,
    nextPage,
    CustomInput,
    applicationData,
    isUpdateExchange: true
  };

  const isApplicationComplete = () => {
    return (
      initialExchangeValue.applicant_first_name &&
      initialExchangeValue.applicant_last_name &&
      initialExchangeValue.applicant_email &&
      initialExchangeValue.project_name &&
      initialExchangeValue.token_name &&
      initialExchangeValue.ticker_logo &&
      initialExchangeValue.project_website &&
      initialExchangeValue.project_description &&
      initialExchangeValue.twitter !== '' &&
      initialExchangeValue.discord !== '' &&
      initialExchangeValue.blockchain_network &&
      initialExchangeValue.token_address &&
      Number(initialExchangeValue.number_active_users) > 0 &&
      initialExchangeValue.market_makers &&
      initialExchangeValue.market_makers.length > 0 &&
      initialExchangeValue.marketing_partners !== '' &&
      initialExchangeValue.exchange_launch_marketing_campaign !== '' &&
      initialExchangeValue.marketing_budget_allocated_for_listing !== '' &&
      (initialExchangeValue.whitepapers_doc ||
        initialExchangeValue.whitepapers_doc_url) &&
      (initialExchangeValue.pitch_decks_doc ||
        initialExchangeValue.pitch_decks_doc_url) &&
      initialExchangeValue.listing_program_at_bybit &&
      initialExchangeValue.listing_program_at_bybit.length > 0 &&
      initialExchangeValue.listing_date !== '' &&
      initialExchangeValue.listing_price !== '' &&
      initialExchangeValue.token_use_case !== '' &&
      initialExchangeValue.tokenomics_doc &&
      initialExchangeValue.token_distribution_doc &&
      initialExchangeValue.road_map !== '' &&
      initialExchangeValue.unique_selling_point !== '' &&
      initialExchangeValue.target_geo &&
      initialExchangeValue.target_geo.length > 0
    );
  };

  const isLiteApplicationComplete = () => {
    return (
      initialExchangeValue.applicant_first_name &&
      initialExchangeValue.applicant_last_name &&
      initialExchangeValue.applicant_email &&
      initialExchangeValue.project_name &&
      initialExchangeValue.token_name &&
      initialExchangeValue.ticker_logo &&
      initialExchangeValue.project_website &&
      initialExchangeValue.project_description &&
      initialExchangeValue.twitter !== '' &&
      initialExchangeValue.discord !== '' &&
      initialExchangeValue.blockchain_network &&
      initialExchangeValue.token_address &&
      Number(initialExchangeValue.number_active_users) > 0
    );
  };

  const handleApplicationComplete = () => {
    if (isApplicationComplete()) {
      let data = { application_status: 'COMPLETE' };

      UpdateExchangeApplication({ application_id: applicationId }, data)
        .then((res) => {
          const application = get(res, 'data.data.data', '');
          setApplicationData(application);
          toast.success('Application Updated successfully', {
            style: { fontSize: '12px' }
          });
          GetApplicationDetailsEdit(applicationId);
          setIsLoading(false);
        })
        .catch((err) => {
          const errorMessage = get(err, 'data.error.message');
          toast.error(notificationMessageFunction(errorMessage), {
            style: { fontSize: '12px' }
          });
        });
    } else if (isLiteApplicationComplete()) {
      let data = { application_status: 'LITE_COMPLETE' };

      UpdateExchangeApplication({ application_id: applicationId }, data)
        .then((res) => {
          const application = get(res, 'data.data.data', '');
          setApplicationData(application);
          toast.success('Application Updated successfully', {
            style: { fontSize: '12px' }
          });
          GetApplicationDetailsEdit(applicationId);
          setIsLoading(false);
        })
        .catch((err) => {
          const errorMessage = get(err, 'data.error.message');
          toast.error(notificationMessageFunction(errorMessage), {
            style: { fontSize: '12px' }
          });
        });
    } else {
      toast.error('Complete all the details first', { style: { fontSize: '12px' } });
    }
  };

  const GetApplicationDetailsEdit = (applicationId) => {
    GetApplication({ application_id: applicationId }).then((res) => {
      const application = get(res, 'data.data.data[0]', '');
      setApplicationAll(application);
      if (application.exchange_application) {
        setInitialExchangeValue({
          applicant_first_name: application.exchange_application.applicant_first_name,
          applicant_last_name: application.exchange_application.applicant_last_name,
          applicant_email: application.exchange_application.applicant_email,
          project_name: application.exchange_application.project_name,
          token_name: application.exchange_application.token_name,
          ticker_logo: application.exchange_application.ticker_logo,
          project_website: application.exchange_application.project_website,
          project_description: application.exchange_application.project_description,
          team_members: application.exchange_application.team_members,
          twitter:
            application.exchange_application.social_media &&
            application.exchange_application.social_media.length > 0
              ? application.exchange_application.social_media.filter(
                  (social) => social.name === 'twitter'
                )[0].account_id
              : '',
          discord:
            application.exchange_application.social_media &&
            application.exchange_application.social_media.length > 0
              ? application.exchange_application.social_media.filter(
                  (social) => social.name === 'discord'
                )[0].account_id
              : '',
          blockchain_network: application.exchange_application.blockchain_network,
          token_address: application.exchange_application.token_address,
          number_active_users: application.exchange_application.number_of_active_users,
          market_makers: application.exchange_application.market_makers,
          marketing_partners: application.exchange_application.marketing_partners,
          exchange_launch_marketing_campaign:
            application.exchange_application.exchange_launch_marketing_campaign,
          marketing_budget_allocated_for_listing:
            application.exchange_application.marketing_budget_allocated_for_listing,
          whitepapers_doc: application.exchange_application.whitepapers_doc,
          whitepapers_doc_url: application.exchange_application.whitepapers_doc_url,
          pitch_decks_doc: application.exchange_application.pitch_decks_doc,
          pitch_decks_doc_url: application.exchange_application.pitch_decks_doc_url,
          listing_program_at_bybit: application.exchange_application.listing_program,
          listing_date: application.exchange_application.listing_date,
          listing_price: application.exchange_application.listing_price,
          token_use_case: application.exchange_application.token_use_case,
          tokenomics_doc: application.exchange_application.tokenomics_doc,
          token_distribution_doc: application.exchange_application.token_distribution_doc,
          road_map: application.exchange_application.road_map,
          unique_selling_point: application.exchange_application.unique_selling_point,
          target_geo: application.exchange_application.target_geo,
          demo_link: application.exchange_application.demo_link
        });
        setApplicationData(application);
      }
    });
  };

  useEffect(() => {
    if (applicationId !== '') {
      GetApplicationDetailsEdit(applicationId);
    }
  }, [applicationId]);

  const renderStep = (stepNumber, StepComponent, valid = false) => {
    return (
      <div
        className={`py-2 ${
          valid ? 'exchange-card-border' : 'exchange-card-border-danger'
        }  ml-2 mr-2 mt-2`}
        style={{ background: primaryColorState }}>
        <StepComponent
          {...commonProps}
          history={history}
          toggleSection={() => toggleSection(stepNumber)}
          isSectionCollapsed={openedSection !== stepNumber}
          GetApplicationDetailsEdit={GetApplicationDetailsEdit}
          primaryTextState={primaryTextState}
        />
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className='mt-4'>
        {renderStep(
          0,
          Step1,
          initialExchangeValue.applicant_first_name &&
            initialExchangeValue.applicant_last_name &&
            initialExchangeValue.applicant_email
            ? true
            : false
        )}
        {renderStep(
          1,
          Step2,
          initialExchangeValue.project_name &&
            initialExchangeValue.token_name &&
            initialExchangeValue.ticker_logo &&
            initialExchangeValue.project_website &&
            initialExchangeValue.project_description
            ? true
            : false
        )}
        {renderStep(2, Step3, true)}
        {renderStep(
          3,
          Step4,
          initialExchangeValue.twitter !== '' && initialExchangeValue.discord !== ''
            ? true
            : false
        )}
        {renderStep(4, Step5, true)}
        {renderStep(
          5,
          Step6,
          initialExchangeValue.blockchain_network && initialExchangeValue.token_address
            ? true
            : false
        )}
        {renderStep(
          6,
          Step7,
          Number(initialExchangeValue.number_active_users) > 0 ? true : false
        )}
        <span className='d-flex justify-content-center align-items-center ml-3 mr-3'>
          <hr style={{ borderStyle: 'dashed', width: 'auto', flexGrow: 1 }} />
          <span className='ml-1 mr-1'>Lite Application Ready</span>
          <hr style={{ borderStyle: 'dashed', width: 'auto', flexGrow: 1 }} />
        </span>

        {renderStep(7, Step8, true)}
        {renderStep(8, Step9, true)}
        {renderStep(9, Step10, true)}
        {renderStep(10, Step11, true)}
        {renderStep(11, Step12, true)}
        {renderStep(12, Step13, true)}
        {renderStep(13, Step14, true)}
        {renderStep(14, Step15, true)}
        {renderStep(15, Step16, true)}
        {renderStep(16, Step17, true)}
        {renderStep(17, Step18, true)}
        {renderStep(18, Step19, true)}
        {renderStep(19, Step20, true)}
        {renderStep(20, Step21, true)}
        {renderStep(21, Step22, true)}
        {renderStep(22, Step23, true)}
        {renderStep(23, Step24, true)}
        <div className='d-flex justify-content-center align-items-center mt-5 mb-3'>
          <button
            onClick={handleApplicationComplete}
            className='btn application-button text-white'
            type='submit'>
            Complete
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ApplicationDetails;

const CustomInput = ({
  label,
  htmlFor,
  setFieldTouched,
  name,
  placeholder,
  values,
  handleChange,
  errors,
  touched,
  type = 'text',
  extraLabel,
  primaryTextState
}) => (
  <FormGroup className='mb-2'>
    <div className='font-size-12'>
      <Label
        htmlFor={htmlFor}
        className='text-black font-weight-normal'
        style={{ color: primaryTextState }}>
        {label} {extraLabel}
      </Label>
    </div>
    <div>
      <AuthInput
        setFieldTouched={setFieldTouched}
        withTouchError={true}
        type={type}
        name={name}
        className={'font-size-12 font-weight-400 input-height'}
        placeholder={placeholder}
        values={values}
        handleChange={handleChange}
        errors={errors}
        touched={touched}
      />
    </div>
  </FormGroup>
);
