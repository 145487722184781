import React, { useState } from 'react';
import { Button, FormGroup, Label } from 'reactstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { constants as BUTTON_CONST } from '../../constants/button';
import { constants as CONST } from '../../constants/constant';
import { constants as ROUTE_CONST } from '../../constants/routes';
import AuthInput from '../../Custom/AuthInput';
import { BusinessWidgetVerificationAPI } from '../../services/Auth';
import toast, { Toaster } from 'react-hot-toast';
import { notificationMessageFunction } from '../../constants/notificationConst';
import Cookies from 'js-cookie';
import DrawerPage from '../DrawerPage';
import { useThemeContext } from '../ThemeProvider';
import { createParamsData } from '../../constants/params';

function VerifyWithOTP(props) {
  const { containerColorState, primaryTextState } = useThemeContext();
  const params = window.location.search;
  const paramsValue = createParamsData(params);
  const [isWidgetLoading, setIsWidgetLoading] = useState(false);
  const VerificationSchema = Yup.object().shape({
    otp: Yup.string()
      .required('OTP is required')
      .length(6, 'OTP must be 6 characters long')
  });

  const handleSubmit = async (values) => {
    let data = {
      email: Cookies.get(CONST.EMAIL),
      otp: values.otp
    };
    setIsWidgetLoading(true);

    BusinessWidgetVerificationAPI(data)
      .then(async (res) => {
        if (
          res &&
          res.data &&
          res.data.data &&
          res.data.data.data &&
          res.data.data.data.accessToken
        ) {
          localStorage.setItem(CONST.ACCESSTOKEN, res.data.data.data.accessToken);
          Cookies.set(CONST.ACCESSTOKEN, res.data.data.data.accessToken);
          localStorage.setItem(CONST.ACCOUNT_TYPE, data.account_type);
          localStorage.setItem(CONST.EMAIL, data.email);
          localStorage.setItem(CONST.ROLE, data.role ? data.role : 'Admin');
        }
        const savedPath = localStorage.getItem('preAuthPath');
        if (savedPath) {
          localStorage.removeItem('preAuthPath');
          props.history.push(savedPath);
        } else {
          props.history.push(
            ROUTE_CONST.LANDING_PAGE +
              '?' +
              (paramsValue && paramsValue.widgetTheme
                ? `&widgetTheme=${paramsValue.widgetTheme}`
                : '') +
              (paramsValue && paramsValue.containerColor
                ? `&containerColor=${paramsValue.containerColor}`
                : '') +
              (paramsValue && paramsValue.primaryColor
                ? `&primaryColor=${paramsValue.primaryColor}`
                : '') +
              (paramsValue && paramsValue.primaryText
                ? `&primaryText=${paramsValue.primaryText}`
                : '') +
              (paramsValue && paramsValue.secondaryText
                ? `&secondaryText=${paramsValue.secondaryText}`
                : '')
          );
        }
        setIsWidgetLoading(false);
      })
      .catch((err) => {
        setIsWidgetLoading(false);
        const errorMessage = err?.data?.error?.message;
        toast.error(notificationMessageFunction(errorMessage), {
          style: { fontSize: '12px' }
        });
      });
  };

  return (
    <div className='widget-container'>
      <div className='d-flex card p-3' style={{ background: containerColorState }}>
        {isWidgetLoading ? (
          <div className='d-flex justify-content-center align-items-center h-87vh'>
            <div className='text-muted font-size-16'>
              <i className='fas fa-circle-notch fa-spin mr-2'></i>
              <span style={{ color: primaryTextState }}>Loading...</span>
            </div>
          </div>
        ) : (
          <>
            <DrawerPage />
            <span
              className='ml-2 mt-4 mr-2 font-size-18 d-flex align-items-center justify-content-center font-weight-bold mb-3'
              style={{ color: primaryTextState }}>
              Email Verification
            </span>
            <div className='text-left ml-2 mr-2'>
              <p className='text-muted font-size-12' style={{ color: primaryTextState }}>
                {CONST.VERIFICATION_DESCRIPTION.replace(
                  'EMAIL_ADDRESS',
                  Cookies.get(CONST.EMAIL)
                )}
              </p>
            </div>
            <Formik
              initialValues={{ otp: '' }}
              validationSchema={VerificationSchema}
              onSubmit={handleSubmit}>
              {({ values, errors, touched, handleChange }) => (
                <Form className='ml-2 mr-2'>
                  <div className='mb-3'>
                    <FormGroup>
                      <Label
                        htmlFor='email'
                        className='text-black font-size-12'
                        style={{ color: primaryTextState }}>
                        OTP
                      </Label>
                      <AuthInput
                        type='text'
                        values={values}
                        placeholder='Enter otp'
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                        name='otp'
                        className={`font-size-12 input-placeholder`}
                      />
                    </FormGroup>
                  </div>
                  <div className='mt-3 text-center w-100 waves-effect waves-light'>
                    <Button
                      type='submit'
                      name='btn'
                      className={`btn application-button text-white`}
                      onSubmit={(values) => handleSubmit(values)}>
                      {BUTTON_CONST.VERIFY_OTP}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </>
        )}
        <Toaster position='bottom-center' reverseOrder={false} />
      </div>
    </div>
  );
}

export default VerifyWithOTP;
