import React, { useEffect, useState } from 'react';
import { useQueryParam, StringParam } from 'use-query-params';
import DrawerPage from '../../DrawerPage';
import { GetApplication } from '../../../services/Application';
import EditOnrampApplication from './EditOnrampApplication';
import EditExchangeApplication from './EditExchangeApplication';
import { useHistory, useLocation } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { useThemeContext } from '../../ThemeProvider';

const CustomHeaders = ({ headerName, primaryTextState }) => {
  return (
    <div className='col-12 col-sm-auto font-size-14 pb-2 pb-sm-0 mt-2 ml-2'>
      <span className='text-black' style={{ color: primaryTextState }}>
        <u>{headerName}</u>
      </span>
    </div>
  );
};

function EditApplication(props) {
  const { containerColorState, primaryColorState, primaryTextState } = useThemeContext();

  const history = useHistory();
  const location = useLocation();
  const [applicationId] = useQueryParam('sub_application_id', StringParam);
  const [applicationTypeData, setApplicationTypeData] = useState({});
  const [completedfields, setCompletedFields] = useState([]);
  const [fields, setFields] = useState({});
  const [beneficialUsersData, setBeneficialUsersData] = useState();
  const [completedBeneficialUsersData, setCompletedBeneficialUsersData] = useState([]);

  const setFieldData = async (data) => {
    setFields(data['field']);
    setBeneficialUsersData(data['field'].beneficialUsers);
    setCompletedBeneficialUsersData(data.completed_beneficiary);
    setCompletedFields(data['completed_field'] ? data['completed_field'] : []);
  };

  const fetchApplicationData = (applicationId) => {
    GetApplication({ sub_application_id: applicationId })
      .then((res) => {
        setApplicationTypeData(res.data.data.data[0]);
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (applicationId !== '') {
      fetchApplicationData(applicationId);
    }
  }, [applicationId]);

  return (
    <React.Fragment>
      <div className='widget-container'>
        <div
          className='d-flex card py-2'
          style={{ backgroundColor: containerColorState }}>
          <span
            className='font-size-16 font-weight-medium d-flex justify-content-center align-items-center mt-1'
            style={{ color: primaryTextState }}>
            Edit Applications
          </span>
          <DrawerPage />

          <hr className='mr-2 ml-2 mt-2 mb-2' />

          {applicationTypeData.application_type !== 'EXCHANGE' ? (
            <div>
              <EditOnrampApplication
                fields={fields}
                history={history}
                setFieldData={setFieldData}
                CustomHeaders={CustomHeaders}
                completedfields={completedfields}
                beneficialUsersData={beneficialUsersData}
                completedBeneficialUsersData={completedBeneficialUsersData}
                primaryTextState={primaryTextState}
              />
            </div>
          ) : (
            <div className='mr-2 ml-2'>
              <EditExchangeApplication
                applicationTypeData={applicationTypeData}
                completedfields={completedfields}
                CustomHeaders={CustomHeaders}
                history={history}
                fields={fields}
                applicationId={applicationId}
                responseId={location.state}
                primaryTextState={primaryTextState}
              />
            </div>
          )}
          <Toaster position='bottom-center' reverseOrder={false} />
        </div>
      </div>
    </React.Fragment>
  );
}

export default EditApplication;
