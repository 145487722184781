import React, { useState } from 'react';
import { FormGroup, Label } from 'reactstrap';
import { Toaster } from 'react-hot-toast';
import AuthInput from '../../Custom/AuthInput';
import Step1 from './Steps/Step1';
import Step2 from './Steps/Step2';
import Step3 from './Steps/Step3';
import Step4 from './Steps/Step4';
import Step5 from './Steps/Step5';
import Step6 from './Steps/Step6';
import Step7 from './Steps/Step7';
import Step8 from './Steps/Step8';
import Step9 from './Steps/Step9';
import Step10 from './Steps/Step10';
import Step11 from './Steps/Step11';
import Step12 from './Steps/Step12';
import Step13 from './Steps/Step13';
import Step14 from './Steps/Step14';
import Step15 from './Steps/Step15';
import Step16 from './Steps/Step16';
import Step17 from './Steps/Step17';
import Step18 from './Steps/Step18';
import Step19 from './Steps/Step19';
import Step20 from './Steps/Step20';
import Step21 from './Steps/Step21';
import Step22 from './Steps/Step22';
import Step23 from './Steps/Step23';
import Step24 from './Steps/Step24';
import Congratulations from './Steps/Congratulations';
import HorizontalStepper from './Steps/HorizontalStepper';
import DrawerPage from '../DrawerPage';
import { useThemeContext } from '../ThemeProvider';
import LiteApplication from './Steps/LiteApplication';

function ExchangeApplication({ history }) {
  const {
    paramsValue,
    containerColorState,
    secondaryTextState,
    primaryTextState,
    primaryColorState
  } = useThemeContext();

  const [step, setStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [exchangeCompleteStep, setExchangeCompleteStep] = useState([0]);
  const [applicationId, setApplicationId] = useState('');
  const [applicationData, setApplicationData] = useState({});
  const [updateRound, setUpdateRound] = useState(false);

  const prevPage = () => {
    if (step) {
      setStep(step - 1);
    }
  };
  const nextPage = () => {
    if (step < 27) {
      setStep(step + 1);
    }
  };

  const currentComponent = () => {
    const commonProps = {
      setApplicationId,
      isLoading,
      setIsLoading,
      setExchangeCompleteStep,
      applicationId,
      setApplicationData,
      prevPage,
      nextPage,
      CustomInput,
      updateRound,
      setUpdateRound,
      applicationData,
      primaryTextState,
      paramsValue,
      primaryColorState,
      history
    };

    switch (step) {
      case 0:
        return <Step1 {...commonProps} />;
      case 1:
        return <Step2 {...commonProps} />;
      case 2:
        return <Step3 {...commonProps} />;
      case 3:
        return <Step4 {...commonProps} />;
      case 4:
        return <Step5 {...commonProps} />;
      case 5:
        return <Step6 {...commonProps} />;
      case 6:
        return <Step7 {...commonProps} />;
      case 7:
        return <LiteApplication {...commonProps} />;
      case 8:
        return <Step8 {...commonProps} />;
      case 9:
        return <Step9 {...commonProps} />;
      case 10:
        return <Step10 {...commonProps} />;
      case 11:
        return <Step11 {...commonProps} />;
      case 12:
        return <Step12 {...commonProps} />;
      case 13:
        return <Step13 {...commonProps} />;
      case 14:
        return <Step14 {...commonProps} />;
      case 15:
        return <Step15 {...commonProps} />;
      case 16:
        return <Step16 {...commonProps} />;
      case 17:
        return <Step17 {...commonProps} />;
      case 18:
        return <Step18 {...commonProps} />;
      case 19:
        return <Step19 {...commonProps} />;
      case 20:
        return <Step20 {...commonProps} />;
      case 21:
        return <Step21 {...commonProps} />;
      case 22:
        return <Step22 {...commonProps} />;
      case 23:
        return <Step23 {...commonProps} />;
      case 24:
        return <Step24 {...commonProps} />;
      case 25:
        return <Congratulations {...commonProps} />;
      default:
    }
  };

  return (
    <React.Fragment>
      <div className='widget-container'>
        <div
          className='d-flex card py-2'
          style={{ backgroundColor: containerColorState }}>
          {!updateRound && (
            <>
              <span
                className='font-size-16 font-weight-medium d-flex justify-content-center align-items-center mt-1'
                style={{ color: primaryTextState }}>
                Exchange Application
              </span>
              <DrawerPage />
              <div style={{ marginTop: '32px' }} className='ml-2 mr-2'>
                <HorizontalStepper
                  activeStep={step}
                  setActiveStep={setStep}
                  exchangeCompleteStep={exchangeCompleteStep}
                  primaryTextState={primaryTextState}
                  secondaryTextState={secondaryTextState}
                />
              </div>
              <hr className='ml-2 mr-2' />
            </>
          )}

          <div
            className='py-2'
            style={{ maxHeight: '750px', overflowY: 'auto', overflowX: 'hidden' }}>
            {currentComponent()}
          </div>
          <Toaster position='bottom-center' reverseOrder={false} />
        </div>
      </div>
    </React.Fragment>
  );
}

export default ExchangeApplication;

const CustomInput = ({
  label,
  htmlFor,
  setFieldTouched,
  name,
  placeholder,
  values,
  handleChange,
  errors,
  touched,
  type = 'text',
  extraLabel,
  primaryTextState
}) => (
  <FormGroup className='mb-2'>
    <div className='font-size-12'>
      <Label
        htmlFor={htmlFor}
        className='text-black font-weight-normal'
        style={{ color: primaryTextState }}>
        {label} {extraLabel}
      </Label>
    </div>
    <div>
      <AuthInput
        setFieldTouched={setFieldTouched}
        withTouchError={true}
        type={type}
        name={name}
        className={'font-size-12 font-weight-400 input-height'}
        placeholder={placeholder}
        values={values}
        handleChange={handleChange}
        errors={errors}
        touched={touched}
      />
    </div>
  </FormGroup>
);
