export const constants = {
  VALID_EMAIL: 'Enter valid email address',
  EMAIL_REQUIRED: 'Please enter email address',
  PASSWORD_REQUIRED: 'Please enter password',
  CONFIRM_PASSWORD_REQUIRED: 'Please enter confirm password',
  PASSWORD_LENGTH: 'Password must be at least 8 character',
  SPACE_NOT_ALLOWED: 'space is not allowed',
  SELECT_ACCOUNT_TYPE: 'Please select account type',
  ON_RAMP_NAME: 'Please enter on ramp name',
  PERMITTED_SERVICES: 'select atleast one Service',
  COMPANY_NAME: 'Please enter company name',
  EXCHANGE_NAME: 'Please enter exchange name',
  EXCHANGE_NAME_24HOUR: 'Please enter 24 hour trading volume',
  BRAND_NAME: 'Please enter brand name',
  WEBSITE: 'Please enter website url',
  INVALID_URL: 'Please Enter valid URL',
  LOGO_URL: 'Please enter logo url',
  OTP: 'Please enter OTP',
  PROJECT_NAME: 'Please enter project name',
  REGISTER_NUMBER: 'Please enter register number',
  BUSINESS_CATEGORY: 'Please enter business category',
  COUNTRY: 'Please enter country',
  DESCRIPTION: 'Please enter product description',
  NETWORK: 'Please enter Network',
  TOKEN_ADDRESS: 'Please enter Token Address',
  TICKER: 'Value id required',
  NAME: 'Ticker must be between 2 and 5 letters.',
  ICON: 'Invalid icon file. File must be of type PNG.Invalid icon file. File must be smaller than 2mb in size.Invalid icon file. File must be larger than 512px x 512px in dimensions.',
  TERMSOFSERVICEDOC: 'Terms of Service is required',
  PRIVACYPOLICYDOC: 'Privacy Policy is required',
  APPLICANT_FIRST_NAME: 'Please enter Applicant First Name',
  APPLICANT_LAST_NAME: 'Please enter Applicant Last Name',
  APPLICANT_EMAIL: 'Please enter Applicant Email',
  INVALID_EMAIL: 'Please enter valid Email',
  TOKEN_NAME: 'Please enter Token name',
  PROJECT_WEBSITE: 'Please enter Project Website',
  PROJECT_DESCRIPTION: 'Project description is required',
  VALID_PROJECT_DESCRIPTION: 'Project description must be within 1000 words',
  SELECT_CHECKBOX: 'Please select at least one listing program',
  LISTING_DATE_REQUIRED: 'Listing date is required',
  LISTING_PRICE_REQUIRED: 'Listing price is required',
  MIN_LISTING_PRICE_REQUIRED: 'Listing price must be at least 1',
  TOKEN_USE_CASE_REQUIRED: 'Description Required',
  TOKENOMICS_DOC: 'Please upload a PDF file',
  TOKEN_TICKER_DOC: 'Please upload a logo file',
  INVALID_FILE_TYPE: 'Invalid file format. Only PDF files are allowed.',
  ROADMAP_REQUIRED: 'Field is required',
  TARGETGEO_REQUIRED: 'Field is required',
  BLOCK_CHAIN_REQUIRED: 'Blockchain network is required',
  TICKER_LOGO_REQUIRED: 'Ticker Logo is required'
};
