import React, { useEffect, useRef, useState } from 'react';
import { Col, Collapse, Container, FormGroup, Label } from 'reactstrap';
import { Formik, Form, ErrorMessage } from 'formik';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import get from 'lodash/get';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { constants as CONST } from '../../../constants/application';
import { constants as ERROR_CONST } from '../../../constants/errorMessage';
import documentUploadIcon from '../../../assets/images/documentupload.png';
import documentUploadedIcon from '../../../assets/images/documentuploaded.png';
import { GetApplication, UpdateExchangeApplication } from '../../../services/Application';
import { notificationMessageFunction } from '../../../constants/notificationConst';
import { StepsCalculator } from './StepsCalculator';
import { UpdateMyProfileAPI } from '../../../services/Auth';
import Loader from '../../../constants/Loader';

function Step2({
  isLoading,
  setIsLoading,
  setExchangeCompleteStep,
  applicationId,
  setApplicationData,
  prevPage,
  nextPage,
  CustomInput,
  applicationData,
  isUpdateExchange = false,
  isSectionCollapsed,
  toggleSection,
  GetApplicationDetailsEdit,
  primaryTextState
}) {
  const [initialValue, setInitialValue] = useState({
    project_name: '',
    token_name: '',
    ticker_logo: '',
    project_website: '',
    project_description: ''
  });
  const [completeStep, setCompleteStep] = useState(0);

  const ProjectBackgroundSchema = Yup.object().shape({
    project_name: Yup.string().required(ERROR_CONST.PROJECT_NAME),
    token_name: Yup.string().required(ERROR_CONST.TOKEN_NAME),

    project_website: Yup.string()
      .url(ERROR_CONST.INVALID_URL)
      .required(ERROR_CONST.PROJECT_WEBSITE),

    project_description: Yup.string().required(ERROR_CONST.PROJECT_DESCRIPTION),
    ticker_logo: Yup.mixed().required(ERROR_CONST.TICKER_LOGO_REQUIRED)
  });

  useEffect(() => {
    if (applicationId !== '' && !isUpdateExchange) {
      GetApplication({ application_id: applicationId }).then((res) => {
        const application = get(res, 'data.data.data[0]', '');
        setInitialValue({
          project_name: application.exchange_application.project_name,
          token_name: application.exchange_application.token_name,
          project_website: application.exchange_application.project_website,
          project_description: application.exchange_application.project_description,
          ticker_logo: application.exchange_application.ticker_logo
        });

        setCompleteStep(application.exchange_application.percentage_complete);
      });
    } else {
      if (applicationData && applicationData.exchange_application) {
        setInitialValue({
          project_name: applicationData.exchange_application.project_name,
          token_name: applicationData.exchange_application.token_name,
          project_website: applicationData.exchange_application.project_website,
          project_description: applicationData.exchange_application.project_description,
          ticker_logo: applicationData.exchange_application.ticker_logo
        });
      }
    }
  }, [applicationData, applicationId, isUpdateExchange]);

  const handleSubmit = (values, { setFieldError }) => {
    const payloadData = new FormData();
    payloadData.append('project_name', values.project_name);
    payloadData.append('token_name', values.token_name);
    payloadData.append('project_website', values.project_website);
    payloadData.append('project_description', values.project_description);
    if (
      values.ticker_logo &&
      typeof values.ticker_logo !== 'string' &&
      values.ticker_logo.size / 1024 > 50000
    ) {
      setFieldError('ticker_logo', 'File size should be less than 50 MB');
      return;
    }
    payloadData.append('ticker_logo', values.ticker_logo);
    completeFormSubmission(payloadData, values);
  };

  const completeFormSubmission = (payloadData, values) => {
    let stepsRequired = Math.floor(StepsCalculator(5, completeStep, 28));
    payloadData.append('percentage_complete', stepsRequired);
    JSON.stringify(payloadData);
    setIsLoading(true);

    if (applicationId !== '') {
      UpdateExchangeApplication({ application_id: applicationId }, payloadData)
        .then((res) => {
          updateProfile(values.project_name);
          const application = get(res, 'data.data.data', '');
          setApplicationData(application);
          if (isUpdateExchange) {
            toast.success('Project Background updated successfully', {
              style: { fontSize: '12px' }
            });
            GetApplicationDetailsEdit(applicationId);
          }
          nextPage();
          setIsLoading(false);
        })
        .catch((err) => {
          const errorMessage = get(err, 'data.error.message');
          toast.error(notificationMessageFunction(errorMessage), {
            style: { fontSize: '12px' }
          });
        });
    }
    setExchangeCompleteStep(Array.from({ length: 2 }, (_, index) => index));
  };

  const updateProfile = (company_name) => {
    const payload = { company_name: company_name };

    UpdateMyProfileAPI(payload)
      .then((res) => {})
      .catch((err) => {});
  };

  const fileInputRef = useRef(null);

  const handleFileButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <React.Fragment>
      <div onClick={isSectionCollapsed ? toggleSection : undefined}>
        {isUpdateExchange ? (
          <>
            <div
              className='cursor-pointer col-12 font-size-14 ml-2 d-flex justify-content-between align-items-center'
              onClick={toggleSection}>
              <span
                className='text-black font-weight-400'
                style={{ color: primaryTextState }}>
                Project Background
              </span>
              <i
                className={`font-size-22 mdi mdi-chevron-${
                  isSectionCollapsed === true ? 'up' : 'down'
                }`}
                style={{ color: primaryTextState }}
              />
            </div>
          </>
        ) : (
          <div className='col-12 font-size-16 pb-2 ml-2'>
            <span
              className='text-black font-weight-400'
              style={{ color: primaryTextState }}>
              Project Background
            </span>
          </div>
        )}
        <Collapse isOpen={!isSectionCollapsed}>
          {isLoading ? (
            <div className='mt-5'>
              <Loader />
            </div>
          ) : (
            <Container className='col-12 col-sm-auto pt-3'>
              <div>
                <Formik
                  enableReinitialize={true}
                  initialValues={initialValue}
                  validationSchema={ProjectBackgroundSchema}
                  onSubmit={handleSubmit}>
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form>
                      <Col>
                        <CustomInput
                          label={CONST.PROJECT_NAME}
                          htmlFor='project_name'
                          name='project_name'
                          placeholder={CONST.ENTER_PROJECT_NAME}
                          values={values}
                          handleChange={handleChange}
                          errors={errors}
                          touched={touched}
                          primaryTextState={primaryTextState}
                        />
                      </Col>
                      <Col>
                        <CustomInput
                          label={CONST.TOKEN_NAME}
                          htmlFor='token_name'
                          name='token_name'
                          placeholder={CONST.ENTER_TOKEN_NAME}
                          values={values}
                          handleChange={handleChange}
                          errors={errors}
                          touched={touched}
                          primaryTextState={primaryTextState}
                        />
                      </Col>

                      <Col>
                        <CustomInput
                          label={CONST.PROJECT_WEBSITE}
                          htmlFor='project_website'
                          name='project_website'
                          placeholder={CONST.ENTER_URL_PLACEHOLDER}
                          values={values}
                          handleChange={handleChange}
                          errors={errors}
                          touched={touched}
                          primaryTextState={primaryTextState}
                        />
                      </Col>

                      <Col>
                        <CustomInput
                          label={CONST.PROJECT_DESCRIPTION}
                          htmlFor='project_description'
                          name='project_description'
                          placeholder={CONST.ENTER_DESCRIPTION}
                          values={values}
                          handleChange={handleChange}
                          errors={errors}
                          touched={touched}
                          type='textarea'
                          primaryTextState={primaryTextState}
                        />
                      </Col>

                      <Col>
                        <FileUploadFunction
                          handleFileButtonClick={handleFileButtonClick}
                          values={values}
                          fileInputRef={fileInputRef}
                          setFieldValue={setFieldValue}
                          primaryTextState={primaryTextState}
                        />
                      </Col>

                      <div className='d-flex justify-content-between align-items-center mt-5 mr-2 ml-2'>
                        <i
                          className='mdi mdi-chevron-double-left font-size-32 ml-1 cursor-pointer'
                          onClick={prevPage}
                          style={{ color: primaryTextState }}
                        />
                        <button
                          className='btn application-button text-white'
                          type='submit'
                          onSubmit={(values) => handleSubmit(values)}>
                          Next
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </Container>
          )}
        </Collapse>
      </div>
    </React.Fragment>
  );
}

export default Step2;

export const FileUploadFunction = ({
  handleFileButtonClick,
  values,
  fileInputRef,
  setFieldValue,
  primaryTextState
}) => {
  return (
    <FormGroup className='mb-3'>
      <div className='font-size-12'>
        <Label
          htmlFor='ticker_logo'
          className='text-black font-weight-normal'
          style={{ color: primaryTextState }}>
          Ticker Logo
        </Label>
      </div>
      <div className='file-upload d-flex direction-row justify-content-between align-items-center pl-1'>
        <Label htmlFor='ticker_logo' className='mt-2' onClick={handleFileButtonClick}>
          <div className='cursor-pointer'>
            <span className='rounded-circle' style={{ color: '#134a87', fontSize: 12 }}>
              {values.ticker_logo ? (
                <img src={documentUploadedIcon} alt='ticker_logo' />
              ) : (
                <img src={documentUploadIcon} alt='ticker_logo' />
              )}
            </span>
            <span className='font-size-12 ml-2' style={{ color: primaryTextState }}>
              {CONST.ICON}
            </span>
          </div>
        </Label>
        <div>
          {values.ticker_logo !== null && typeof values.ticker_logo === 'object' ? (
            <a
              href={URL.createObjectURL(values.ticker_logo)}
              target='_blank'
              rel='noopener noreferrer'
              className='pr-2 font-size-12 font-weight-bold text-wrap text-primary'>
              View File
            </a>
          ) : values.ticker_logo !== null &&
            typeof values.ticker_logo === 'string' &&
            values.ticker_logo.length > 0 ? (
            <a
              href={values.ticker_logo}
              target='_blank'
              rel='noopener noreferrer'
              className='pr-2 font-size-12 font-weight-bold text-wrap text-primary'>
              View File
            </a>
          ) : (
            ''
          )}
        </div>
        <input
          type='file'
          name='ticker_logo'
          style={{ display: 'none' }}
          ref={fileInputRef}
          onChange={(event) => {
            setFieldValue('ticker_logo', event.currentTarget.files[0]);
          }}
        />

        <ReactTooltip
          anchorId='ticker_logo'
          place='bottom'
          content={values.ticker_logo && values.ticker_logo.name}
        />
      </div>
      <ErrorMessage name='ticker_logo'>
        {(msg) => (
          <div style={{ fontSize: 12 }} className='text-left mt-1 text-danger'>
            {msg}
          </div>
        )}
      </ErrorMessage>
    </FormGroup>
  );
};
