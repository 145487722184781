export const constants = {
  LANDING_PAGE: '/landing-page',
  LITE_COMPLETE: '/lite-completed',
  LOGIN: '/login',
  VERIFY_EMAIL: '/verify-email',
  ONRAMP_APPLICATION: '/onramp-application',
  VIEW_APPLICATIONS: '/view-applications',
  EXCHANGE_APPLICATION: '/exchange-application',
  VIEW_EXCHANGE_APPLICATION: '/view-exchange-application',
  VIEW_ONRAMP_APPLICATION: '/view-onramp-application',
  EDIT_SUBAPPLICATION: '/sub-application/edit'
};
