import { Redirect } from 'react-router-dom';
import { constants as ROUTE_CONST } from '../constants/routes';
// Authentication related pages

import LandingPage from '../pages/LandingPage';
import Login from '../pages/AuthFlow/Login';
import VerifyWithOTP from '../pages/AuthFlow/VerifyWithOTP';
import ExchangeApplication from '../pages/ExchangeApplication';
import OnrampApplication from '../pages/OnrampApplication';
import ViewApplications from '../pages/ViewApplications';
import ViewExchnagesApplication from '../pages/ViewApplications/ExchangeApplication';
import ViewOnrampApplication from '../pages/ViewApplications/OnrampApplication';
import EditApplication from '../pages/ViewApplications/Requests/EditApplication';
import LiteComplete from '../pages/AuthFlow/LiteComplete';

// import Error404 from "../pages/Utility/Error404";
// import Error500 from "../pages/Utility/Error500";

const authProtectedRoutes = [
  { path: ROUTE_CONST.LANDING_PAGE, component: LandingPage },
  { path: ROUTE_CONST.EXCHANGE_APPLICATION, component: ExchangeApplication },
  { path: ROUTE_CONST.VIEW_APPLICATIONS, component: ViewApplications },
  { path: ROUTE_CONST.ONRAMP_APPLICATION, component: OnrampApplication },
  { path: ROUTE_CONST.VIEW_EXCHANGE_APPLICATION, component: ViewExchnagesApplication },
  { path: ROUTE_CONST.VIEW_ONRAMP_APPLICATION, component: ViewOnrampApplication },
  { path: ROUTE_CONST.EDIT_SUBAPPLICATION, component: EditApplication },
  {
    path: '/',
    exact: true,
    component: () => <Redirect to={ROUTE_CONST.LANDING_PAGE} />
  }
  // this route should be at the end of all other routes
];

const publicRoutes = [
  { path: ROUTE_CONST.LITE_COMPLETE, component: LiteComplete },
  { path: ROUTE_CONST.LOGIN, component: Login },
  { path: ROUTE_CONST.LANDING_PAGE, component: LandingPage },
  { path: ROUTE_CONST.VERIFY_EMAIL, component: VerifyWithOTP }
];

export { authProtectedRoutes, publicRoutes };
