import React, { useEffect, useState } from 'react';
import { Col, Collapse, Container, FormGroup, Label } from 'reactstrap';
import { Formik, Form } from 'formik';
import get from 'lodash/get';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { GetApplication, UpdateExchangeApplication } from '../../../services/Application';
import { StepsCalculator } from './StepsCalculator';
import { notificationMessageFunction } from '../../../constants/notificationConst';
import { constants as CONST } from '../../../constants/application';
import { constants as ERROR_CONST } from '../../../constants/errorMessage';
import AuthInput from '../../../Custom/AuthInput';
import Loader from '../../../constants/Loader';

function Step7({
  isLoading,
  setIsLoading,
  setExchangeCompleteStep,
  applicationId,
  nextPage,
  setApplicationData,
  prevPage,
  applicationData,
  isUpdateExchange = false,
  isSectionCollapsed,
  toggleSection,
  GetApplicationDetailsEdit,
  primaryTextState
}) {
  applicationData = applicationData.exchange_application;

  const CapMarketSchema = Yup.object().shape({
    number_active_users: Yup.string().required(ERROR_CONST.ROADMAP_REQUIRED)
  });
  const [initialValue, setInitialValue] = useState({
    number_active_users: ''
  });
  const [completeStep, setCompleteStep] = useState(0);

  useEffect(() => {
    if (applicationId !== '' && !isUpdateExchange) {
      GetApplication({ application_id: applicationId }).then((res) => {
        const application = get(res, 'data.data.data[0]', '');
        if (
          application.exchange_application &&
          application.exchange_application.number_of_active_users
        ) {
          setInitialValue({
            number_active_users: Number(
              application.exchange_application.number_of_active_users +
                ''.replace(/[^0-9]/g, '')
            ).toLocaleString()
          });
        }
        setCompleteStep(application.exchange_application.percentage_complete);
      });
    } else {
      if (applicationData && applicationData.number_of_active_users) {
        setInitialValue({
          number_active_users: Number(
            applicationData.number_of_active_users + ''.replace(/[^0-9]/g, '')
          ).toLocaleString()
        });
      }
    }
  }, [applicationData, applicationId, isUpdateExchange]);

  function convertToNumber(str) {
    const sanitizedString = str.replace(/[,]/g, ''); // Remove $ and commas
    return parseInt(sanitizedString, 10); // Convert to integer
  }

  const handleSubmit = (values) => {
    let stepsRequired = Math.floor(StepsCalculator(1, completeStep, 43));
    if (values.number_active_users !== applicationData.number_of_active_users) {
      const data = {
        number_of_active_users: convertToNumber(values.number_active_users),
        percentage_complete: stepsRequired
      };
      setIsLoading(true);

      UpdateExchangeApplication({ application_id: applicationId }, data)
        .then((res) => {
          const application = get(res, 'data.data.data', '');
          setApplicationData(application);
          if (isUpdateExchange) {
            toast.success('Number of active users updated successfully', {
              style: { fontSize: '12px' }
            });
            GetApplicationDetailsEdit(applicationId);
          }
          nextPage();
          setIsLoading(false);
        })
        .catch((err) => {
          const errorMessage = get(err, 'data.error.message');
          toast.error(notificationMessageFunction(errorMessage), {
            style: { fontSize: '12px' }
          });
        });
      setExchangeCompleteStep(Array.from({ length: 7 }, (_, index) => index));
    } else {
    }
    nextPage();
  };

  return (
    <React.Fragment>
      <div onClick={isSectionCollapsed ? toggleSection : undefined}>
        {isUpdateExchange ? (
          <>
            <div
              className='cursor-pointer col-12 font-size-14 ml-2 d-flex justify-content-between align-items-center'
              onClick={toggleSection}>
              <span
                className='text-black font-weight-400'
                style={{ color: primaryTextState }}>
                Number of active users in the product
              </span>
              <i
                className={`font-size-22 mdi mdi-chevron-${
                  isSectionCollapsed === true ? 'up' : 'down'
                }`}
                style={{ color: primaryTextState }}
              />
            </div>
          </>
        ) : (
          <div className='col-12 font-size-16 pb-2 ml-2'>
            <span
              className='text-black font-weight-400'
              style={{ color: primaryTextState }}>
              Number of active users in the product
            </span>
          </div>
        )}
        <Collapse isOpen={!isSectionCollapsed}>
          {isLoading ? (
            <div className='mt-5'>
              <Loader />
            </div>
          ) : (
            <Container className='col-12 col-sm-auto pt-3'>
              <div>
                <Formik
                  initialValues={initialValue}
                  enableReinitialize={true}
                  validationSchema={CapMarketSchema}
                  onSubmit={handleSubmit}>
                  {({ errors, touched, values, setErrors, setFieldValue }) => (
                    <Form>
                      <div>
                        <Col>
                          <FormGroup className='mb-4'>
                            <div className='font-size-12'>
                              <Label
                                htmlFor='number_active_users'
                                className='text-black font-weight-normal'
                                style={{ color: primaryTextState }}>
                                {CONST.NUMBEROFACTIVEUSERS}
                              </Label>
                            </div>
                            <div>
                              <AuthInput
                                type='text'
                                name='number_active_users'
                                className='font-size-12'
                                values={values}
                                placeholder={CONST.ENTER_NUMBER_OF_ACTIVE_USERS}
                                handleChange={(e) => {
                                  setFieldValue(
                                    'number_active_users',
                                    Number(
                                      e.target.value.replace(/[^0-9]/g, '')
                                    ).toLocaleString()
                                  );
                                }}
                                errors={errors}
                                touched={touched}></AuthInput>
                            </div>
                          </FormGroup>
                        </Col>
                      </div>

                      <div className='d-flex justify-content-between align-items-center mt-4 mr-2 ml-2'>
                        <i
                          className='mdi mdi-chevron-double-left font-size-32 ml-1 cursor-pointer'
                          onClick={prevPage}
                          style={{ color: primaryTextState }}
                        />
                        <button
                          className='btn application-button text-white'
                          type='submit'
                          onSubmit={(values) => handleSubmit(values)}>
                          Next
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </Container>
          )}
        </Collapse>
      </div>
    </React.Fragment>
  );
}

export default Step7;
