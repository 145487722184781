import React, { useState } from 'react';
import { Button, FormGroup, Label } from 'reactstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { constants as BUTTON_CONST } from '../../constants/button';
import { constants as CONST } from '../../constants/constant';
import { constants as ROUTE_CONST } from '../../constants/routes';
import AuthInput from '../../Custom/AuthInput';
import { BusinessWidgetLoginAPI } from '../../services/Auth';
import toast, { Toaster } from 'react-hot-toast';
import { notificationMessageFunction } from '../../constants/notificationConst';
import Cookies from 'js-cookie';
import DrawerPage from '../DrawerPage';
import { useThemeContext } from '../ThemeProvider';
import { createParamsData } from '../../constants/params';

function Login(props) {
  const { containerColorState, primaryTextState } = useThemeContext();
  const params = window.location.search;
  const paramsValue = createParamsData(params);
  const SignupSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email address').required('Email is required')
  });
  const [isWidgetLoading, setIsWidgetLoading] = useState(false);

  const handleSubmit = async (values) => {
    let data = {
      email: values.email,
      account_type: 2
    };
    BusinessWidgetLoginAPI(data)
      .then(async (res) => {
        Cookies.set(CONST.EMAIL, values.email);
        localStorage.setItem(CONST.EMAIL, values.email);
        const savedPath = localStorage.getItem('preAuthPath');
        props.history.push(
          ROUTE_CONST.VERIFY_EMAIL +
            '?' +
            (paramsValue && paramsValue.widgetTheme
              ? `&widgetTheme=${paramsValue.widgetTheme}`
              : '') +
            (paramsValue && paramsValue.containerColor
              ? `&containerColor=${paramsValue.containerColor}`
              : '') +
            (paramsValue && paramsValue.primaryColor
              ? `&primaryColor=${paramsValue.primaryColor}`
              : '') +
            (paramsValue && paramsValue.primaryText
              ? `&primaryText=${paramsValue.primaryText}`
              : '') +
            (paramsValue && paramsValue.secondaryText
              ? `&secondaryText=${paramsValue.secondaryText}`
              : '')
        );
        setIsWidgetLoading(true);
      })
      .catch((err) => {
        setIsWidgetLoading(false);
        const errorMessage = err?.data?.error?.message;
        toast.error(notificationMessageFunction(errorMessage), {
          style: { fontSize: '12px' }
        });
      });
  };

  return (
    <div className='widget-container'>
      {isWidgetLoading ? (
        <div className='d-flex justify-content-center align-items-center h-87vh'>
          <div className='text-muted font-size-16'>
            <i className='fas fa-circle-notch fa-spin mr-2'></i>
            <span style={{ color: primaryTextState }}>Loading...</span>
          </div>
        </div>
      ) : (
        <div className='d-flex card p-3' style={{ background: containerColorState }}>
          <DrawerPage />
          <span
            className='ml-2 mt-4 mr-2 font-size-18 d-flex align-items-center justify-content-center font-weight-bold mb-3'
            style={{ color: primaryTextState }}>
            Listus Login
          </span>
          <Formik
            initialValues={{ email: '' }}
            validationSchema={SignupSchema}
            onSubmit={handleSubmit}>
            {({ values, errors, touched, handleChange }) => (
              <Form className='ml-2 mr-2'>
                <div className='mb-3'>
                  <FormGroup>
                    <Label
                      htmlFor='email'
                      className='text-black font-size-12'
                      style={{ color: primaryTextState }}>
                      Email Address
                    </Label>
                    <AuthInput
                      type='text'
                      values={values}
                      placeholder='Enter email address'
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                      name='email'
                      className={`font-size-12 input-placeholder`}
                    />
                  </FormGroup>
                </div>
                <div className='mt-3 text-center w-100 waves-effect waves-light'>
                  <Button
                    type='submit'
                    name='btn'
                    className={`btn application-button text-white`}
                    onSubmit={(values) => handleSubmit(values)}>
                    {BUTTON_CONST.SIGN_IN}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
      <Toaster position='bottom-center' reverseOrder={false} />
    </div>
  );
}

export default Login;
