import React, { useEffect, useState } from 'react';
import { useQueryParam, StringParam } from 'use-query-params';
import get from 'lodash/get';
import { Paginator } from 'primereact/paginator';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import GetResponse from './GetResponse';
import {
  GetApplication,
  completeAddQuestions,
  completeReqAction
} from '../../../services/Application';
import { FormGroup, Input, Label } from 'reactstrap';

const TextQuestion = ({ question, handleInputChange, primaryTextState }) => (
  <div className='mb-3'>
    <Label
      className='pt-2 font-size-12 font-weight-medium'
      style={{ color: primaryTextState }}>
      {question.question}
    </Label>
    <FormGroup>
      <Input
        type='textarea'
        id={`${question._id}_answer`}
        rows='6'
        value={question.answer}
        style={{ color: primaryTextState }}
        className={'font-size-12 border-dark'}
        onChange={(e) => handleInputChange(e, question)}
      />
    </FormGroup>
  </div>
);

const FileQuestion = ({ question, handleInputChange, primaryTextState }) => (
  <div className='mb-3'>
    <Label
      className='pt-2 font-size-12 font-weight-medium'
      style={{ color: primaryTextState }}>
      {question.question}
    </Label>
    <FormGroup
      className='d-flex direction-row justify-content-between align-items-center pl-1'
      style={{
        borderRadius: '8px',
        border: '1px solid',
        height: 45
      }}>
      <Label
        htmlFor={`${question._id}_file`}
        style={{ color: primaryTextState }}
        className='cursor-pointer'>
        <span className='d-flex align-items-center justify-content-center mt-2 ml-2'>
          Upload File
        </span>
        <Input
          type='file'
          id={`${question._id}_file`}
          onChange={(e) => handleInputChange(e, question)}
          style={{ display: 'none' }}
        />
      </Label>
      {question.answer && (
        <a
          className='pr-2 font-size-12 font-weight-bold text-wrap text-primary'
          href={question.answer}
          target='_blank'
          rel='noopener noreferrer'>
          View File
        </a>
      )}
    </FormGroup>
  </div>
);

function ResponsePage({
  GetApplicationResponseFunc,
  page,
  setPage,
  setApplicationId,
  statusSuggestions,
  setFirst,
  allResponses,
  totalCount,
  loading,
  currentPageData,
  setCurrentPageData,
  order,
  first,
  primaryTextState,
  containerColorState,
  primaryColorState,
  paramsValue
}) {
  const [applicationId] = useQueryParam('application_id', StringParam);
  const [requestModal, setRequestModal] = useState(false);
  const [answerModal, setAnswerModal] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);
  const [responseId, setResponseId] = useState('');
  const [isLoaded, setIsLoaded] = useState(true);
  const [formData, setFormData] = useState([]);
  const [questionList, setQuestionList] = useState([]);
  const [subAppId, setSubApplication] = useState('');

  useEffect(() => {
    setApplicationId(applicationId);
  }, []);

  const onPageChange = (event) => {
    setFirst(event.first);
    setPage(event.page + 1);
    const newPageData = allResponses.slice(event.first, event.first + event.rows);
    setCurrentPageData(newPageData);
  };

  useEffect(() => {
    GetApplicationResponseFunc(applicationId);
  }, [applicationId, order, statusSuggestions]);

  const toggleRowExpansion = (index) => {
    if (expandedRows.includes(index)) {
      setExpandedRows(expandedRows.filter((rowIndex) => rowIndex !== index));
    } else {
      setExpandedRows([...expandedRows, index]);
    }
  };

  const actionToggle = () => {
    setRequestModal(!requestModal);
  };

  const answerToggle = () => {
    setAnswerModal(!answerModal);
  };

  const confirmActionComplete = () => {
    setIsLoaded(false);
    completeReqAction(responseId)
      .then((res) => {
        setIsLoaded(true);
        actionToggle();
        GetApplicationResponseFunc(applicationId);
      })
      .catch((error) => {
        console.log('error completeReqAction', error);
      });
  };

  useEffect(() => {
    if (answerModal) {
      GetApplication({ sub_application_id: subAppId }).then((res) => {
        const application = get(res, 'data.data.data[0]', {});
        setQuestionList(application?.add_questions || []);
      });
    }
  }, [answerModal]);

  const handleInputChange = (e, question) => {
    const { value, type } = e.target;
    if (type === 'file') {
      const updatedAnswer = { _id: question._id, answer: e.target.files[0] };

      setFormData((prevFormData) => [...prevFormData, updatedAnswer]);
    } else {
      setFormData((prevFormData) => {
        const answerExists = prevFormData.some((answer) => answer._id === question._id);
        if (answerExists) {
          return prevFormData.map((answer) =>
            answer._id === question._id ? { ...answer, answer: value } : answer
          );
        } else {
          return [...prevFormData, { _id: question._id, answer: value }];
        }
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoaded(false);
    const payload = new FormData();
    formData.forEach((question) => {
      if (question.type === 'file') {
        payload.append(question._id, question.answer, question.answer.name);
      } else {
        payload.append(question._id, question.answer);
      }
    });
    completeAddQuestions(responseId, subAppId, payload)
      .then((res) => {
        GetApplicationResponseFunc(applicationId);

        setIsLoaded(true);
        answerToggle();
      })
      .catch((err) => {});
  };

  return (
    <React.Fragment>
      {requestModal ? (
        <div className='application-card-border ml-2 mr-2 mt-3'>
          <div className='mr-2 ml-2 d-flex justify-content-between align-items-center'>
            <span style={{ color: primaryTextState }}>Request Action</span>
            <i
              className='mdi mdi-close noti-icon font-size-16 cursor-pointer'
              style={{ color: primaryTextState }}
              onClick={actionToggle}></i>
          </div>
          <hr className='mr-2 ml-2' />
          <div>
            {!isLoaded ? (
              <div className='h-100 d-flex align-items-center justify-content-center py-4'>
                <i
                  className='fas fa-circle-notch fa-spin text-muted'
                  style={{ fontSize: 48 }}></i>
              </div>
            ) : (
              <>
                <div className='col-12 col-sm-auto font-size-12 pt-3 pb-3 text-center'>
                  <span className='text-black' style={{ color: primaryTextState }}>
                    Have you completed Action?
                  </span>
                </div>
                <div className='font-size-22'></div>
                <div className='d-flex justify-content-center mt-3 mb-4'>
                  <button
                    className='btn application-button text-white d-flex align-items-center justify-content-center'
                    style={{ width: '100px', height: '35px' }}
                    type='button'
                    onClick={confirmActionComplete}>
                    Confirm
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      ) : answerModal ? (
        <div className='application-card-border ml-2 mr-2 mt-3'>
          <div className='mr-2 ml-2 d-flex justify-content-between align-items-center'>
            <span style={{ color: primaryTextState }}>Answer Question</span>
            <i
              className='mdi mdi-close noti-icon font-size-16 cursor-pointer'
              onClick={answerToggle}
              style={{ color: primaryTextState }}></i>
          </div>
          <hr className='mr-2 ml-2' style={{ background: primaryTextState }} />
          <div className='modal-body'>
            {!isLoaded ? (
              <div>Loading...</div>
            ) : (
              <form onSubmit={handleSubmit}>
                {questionList.map((question) =>
                  question.type === 'file' ? (
                    <FileQuestion
                      key={question._id}
                      question={question}
                      handleInputChange={handleInputChange}
                      primaryTextState={primaryTextState}
                    />
                  ) : (
                    <TextQuestion
                      key={question._id}
                      question={question}
                      handleInputChange={handleInputChange}
                      primaryTextState={primaryTextState}
                    />
                  )
                )}
                <div className='text-center d-flex justify-content-center'>
                  <button
                    className='btn application-button text-white d-flex align-items-center justify-content-center'
                    style={{ width: '100px', height: '35px' }}
                    type='submit'>
                    Submit
                  </button>
                </div>{' '}
              </form>
            )}
          </div>
        </div>
      ) : (
        <>
          <div className='ml-2 mr-2 mt-3'>
            {loading ? (
              <div className='h-100 d-flex align-items-center justify-content-center py-4'>
                <i
                  className='fas fa-circle-notch fa-spin text-muted'
                  style={{ fontSize: 48 }}></i>
              </div>
            ) : (
              <>
                {currentPageData && currentPageData.length > 0 ? (
                  currentPageData.map((application, key) => (
                    <React.Fragment key={key}>
                      <div onClick={() => toggleRowExpansion(key)}>
                        <GetResponse
                          application={application}
                          index={key}
                          expandedRows={expandedRows}
                          setResponseId={setResponseId}
                          actionToggle={actionToggle}
                          answerToggle={answerToggle}
                          applicationId={applicationId}
                          setSubApplication={setSubApplication}
                          primaryColorState={primaryColorState}
                          primaryTextState={primaryTextState}
                          paramsValue={paramsValue}
                        />
                      </div>
                      {expandedRows.includes(key) && (
                        <>
                          <hr className='ml-2 mr-2' />
                          <div className='application-card-border ml-2 mr-2'>
                            <div className='expanded-row font-size-12'>
                              {application &&
                              application.applicationResponse.field &&
                              Object.keys(application.applicationResponse.field).length >
                                0 ? (
                                <ul className='notes-list p-3'>
                                  {Object.keys(application.applicationResponse.field).map(
                                    (fItem, index) => (
                                      <li
                                        key={index}
                                        className='font-size-12'
                                        style={{ color: primaryTextState }}>
                                        <span className='font-weight-medium'>
                                          <u>{fItem.toUpperCase()}</u>:
                                        </span>
                                        {'  '}
                                        {application.applicationResponse.field[fItem]}
                                      </li>
                                    )
                                  )}
                                </ul>
                              ) : application && application.applicationResponse.notes ? (
                                <span
                                  className='notes-list font-size-12'
                                  style={{ color: primaryTextState }}>
                                  {application.applicationResponse.notes}
                                </span>
                              ) : application &&
                                application.application_status === 'ADD_QUESTIONS' &&
                                application.add_questions &&
                                application.add_questions.length > 0 ? (
                                <ul className='notes-list p-3'>
                                  {application.add_questions.map((que, index) => (
                                    <li
                                      key={index}
                                      className='font-size-12'
                                      style={{ color: primaryTextState }}>
                                      <span
                                        className='font-weight-medium'
                                        style={{ color: primaryTextState }}>
                                        <u>{que.type.toUpperCase()}</u>:
                                      </span>
                                      {'  '}
                                      {que.question}
                                    </li>
                                  ))}
                                </ul>
                              ) : (
                                <div
                                  className='no-notes'
                                  style={{ color: primaryTextState }}>
                                  No notes available
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </React.Fragment>
                  ))
                ) : (
                  <div className='application-card-border ml-1 mr-1 mt-3 py-2 d-flex justify-content-center'>
                    <span style={{ color: primaryTextState }}>No response found</span>
                  </div>
                )}
              </>
            )}
          </div>

          <Paginator
            rows={5}
            totalRecords={totalCount}
            first={first}
            onPageChange={(e) => onPageChange(e)}
            style={{ backgroundColor: containerColorState, color: primaryTextState }}
          />
        </>
      )}
    </React.Fragment>
  );
}

export default ResponsePage;
