import React, { useEffect, useState } from 'react';
import { Col, Collapse, Container } from 'reactstrap';
import { Formik, Form } from 'formik';
import get from 'lodash/get';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { constants as ERROR_CONST } from '../../../constants/errorMessage';
import { constants as CONST } from '../../../constants/application';
import './exchangeApplication.css';
import {
  CreateApplication,
  GetApplication,
  UpdateExchangeApplication
} from '../../../services/Application';
import { StepsCalculator } from './StepsCalculator';
import { notificationMessageFunction } from '../../../constants/notificationConst';
import { ReferralCodeDetailsAPI } from '../../../services/Auth';
import { constants as STORAGEKEY } from '../../../constants/constant';
import Loader from '../../../constants/Loader';

function Step1({
  isLoading,
  setIsLoading,
  setExchangeCompleteStep,
  applicationId,
  setApplicationId,
  nextPage,
  setApplicationData,
  CustomInput,
  applicationData,
  isUpdateExchange = false,
  isSectionCollapsed,
  toggleSection,
  GetApplicationDetailsEdit,
  primaryTextState
}) {
  const [initialValue, setInitialValue] = useState({
    applicant_first_name: '',
    applicant_last_name: '',
    applicant_email: '',
    applicant_telegram: ''
  });
  const [completeStep, setCompleteStep] = useState(0);

  const ApplicantInfoSchema = Yup.object().shape({
    applicant_first_name: Yup.string().required(ERROR_CONST.APPLICANT_FIRST_NAME),
    applicant_last_name: Yup.string().required(ERROR_CONST.APPLICANT_LAST_NAME),
    applicant_email: Yup.string()
      .required(ERROR_CONST.APPLICANT_EMAIL)
      .email(ERROR_CONST.INVALID_EMAIL),
    applicant_telegram: Yup.string()
  });

  useEffect(() => {
    ReferralCodeDetailsAPI(window.location.hostname)
      .then((res) => {
        const referralDetails = res?.data?.data?.data;
        localStorage.setItem(STORAGEKEY.REFERRED_USER_ID, referralDetails.user_id || '');
      })
      .catch((err) => {});
    if (applicationId !== '' && !isUpdateExchange) {
      GetApplication({ application_id: applicationId }).then((res) => {
        const application = get(res, 'data.data.data[0]', '');
        setInitialValue({
          applicant_first_name: application.exchange_application.applicant_first_name,
          applicant_last_name: application.exchange_application.applicant_last_name,
          applicant_email: application.exchange_application.applicant_email,
          applicant_telegram: application.exchange_application.applicant_telegram
        });

        setCompleteStep(application.exchange_application.percentage_complete);
      });
    } else {
      if (applicationData && applicationData.exchange_application) {
        setInitialValue({
          applicant_first_name: applicationData.exchange_application.applicant_first_name,
          applicant_last_name: applicationData.exchange_application.applicant_last_name,
          applicant_email: applicationData.exchange_application.applicant_email,
          applicant_telegram: applicationData.exchange_application.applicant_telegram
        });
      }
    }
  }, [applicationData, applicationId, isUpdateExchange]);

  const handleSubmit = (values) => {
    setIsLoading(true);
    let stepsRequired = Math.floor(StepsCalculator(3, completeStep, 10));
    const data = {
      applicant_first_name: values.applicant_first_name.toLowerCase(),
      applicant_last_name: values.applicant_last_name,
      applicant_email: values.applicant_email,
      applicant_telegram: values.applicant_telegram,
      application_type: 'EXCHANGE',
      application_status: 'INCOMPLETE',
      percentage_complete: stepsRequired
    };

    if (applicationId !== '') {
      UpdateExchangeApplication({ application_id: applicationId }, data)
        .then((res) => {
          const application = get(res, 'data.data.data', '');
          setApplicationData(application);
          if (isUpdateExchange) {
            toast.success('Applicant Background updated successfully', {
              style: { fontSize: '12px' }
            });

            GetApplicationDetailsEdit(applicationId);
          }
          nextPage();
          setIsLoading(false);
        })
        .catch((err) => {
          const errorMessage = get(err, 'data.error.message');
          toast.error(notificationMessageFunction(errorMessage), {
            style: { fontSize: '12px' }
          });
        });
    } else {
      CreateApplication({
        ...data,
        referred_user_id: localStorage.getItem(STORAGEKEY.REFERRED_USER_ID)
      })
        .then((res) => {
          setApplicationId(get(res, 'data.data.data', ''));
          nextPage();
          setIsLoading(false);
        })
        .catch((err) => {
          const errorMessage = get(err, 'data.error.message');
          toast.error(notificationMessageFunction(errorMessage), {
            style: { fontSize: '12px' }
          });
        });
    }
    setExchangeCompleteStep([0]);
  };

  return (
    <React.Fragment>
      <div onClick={isSectionCollapsed ? toggleSection : undefined}>
        {isUpdateExchange ? (
          <>
            <div
              className='cursor-pointer col-12 font-size-14 ml-2 d-flex justify-content-between align-items-center'
              onClick={toggleSection}>
              <span
                className='text-black font-weight-400'
                style={{ color: primaryTextState }}>
                Applicant Background
              </span>
              <i
                className={`font-size-22 mdi mdi-chevron-${
                  isSectionCollapsed === true ? 'up' : 'down'
                }`}
                style={{ color: primaryTextState }}
              />
            </div>
          </>
        ) : (
          <div className='col-12 font-size-16 pb-2 ml-2'>
            <span
              className='text-black font-weight-400'
              style={{ color: primaryTextState }}>
              Applicant Background
            </span>
          </div>
        )}
        <Collapse isOpen={!isSectionCollapsed}>
          {isLoading ? (
            <div className='mt-5'>
              <Loader />
            </div>
          ) : (
            <Container className='col-12 col-sm-auto pt-3'>
              <div>
                <Formik
                  enableReinitialize={true}
                  initialValues={initialValue}
                  validationSchema={ApplicantInfoSchema}
                  onSubmit={handleSubmit}>
                  {({ errors, touched, values, handleChange }) => (
                    <Form>
                      <Col>
                        <CustomInput
                          label={CONST.FIRST_NAME}
                          htmlFor='applicant_first_name'
                          name='applicant_first_name'
                          placeholder={CONST.ENTER_APPLICANT_FIRST_NAME}
                          values={values}
                          handleChange={handleChange}
                          errors={errors}
                          touched={touched}
                          primaryTextState={primaryTextState}
                        />
                      </Col>
                      <Col>
                        <CustomInput
                          label={CONST.LAST_NAME}
                          htmlFor='applicant_last_name'
                          name='applicant_last_name'
                          placeholder={CONST.ENTER_APPLICANT_LAST_NAME}
                          values={values}
                          handleChange={handleChange}
                          errors={errors}
                          touched={touched}
                          primaryTextState={primaryTextState}
                        />
                      </Col>
                      <Col>
                        <CustomInput
                          label={CONST.EMAIL}
                          htmlFor='applicant_email'
                          name='applicant_email'
                          placeholder={CONST.ENTER_APPLICANT_EMAIL}
                          values={values}
                          type='email'
                          handleChange={handleChange}
                          errors={errors}
                          touched={touched}
                          primaryTextState={primaryTextState}
                        />
                      </Col>
                      <Col>
                        <CustomInput
                          label={CONST.APPLICANT_TELEGRAM}
                          htmlFor='applicant_telegram'
                          name='applicant_telegram'
                          placeholder={CONST.ENTER_APPLICANT_TELEGRAM_NAME}
                          values={values}
                          handleChange={handleChange}
                          errors={errors}
                          touched={touched}
                          extraLabel={`(${CONST.OPTIONAL})`}
                          primaryTextState={primaryTextState}
                        />
                      </Col>

                      <div className='d-flex justify-content-end mr-2 mt-4'>
                        <button
                          className='btn application-button text-white'
                          type='submit'
                          onSubmit={(values) => handleSubmit(values)}>
                          Next
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </Container>
          )}
        </Collapse>
      </div>
    </React.Fragment>
  );
}

export default Step1;
