import React, { useEffect, useState } from 'react';
import { Col, Collapse, Container, FormGroup, Label, Row } from 'reactstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import get from 'lodash/get';
import { constants as ERROR_CONST } from '../../../constants/errorMessage';
import { GetApplication, UpdateExchangeApplication } from '../../../services/Application';
import { StepsCalculator } from './StepsCalculator';
import { notificationMessageFunction } from '../../../constants/notificationConst';
import toast from 'react-hot-toast';
import { constants as CONST } from '../../../constants/application';
import AuthInput from '../../../Custom/AuthInput';
import { handleSubmitLiteApplication } from './LiteComplete';
import Loader from '../../../constants/Loader';

function Step20({
  isLoading,
  setIsLoading,
  setExchangeCompleteStep,
  applicationId,
  nextPage,
  setApplicationData,
  prevPage,
  applicationData,
  isUpdateExchange = false,
  isSectionCollapsed,
  toggleSection,
  GetApplicationDetailsEdit,
  primaryTextState,
  history,
  paramsValue
}) {
  const applicationStatus = applicationData.application_status;
  applicationData = applicationData.exchange_application;

  const [initialValue, setInitialValue] = useState({
    road_map: ''
  });

  const Road_MapSchema = Yup.object().shape({
    road_map: Yup.string().required(ERROR_CONST.ROADMAP_REQUIRED)
  });
  const [completeStep, setCompleteStep] = useState(0);

  useEffect(() => {
    if (applicationId !== '' && !isUpdateExchange) {
      GetApplication({ application_id: applicationId }).then((res) => {
        const application = get(res, 'data.data.data[0]', '');
        if (application.exchange_application.road_map) {
          setInitialValue({
            road_map: application.exchange_application.road_map
          });
        }
        setCompleteStep(application.exchange_application.percentage_complete);
      });
    } else {
      if (applicationData && applicationData.road_map) {
        setInitialValue({
          road_map: applicationData.road_map
        });
      }
    }
  }, [applicationData, applicationId, isUpdateExchange]);

  const handleSubmit = (values) => {
    let stepsRequired = Math.floor(StepsCalculator(1, completeStep, 93));
    if (values.road_map !== applicationData.road_map) {
      const data = {
        road_map: values.road_map,
        percentage_complete: stepsRequired
      };
      setIsLoading(true);

      UpdateExchangeApplication({ application_id: applicationId }, data)
        .then((res) => {
          const application = get(res, 'data.data.data', '');
          setApplicationData(application);
          if (isUpdateExchange) {
            toast.success('Road Map and Upcoming Events/Updates updated successfully', {
              style: { fontSize: '12px' }
            });
            GetApplicationDetailsEdit(applicationId);
          }
          nextPage();
          setIsLoading(false);
        })
        .catch((err) => {
          const errorMessage = get(err, 'data.error.message');
          toast.error(notificationMessageFunction(errorMessage), {
            style: { fontSize: '12px' }
          });
        });
    } else {
      nextPage();
    }
    setExchangeCompleteStep(Array.from({ length: 21 }, (_, index) => index));
  };

  return (
    <React.Fragment>
      <div onClick={isSectionCollapsed ? toggleSection : undefined}>
        {isUpdateExchange ? (
          <>
            <div
              className='cursor-pointer col-12 font-size-14 ml-2 d-flex justify-content-between align-items-center'
              onClick={toggleSection}>
              <span
                className='text-black font-weight-400'
                style={{ color: primaryTextState }}>
                Road Map and Upcoming Events/Updates
              </span>
              <i
                className={`font-size-22 mdi mdi-chevron-${
                  isSectionCollapsed === true ? 'up' : 'down'
                }`}
                style={{ color: primaryTextState }}
              />
            </div>
          </>
        ) : (
          <div className='col-12 font-size-16 pb-2 ml-2'>
            <span
              className='text-black font-weight-400'
              style={{ color: primaryTextState }}>
              Road Map and Upcoming Events/Updates
            </span>
          </div>
        )}
        <Collapse isOpen={!isSectionCollapsed}>
          {isLoading ? (
            <div className='mt-5'>
              <Loader />
            </div>
          ) : (
            <Container className='col-12 col-sm-auto pt-4 ml-2 mr-2'>
              <div>
                <Formik
                  initialValues={initialValue}
                  enableReinitialize={true}
                  validationSchema={Road_MapSchema}
                  onSubmit={handleSubmit}>
                  {({ errors, touched, values, handleChange }) => (
                    <Form>
                      <div>
                        <Row>
                          <Col>
                            <FormGroup className='mb-4'>
                              <div className='font-size-12'>
                                <Label
                                  htmlFor='road_map'
                                  className='text-black font-weight-normal'
                                  style={{ color: primaryTextState }}>
                                  {CONST.ROADMAP}
                                </Label>
                              </div>
                              <div>
                                <AuthInput
                                  className='font-size-12'
                                  type='textarea'
                                  name='road_map'
                                  placeholder={CONST.ENTER_DESCRIPTION}
                                  values={values}
                                  handleChange={handleChange}
                                  errors={errors}
                                  touched={touched}></AuthInput>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>

                      <div className='d-flex justify-content-between align-items-center mt-3 mr-2 ml-2'>
                        <i
                          className='mdi mdi-chevron-double-left font-size-32 ml-1 cursor-pointer'
                          onClick={prevPage}
                          style={{ color: primaryTextState }}
                        />
                        <div>
                          {(applicationStatus === 'INCOMPLETE' || !applicationStatus) && (
                            <button
                              className='btn btn-status-change text-white mr-2'
                              type='submit'
                              style={{ width: '142px', height: '50px' }}
                              onClick={() =>
                                handleSubmitLiteApplication({
                                  applicationId,
                                  setApplicationData,
                                  history,
                                  paramsValue,
                                  setIsLoading
                                })
                              }>
                              Submit
                            </button>
                          )}
                          <button
                            className='btn application-button text-white'
                            type='submit'
                            onSubmit={(values) => handleSubmit(values)}>
                            Next
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </Container>
          )}
        </Collapse>
      </div>
    </React.Fragment>
  );
}

export default Step20;
