import React, { useEffect, useState } from 'react';
import ListusLogo from '../../assets/images/listus-logo-b.png';
import ListusLogoW from '../../assets/images/listus-logo-w.png';
import { stringify } from 'query-string';
import get from 'lodash/get';
import './landingPage.css';
import { Link } from 'react-router-dom';
import { constants as ROUTE_CONST } from '../../constants/routes';
import { GetMyProfileAPI, ReferralCodeDetailsAPI } from '../../services/Auth';
import { constants as STORAGEKEY } from '../../constants/constant';
import file from '../../assets/images/file.png';
import atmCard from '../../assets/images/atm-card.png';
import exchange from '../../assets/images/exchange.png';
import smartContract from '../../assets/images/smart-contract.png';
import marketing from '../../assets/images/marketing.png';
import orange from '../../assets/images/orange-dot.png';
import DrawerPage from '../DrawerPage';
import { GetAllApplications } from '../../services/Application';
import { useThemeContext } from '../ThemeProvider';

function LandingPage({ history }) {
  const {
    params,
    widgetTheme,
    containerColorState,
    primaryColorState,
    primaryTextState,
    secondaryTextState
  } = useThemeContext();

  const [myProfile, setMyProfile] = useState({});
  const [referralData, setReferralData] = useState({});
  const [application, setApplication] = useState({});

  const myProfileDataFunction = () => {
    GetMyProfileAPI()
      .then((res) => {
        const profileData = res?.data?.data?.data;
        setMyProfile(profileData);
      })
      .catch((err) => {});
  };

  const getAllApplications = () => {
    GetAllApplications({ page: 1 })
      .then((res) => {
        let newArray = get(res, 'data.data.data', []);
        newArray = newArray.filter((app) => app.application_status !== 'COMPLETE');
        const lastApp =
          newArray &&
          newArray.length > 0 &&
          newArray.find((app) =>
            app.application_status === 'COMPLETE'
              ? '100'
              : app?.application_type === 'ONRAMP'
              ? app?.app_onramp_per
              : app?.application_type === 'EXCHANGE'
              ? app?.app_exchange_per
              : '' < 100
          );

        if (lastApp && Object.keys(lastApp).length > 0) {
          setApplication(lastApp);
        } else {
          setApplication({});
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    const accessToken = localStorage.getItem(STORAGEKEY.ACCESSTOKEN);
    if (accessToken) {
      myProfileDataFunction();
      getAllApplications();
    }

    ReferralCodeDetailsAPI(window.location.hostname)
      .then((res) => {
        const referralDetails = res?.data?.data?.data;
        localStorage.setItem(STORAGEKEY.REFERRED_USER_ID, referralDetails.user_id || '');
        setReferralData(referralDetails);
      })
      .catch((err) => {});
  }, []);

  return (
    <React.Fragment>
      <div className='widget-container'>
        <div
          className='card d-flex justify-content-center py-2'
          style={{ backgroundColor: containerColorState }}>
          <DrawerPage />

          <div className='authentication-bg'>
            <div className='content-container mt-3 d-flex justify-content-center flex-column align-items-center'>
              {referralData && referralData.brand_logo ? (
                <>
                  {widgetTheme === 'dark' ? (
                    <img src={ListusLogoW} alt='Listus Logo' />
                  ) : (
                    <img src={ListusLogo} alt='Listus Logo' />
                  )}
                  <span className='font-size-32 text-dark'>
                    <i className='mdi mdi-close' style={{ color: primaryTextState }} />
                  </span>
                  <img src={referralData.brand_logo} alt='Brand logo' />
                </>
              ) : (
                <>
                  {widgetTheme === 'dark' ? (
                    <img src={ListusLogoW} alt='Listus Logo' />
                  ) : (
                    <img src={ListusLogo} alt='Listus Logo' />
                  )}
                </>
              )}
            </div>
          </div>
          <ApplicationsList
            params={params}
            myProfile={myProfile}
            application={application}
            history={history}
            referralData={referralData}
            primaryTextState={primaryTextState}
            secondaryTextState={secondaryTextState}
            primaryColorState={primaryColorState}
            myProfileDataFunction={myProfileDataFunction}
          />
        </div>
      </div>
    </React.Fragment>
  );
}

export default LandingPage;

export const ApplicationsList = ({
  params = '',
  myProfile,
  application,
  history,
  referralData,
  primaryTextState,
  secondaryTextState,
  primaryColorState,
  myProfileDataFunction
}) => {
  const handleContinue = () => handleContinueClick(application, history);

  const handleContinueClick = (application, history) => {
    if (application.application_type === 'ONRAMP') {
      localStorage.setItem(
        'preAuthPath',
        `/view-onramp-application${params === '' ? '?' : params}&${stringify({
          application_id: application._id
        })}`
      );

      history.push(
        `/view-onramp-application${params === '' ? '?' : params}&${stringify({
          application_id: application._id
        })}`
      );
    } else {
      localStorage.setItem(
        'preAuthPath',
        `/view-exchange-application${params === '' ? '?' : params}&${stringify({
          application_id: application._id
        })}`
      );

      history.push(
        `/view-exchange-application${params === '' ? '?' : params}&${stringify({
          application_id: application._id
        })}`
      );
    }
  };

  return (
    <div className='round-info ml-4 mr-4'>
      {application && Object.keys(application).length > 0 ? (
        <div className='d-flex justify-content-between mr-2'>
          <div className='col-6 pl-0 mr-1'>
            <Link to={ROUTE_CONST.VIEW_APPLICATIONS + params}>
              <div
                className='pl-4 pr-4 create-new-application application-card-border cursor-pointer d-flex align-items-center'
                style={{ background: primaryColorState }}>
                <div className='mr-2'>
                  <img src={file} alt={atmCard} height={22} />
                </div>
                <div className='ml-2 application-type d-flex justify-contnet-between'>
                  <span
                    className='font-size-14 font-weight-medium'
                    style={{ color: primaryTextState }}>
                    View Applications
                  </span>
                </div>
              </div>
            </Link>
          </div>
          <div className='col-6 p-0'>
            <div
              className='pl-4 pr-4 create-new-application application-card-border cursor-pointer d-flex align-items-center'
              style={{ background: primaryColorState }}
              onClick={handleContinue}>
              <div className='mr-2'>
                <img src={orange} alt={atmCard} height={22} />
              </div>
              <div className='ml-2 application-type d-flex justify-contnet-between'>
                <span
                  className='font-size-14 font-weight-medium'
                  style={{ color: primaryTextState }}>
                  {application.application_status === 'COMPLETE'
                    ? '100'
                    : application?.application_type === 'ONRAMP'
                    ? application?.app_onramp_per
                    : application?.application_type === 'EXCHANGE'
                    ? application?.app_exchange_per
                    : ''}{' '}
                  % {application.application_type === 'EXCHANGE' ? 'Exchange' : 'Onramp'}{' '}
                  Continue
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <CommonApplicationCard
          params={params}
          route={ROUTE_CONST.VIEW_APPLICATIONS + params}
          image={file}
          applicationType={'View Applications'}
          appDetail={'View'}
          myProfile={myProfile}
          isAllowed={true}
          primaryTextState={primaryTextState}
          secondaryTextState={secondaryTextState}
          primaryColorState={primaryColorState}
          myProfileDataFunction={myProfileDataFunction}
        />
      )}
      {((referralData && Object.keys(referralData).length === 0) ||
        (referralData &&
          referralData.applications &&
          referralData.applications.includes('onramp_app'))) && (
        <CommonApplicationCard
          params={params}
          route={ROUTE_CONST.ONRAMP_APPLICATION + params}
          image={atmCard}
          applicationType={'On Ramp Application'}
          appDetail={'Create'}
          myProfile={myProfile}
          isAllowed={true}
          primaryTextState={primaryTextState}
          secondaryTextState={secondaryTextState}
          primaryColorState={primaryColorState}
          myProfileDataFunction={myProfileDataFunction}
        />
      )}

      {((referralData && Object.keys(referralData).length === 0) ||
        (referralData &&
          referralData.applications &&
          referralData.applications.includes('exchange_app'))) && (
        <CommonApplicationCard
          params={params}
          route={ROUTE_CONST.EXCHANGE_APPLICATION + params}
          image={exchange}
          applicationType={'Exchange Application'}
          appDetail={'Create'}
          myProfile={myProfile}
          isAllowed={true}
          primaryTextState={primaryTextState}
          secondaryTextState={secondaryTextState}
          primaryColorState={primaryColorState}
          myProfileDataFunction={myProfileDataFunction}
        />
      )}

      {((referralData && Object.keys(referralData).length === 0) ||
        (referralData &&
          referralData.applications &&
          referralData.applications.includes('smart_contract_app'))) && (
        <CommonApplicationCard
          params={params}
          route={''}
          image={smartContract}
          applicationType={'Smart Contract Audit Application (Coming Soon)'}
          appDetail={''}
          myProfile={myProfile}
          isAllowed={false}
          primaryTextState={primaryTextState}
          secondaryTextState={secondaryTextState}
          primaryColorState={primaryColorState}
          myProfileDataFunction={myProfileDataFunction}
        />
      )}

      {((referralData && Object.keys(referralData).length === 0) ||
        (referralData &&
          referralData.applications &&
          referralData.applications.includes('marketing_app'))) && (
        <CommonApplicationCard
          params={params}
          route={''}
          image={marketing}
          applicationType={'Marketing Application (Coming Soon)'}
          appDetail={''}
          myProfile={myProfile}
          isAllowed={false}
          primaryTextState={primaryTextState}
          secondaryTextState={secondaryTextState}
          primaryColorState={primaryColorState}
          myProfileDataFunction={myProfileDataFunction}
        />
      )}
    </div>
  );
};

export const CommonApplicationCard = ({
  myProfile,
  route,
  image,
  applicationType,
  appDetail,
  isAllowed,
  primaryTextState,
  secondaryTextState,
  primaryColorState,
  params,
  myProfileDataFunction
}) => {
  return (
    <Link
      to={
        isAllowed
          ? myProfile && Object.keys(myProfile).length > 0
            ? route
            : ROUTE_CONST.LOGIN + params
          : ''
      }
      onClick={(event) => {
        localStorage.setItem('preAuthPath', route);
        if (myProfile && Object.keys(myProfile).length > 0) {
          myProfileDataFunction();
        }
        if (!isAllowed) event.preventDefault();
      }}
      className={`${isAllowed ? 'cursor-pointer' : 'cursor-not-allowed'} `}>
      <div
        className={`${
          isAllowed ? 'create-new-application' : ''
        } mb-2 pl-4 pr-4 application-card-border  d-flex justify-content-between`}
        style={{ background: primaryColorState }}>
        <div className='d-flex align-items-center '>
          <div className='mr-2'>
            <img src={image} alt={applicationType} height={22} />
          </div>
          <div className='ml-2 application-type d-flex justify-contnet-between'>
            <span
              className='font-size-14 font-weight-medium'
              style={{ color: primaryTextState }}>
              {applicationType}
            </span>
          </div>
        </div>
        <span
          className='font-size-12  text-grey d-flex align-items-center justify-content-center hidden-text'
          style={{ color: secondaryTextState }}>
          {appDetail}
        </span>
      </div>
    </Link>
  );
};
