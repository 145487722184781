import React, { useEffect, useState } from 'react';
import { Col, Collapse, Container, FormGroup, Label, Row } from 'reactstrap';
import { Formik, Form, ErrorMessage } from 'formik';
import get from 'lodash/get';
import Select from 'react-select';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import {
  GetAllNetwork,
  GetApplication,
  UpdateExchangeApplication
} from '../../../services/Application';
import { StepsCalculator } from './StepsCalculator';
import { notificationMessageFunction } from '../../../constants/notificationConst';
import { constants as CONST } from '../../../constants/application';
import { constants as ERROR_CONST } from '../../../constants/errorMessage';
import AuthInput from '../../../Custom/AuthInput';
import Loader from '../../../constants/Loader';

function Step6({
  isLoading,
  setIsLoading,
  setExchangeCompleteStep,
  applicationId,
  nextPage,
  setApplicationData,
  prevPage,
  applicationData,
  isUpdateExchange = false,
  isSectionCollapsed,
  toggleSection,
  GetApplicationDetailsEdit,
  primaryTextState,
  primaryColorState
}) {
  const CapMarketSchema = Yup.object().shape({
    blockchain_network: Yup.string().required(ERROR_CONST.NETWORK),
    other_network: Yup.string().when('blockchain_network', {
      is: (value) => value === 'Other',
      then: Yup.string().required(ERROR_CONST.NETWORK)
    }),
    token_address: Yup.string().required(ERROR_CONST.TOKEN_ADDRESS)
  });
  const [initialValue, setInitialValue] = useState({
    blockchain_network: '',
    other_network: '',
    token_address: ''
  });
  const [selectNetworkList, setSelectnetworkList] = useState([]);
  const [allNetworks, setAllNetworks] = useState([]);
  const [completeStep, setCompleteStep] = useState(0);
  const [displayotherNetwork, setDisplayotherNetwork] = useState(false);

  useEffect(() => {
    if (applicationId !== '' && !isUpdateExchange) {
      GetApplication({ application_id: applicationId }).then((res) => {
        const application = get(res, 'data.data.data[0]', '');
        if (application.exchange_application.blockchain_network) {
          setSelectnetworkList(application.exchange_application.blockchain_network);
          setInitialValue({
            blockchain_network: application.exchange_application.blockchain_network,
            other_network: application.exchange_application.other_network,
            token_address: application.exchange_application.token_address
          });
          if (application.exchange_application.blockchain_network === 'Other')
            setDisplayotherNetwork(true);
        }
        setCompleteStep(application.exchange_application.percentage_complete);
      });
    } else {
      if (
        applicationData &&
        applicationData.exchange_application &&
        applicationData.exchange_application.blockchain_network
      ) {
        setSelectnetworkList(applicationData.exchange_application.blockchain_network);
        setInitialValue({
          blockchain_network: applicationData.exchange_application.blockchain_network,
          other_network: applicationData.exchange_application.other_network,
          token_address: applicationData.exchange_application.token_address
        });
        if (applicationData.exchange_application.blockchain_network === 'Other')
          setDisplayotherNetwork(true);
      }
    }
    getAllNetworkApiFunction();
  }, [applicationData, applicationId, isUpdateExchange]);

  const getAllNetworkApiFunction = () => {
    GetAllNetwork()
      .then((res) => {
        const GetData = get(res, 'data.data.data', []);
        const setFinalData = GetData.map((item) => ({
          label: item.network_symbol,
          value: item.network_symbol
        }));
        setFinalData.push({ label: 'Other', value: 'Other' });
        setAllNetworks(setFinalData);
      })
      .catch((err) => {
        const errorMessage = get(err, 'data.error.message');
        toast.error(notificationMessageFunction(errorMessage), {
          style: { fontSize: '12px' }
        });
      });
  };

  const handleSubmit = (values) => {
    if (selectNetworkList !== '') {
      let stepsRequired = Math.floor(StepsCalculator(1, completeStep, 39));
      const data = {
        blockchain_network: selectNetworkList,
        other_network: values.other_network,
        token_address: values.token_address,
        percentage_complete: stepsRequired
      };
      setIsLoading(true);

      UpdateExchangeApplication({ application_id: applicationId }, data)
        .then((res) => {
          const application = get(res, 'data.data.data', '');
          setApplicationData(application);
          if (isUpdateExchange) {
            toast.success('Supported Chain updated successfully', {
              style: { fontSize: '12px' }
            });
            GetApplicationDetailsEdit(applicationId);
          }
          nextPage();
          setIsLoading(false);
        })
        .catch((err) => {
          const errorMessage = get(err, 'data.error.message');
          toast.error(notificationMessageFunction(errorMessage), {
            style: { fontSize: '12px' }
          });
        });
      setExchangeCompleteStep(Array.from({ length: 5 }, (_, index) => index));
    }
  };

  const changeHandler = (selectValues, setFieldValue) => {
    if (selectValues.label === 'Other') {
      setDisplayotherNetwork(true);
    } else {
      setFieldValue('other_network', '');
      setDisplayotherNetwork(false);
    }
    setSelectnetworkList(selectValues.value);
    setFieldValue('blockchain_network', selectValues.label);
  };

  return (
    <React.Fragment>
      <div onClick={isSectionCollapsed ? toggleSection : undefined}>
        {isUpdateExchange ? (
          <>
            <div
              className='cursor-pointer col-12 font-size-14 ml-2 d-flex justify-content-between align-items-center'
              onClick={toggleSection}>
              <span
                className='text-black font-weight-400'
                style={{ color: primaryTextState }}>
                Chains Supported
              </span>
              <i
                className={`font-size-22 mdi mdi-chevron-${
                  isSectionCollapsed === true ? 'up' : 'down'
                }`}
                style={{ color: primaryTextState }}
              />
            </div>
          </>
        ) : (
          <div className='col-12 font-size-16 pb-2 ml-2'>
            <span
              className='text-black font-weight-400'
              style={{ color: primaryTextState }}>
              Chains Supported
            </span>
          </div>
        )}
        <Collapse isOpen={!isSectionCollapsed}>
          {isLoading ? (
            <div className='mt-5'>
              <Loader />
            </div>
          ) : (
            <Container className='col-12 col-sm-auto pt-3' style={{ minHeight: '500px' }}>
              <div>
                <Formik
                  initialValues={initialValue}
                  enableReinitialize={true}
                  validationSchema={CapMarketSchema}
                  onSubmit={handleSubmit}>
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form>
                      <div>
                        <Col>
                          <FormGroup className='mb-2'>
                            <div className='font-size-12'>
                              <Label
                                htmlFor='blockchain_network'
                                className='text-black font-weight-normal'
                                style={{ color: primaryTextState }}>
                                {CONST.NETWORK}
                              </Label>
                            </div>
                            <div>
                              <Select
                                className='select-input border-grey font-size-12'
                                style={{
                                  paddingRight: '30px',
                                  borderRadius: '8px'
                                }}
                                styles={{
                                  control: (styles) => ({
                                    ...styles,
                                    backgroundColor: primaryColorState,
                                    borderRadius: '8px'
                                  }),
                                  singleValue: (styles) => ({
                                    ...styles,
                                    color: primaryTextState
                                  })
                                }}
                                options={allNetworks}
                                value={allNetworks.find(
                                  (c) => c.value === selectNetworkList
                                )}
                                onChange={(val) => changeHandler(val, setFieldValue)}
                                name='blockchain_network'
                              />
                              <ErrorMessage name='blockchain_network'>
                                {(errorMsg) => (
                                  <div className='font-size-12' style={{ color: 'red' }}>
                                    {errorMsg}
                                  </div>
                                )}
                              </ErrorMessage>
                            </div>
                          </FormGroup>
                        </Col>
                        {displayotherNetwork && (
                          <Col>
                            <FormGroup className='mb-2'>
                              <div className='font-size-12'>
                                <Label
                                  htmlFor='other_network'
                                  className='text-black font-weight-normal'
                                  style={{ color: primaryTextState }}>
                                  {CONST.OTHER_NETWORK}
                                </Label>
                              </div>
                              <div>
                                <AuthInput
                                  type='text'
                                  name='other_network'
                                  className='font-size-12'
                                  values={values}
                                  placeholder={CONST.OTHER_NETWORK}
                                  handleChange={(e) => {
                                    setFieldValue('other_network', e.target.value);
                                  }}
                                  errors={errors}
                                  touched={touched}
                                  disabledField={!displayotherNetwork}></AuthInput>
                              </div>
                            </FormGroup>
                          </Col>
                        )}
                        <Col>
                          <FormGroup className='mb-4'>
                            <div className='font-size-12'>
                              <Label
                                htmlFor='token_address'
                                className='text-black font-weight-normal'
                                style={{ color: primaryTextState }}>
                                {CONST.TOKEN_ADDRESS}
                              </Label>
                            </div>
                            <div>
                              <AuthInput
                                type='text'
                                name='token_address'
                                className='font-size-12'
                                values={values}
                                handleChange={handleChange}
                                placeholder={CONST.ENTER_TOKEN_ADDRESS_NAME}
                                errors={errors}
                                touched={touched}></AuthInput>
                            </div>
                          </FormGroup>
                        </Col>
                      </div>

                      <div className='d-flex justify-content-between align-items-center mt-4 mr-2 ml-2'>
                        <i
                          className='mdi mdi-chevron-double-left font-size-32 ml-1 cursor-pointer'
                          onClick={prevPage}
                          style={{ color: primaryTextState }}
                        />
                        <button
                          className='btn application-button text-white'
                          type='submit'
                          onSubmit={(values) => handleSubmit(values)}>
                          Next
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </Container>
          )}
        </Collapse>
      </div>
    </React.Fragment>
  );
}

export default Step6;
