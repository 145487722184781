import React, { useEffect, useRef, useState } from 'react';
import { Col, Collapse, Container, FormGroup, Label, Row } from 'reactstrap';
import { Formik, Form, ErrorMessage } from 'formik';
import get from 'lodash/get';
import * as Yup from 'yup';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import toast from 'react-hot-toast';
import { GetApplication, UpdateExchangeApplication } from '../../../services/Application';
import { StepsCalculator } from './StepsCalculator';
import { notificationMessageFunction } from '../../../constants/notificationConst';
import { constants as CONST } from '../../../constants/application';
import documentUploadIcon from '../../../assets/images/documentupload.png';
import documentUploadedIcon from '../../../assets/images/documentuploaded.png';
import AuthInput from '../../../Custom/AuthInput';
import { handleSubmitLiteApplication } from './LiteComplete';
import Loader from '../../../constants/Loader';

function Step13({
  isLoading,
  setIsLoading,
  setExchangeCompleteStep,
  applicationId,
  nextPage,
  setApplicationData,
  prevPage,
  applicationData,
  isUpdateExchange = false,
  isSectionCollapsed,
  toggleSection,
  GetApplicationDetailsEdit,
  primaryTextState,
  history,
  paramsValue
}) {
  const applicationStatus = applicationData.application_status;

  const [completeStep, setCompleteStep] = useState(0);
  const [initialValue, setInitialValue] = useState({
    whitepapers_doc: '',
    whitepapers_doc_status: '',
    whitepapers_doc_url: '',
    pitch_decks_doc: '',
    pitch_decks_doc_status: '',
    pitch_decks_doc_url: ''
  });
  const fileOrUrlRequired = Yup.mixed().test(
    'fileOrUrl',
    'Either a file or a URL is required, but not both',
    function (value) {
      const { whitepapers_doc, whitepapers_doc_url } = this.parent;
      const whitepapersProvided = whitepapers_doc || whitepapers_doc_url;

      return whitepapersProvided || !whitepapersProvided;
    }
  );

  const fileOrUrlPitch = Yup.mixed().test(
    'fileOrUrl',
    'Either a file or a URL is required, but not both',
    function (value) {
      const { pitch_decks_doc, pitch_decks_doc_url } = this.parent;
      const pitchDecksProvided = pitch_decks_doc || pitch_decks_doc_url;

      return !pitchDecksProvided || pitchDecksProvided;
    }
  );

  const validationSchema = Yup.object().shape({
    pitch_decks_doc: fileOrUrlPitch,
    pitch_decks_doc_url: fileOrUrlPitch,
    whitepapers_doc: fileOrUrlRequired,
    whitepapers_doc_url: fileOrUrlRequired
  });

  useEffect(() => {
    if (applicationId !== '' && !isUpdateExchange) {
      GetApplication({ application_id: applicationId }).then((res) => {
        const application = get(res, 'data.data.data[0]', '');

        setInitialValue({
          whitepapers_doc: application?.exchange_application?.whitepapers_doc,
          whitepapers_doc_status:
            application?.exchange_application?.whitepapers_doc_status,
          whitepapers_doc_url: application?.exchange_application?.whitepapers_doc_url,
          pitch_decks_doc: application?.exchange_application?.pitch_decks_doc,
          pitch_decks_doc_status:
            application?.exchange_application?.pitch_decks_doc_status,
          pitch_decks_doc_url: application?.exchange_application?.pitch_decks_doc_url
        });

        setCompleteStep(application.exchange_application.percentage_complete);
      });
    } else {
      setInitialValue({
        whitepapers_doc: applicationData?.exchange_application?.whitepapers_doc,
        whitepapers_doc_url: applicationData?.exchange_application?.whitepapers_doc_url,
        whitepapers_doc_status:
          applicationData?.exchange_application?.whitepapers_doc_status,
        pitch_decks_doc: applicationData?.exchange_application?.pitch_decks_doc,
        pitch_decks_doc_status:
          applicationData?.exchange_application?.pitch_decks_doc_status,
        pitch_decks_doc_url: applicationData?.exchange_application?.pitch_decks_doc_url
      });
    }
  }, [applicationData, applicationId, isUpdateExchange]);

  const handleSubmit = (values, { setFieldError }) => {
    const data = new FormData();
    let hasError = false;
    if (
      initialValue.whitepapers_doc_status !== '' &&
      initialValue.whitepapers_doc_status !== undefined &&
      initialValue.pitch_decks_doc_status !== undefined &&
      initialValue.pitch_decks_doc_status !== '' &&
      typeof values.whitepapers_doc !== 'object' &&
      typeof values.pitch_decks_doc !== 'object'
    ) {
      nextPage();
      setIsLoading(false);
    } else {
      if (
        values.whitepapers_doc &&
        typeof values.whitepapers_doc !== 'string' &&
        values.whitepapers_doc.size / 1024 > 50000
      ) {
        setFieldError('whitepapers_doc', 'File size should be less than 50 MB');
        return;
      }

      if (
        values.pitch_decks_doc &&
        typeof values.pitch_decks_doc !== 'string' &&
        values.pitch_decks_doc.size / 1024 > 50000
      ) {
        setFieldError('pitch_decks_doc', 'File size should be less than 50 MB');
        return;
      }

      if (!values.whitepapers_doc && !values.whitepapers_doc_url) {
        setFieldError('whitepapers_doc', 'White Paper or URL is required');
        hasError = true;
      }
      if (!values.pitch_decks_doc && !values.pitch_decks_doc_url) {
        setFieldError('pitch_decks_doc', 'Pitch Decks Doc or URL is required');
        hasError = true;
      }

      if (values.whitepapers_doc && values.whitepapers_doc_url) {
        data.append('whitepapers_doc', values.whitepapers_doc);
        data.append('whitepapers_doc_status', 'uploading');
      } else if (values.whitepapers_doc && !values.whitepapers_doc_url) {
        data.append('whitepapers_doc', values.whitepapers_doc);
        data.append('whitepapers_doc_status', 'uploading');
      } else {
        data.append('whitepapers_doc_url', values.whitepapers_doc_url);
        data.append('whitepapers_doc_status', 'url_added');
      }

      if (values.pitch_decks_doc && values.pitch_decks_doc_url) {
        data.append('pitch_decks_doc', values.pitch_decks_doc);
        data.append('pitch_decks_doc_status', 'uploading');
      } else if (values.pitch_decks_doc && !values.pitch_decks_doc_url) {
        data.append('pitch_decks_doc', values.pitch_decks_doc);
        data.append('pitch_decks_doc_status', 'uploading');
      } else {
        data.append('pitch_decks_doc_url', values.pitch_decks_doc_url);
        data.append('pitch_decks_doc_status', 'url_added');
      }

      let stepsRequired = Math.floor(StepsCalculator(2, completeStep, 64));
      data.append('percentage_complete', stepsRequired);

      if (applicationId !== '' && !hasError) {
        setIsLoading(true);
        UpdateExchangeApplication({ application_id: applicationId }, data)
          .then((res) => {
            const application = get(res, 'data.data.data', '');
            setApplicationData(application);
            if (isUpdateExchange) {
              toast.success("Project's Whitepaper + Pitch deck updated successfully", {
                style: { fontSize: '12px' }
              });
              GetApplicationDetailsEdit(applicationId);
            }
            nextPage();
            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
            const errorMessage = get(err, 'data.error.message');
            toast.error(notificationMessageFunction(errorMessage), {
              style: { fontSize: '12px' }
            });
          });
      }
    }
    setExchangeCompleteStep(Array.from({ length: 13 }, (_, index) => index));
  };

  return (
    <React.Fragment>
      <div onClick={isSectionCollapsed ? toggleSection : undefined}>
        {isUpdateExchange ? (
          <>
            <div
              className='cursor-pointer col-12 font-size-14 ml-2 d-flex justify-content-between align-items-center'
              onClick={toggleSection}>
              <span
                className='text-black font-weight-400'
                style={{ color: primaryTextState }}>
                Project's Whitepaper + Pitch deck
              </span>
              <i
                className={`font-size-22 mdi mdi-chevron-${
                  isSectionCollapsed === true ? 'up' : 'down'
                }`}
                style={{ color: primaryTextState }}
              />
            </div>
          </>
        ) : (
          <div className='col-12 font-size-16 pb-2 ml-2'>
            <span
              className='text-black font-weight-400'
              style={{ color: primaryTextState }}>
              Project's Whitepaper + Pitch deck
            </span>
          </div>
        )}
        <Collapse isOpen={!isSectionCollapsed}>
          {isLoading ? (
            <div className='mt-5'>
              <Loader />
            </div>
          ) : (
            <Container className='col-12 col-sm-auto pt-4'>
              <div>
                <Formik
                  enableReinitialize={true}
                  initialValues={initialValue}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}>
                  {({ errors, values, handleChange, setFieldValue }) => (
                    <Form>
                      <div className=''>
                        <Col>
                          <CustomFileInput
                            labelName="Project's Whitepapers"
                            fieldName='whitepapers_doc'
                            errors={errors}
                            headerName={'Whitepapers'}
                            values={values}
                            handleChange={handleChange}
                            setFieldValue={setFieldValue}
                            primaryTextState={primaryTextState}
                          />
                        </Col>
                        <Col>
                          <CustomFileInput
                            labelName="Project's Pitch Decks"
                            fieldName='pitch_decks_doc'
                            errors={errors}
                            headerName={'Pitch decks'}
                            values={values}
                            handleChange={handleChange}
                            setFieldValue={setFieldValue}
                            primaryTextState={primaryTextState}
                          />
                        </Col>
                      </div>

                      <div className='d-flex justify-content-between align-items-center mt-4 mr-2'>
                        <i
                          className='mdi mdi-chevron-double-left font-size-32 ml-1 cursor-pointer'
                          onClick={prevPage}
                          style={{ color: primaryTextState }}
                        />
                        <div>
                          {(applicationStatus === 'INCOMPLETE' || !applicationStatus) && (
                            <button
                              className='btn btn-status-change text-white mr-2'
                              type='submit'
                              style={{ width: '142px', height: '50px' }}
                              onClick={() =>
                                handleSubmitLiteApplication({
                                  applicationId,
                                  setApplicationData,
                                  history,
                                  paramsValue,
                                  setIsLoading
                                })
                              }>
                              Submit
                            </button>
                          )}
                          <button
                            className='btn application-button text-white'
                            type='submit'
                            onSubmit={(values) => handleSubmit(values)}>
                            Next
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </Container>
          )}
        </Collapse>
      </div>
    </React.Fragment>
  );
}

export default Step13;

const CustomFileInput = ({
  labelName,
  fieldName,
  errors,
  headerName,
  values,
  handleChange,
  setFieldValue,
  primaryTextState
}) => {
  const fileInputRef = useRef(null);
  const handleFileButtonClick = () => {
    fileInputRef.current.click();
  };
  return (
    <FormGroup className='mb-3'>
      <div className='font-size-12'>
        <Label
          htmlFor='description'
          className={`text-black cursor-pointer font-weight-normal ${
            !labelName ? 'mt-3' : 'mt-1'
          }`}
          data-replace={`${labelName}`}
          style={{ color: primaryTextState }}>
          <span>
            {labelName}:{' '}
            <span className='font-weight-medium'>
              {values[fieldName + '_status'] === 'uploaded'
                ? 'Uploaded'
                : values[fieldName + '_status'] === 'uploading'
                ? 'Uploading'
                : values[fieldName + '_status'] === 'upload_error'
                ? 'Upload Error'
                : ''}
            </span>
          </span>
        </Label>
      </div>
      <div className='file-upload d-flex direction-row justify-content-between align-items-center pl-1'>
        <Label
          htmlFor={values[fieldName]}
          className='mt-2'
          onClick={handleFileButtonClick}
          style={{ color: primaryTextState }}>
          <div className='cursor-pointer'>
            <span className='rounded-circle' style={{ color: '#134a87', fontSize: 12 }}>
              {values[fieldName] ? (
                <img src={documentUploadedIcon} alt={values[fieldName]} />
              ) : (
                <img src={documentUploadIcon} alt={values[fieldName]} />
              )}
            </span>
            <span className='font-size-12 ml-2'>{headerName}</span>
          </div>
        </Label>
        <div>
          {values[fieldName] !== null && typeof values[fieldName] === 'object' ? (
            <a
              href={URL.createObjectURL(values[fieldName])}
              target='_blank'
              rel='noopener noreferrer'
              className='pr-2 font-size-12 font-weight-bold text-wrap text-primary'>
              View File
            </a>
          ) : values[fieldName] !== null &&
            typeof values[fieldName] === 'string' &&
            values[fieldName].length > 0 ? (
            <a
              href={values[fieldName]}
              target='_blank'
              rel='noopener noreferrer'
              className='pr-2 font-size-12 font-weight-bold text-wrap text-primary'>
              View File
            </a>
          ) : (
            ''
          )}
        </div>
        <input
          type='file'
          name={fieldName}
          style={{ display: 'none' }}
          ref={fileInputRef}
          onChange={(event) => {
            setFieldValue(fieldName, event.currentTarget.files[0]);
          }}
        />

        <ReactTooltip
          anchorId={fieldName}
          place='bottom'
          content={values[fieldName] && values[fieldName].name}
        />
      </div>
      <ErrorMessage name={fieldName}>
        {(msg) => (
          <div style={{ fontSize: 12 }} className='text-left mt-1 text-danger'>
            {msg}
          </div>
        )}
      </ErrorMessage>

      <Row className='mt-2 d-flex justify-content-center align-items-center'>
        <Col
          lg={2}
          className='d-flex justify-content-center align-items-center'
          style={{ color: primaryTextState }}>
          OR
        </Col>
      </Row>

      <div className='mt-2'>
        <AuthInput
          type='text'
          name={fieldName + '_url'}
          values={values}
          className='font-size-12'
          handleChange={handleChange}
          placeholder={CONST.ENTER_URL_PLACEHOLDER}
          errors={errors}
        />
      </div>
    </FormGroup>
  );
};
