export const DevURL = `${process.env.REACT_APP_BACKEND_RNALYS_HOST}/api/`;
// export const DevURL = 'http://localhost:3005/api/';

const BUSINESS = 'business';
const ADMIN = 'admin';
const ONRAMP = 'onramp';
const CRYPTO_ASSETS = 'crypto_assets';
const NETWORK = 'network';
const BUSINESS_APPLICATION = 'business/application';
export const NEWS = 'news';
export const MERCURYO_WIDGET = 'mercuryo_widget';
export const GET_ETH = 'get_eth';
export const NETWORK_API = NETWORK;

export const APPLICATIONS = BUSINESS + '/application';
export const CREATE_APPLICATION = BUSINESS_APPLICATION + '/create_application';
export const GET_APPLICATION = BUSINESS_APPLICATION + '/get_application';
export const EDIT_APPLICATION = BUSINESS_APPLICATION + '/edit_application';
export const DELETE_APPLICATION = BUSINESS_APPLICATION + '/delete_application';
export const EDIT_EXCHANGE_APPLICATION =
  BUSINESS_APPLICATION + '/edit_exchange_application';
export const EDIT_USER = BUSINESS_APPLICATION + '/edit_application/edit_user';
export const DELETE_USER = BUSINESS_APPLICATION + '/edit_application/delete_user';
export const UPLOAD_DOCS = BUSINESS_APPLICATION + '/upload_docs';
export const APPLICATION_RESPONSE = BUSINESS_APPLICATION + '/application_response';
export const APPLICATION_NOTIFICATION =
  BUSINESS_APPLICATION + '/application_notification';
export const DELETE_NOTIFICATION = BUSINESS_APPLICATION + '/notification_read';
export const EDIT_ONRAMP_APPLICATION = BUSINESS_APPLICATION + '/edit_onramp_application';
export const PAY_QUOTE = BUSINESS_APPLICATION + '/pay_quote';
export const CONFIRM_PAYMENT = BUSINESS_APPLICATION + '/confirm_payment';
export const REQUEST_ACTION = BUSINESS_APPLICATION + '/request_action';
export const ANS_QUESTIONS = BUSINESS_APPLICATION + '/add_questions';

export const URL = DevURL;
