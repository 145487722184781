import React from 'react';
import SimpleBar from 'simplebar-react';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import step1 from '../../../assets/images/step1.png';
import step1_2 from '../../../assets/images/step1_2.png';
import step2 from '../../../assets/images/step2.png';
import step2_2 from '../../../assets/images/step2_2.png';
import step3 from '../../../assets/images/step3.png';
import step3_2 from '../../../assets/images/step3_2.png';
import step4 from '../../../assets/images/step4.png';
import step4_2 from '../../../assets/images/step4_2.png';
import step5 from '../../../assets/images/step5.png';
import step5_2 from '../../../assets/images/step5_2.png';
import step6 from '../../../assets/images/step6.png';
import step6_2 from '../../../assets/images/step6_2.png';
import step7 from '../../../assets/images/step7.png';
import step7_2 from '../../../assets/images/step7_2.png';
import lite from '../../../assets/images/lite.png';
import lite_2 from '../../../assets/images/lite_2.png';
import step8 from '../../../assets/images/step8.png';
import step8_2 from '../../../assets/images/step8_2.png';
import step9 from '../../../assets/images/step9.png';
import step9_2 from '../../../assets/images/step9_2.png';
import step10 from '../../../assets/images/step10.png';
import step10_2 from '../../../assets/images/step10_2.png';
import step11 from '../../../assets/images/step11.png';
import step11_2 from '../../../assets/images/step11_2.png';
import step12 from '../../../assets/images/step12.png';
import step12_2 from '../../../assets/images/step12_2.png';
import step13 from '../../../assets/images/step13.png';
import step13_2 from '../../../assets/images/step13_2.png';
import step14 from '../../../assets/images/step14.png';
import step14_2 from '../../../assets/images/step14_2.png';
import step15 from '../../../assets/images/step15.png';
import step15_2 from '../../../assets/images/step15_2.png';
import step16 from '../../../assets/images/step16.png';
import step16_2 from '../../../assets/images/step16_2.png';
import step17 from '../../../assets/images/step17.png';
import step17_2 from '../../../assets/images/step17_2.png';
import step18 from '../../../assets/images/step18.png';
import step18_2 from '../../../assets/images/step18_2.png';
import step19 from '../../../assets/images/step19.png';
import step19_2 from '../../../assets/images/step19_2.png';
import step20 from '../../../assets/images/step20.png';
import step20_2 from '../../../assets/images/step20_2.png';
import step21 from '../../../assets/images/step21.png';
import step21_2 from '../../../assets/images/step21_2.png';
import step22 from '../../../assets/images/step22.png';
import step22_2 from '../../../assets/images/step22_2.png';
import step23 from '../../../assets/images/step23.png';
import step23_2 from '../../../assets/images/step23_2.png';
import step24 from '../../../assets/images/step24.png';
import step24_2 from '../../../assets/images/step24_2.png';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: 'linear-gradient(180deg, #0055B9 0%, #5590D6 100%)'
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: 'linear-gradient(180deg, #0055B9 0%, #5590D6 100%)'
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1
  }
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: ownerState.active ? '#FFF' : '#FFF',
  border: '2px solid #0055B9',
  zIndex: 1,
  color: ownerState.active ? '#FFF' : '#0055B9',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage: 'linear-gradient(180deg, #0055B9 0%, #5590D6 100%)'
  }),
  ...(ownerState.completed && {
    backgroundImage: '#FFF',
    border: '2px solid #0055B9'
  })
}));

function ColorlibStepIcon(props) {
  const { active, completed, className, icon } = props;

  const icons = {
    1: <img src={icon === 1 && active ? step1 : step1_2} alt='step1' height={18} />,
    2: <img src={icon === 2 && active ? step2 : step2_2} alt='step2' height={18} />,
    3: <img src={icon === 3 && active ? step3 : step3_2} alt='step3' height={18} />,
    4: <img src={icon === 4 && active ? step4 : step4_2} alt='step4' height={18} />,
    5: <img src={icon === 5 && active ? step5 : step5_2} alt='step5' height={18} />,
    6: <img src={icon === 6 && active ? step6 : step6_2} alt='step6' height={18} />,
    7: <img src={icon === 7 && active ? step7 : step7_2} alt='step7' height={18} />,
    8: <img src={icon === 8 && active ? lite : lite_2} alt='step8' height={18} />,
    9: <img src={icon === 9 && active ? step8 : step8_2} alt='step8' height={18} />,
    10: <img src={icon === 10 && active ? step9 : step9_2} alt='step9' height={18} />,
    11: <img src={icon === 11 && active ? step10 : step10_2} alt='step10' height={18} />,
    12: <img src={icon === 12 && active ? step11 : step11_2} alt='step11' height={18} />,
    13: <img src={icon === 13 && active ? step12 : step12_2} alt='step12' height={18} />,
    14: <img src={icon === 14 && active ? step13 : step13_2} alt='step13' height={18} />,
    15: <img src={icon === 15 && active ? step14 : step14_2} alt='step14' height={18} />,
    16: <img src={icon === 16 && active ? step15 : step15_2} alt='step15' height={18} />,
    17: <img src={icon === 17 && active ? step16 : step16_2} alt='step16' height={18} />,
    18: <img src={icon === 18 && active ? step17 : step17_2} alt='step17' height={18} />,
    19: <img src={icon === 19 && active ? step18 : step18_2} alt='step18' height={18} />,
    20: <img src={icon === 20 && active ? step19 : step19_2} alt='step19' height={18} />,
    21: <img src={icon === 21 && active ? step20 : step20_2} alt='step20' height={18} />,
    22: <img src={icon === 22 && active ? step21 : step21_2} alt='step21' height={18} />,
    23: <img src={icon === 23 && active ? step22 : step22_2} alt='step22' height={18} />,
    24: <img src={icon === 24 && active ? step23 : step23_2} alt='step23' height={18} />,
    25: <img src={icon === 25 && active ? step24 : step24_2} alt='step24' height={18} />
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

const steps = [
  'Application Background',
  'Project Background',
  'Team Members',
  'Social Media',
  'Coin Marketcap',
  'Chains',
  'Active Users',
  'Lite Complete',
  'Trading Volume',
  'Market Makers',
  'Influencers or Marketing Partners',
  'Promotional Strategies',
  'Exchange Marketing Budget',
  'Project Docs',
  'Programs of Interest',
  'Listing Date',
  'Listing Price',
  'Auditing report',
  'Tokenomics',
  'Investment History',
  'Roadmap',
  'Project Uniqueness',
  'TargetGeo',
  'Demo',
  'Additional Details'
];

export default function HorizontalStepper({
  activeStep,
  setActiveStep,
  exchangeCompleteStep,
  primaryTextState,
  secondaryTextState
}) {
  return (
    <Stack sx={{ width: '100%' }} spacing={4}>
      <SimpleBar style={{ maxHeight: '110px', overflowY: 'hidden' }}>
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<ColorlibConnector />}>
          {steps.map((step, index) => (
            <Step key={step}>
              <StepLabel
                classes={{
                  label: `font-size-10 ${
                    activeStep === index
                      ? 'font-weight-bold text-black'
                      : index < activeStep
                      ? 'text-grey'
                      : 'text-grey'
                  }`
                }}
                StepIconComponent={ColorlibStepIcon}
                onClick={() =>
                  exchangeCompleteStep.includes(index) && setActiveStep(index)
                }
                completed={index < activeStep}>
                <span
                  style={{
                    color: activeStep === index ? primaryTextState : secondaryTextState
                  }}>
                  {step}
                </span>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </SimpleBar>
    </Stack>
  );
}
