import React, { useEffect, useRef } from 'react';
import { Col, Collapse, Container, FormGroup, Input, Label } from 'reactstrap';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import get from 'lodash/get';
import Select from 'react-select';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useState } from 'react';
import { constants as CONST } from '../../../constants/application';
import { constants as ERROR_CONST } from '../../../constants/errorMessage';
import documentUploadIcon from '../../../assets/images/documentupload.png';
import documentUploadedIcon from '../../../assets/images/documentuploaded.png';
import { notificationMessageFunction } from '../../../constants/notificationConst';
import {
  CreateApplication,
  GetAllNetwork,
  GetApplication,
  UpdateApplication
} from '../../../services/Application';
import toast from 'react-hot-toast';
import AuthInput from '../../../Custom/AuthInput';
import { ReferralCodeDetailsAPI } from '../../../services/Auth';
import { constants as STORAGEKEY } from '../../../constants/constant';

function Step1({
  applicationId,
  setApplicationId,
  nextPage,
  CustomInput,
  setOnrampCompleteStep,
  isUpdateOnramp = false,
  isSectionCollapsed,
  toggleSection,
  applicationData,
  setApplicationData,
  primaryTextState,
  primaryColorState
}) {
  const TickerSchema = Yup.object().shape({
    ticker_name: Yup.string().min(2, ERROR_CONST.NAME).max(5, ERROR_CONST.NAME)
  });
  const GeneralInfoSchema = Yup.object()
    .shape({
      legal_entity_name: Yup.string().required(ERROR_CONST.COMPANY_NAME),
      project_name: Yup.string().required(ERROR_CONST.PROJECT_NAME),
      legal_entity_register_number: Yup.string()
        .required(ERROR_CONST.REGISTER_NUMBER)
        .matches(/^\S*$/, ERROR_CONST.SPACE_NOT_ALLOWED),
      website: Yup.string().required(ERROR_CONST.WEBSITE).url(ERROR_CONST.INVALID_URL),
      business_category: Yup.string().required(ERROR_CONST.BUSINESS_CATEGORY),
      country_of_incorporation: Yup.string().required(ERROR_CONST.COUNTRY),
      token_address: Yup.string().required(ERROR_CONST.TOKEN_ADDRESS),
      product_description: Yup.string().required(ERROR_CONST.DESCRIPTION),
      network: Yup.string(),
      other_network: Yup.string().when('network', {
        is: (value) => value === 'Other',
        then: Yup.string().required(ERROR_CONST.NETWORK)
      }),
      ticker: Yup.string(),
      ticker_logo: Yup.mixed().required(ERROR_CONST.TICKER_LOGO_REQUIRED)
    })
    .concat(TickerSchema);

  const [initialValue, setInitialValue] = useState({
    legal_entity_name: '',
    project_name: '',
    legal_entity_register_number: '',
    website: '',
    business_category: '',
    country_of_incorporation: '',
    token_address: '',
    product_description: '',
    ticker: '',
    ticker_name: '',
    ticker_logo: '',
    network: '',
    other_network: ''
  });
  const [buyCheck, setBuyCheck] = useState(false);
  const [sellCheck, setSellCheck] = useState(false);
  const [selectedFields, setSelectedFields] = useState([]);
  const [selectedCategoryFields, setSelectedCategoryFields] = useState([]);
  const [otherNetwork, setOtherNetwork] = useState(false);
  const [allNetworks, setAllNetworks] = useState([]);
  const businesscategory = [
    'Blockchain',
    'NFT',
    'CeFi',
    'DeFi',
    'Gaming',
    'Metaverse',
    'DAO',
    'Ecommerce',
    'Enterprise',
    'Infrastructure',
    'Wallet',
    'Other'
  ];
  const [businessCategory] = useState(
    businesscategory.map((i) => {
      return { label: i, value: i };
    })
  );
  useEffect(() => {
    ReferralCodeDetailsAPI(window.location.hostname)
      .then((res) => {
        const referralDetails = res?.data?.data?.data;
        localStorage.setItem(STORAGEKEY.REFERRED_USER_ID, referralDetails.user_id || '');
      })
      .catch((err) => {});
    if (applicationId !== '' && !isUpdateOnramp) {
      GetApplication({ application_id: applicationId }).then((res) => {
        const application = get(res, 'data.data.data[0]', '');
        setApplicationData(application);
        setInitialValue({
          legal_entity_name: application.legal_entity_name,
          project_name: application.project_name,
          legal_entity_register_number: application.legal_entity_register_number,
          website: application.website,
          business_category: application.business_category,
          country_of_incorporation: application.country_of_incorporation,
          token_address: application.token_address,
          product_description: application.product_description,
          ticker: application.ticker,
          ticker_name: application.ticker_name,
          ticker_logo: application.ticker_logo,
          network: application.network,
          other_network: application.other_network
        });

        setBuyCheck(application.product_enable_buy);
        setSellCheck(application.product_enable_sell);
        setSelectedCategoryFields({
          label: application.business_category,
          value: application.business_category
        });
        if (application.network) {
          setSelectedFields({ label: application.network, value: application.network });
          if (application.network === 'Other') setOtherNetwork(true);
        }
      });
    } else {
      if (applicationData) {
        setInitialValue({
          legal_entity_name: applicationData.legal_entity_name,
          project_name: applicationData.project_name,
          legal_entity_register_number: applicationData.legal_entity_register_number,
          website: applicationData.website,
          business_category: applicationData.business_category,
          country_of_incorporation: applicationData.country_of_incorporation,
          token_address: applicationData.token_address,
          product_description: applicationData.product_description,
          ticker: applicationData.ticker,
          ticker_name: applicationData.ticker_name,
          ticker_logo: applicationData.ticker_logo,
          network: applicationData.network,
          other_network: applicationData.other_network
        });
      }
    }
    GetAllNetwork()
      .then((res) => {
        const GetData = get(res, 'data.data.data', []);
        const setFinalData = GetData.map((item) => ({
          label: item.network_symbol,
          value: item.network_symbol
        }));

        setAllNetworks([...setFinalData, { label: 'Other', value: 'Other' }]);
      })
      .catch((err) => {});
  }, [applicationData, applicationId, isUpdateOnramp]);

  const handleSubmit = (values, { setFieldError }) => {
    const payloadData = new FormData();
    payloadData.append('application_type', 'ONRAMP');
    payloadData.append('application_status', 'INCOMPLETE');
    payloadData.append('legal_entity_name', values.legal_entity_name.toLowerCase());
    payloadData.append('project_name', values.project_name);
    payloadData.append(
      'legal_entity_register_number',
      values.legal_entity_register_number
    );
    payloadData.append('website', values.website);
    payloadData.append('business_category', values.business_category);
    payloadData.append('country_of_incorporation', values.country_of_incorporation);
    payloadData.append('token_address', values.token_address);
    payloadData.append('product_enable_buy', buyCheck);
    payloadData.append('product_enable_sell', sellCheck);
    payloadData.append('product_description', values.product_description);
    payloadData.append('ticker', values.ticker ? values.ticker : 'Name');
    payloadData.append('ticker_name', values.ticker_name);

    if (
      values.ticker_logo &&
      typeof values.ticker_logo !== 'string' &&
      values.ticker_logo.size / 1024 > 50000
    ) {
      setFieldError('ticker_logo', 'File size should be less than 50 MB');
      return;
    }
    payloadData.append('ticker_logo', values.ticker_logo);

    payloadData.append('network', selectedFields.label ? selectedFields.label : '');
    if (selectedFields.label && selectedFields.label === 'Other') {
      payloadData.append('other_network', values.other_network);
    } else {
      payloadData.append('other_network', '');
    }
    if (applicationId !== '') {
      UpdateApplication(applicationId, payloadData)
        .then((res) => {
          nextPage();
        })
        .catch((err) => {
          const errorMessage = get(err, 'data.error.message');
          toast.error(notificationMessageFunction(errorMessage), {
            style: { fontSize: '12px' }
          });
        });
    } else {
      payloadData.append(
        'referred_user_id',
        localStorage.getItem(STORAGEKEY.REFERRED_USER_ID)
      );
      CreateApplication(payloadData)
        .then((res) => {
          setApplicationId(get(res, 'data.data.data', ''));
          nextPage();
        })
        .catch((err) => {
          const errorMessage = get(err, 'data.error.message');
          toast.error(notificationMessageFunction(errorMessage), {
            style: { fontSize: '12px' }
          });
        });
    }
    setOnrampCompleteStep(Array.from({ length: 1 }, (_, index) => index));
  };

  const handleChangeOpt = (selected, setFieldValue) => {
    if (selected.value === 'Other') {
      setOtherNetwork(true);
      setSelectedFields(selected);
      setFieldValue('network', selected.value);
      return;
    }
    setOtherNetwork(false);
    setFieldValue('network', selected.value);
    setSelectedFields(selected);
  };

  const fileInputRef = useRef(null);

  const handleFileButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <React.Fragment>
      <div onClick={isSectionCollapsed ? toggleSection : undefined}>
        {isUpdateOnramp ? (
          <>
            <div
              className='cursor-pointer col-12 font-size-14 ml-2 d-flex justify-content-between align-items-center'
              onClick={toggleSection}>
              <span className='font-weight-400' style={{ color: primaryTextState }}>
                General Information
              </span>
              <i
                className={`font-size-22 mdi mdi-chevron-${
                  isSectionCollapsed === true ? 'up' : 'down'
                }`}
                style={{ color: primaryTextState }}
              />
            </div>
          </>
        ) : (
          <div className='col-12 font-size-16 pb-2 ml-2'>
            <span className='font-weight-400' style={{ color: primaryTextState }}>
              General Information
            </span>
          </div>
        )}
        <Collapse isOpen={!isSectionCollapsed}>
          <Container className='pt-3 pt-md-1'>
            <div>
              <Formik
                enableReinitialize={true}
                initialValues={initialValue}
                validationSchema={GeneralInfoSchema}
                onSubmit={handleSubmit}>
                {({
                  errors,
                  touched,
                  values,
                  handleChange,
                  setFieldValue,
                  setFieldTouched
                }) => (
                  <Form>
                    <div>
                      <Col>
                        <CustomInput
                          label={CONST.LEGAL_ENTITY}
                          htmlFor='legal_entity'
                          setFieldTouched={setFieldTouched}
                          name='legal_entity_name'
                          placeholder={CONST.ENTER_LEGAL_ENTITY}
                          values={values}
                          handleChange={handleChange}
                          errors={errors}
                          touched={touched}
                          primaryTextState={primaryTextState}
                        />
                      </Col>
                      <Col>
                        <CustomInput
                          label={CONST.PROJECT_NAME}
                          htmlFor='project_name'
                          setFieldTouched={setFieldTouched}
                          name='project_name'
                          placeholder={CONST.ENTER_PROJECT_NAME}
                          values={values}
                          handleChange={handleChange}
                          errors={errors}
                          touched={touched}
                          primaryTextState={primaryTextState}
                        />
                      </Col>

                      <Col>
                        <FormGroup className='mb-3'>
                          <div className='font-size-12'>
                            <Label
                              htmlFor='business_category'
                              style={{ color: primaryTextState }}
                              className='text-black font-weight-normal'>
                              {CONST.CATEGORY}
                            </Label>
                          </div>
                          <div>
                            <div
                              className={
                                errors['business_category'] &&
                                touched['business_category']
                                  ? 'border-danger'
                                  : ''
                              }>
                              <Select
                                isClearable={false}
                                isSearchable={true}
                                className='font-size-12 border-grey'
                                name='business_category'
                                options={businessCategory}
                                value={selectedCategoryFields}
                                styles={{
                                  control: (styles) => ({
                                    ...styles,
                                    backgroundColor: primaryColorState,
                                    borderRadius: '8px'
                                  }),
                                  singleValue: (styles) => ({
                                    ...styles,
                                    color: primaryTextState
                                  })
                                }}
                                onChange={(e) => {
                                  setSelectedCategoryFields(e);
                                  setFieldValue('business_category', e.value);
                                }}
                                placeholder='Choose Category'
                              />
                            </div>
                            <ErrorMessage name='business_category'>
                              {(errorMsg) => (
                                <div style={{ color: 'red' }}>{errorMsg}</div>
                              )}
                            </ErrorMessage>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col>
                        <CustomInput
                          label={CONST.REGISTRATION_NO}
                          htmlFor='legal_entity_register_number'
                          setFieldTouched={setFieldTouched}
                          name='legal_entity_register_number'
                          placeholder={CONST.ENTER_REGISTRATION_NO}
                          values={values}
                          handleChange={handleChange}
                          errors={errors}
                          touched={touched}
                          primaryTextState={primaryTextState}
                        />
                      </Col>

                      <Col>
                        <FormGroup className='mb-3 mt-3'>
                          <div className='d-flex justify-content-between font-size-12'>
                            <Label
                              htmlFor='products'
                              className='text-black font-weight-400'
                              style={{ color: primaryTextState }}>
                              {CONST.PRODUCTS}
                            </Label>
                            <div className='d-flex direction-row'>
                              <div className='form-check custom-checkbox mr-3'>
                                <Input
                                  name='product_buy_enable'
                                  type='checkbox'
                                  onChange={() => setBuyCheck(!buyCheck)}
                                  checked={buyCheck}
                                  style={{ height: 14, width: 14 }}
                                  value={buyCheck}
                                  className='form-check-input mt-1'
                                />
                                <Label
                                  className='form-check-label font-size-12'
                                  style={{ color: primaryTextState }}
                                  onClick={() => {
                                    setBuyCheck(!buyCheck);
                                  }}>
                                  {CONST.BUY}
                                </Label>
                              </div>
                              <div className='form-check custom-checkbox'>
                                <Input
                                  name='product_sell_enable'
                                  type='checkbox'
                                  value={sellCheck}
                                  style={{ height: 14, width: 14 }}
                                  className='form-check-input mt-1'
                                  onChange={() => setSellCheck(!sellCheck)}
                                  checked={sellCheck}
                                />
                                <Label
                                  className='form-check-label font-size-12'
                                  style={{ color: primaryTextState }}
                                  onClick={() => {
                                    setSellCheck(!sellCheck);
                                  }}>
                                  {CONST.SELL}
                                </Label>
                              </div>
                            </div>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col>
                        <CustomInput
                          label={CONST.TOKEN}
                          htmlFor='token_address'
                          setFieldTouched={setFieldTouched}
                          name='token_address'
                          placeholder={CONST.ENTER_TOKEN_ADDRESS_NAME}
                          values={values}
                          handleChange={handleChange}
                          errors={errors}
                          touched={touched}
                          primaryTextState={primaryTextState}
                        />
                      </Col>

                      <Col>
                        <CustomInput
                          label={CONST.COUNTRY}
                          htmlFor='country_of_incorporation'
                          setFieldTouched={setFieldTouched}
                          name='country_of_incorporation'
                          placeholder={CONST.ENTER_COUNTRY}
                          values={values}
                          handleChange={handleChange}
                          errors={errors}
                          touched={touched}
                          primaryTextState={primaryTextState}
                        />
                      </Col>
                      <Col>
                        <CustomInput
                          label={CONST.WEBSITE}
                          htmlFor='website'
                          setFieldTouched={setFieldTouched}
                          name='website'
                          placeholder={CONST.ENTER_PROJECT_WEBSITE}
                          values={values}
                          handleChange={handleChange}
                          errors={errors}
                          touched={touched}
                          primaryTextState={primaryTextState}
                        />
                      </Col>

                      <Col>
                        <FormGroup style={{ marginBottom: '1.80rem' }}>
                          <div className='font-size-12'>
                            <Label
                              htmlFor='network'
                              style={{ color: primaryTextState }}
                              className='text-black font-weight-normal'>
                              {CONST.NETWORK}
                            </Label>
                          </div>
                          <div className={errors['network'] ? 'border-danger' : ''}>
                            <Select
                              isClearable={false}
                              isSearchable={true}
                              name='network'
                              className='font-size-12'
                              options={allNetworks}
                              styles={{
                                control: (styles) => ({
                                  ...styles,
                                  backgroundColor: primaryColorState,
                                  borderRadius: '8px'
                                }),
                                singleValue: (styles) => ({
                                  ...styles,
                                  color: primaryTextState
                                })
                              }}
                              onChange={(e) => handleChangeOpt(e, setFieldValue)}
                              value={selectedFields}
                            />
                          </div>
                          {otherNetwork && (
                            <>
                              <div className='mt-2'>
                                <AuthInput
                                  setFieldTouched={setFieldTouched}
                                  withTouchError={true}
                                  type='text'
                                  placeholder={'Enter network'}
                                  name='other_network'
                                  values={values}
                                  handleChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                />
                              </div>
                              <ErrorMessage name='listing_program_at_bybit'>
                                {(errorMsg) => (
                                  <div style={{ color: 'red' }}>{errorMsg}</div>
                                )}
                              </ErrorMessage>
                            </>
                          )}
                        </FormGroup>

                        <CustomInput
                          label={CONST.TICKER}
                          htmlFor='ticker'
                          setFieldTouched={setFieldTouched}
                          name='ticker'
                          placeholder={CONST.ENTER_TICKER}
                          values={values}
                          handleChange={handleChange}
                          errors={errors}
                          touched={touched}
                          primaryTextState={primaryTextState}
                        />
                      </Col>
                      <Col>
                        <CustomInput
                          label={CONST.DESCRIPTION}
                          htmlFor='product_description'
                          setFieldTouched={setFieldTouched}
                          name='product_description'
                          placeholder={CONST.ENTER_DESCRIPTION}
                          values={values}
                          handleChange={handleChange}
                          errors={errors}
                          touched={touched}
                          type='textarea'
                          primaryTextState={primaryTextState}
                        />
                      </Col>

                      <Col>
                        <CustomInput
                          label={CONST.TICKER_NAME}
                          htmlFor='ticker_name'
                          setFieldTouched={setFieldTouched}
                          name='ticker_name'
                          placeholder={CONST.TICKER_NAME}
                          values={values}
                          handleChange={handleChange}
                          errors={errors}
                          touched={touched}
                          primaryTextState={primaryTextState}
                        />
                      </Col>
                      <Col>
                        <FileUploadFunction
                          handleFileButtonClick={handleFileButtonClick}
                          values={values}
                          fileInputRef={fileInputRef}
                          setFieldValue={setFieldValue}
                          primaryTextState={primaryTextState}
                        />
                      </Col>
                    </div>
                    <div className='d-flex justify-content-end mr-2 mt-4'>
                      <button
                        className='btn application-button text-white'
                        type='submit'
                        onSubmit={(values) => handleSubmit(values)}>
                        Next
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Container>
        </Collapse>
      </div>
    </React.Fragment>
  );
}

export default Step1;

export const FileUploadFunction = ({
  handleFileButtonClick,
  values,
  fileInputRef,
  setFieldValue,
  primaryTextState
}) => {
  return (
    <FormGroup className='mb-3'>
      <div className='font-size-12'>
        <Label
          htmlFor='ticker_logo'
          className='text-black font-weight-normal'
          style={{ color: primaryTextState }}>
          Ticker Logo
        </Label>
      </div>
      <div className='file-upload d-flex direction-row justify-content-between align-items-center pl-1'>
        <Label htmlFor='ticker_logo' className='mt-2' onClick={handleFileButtonClick}>
          <div className='cursor-pointer'>
            <span className='rounded-circle' style={{ color: '#134a87', fontSize: 12 }}>
              {values.ticker_logo ? (
                <img src={documentUploadedIcon} alt='ticker_logo' />
              ) : (
                <img src={documentUploadIcon} alt='ticker_logo' />
              )}
            </span>
            <span className='font-size-12 ml-2' style={{ color: primaryTextState }}>
              {CONST.ICON}
            </span>
          </div>
        </Label>
        <div>
          {values.ticker_logo !== null && typeof values.ticker_logo === 'object' ? (
            <a
              href={URL.createObjectURL(values.ticker_logo)}
              target='_blank'
              rel='noopener noreferrer'
              className='pr-2 font-size-12 font-weight-bold text-wrap text-primary'
              style={{ color: primaryTextState }}>
              View File
            </a>
          ) : values.ticker_logo !== null &&
            typeof values.ticker_logo === 'string' &&
            values.ticker_logo.length > 0 ? (
            <a
              href={values.ticker_logo}
              target='_blank'
              rel='noopener noreferrer'
              className='pr-2 font-size-12 font-weight-bold text-wrap text-primary'
              style={{ color: primaryTextState }}>
              View File
            </a>
          ) : (
            ''
          )}
        </div>
        <input
          type='file'
          name='ticker_logo'
          style={{ display: 'none' }}
          ref={fileInputRef}
          onChange={(event) => {
            setFieldValue('ticker_logo', event.currentTarget.files[0]);
          }}
        />

        <ReactTooltip
          anchorId='ticker_logo'
          place='bottom'
          content={values.ticker_logo && values.ticker_logo.name}
        />
      </div>
      <ErrorMessage name='ticker_logo'>
        {(msg) => (
          <div style={{ fontSize: 12 }} className='text-left mt-1 text-danger'>
            {msg}
          </div>
        )}
      </ErrorMessage>
    </FormGroup>
  );
};
