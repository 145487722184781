import moment from 'moment';
import { stringify } from 'query-string';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

// import AddQuestionAnswer from '../../BusinessApplication/ViewOnRampApplication/add_questions_answer';

function GetResponse({
  application,
  index,
  expandedRows,
  setResponseId,
  actionToggle,
  answerToggle,
  setSubApplication,
  primaryColorState,
  primaryTextState,
  paramsValue
}) {
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };

  const ProfileIcon = ({ iconUrl }) => (
    <div style={{ padding: '5px' }}>
      {iconUrl && iconUrl.length > 0 ? (
        <img
          src={iconUrl}
          style={{ borderRadius: '50%' }}
          width={40}
          height={40}
          alt='user'
        />
      ) : (
        <i
          className='fa fa-user'
          style={{
            borderRadius: '50%',
            border: '1px solid #f0f0f0',
            width: '40px',
            height: '40px',
            background: '#f0f0f0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}></i>
      )}
    </div>
  );

  const statusSuggestions = [
    { id: 'REQUEST_ACTION', name: 'Action' },
    { id: 'ACCEPT', name: 'Accept' },
    { id: 'KYB', name: 'KYB' },
    { id: 'REJECT', name: 'Reject' },
    { id: 'QUOTE', name: 'Quote' },
    { id: 'PAID', name: 'Listd🎉' },
    { id: 'ADD_QUESTIONS', name: 'Add Question' },
    { id: 'PROCESSING', name: 'Processing' }
  ];

  const getSeverity = (status) => {
    const statusToSeverity = {
      REJECT: 'danger',
      ACCEPT: 'success',
      ADD_QUESTIONS: 'info',
      negotiation: 'warning',
      COMPLETE: 'success',
      QUOTE: 'secondary',
      renewal: 'secondary'
    };

    return statusToSeverity[status] || 'secondary';
  };

  const actionBodyTemplate = (rowData) => {
    if (rowData.applicationResponse.field) {
      return Object.keys(rowData.applicationResponse.field).length ===
        rowData.applicationResponse.completed_field.length ? (
        <div>Complete Action</div>
      ) : (
        <Link
          to={{
            pathname: '/sub-application/edit',
            search:
              `?${stringify({
                sub_application_id: rowData.applicationResponse.onramp_sub_application_id
              })}` +
              (paramsValue && paramsValue.widgetTheme
                ? `&widgetTheme=${paramsValue.widgetTheme}`
                : '') +
              (paramsValue && paramsValue.containerColor
                ? `&containerColor=${paramsValue.containerColor}`
                : '') +
              (paramsValue && paramsValue.primaryColor
                ? `&primaryColor=${paramsValue.primaryColor}`
                : '') +
              (paramsValue && paramsValue.primaryText
                ? `&primaryText=${paramsValue.primaryText}`
                : '') +
              (paramsValue && paramsValue.secondaryText
                ? `&secondaryText=${paramsValue.secondaryText}`
                : ''),
            state: rowData.applicationResponse
          }}>
          <button
            style={{ width: 120 }}
            className='btn btn-status-change font-weight-bold font-size-10'>
            Edit Application
          </button>
        </Link>
      );
    } else if (rowData.applicationResponse.status === 'ADD_QUESTIONS') {
      return rowData.applicationResponse.response_complete ? (
        <div>Complete Action</div>
      ) : (
        <div
          onClick={() => {
            answerToggle();
            setSubApplication(rowData._id);
            setResponseId(rowData.applicationResponse._id);
          }}
          style={{ width: 120 }}
          className='btn btn-status-change font-weight-boldfont-size-12'>
          Add Answer
        </div>
      );
    } else {
      return rowData.applicationResponse.status === 'QUOTE' &&
        rowData.applicationResponse.quote_price &&
        rowData.applicationResponse.quote_price.currency ? (
        <>
          <div
            onClick={() => {
              toggle();
              setSubApplication(rowData._id);
            }}
            style={{ width: 120 }}
            className='btn btn-status-change font-size-10 font-weight-bold'>
            Pay ${rowData.applicationResponse.quote_price.amount}
          </div>
        </>
      ) : rowData.applicationResponse.status === 'QUOTE' &&
        rowData.applicationResponse.quote_price &&
        rowData.applicationResponse.quote_price.crypto_currency ? (
        <>
          {rowData.application_status === 'PAID' ? (
            'Action Completed'
          ) : (
            <div
              onClick={() => {
                setSubApplication(rowData._id);
                const externalURL =
                  rowData.applicationResponse.payments_txs[0]?.invoiceLinks?.pay;
                const newTab = window.open(externalURL, '_blank');
                newTab.focus();
              }}
              style={{ width: 180 }}
              className='btn btn-status-change font-size-12 font-weight-medium'>
              Pay {rowData.applicationResponse.quote_price.amount}{' '}
              {rowData.applicationResponse.quote_price.crypto_currency.toUpperCase()}
            </div>
          )}
        </>
      ) : rowData.applicationResponse.status === 'REQUEST_ACTION' &&
        !rowData.applicationResponse.response_complete ? (
        <>
          {' '}
          <div
            onClick={() => {
              actionToggle();
              setResponseId(rowData.applicationResponse._id);
            }}
            className='btn cursor-pointer btn-status-change font-weight-bold font-size-12'>
            Complete Request Action
          </div>
        </>
      ) : (
        'Action Completed'
      );
    }
  };

  return (
    <React.Fragment>
      <div
        className='application-card-border subcard-shadow-application ml-1 mr-1'
        style={{ background: primaryColorState }}>
        <div className='d-flex justify-content-between align-items-center'>
          <span
            className='font-size-12 font-weight-500 text-dark-grey'
            style={{ color: primaryTextState }}>
            Requested By
          </span>
          <span className='d-flex flex-row'>
            <ProfileIcon
              width={14}
              height={14}
              iconUrl={
                application &&
                application.applicationResponse &&
                application.applicationResponse.logo_url
                  ? application.applicationResponse.logo_url
                  : ''
              }
            />
            <span
              className='font-size-12 font-weight-500 text-dark-grey d-flex justify-content-between align-items-center'
              style={{ color: primaryTextState }}>
              {application.applicationResponse &&
              application.applicationResponse.on_ramp_name
                ? application.applicationResponse.on_ramp_name
                : ''}
            </span>
          </span>
        </div>
        <div className='d-flex justify-content-between align-items-center mt-2'>
          <span
            className='font-size-12 font-weight-500 text-dark-grey'
            style={{ color: primaryTextState }}>
            Status
          </span>
          <span className='d-flex flex-row'>
            <span
              className={`px-2 py-1 font-size-12 badge badge-pill badge-${getSeverity(
                application.status
              )}`}>
              {
                statusSuggestions.find(
                  (status) => status.id === application.application_status
                ).name
              }
            </span>
          </span>
        </div>
        <div className='d-flex justify-content-between align-items-center mt-3'>
          <span
            className='font-size-12 font-weight-500 text-dark-grey'
            style={{ color: primaryTextState }}>
            Date Submitted
          </span>
          <span className='d-flex flex-row'>
            <span
              className='font-size-12 font-weight-500 text-dark-grey d-flex justify-content-between align-items-center'
              style={{ color: primaryTextState }}>
              {moment(application.applicationResponse.created_at).format(
                'DD/MM/YYYY hh:mm A'
              )}
            </span>
          </span>
        </div>
        <div className='d-flex justify-content-between align-items-center mt-3'>
          <span
            className='font-size-12 font-weight-500 text-dark-grey'
            style={{ color: primaryTextState }}>
            Needed Review
          </span>
          <span className='d-flex flex-row'>
            <span
              className='font-size-12 font-weight-500 text-dark-grey d-flex justify-content-between align-items-center'
              style={{ color: primaryTextState }}>
              {actionBodyTemplate(application)}
            </span>
          </span>
        </div>
        <hr />
        <div className='d-flex justify-content-between align-items-center mt-3'>
          <span
            className='font-size-12 font-weight-500 text-dark-grey'
            style={{ color: primaryTextState }}>
            Field Changes Requested
          </span>
          <span
            className='font-size-12 font-weight-500 text-dark-grey'
            style={{ color: primaryTextState }}>
            Notes
          </span>
        </div>
        <div className='d-flex justify-content-between align-items-center mt-3'>
          <span
            className='font-size-12 font-weight-500 text-dark-grey'
            style={{ color: primaryTextState }}>
            {application &&
            application.applicationResponse &&
            application.applicationResponse.field &&
            Object.keys(application.applicationResponse.field).length > 0 ? (
              Object.keys(application.applicationResponse.field).map((value, index) => (
                <div
                  className={
                    application.applicationResponse.completed_field.includes(value)
                      ? 'text-success'
                      : 'text-warning'
                  }
                  key={index}>
                  {index + 1}. {value}
                </div>
              ))
            ) : application &&
              application.application_status === 'ADD_QUESTIONS' &&
              application.add_questions &&
              application.add_questions.length > 0 ? (
              application.add_questions.map((que, index) => (
                <div key={index}>
                  {index + 1}. {que.type}
                </div>
              ))
            ) : (
              <div>No fields available</div>
            )}
          </span>
          <span
            className='font-size-12 font-weight-500 text-dark-grey'
            style={{ color: primaryTextState }}>
            {application.applicationResponse.field &&
            Object.keys(application.applicationResponse.field).length > 0 ? (
              Object.entries(application.applicationResponse.field).map(
                ([key, value]) => {
                  if (value.length > 0) {
                    const truncatedValue =
                      String(value).length > 20
                        ? `${String(value).substring(0, 20)}...`
                        : String(value);
                    return (
                      <div key={key} data-tip={value}>
                        &#8226; {truncatedValue}
                      </div>
                    );
                  } else {
                    return <div key={key} data-tip={value}></div>;
                  }
                }
              )
            ) : application && application.applicationResponse.notes ? (
              String(application.applicationResponse.notes).length > 20 ? (
                `${String(application.applicationResponse.notes).substring(0, 20)}...`
              ) : (
                String(application.applicationResponse.notes)
              )
            ) : application &&
              application.application_status === 'ADD_QUESTIONS' &&
              application.add_questions &&
              application.add_questions.length > 0 ? (
              application.add_questions.map((que, index) => (
                <div key={index}>
                  {index + 1}.
                  {String(que.question).length > 20
                    ? `${String(que.question).substring(0, 20)}
              ...`
                    : String(que.question)}
                </div>
              ))
            ) : (
              <div className='d-flex justify-content-center align-items-center'>
                <span>No notes available</span>{' '}
              </div>
            )}
          </span>
        </div>
        <div className='d-flex align-items-center justify-content-center mt-4 mb-2'>
          {expandedRows && expandedRows.includes(index) ? (
            <i
              className='mdi mdi-chevron-double-down font-size-24'
              style={{ color: primaryTextState }}
            />
          ) : (
            <i
              className='mdi mdi-chevron-double-up font-size-24'
              style={{ color: primaryTextState }}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default GetResponse;
