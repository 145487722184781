import React from 'react';
import { Container } from 'reactstrap';
import { UpdateExchangeApplication } from '../../../services/Application';
import get from 'lodash/get';
import toast from 'react-hot-toast';
import { notificationMessageFunction } from '../../../constants/notificationConst';
import { constants as ROUTE_CONST } from '../../../constants/routes';

function LiteApplication({
  applicationId,
  prevPage,
  nextPage,
  primaryTextState,
  setApplicationData,
  setIsLoading,
  history,
  paramsValue,
  setExchangeCompleteStep
}) {
  const handleSubmitLiteApplication = () => {
    let data = { application_status: 'LITE_COMPLETE' };

    UpdateExchangeApplication({ application_id: applicationId }, data)
      .then((res) => {
        const application = get(res, 'data.data.data', '');
        setApplicationData(application);
        history.push(
          ROUTE_CONST.VIEW_APPLICATIONS +
            '?' +
            (paramsValue && paramsValue.widgetTheme
              ? `&widgetTheme=${paramsValue.widgetTheme}`
              : '') +
            (paramsValue && paramsValue.containerColor
              ? `&containerColor=${paramsValue.containerColor}`
              : '') +
            (paramsValue && paramsValue.primaryColor
              ? `&primaryColor=${paramsValue.primaryColor}`
              : '') +
            (paramsValue && paramsValue.primaryText
              ? `&primaryText=${paramsValue.primaryText}`
              : '') +
            (paramsValue && paramsValue.secondaryText
              ? `&secondaryText=${paramsValue.secondaryText}`
              : '')
        );
        setIsLoading(false);
      })
      .catch((err) => {
        const errorMessage = get(err, 'data.error.message');
        toast.error(notificationMessageFunction(errorMessage), {
          style: { fontSize: '12px' }
        });
      });
  };

  const handleSubmit = () => {
    nextPage();
    setExchangeCompleteStep(Array.from({ length: 8 }, (_, index) => index));
  };

  return (
    <React.Fragment>
      <Container
        className='pt-4 m-0 d-flex align-items-center justify-content-center'
        style={{ flexDirection: 'column', width: '490px', textAlign: 'center' }}>
        <span className='text-black font-size-22' style={{ color: primaryTextState }}>
          Congratulations🎊
        </span>
        <span className='text-black font-size-16 m-4' style={{ color: primaryTextState }}>
          You have completed your Lite Application. Would you like to finish here, or
          would you like to continue?
        </span>
      </Container>
      <div className='d-flex justify-content-between align-items-center mt-5 mr-3 ml-3'>
        <i
          className='mdi mdi-chevron-double-left font-size-32 ml-1 cursor-pointer'
          onClick={prevPage}
          style={{ color: primaryTextState }}
        />
        <div>
          <button
            className='btn btn-status-change text-white mr-2'
            type='submit'
            style={{ width: '142px', height: '50px' }}
            onClick={(values) => handleSubmitLiteApplication(values)}>
            Submit
          </button>
          <button
            className='btn application-button text-white'
            type='submit'
            onClick={(values) => handleSubmit(values)}>
            Continue
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}

export default LiteApplication;
