import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { StringParam, useQueryParam } from 'use-query-params';
import DrawerPage from '../../DrawerPage';
import { useHistory } from 'react-router-dom';
import ApplicationDetails from './ApplicationDetails';
import Notification from '../ExchangeApplication/Notification';
import toast, { Toaster } from 'react-hot-toast';
import { GetApplicationResponse } from '../../../services/Application';
import { notificationMessageFunction } from '../../../constants/notificationConst';
import ResponsePage from './ResponsePage';
import { useThemeContext } from '../../ThemeProvider';

function ViewOnrampApplication(props) {
  const { paramsValue, containerColorState, primaryColorState, primaryTextState } =
    useThemeContext();

  const [page, setPage] = useState('response');
  const history = useHistory();
  const [applicationIdData] = useQueryParam('application_id', StringParam);
  const [order, setOrder] = useState(null);
  const [first, setFirst] = useState(0);
  const [currentPageData, setCurrentPageData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [applicationId, setApplicationId] = useState(applicationIdData || null);
  const [allResponses, setAllResponses] = useState([]);
  const [responsePage, setResponsePage] = useState(1);
  const [statusSuggestions, setStatusSuggestions] = useState([
    {
      id: 'REQUEST_ACTION',
      name: 'Action',
      icon: 'fas fa-unlock',
      color: 'black',
      checked: false
    },
    {
      id: 'ACCEPT',
      name: 'Accept',
      icon: 'fas fa-check-circle',
      color: 'green',
      checked: false
    },
    { id: 'KYB', name: 'KYB', icon: 'fas fa-lock', color: 'black', checked: false },
    {
      id: 'QUOTE',
      name: 'Quote',
      icon: 'fas fa-quote-left',
      color: 'black',
      checked: false
    },
    {
      id: 'PAID',
      name: 'Paid',
      icon: 'fas fa-thumbs-up',
      color: 'green',
      checked: false
    },
    {
      id: 'ADD_QUESTIONS',
      name: 'Add Question',
      icon: 'fas fa-question',
      color: 'black',
      checked: false
    }
  ]);

  const GetApplicationResponseFunc = (applicationId) => {
    const status = statusSuggestions
      .filter((suggestion) => suggestion.checked)
      .map((suggestion) => suggestion.id);
    GetApplicationResponse({ applicationId, order, status })
      .then((res) => {
        const responses = get(res, 'data.data.data', []);
        const count = get(res, 'data.data.count', []);
        const slicedData = get(res, 'data.data.data', []).slice(first, first + 10);
        setCurrentPageData(slicedData);
        setTotalCount(count);
        setLoading(false);
        setAllResponses(responses);
      })
      .catch((err) => {
        const errorMessage = get(err, 'data.error.message');
        toast.error(notificationMessageFunction(errorMessage), {
          style: { fontSize: '12px' }
        });
      });
  };

  useEffect(() => {
    GetApplicationResponseFunc(applicationId);
  }, [applicationId, order, statusSuggestions]);

  return (
    <React.Fragment>
      <div className='widget-container'>
        <div
          className='d-flex card py-2'
          style={{ backgroundColor: containerColorState }}>
          <span
            className='font-size-16 font-weight-medium d-flex justify-content-center align-items-center mt-1'
            style={{ color: primaryTextState }}>
            Onramp Application
          </span>
          <DrawerPage />
          <hr className='ml-2 mr-2 mt-2 mb-2' />
          <div style={{ maxHeight: '750px', overflowY: 'auto', overflowX: 'hidden' }}>
            <Nav tabs className='nav-tabs-custom nav-justified'>
              <NavItem>
                <NavLink
                  className='cursor-pointer'
                  onClick={() => setPage('application')}>
                  <span
                    className='font-size-10 font-weight-medium'
                    style={{ color: '#2770C6' }}>
                    App
                    <span
                      style={{
                        paddingBottom: '10px',
                        borderBottom: `${
                          page === 'application' ? '2px solid #2770C6' : ''
                        }`
                      }}>
                      lication P
                    </span>
                    age
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className='cursor-pointer' onClick={() => setPage('response')}>
                  <span
                    className='font-size-10 font-weight-medium'
                    style={{ color: '#2770C6' }}>
                    R
                    <span
                      style={{
                        paddingBottom: '10px',
                        borderBottom: `${page === 'response' ? '2px solid #2770C6' : ''}`
                      }}>
                      esponse
                    </span>
                    s
                  </span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className='cursor-pointer'
                  onClick={() => setPage('notification')}>
                  <span
                    className='font-size-10 font-weight-medium'
                    style={{ color: '#2770C6' }}>
                    No
                    <span
                      style={{
                        paddingBottom: '10px',
                        borderBottom: `${
                          page === 'notification' ? '2px solid #2770C6' : ''
                        }`
                      }}>
                      tificatio
                    </span>
                    ns
                  </span>
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={page}>
              <TabPane tabId='application'>
                <div className='mb-2'>
                  <ApplicationDetails
                    props={props}
                    setApplicationId={setApplicationId}
                    applicationId={applicationId}
                    primaryTextState={primaryTextState}
                    primaryColorState={primaryColorState}
                  />
                </div>
              </TabPane>
              <TabPane tabId='response'>
                <div className='mb-2'>
                  <ResponsePage
                    GetApplicationResponseFunc={GetApplicationResponseFunc}
                    page={responsePage}
                    first={first}
                    loading={loading}
                    setFirst={setFirst}
                    setPage={setResponsePage}
                    allResponses={allResponses}
                    order={order}
                    statusSuggestions={statusSuggestions}
                    currentPageData={currentPageData}
                    setCurrentPageData={setCurrentPageData}
                    setApplicationId={setApplicationId}
                    primaryTextState={primaryTextState}
                    containerColorState={containerColorState}
                    primaryColorState={primaryColorState}
                    paramsValue={paramsValue}
                  />
                </div>
              </TabPane>
              <TabPane tabId='notification'>
                <div className='mb-2'>
                  <Notification
                    props={props}
                    page={page}
                    primaryTextState={primaryTextState}
                    primaryColorState={primaryColorState}
                  />
                </div>
              </TabPane>
            </TabContent>
          </div>
          <Toaster position='bottom-center' reverseOrder={false} />
        </div>
      </div>
    </React.Fragment>
  );
}

export default ViewOnrampApplication;
