import React, { useEffect, useState } from 'react';
import { Col, Collapse, Container, FormGroup, Label } from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import get from 'lodash/get';
import { constants as CONST } from '../../../constants/application';
import { constants as ERROR_CONST } from '../../../constants/errorMessage';
import { GetApplication, UpdateApplication } from '../../../services/Application';
import { notificationMessageFunction } from '../../../constants/notificationConst';
import moment from 'moment';
import AuthInput from '../../../Custom/AuthInput';

function Step5({
  setOnrampCompleteStep,
  nextPage,
  applicationId,
  prevPage,
  isUpdateOnramp = false,
  isSectionCollapsed,
  toggleSection,
  applicationData,
  setApplicationData,
  primaryTextState
}) {
  const [initialValue, setInitialValue] = useState({
    consent_info: '',
    consent_OFAC: '',
    consent_region: '',
    consent_region_name: '',
    consent_age: '',
    applicant_email: '',
    applicant_role: '',
    applicant_full_name: '',
    application_date: ''
  });

  useEffect(() => {
    if (applicationId !== '' && !isUpdateOnramp) {
      GetApplication({ application_id: applicationId }).then((res) => {
        const application = get(res, 'data.data.data[0]', '');
        setApplicationData(application);
        const {
          consent_info,
          consent_OFAC,
          consent_age,
          consent_region,
          consent_region_name,
          applicant_email,
          applicant_role,
          applicant_full_name,
          application_date
        } = application;

        if (
          consent_info !== undefined &&
          consent_OFAC !== undefined &&
          consent_age !== undefined &&
          consent_region !== undefined
        ) {
          setInitialValue({
            consent_info: consent_info.toString(),
            consent_OFAC: consent_OFAC !== null ? consent_OFAC.toString() : 'null',
            consent_age: consent_age !== null ? consent_age.toString() : 'null',
            consent_region: consent_region.toString(),
            consent_region_name: consent_region_name,
            applicant_email: applicant_email,
            applicant_role: applicant_role,
            applicant_full_name: applicant_full_name,
            application_date: moment(application_date).format('YYYY-MM-DD')
          });
        }
      });
    } else {
      if (applicationData) {
        const {
          consent_info,
          consent_OFAC,
          consent_age,
          consent_region,
          consent_region_name,
          applicant_email,
          applicant_role,
          applicant_full_name,
          application_date
        } = applicationData;

        if (
          consent_info !== undefined &&
          consent_OFAC !== undefined &&
          consent_age !== undefined &&
          consent_region !== undefined
        ) {
          setInitialValue({
            consent_info: consent_info.toString(),
            consent_OFAC: consent_OFAC !== null ? consent_OFAC.toString() : 'null',
            consent_age: consent_age !== null ? consent_age.toString() : 'null',
            consent_region: consent_region.toString(),
            consent_region_name: consent_region_name,
            applicant_email: applicant_email,
            applicant_role: applicant_role,
            applicant_full_name: applicant_full_name,
            application_date: moment(application_date).format('YYYY-MM-DD')
          });
        }
      }
    }
  }, [applicationData, applicationId, isUpdateOnramp]);

  const handleSubmit = (values) => {
    const data = {
      application_status: 'COMPLETE',
      consent_info: values.consent_info,
      consent_OFAC: values.consent_OFAC !== 'null' ? values.consent_OFAC : null,
      consent_region: values.consent_region,
      consent_region_name: values.consent_region_name,
      consent_age: values.consent_age !== 'null' ? values.consent_age : null,
      applicant_email: values.applicant_email,
      applicant_role: values.applicant_role,
      applicant_full_name: values.applicant_full_name,
      application_date: values.application_date
    };

    UpdateApplication(applicationId, data)
      .then((res) => {
        setOnrampCompleteStep(Array.from({ length: 5 }, (_, index) => index));

        nextPage();
      })
      .catch((err) => {
        const errorMessage = get(err, 'data.error.message');
        toast.error(notificationMessageFunction(errorMessage), {
          style: { fontSize: '12px' }
        });
      });
  };

  const certificateDetailSchema = Yup.object().shape({
    consent_info: Yup.string().required('Field is required'),
    consent_OFAC: Yup.string().required('Field is required'),
    consent_region: Yup.string().required('Field is required'),
    consent_region_name: Yup.string('Field is required'),
    consent_age: Yup.string().required('Field is required'),
    applicant_email: Yup.string()
      .required('Email is required')
      .email(ERROR_CONST.INVALID_EMAIL),
    applicant_role: Yup.string().required('Role is required'),
    applicant_full_name: Yup.string().required('Full Name is required'),
    application_date: Yup.string().required('Application Date is required')
  });

  return (
    <React.Fragment>
      <div onClick={isSectionCollapsed ? toggleSection : undefined}>
        {isUpdateOnramp ? (
          <>
            <div
              className='cursor-pointer col-12 font-size-14 ml-2 d-flex justify-content-between align-items-center'
              onClick={toggleSection}>
              <span
                className='text-black font-weight-400'
                style={{ color: primaryTextState }}>
                Certifications
              </span>
              <i
                className={`font-size-22 mdi mdi-chevron-${
                  isSectionCollapsed === true ? 'up' : 'down'
                }`}
                style={{ color: primaryTextState }}
              />
            </div>
          </>
        ) : (
          <div className='col-12 font-size-16 pb-2 ml-2'>
            <span
              className='text-black font-weight-400'
              style={{ color: primaryTextState }}>
              Certifications
            </span>
          </div>
        )}
        <Collapse isOpen={!isSectionCollapsed}>
          <Container className='pt-4 col-12 col-sm-auto'>
            <div>
              <Formik
                enableReinitialize={true}
                initialValues={initialValue}
                validationSchema={certificateDetailSchema}
                onSubmit={handleSubmit}>
                {({ errors, touched, values, handleChange, setFieldTouched }) => (
                  <Form>
                    <Col>
                      <FormGroup className='mb-4'>
                        <div className='font-size-12'>
                          <Label
                            htmlFor='consent_info'
                            className='text-black font-weight-normal'
                            style={{ color: primaryTextState }}>
                            I hereby certify, to the best of my knowledge, that the
                            information provided in this questionnaire is complete and
                            accurate.
                          </Label>
                        </div>
                        <div className='d-flex direction-row'>
                          <div className='custom-control form-check mr-4'>
                            <Label
                              className='form-check-label font-size-12 mt-2 d-flex align-items-center'
                              style={{ color: primaryTextState }}>
                              <Field
                                name='consent_info'
                                type='radio'
                                value='true'
                                className='form-check-input'
                                style={{
                                  height: '1.725rem',
                                  width: '1rem',
                                  marginRight: '5px'
                                }}
                              />
                              <span
                                style={{
                                  marginTop: '.4rem'
                                }}>
                                {CONST.NA}
                              </span>
                            </Label>
                          </div>
                          <div className='custom-control form-check ml-4'>
                            <Label
                              className='form-check-label font-size-12 ml-2 mt-2 d-flex align-items-center'
                              style={{ color: primaryTextState }}>
                              <Field
                                name='consent_info'
                                type='radio'
                                value='false'
                                className='form-check-input'
                                style={{
                                  height: '1.725rem',
                                  width: '1rem',
                                  marginRight: '5px'
                                }}
                              />
                              <span
                                style={{
                                  marginTop: '.4rem'
                                }}>
                                {CONST.NO}
                              </span>
                            </Label>
                          </div>
                        </div>

                        {errors['consent_info'] && touched['consent_info'] && (
                          <span
                            style={{ fontSize: 12 }}
                            className='text-left text-danger'>
                            {errors['consent_info']}
                          </span>
                        )}
                      </FormGroup>
                    </Col>

                    <Col>
                      <FormGroup className='mb-4'>
                        <div className='font-size-12'>
                          <Label
                            htmlFor='consent_OFAC'
                            className='text-black font-weight-normal'
                            style={{ color: primaryTextState }}>
                            I hereby certify that the partner entity does not interact
                            with or provide services to persons or entities subject to
                            sanctions administered by the US Office of Foreign Assets
                            Control ("OFAC") or those imposed by the UK Office of
                            Financial Sanctions Implementation ("OFSI").
                          </Label>
                        </div>
                        <div className='d-flex direction-row'>
                          <div className='custom-control form-check  mr-4'>
                            <Label
                              className='form-check-label font-size-12 mt-2 d-flex align-items-center'
                              style={{ color: primaryTextState }}>
                              <Field
                                name='consent_OFAC'
                                type='radio'
                                className='form-check-input'
                                value='true'
                                style={{
                                  height: '1.725rem',
                                  width: '1rem',
                                  marginRight: '5px'
                                }}
                              />
                              <span
                                style={{
                                  marginTop: '.4rem'
                                }}>
                                {CONST.YES}
                              </span>
                            </Label>
                          </div>
                          <div className='custom-control form-check ml-4 mr-4'>
                            <Label
                              className='form-check-label font-size-12 ml-2 mt-2 d-flex align-items-center'
                              style={{ color: primaryTextState }}>
                              <Field
                                name='consent_OFAC'
                                type='radio'
                                className='form-check-input'
                                value='false'
                                style={{
                                  height: '1.725rem',
                                  width: '1rem',
                                  marginRight: '5px'
                                }}
                              />
                              <span
                                style={{
                                  marginTop: '.4rem'
                                }}>
                                {CONST.NO}
                              </span>
                            </Label>
                          </div>
                          <div className='custom-control form-check  ml-4 mr-4'>
                            <Label
                              className='form-check-label font-size-12 ml-2 mt-2 d-flex align-items-center'
                              style={{ color: primaryTextState }}>
                              <Field
                                name='consent_OFAC'
                                type='radio'
                                className='form-check-input'
                                value='null'
                                style={{
                                  height: '1.725rem',
                                  width: '1rem',
                                  marginRight: '5px'
                                }}
                              />
                              <span
                                style={{
                                  marginTop: '.35rem'
                                }}>
                                {CONST.NA}
                              </span>
                            </Label>
                          </div>
                        </div>
                        {errors['consent_OFAC'] && touched['consent_OFAC'] && (
                          <span
                            style={{ fontSize: 12 }}
                            className='text-left text-danger'>
                            {errors['consent_OFAC']}
                          </span>
                        )}
                      </FormGroup>
                    </Col>

                    <Col>
                      <FormGroup className='mb-4'>
                        <div className='font-size-12'>
                          <Label
                            htmlFor='consent_region'
                            className='text-black font-weight-normal'
                            style={{ color: primaryTextState }}>
                            I hereby certify that the partner entity does not do business
                            in the following jurisdictions: Cuba, Iran, North Korea,
                            Syria, Crimea region of Ukraine, Donetsk region of Ukraine,
                            Luhansk region of Ukraine, or the Russian Federation.
                          </Label>
                        </div>
                        <div className='custom-control form-check'>
                          <Label
                            className='form-check-label mt-2 font-size-12 d-flex align-items-center'
                            style={{ color: primaryTextState }}>
                            <Field
                              name='consent_region'
                              type='radio'
                              value='true'
                              className='form-check-input'
                              style={{
                                height: '1.725rem',
                                width: '1rem',
                                marginRight: '5px'
                              }}
                            />
                            <span
                              style={{
                                marginTop: '.4rem'
                              }}>
                              {CONST.YES}
                            </span>
                          </Label>
                        </div>

                        <div className='custom-control form-check p-0'>
                          <Label
                            className='form-check-label font-size-12 mt-2 mb-2 ml-1'
                            style={{ color: primaryTextState }}>
                            I do business in these regions
                          </Label>
                        </div>
                        <AuthInput
                          setFieldTouched={setFieldTouched}
                          withTouchError={true}
                          name='consent_region_name'
                          type='text'
                          className={'font-size-12 '}
                          values={values}
                          handleChange={handleChange}
                          placeholder={CONST.ENTER_REGION}
                          errors={errors}
                          touched={touched}
                        />
                        {errors['consent_region'] && touched['consent_region'] && (
                          <span
                            style={{ fontSize: 12 }}
                            className='text-left text-danger'>
                            {errors['consent_region']}
                          </span>
                        )}
                      </FormGroup>
                    </Col>

                    <Col>
                      <FormGroup className='mb-4'>
                        <div className='font-size-12'>
                          <Label
                            htmlFor='consent_age'
                            className='text-black font-weight-normal'
                            style={{ color: primaryTextState }}>
                            I acknowledge that Rampnalysis and its Associated businesses
                            may not provide services to sanctioned individuals, sanctioned
                            jurisdictions, nor minors (individuals under the age of 18).
                          </Label>
                        </div>
                        <div className='d-flex direction-row'>
                          <div className='custom-control form-check  mr-4'>
                            <Label
                              className='form-check-label mt-2 font-size-12 d-flex align-items-center'
                              style={{ color: primaryTextState }}>
                              <Field
                                name='consent_age'
                                type='radio'
                                value='true'
                                className='form-check-input'
                                style={{
                                  height: '1.725rem',
                                  width: '1rem',
                                  marginRight: '5px'
                                }}
                              />
                              <span
                                style={{
                                  marginTop: '.4rem'
                                }}>
                                {CONST.YES}
                              </span>
                            </Label>
                          </div>
                          <div className='custom-control form-check ml-4 mr-4'>
                            <Label
                              className='form-check-label ml-2 mt-2 font-size-12  d-flex align-items-center'
                              style={{ color: primaryTextState }}>
                              <Field
                                name='consent_age'
                                type='radio'
                                value='false'
                                className='form-check-input'
                                style={{
                                  height: '1.725rem',
                                  width: '1rem',
                                  marginRight: '5px'
                                }}
                              />
                              <span
                                style={{
                                  marginTop: '.4rem'
                                }}>
                                {CONST.NO}
                              </span>
                            </Label>
                          </div>
                          <div className='custom-control form-check  ml-4 mr-4'>
                            <Label
                              className='form-check-label ml-2 font-size-12 mt-2 d-flex align-items-center'
                              style={{ color: primaryTextState }}>
                              <Field
                                name='consent_age'
                                type='radio'
                                value='null'
                                className='form-check-input'
                                style={{
                                  height: '1.725rem',
                                  width: '1rem',
                                  marginRight: '5px'
                                }}
                              />
                              <span
                                style={{
                                  marginTop: '.4rem'
                                }}>
                                {CONST.NA}
                              </span>
                            </Label>
                          </div>
                        </div>
                        <div className='d-flex align-items-center'>
                          {errors['consent_age'] && touched['consent_age'] && (
                            <span
                              style={{ fontSize: 12 }}
                              className='text-left text-danger pl-3'>
                              {errors['consent_age']}
                            </span>
                          )}
                        </div>
                      </FormGroup>
                    </Col>

                    <Col>
                      <FormGroup className='mb-2'>
                        <div className='font-size-12'>
                          <Label
                            htmlFor='applicant_full_name'
                            className='text-black font-weight-normal'
                            style={{ color: primaryTextState }}>
                            {CONST.FULL_NAME}
                          </Label>
                        </div>

                        <div>
                          <AuthInput
                            setFieldTouched={setFieldTouched}
                            withTouchError={true}
                            type='text'
                            className={'font-size-12 '}
                            name='applicant_full_name'
                            values={values}
                            handleChange={handleChange}
                            placeholder={CONST.ENTER_FULL_NAME}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup className='mb-2'>
                        <div className='font-size-12'>
                          <Label
                            htmlFor='applicant_email'
                            className='text-black font-weight-normal'
                            style={{ color: primaryTextState }}>
                            {CONST.EMAIL}
                          </Label>
                        </div>
                        <div>
                          <AuthInput
                            setFieldTouched={setFieldTouched}
                            withTouchError={true}
                            type='text'
                            className={'font-size-12 '}
                            name='applicant_email'
                            placeholder={CONST.ENTER_EMAIL}
                            values={values}
                            handleChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                      </FormGroup>
                    </Col>

                    <Col>
                      <FormGroup className='mb-2'>
                        <div className='font-size-12'>
                          <Label
                            htmlFor='applicant_role'
                            className='text-black font-weight-normal'
                            style={{ color: primaryTextState }}>
                            {CONST.ROLE}
                          </Label>
                        </div>

                        <div>
                          <AuthInput
                            setFieldTouched={setFieldTouched}
                            withTouchError={true}
                            type='text'
                            className={'font-size-12 '}
                            name='applicant_role'
                            values={values}
                            handleChange={handleChange}
                            placeholder={CONST.ENTER_ROLE}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup className='mb-2'>
                        <div className='font-size-12'>
                          <Label
                            htmlFor='application_date'
                            className='text-black font-weight-normal'
                            style={{ color: primaryTextState }}>
                            {CONST.DATE}
                          </Label>
                        </div>
                        <div>
                          <AuthInput
                            setFieldTouched={setFieldTouched}
                            withTouchError={true}
                            type='date'
                            className={'font-size-12 '}
                            name='application_date'
                            values={values}
                            handleChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                    <div className='d-flex justify-content-between align-items-center mt-5 mr-2 ml-2'>
                      <i
                        className='mdi mdi-chevron-double-left font-size-32 ml-1 cursor-pointer'
                        onClick={prevPage}
                        style={{ color: primaryTextState }}
                      />
                      <button
                        className='btn application-button text-white'
                        type='submit'
                        onSubmit={(values) => handleSubmit(values)}>
                        Next
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Container>
        </Collapse>
      </div>
    </React.Fragment>
  );
}

export default Step5;
